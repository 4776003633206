import React, { useCallback, useMemo } from 'react';
import { LazyIframe } from '@atlassian/jira-forge-ui-iframe-generic';
import type {
	WorkflowValidatorModule,
	WorkflowConditionModule,
	WorkflowPostFunctionModule,
} from '@atlassian/jira-forge-ui-types';
import { isCustomUIExtension } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import Placeholder from '@atlassian/jira-placeholder';
import type { LazyPropsWithExtensionDataEntryPoint } from '../../../types';
import { Skeleton } from '../skeleton';
import type {
	BridgeSubmitParam,
	PropsCreateEditValidator,
	PropsViewValidator,
	PropsViewCondition,
	PropsCreateEditCondition,
	PropsViewPostFunction,
	PropsCreateEditPostFunction,
} from './types';

const skeletonConfig = [{ width: 100 }, { width: 75 }];

export type PropsViewValidatorWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsViewValidator> & {
		module: WorkflowValidatorModule;
	};

export type PropsViewConditionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsViewCondition> & {
		module: WorkflowConditionModule;
	};

export type PropsViewPostFunctionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsViewPostFunction> & {
		module: WorkflowPostFunctionModule;
	};

type PropsView =
	| PropsViewValidatorWithModule
	| PropsViewConditionWithModule
	| PropsViewPostFunctionWithModule;

export type PropsCreateEditValidatorWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsCreateEditValidator> & {
		module: WorkflowValidatorModule;
	};

export type PropsCreateEditConditionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsCreateEditCondition> & {
		module: WorkflowConditionModule;
	};

export type PropsCreateEditPostFunctionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsCreateEditPostFunction> & {
		module: WorkflowPostFunctionModule;
	};

type PropsCreateEdit =
	| PropsCreateEditValidatorWithModule
	| PropsCreateEditConditionWithModule
	| PropsCreateEditPostFunctionWithModule;

export const LazyWorkflowView = (props: PropsView) => {
	const { entryPoint, extension, extensionData, module, ...restProps } = props;
	const loadingComponent = <Skeleton skeletonConfig={skeletonConfig} />;
	const extraProps = {
		entryPoint,
		extension,
		loadingComponent,
		module,
		extensionData: {
			...extensionData,
			type: module,
			entryPoint,
		},
	};

	return (
		<Placeholder name="workflow-view" fallback={loadingComponent}>
			{isCustomUIExtension(extension, entryPoint) && <LazyIframe {...restProps} {...extraProps} />}
		</Placeholder>
	);
};

export const LazyWorkflowCreateEdit = (props: PropsCreateEdit) => {
	const { entryPoint, extension, extensionData, module, onSetConfigurationCallback, ...restProps } =
		props;
	const loadingComponent = <Skeleton skeletonConfig={skeletonConfig} />;
	const extraProps = {
		entryPoint,
		extension,
		loadingComponent,
		module,
		extensionData: {
			...extensionData,
			type: module,
			entryPoint,
		},
	};

	const setWorkflowRuleConfigurationCallback = useCallback(
		(param: BridgeSubmitParam): void => {
			if (!onSetConfigurationCallback) {
				return;
			}
			const { onConfigure } = param?.data || {};
			const callbackType = typeof onConfigure;
			if (callbackType !== 'function') {
				throw new Error('Invalid "onConfigure" callback - function expected');
			}

			onSetConfigurationCallback(async () => {
				const config = await onConfigure();
				return config;
			});
		},
		[onSetConfigurationCallback],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const customBridgeMethods: any = useMemo(
		() => ({ setWorkflowRuleConfigurationCallback }),
		[setWorkflowRuleConfigurationCallback],
	);

	return (
		<Placeholder name="workflow-create-edit" fallback={loadingComponent}>
			{isCustomUIExtension(extension, entryPoint) && (
				<LazyIframe {...restProps} {...extraProps} customBridgeMethods={customBridgeMethods} />
			)}
		</Placeholder>
	);
};
