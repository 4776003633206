import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const jiraBusinessProjectRedirectRoute = {
	group: ROUTE_GROUPS.CLASSIC_BUSINESS,
	name: ROUTE_NAMES.CLASSIC_BUSINESS_REDIRECT,
	path: '/jira/core/projects/:projectKey',
	exact: true,
	isRedirect: true,
};

export const jiraBusinessOverviewRedirectRoute = {
	group: ROUTE_GROUPS.CLASSIC_BUSINESS,
	name: ROUTE_NAMES.CLASSIC_BUSINESS_OVERVIEW_REDIRECT,
	path: '/jira/core/overviews/:overviewId',
	exact: true,
	isRedirect: true,
};
