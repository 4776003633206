import { UNSAFE_noExposureExp, expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { SUPPORTED_PERSONA_EXPERIENCES, type PersonaExperience } from '../../services/model/types';

export const getTailoredOnboardingExperimentCohort = () => {
	const [expConfig] = UNSAFE_noExposureExp('jwm_tailored_onboarding');
	return expConfig.get('cohort', 'not-enrolled');
};

export const isUserEnrolledInTailoredOnboardingExperiment = () => {
	if (fg('jwm_tailored_onboarding_gate')) {
		const userCohort = getTailoredOnboardingExperimentCohort();
		return userCohort !== 'not-enrolled';
	}
	return false;
};

export const isTailoredQuickstartEnabled = (personaExperience?: PersonaExperience): boolean =>
	Boolean(
		personaExperience &&
			SUPPORTED_PERSONA_EXPERIENCES.includes(personaExperience) &&
			expValEquals('jwm_tailored_onboarding', 'cohort', 'variation'),
	);
