import React, { useEffect } from 'react';
import { Text } from '@atlaskit/primitives';
import { useExperienceSuccess as useExperienceSuccessDI } from '@atlassian/jira-experience-tracker';
import { useFavoriteProjects as useFavoriteProjectsDI } from '@atlassian/jira-favorites-store';
import { useIntlV2 as useIntlDI } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common';
import { useRecentProjects as useRecentProjectsDI } from '@atlassian/jira-recents-store';
import { useIsAnonymous as useIsAnonymousDI } from '@atlassian/jira-tenant-context-controller';
import { EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../../common/constants';
import { MenuInfo as MenuInfoDI } from '../../../../common/ui/menu/info';
import { useProjectNavItems as useProjectNavItemsDI } from '../../../../controllers/items';
import defaultEmptyImg from './assets/project-empty.svg';
import messages from './messages';
import type { EmptyProps } from './types';

const learnMoreHref =
	'https://confluence.atlassian.com/adminjiracloud/creating-editing-deleting-and-hiding-projects-844500729.html';

export const Empty = ({
	MenuInfo = MenuInfoDI,
	children,
	testIdPrefix,
	useExperienceSuccess = useExperienceSuccessDI,
	useFavorite = useFavoriteProjectsDI,
	useIntl = useIntlDI,
	useIsAnonymous = useIsAnonymousDI,
	useItems = useProjectNavItemsDI,
	useRecent = useRecentProjectsDI,
}: EmptyProps) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});

	const text = (
		<>
			<div>
				<Text as="p">{formatMessage(messages.emptyText)}</Text>
			</div>
			<div>
				<a href={learnMoreHref} target="_blank" rel="noopener noreferrer">
					{formatMessage(messages.learnMore)}
				</a>
			</div>
		</>
	);

	const [
		{
			isFetching: isFetchingFavorite,
			hasSuccessOnce: hasSuccessOnceFavorite,
			fetchError: fetchErrorFavorite,
		},
	] = useFavorite();
	const [
		{
			isFetching: isFetchingRecent,
			hasSuccessOnce: hasSuccessOnceRecent,
			fetchError: fetchErrorRecent,
		},
	] = useRecent();

	const { favoriteItems, recentItems } = useItems();

	const notEmpty =
		favoriteItems.length || //  should show fav items
		recentItems.length || // should show recent items
		(isFetchingFavorite && !hasSuccessOnceFavorite) || // should show fav skeleton
		(isFetchingRecent && !hasSuccessOnceRecent) || // should show recent skeleton
		fetchErrorFavorite || // should show error
		fetchErrorRecent; // should show error

	// it appears that when fetch error get thrown in loadstatus, the promise is still pending here
	// let's take the most simple approach for now
	const shouldShowEmptyForAnonymous = isAnonymous;

	const testId = testIdConcat(testIdPrefix, 'empty');

	useEffect(() => {
		const isSuccess = hasSuccessOnceFavorite && hasSuccessOnceRecent;
		if (isSuccess) {
			onSuccess();
		}
	}, [hasSuccessOnceFavorite, hasSuccessOnceRecent, onSuccess]);

	return notEmpty && !shouldShowEmptyForAnonymous ? (
		<>{children}</>
	) : (
		<MenuInfo
			imgAltText={formatMessage(messages.emptyImg)}
			imgSrc={defaultEmptyImg}
			imgHeight={138}
			testId={testId}
			text={text}
		/>
	);
};
