import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { UPSELL_PAGE_DISMISSED_PROPERTY_KEY } from '../../constants';
import { deleteUserProperties } from '../../rest/delete-user-property';
import type { CmdbUpsellPageStoreAction } from '../../types';

export const enablePage =
	(): CmdbUpsellPageStoreAction =>
	async ({ setState }, { atlassianAccountId }) => {
		// Optimistically show Assets in the navigation bar immediately
		setState({ userHasDismissed: false });

		try {
			atlassianAccountId &&
				(await deleteUserProperties('', atlassianAccountId, UPSELL_PAGE_DISMISSED_PROPERTY_KEY));
		} catch (error) {
			fireErrorAnalytics({
				error: error instanceof Error ? error : undefined,
				meta: {
					id: 'fetchUpsellEnabledProperty',
					packageName: 'jiraCmdbUpsellPage',
				},
			});
		}
	};
