import type { Action } from 'react-sweet-state';
import { fireAPIErrorAnalytics } from '@atlassian/jira-onboarding-quickstart-core/src/utils/analytics';
import type { CloudId } from '@atlassian/jira-shared-types';
import type { OrgId } from '../../../../common/types';
import { cachedEndpointCall } from '../../../cached-endpoint';
import type { State } from '../../../cached-endpoint/types';
import { getLinkedOrg } from './endpoint';

export const getOrgId = async (cloudId: CloudId): Promise<OrgId | null> => {
	try {
		const result: {
			id: OrgId;
		} = await getLinkedOrg(cloudId);

		return result.id;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireAPIErrorAnalytics(error, 'getLinkedOrg');
	}
	return null;
};

export const fetchOrgId: (arg1: CloudId) => Action<State<OrgId>> = cachedEndpointCall(getOrgId);
