import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const findAppsRoute = {
	group: ROUTE_GROUPS.MARKETPLACE,
	name: ROUTE_NAMES.MARKETPLACE_INDEX,
	path: '/jira/marketplace',
};

export const appRequestRoute = {
	group: ROUTE_GROUPS.MARKETPLACE,
	name: ROUTE_NAMES.MARKETPLACE_APP_REQUESTS,
	path: '/jira/marketplace/manage/app-requests',
};

export const promotionsRoute = {
	group: ROUTE_GROUPS.MARKETPLACE,
	name: ROUTE_NAMES.MARKETPLACE_PROMOTIONS,
	path: '/jira/marketplace/manage/promotions',
};
