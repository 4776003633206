import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	rootTitle: {
		// eslint-disable-next-line jira/i18n/id-named-by-package
		id: 'software-onboarding-quickstart.services.tasks.config.jwm.root-title.no-translate',
		defaultMessage: 'Quickstart',
		description: 'Initial title of Quickstart for work management projects',
	},
});
