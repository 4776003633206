import { useCallback as useCallbackDI } from 'react';
import { useOverflowStatus as useOverflowStatusDI } from '@atlaskit/atlassian-navigation';
import {
	useAnalyticsEvents as useAnalyticsEventsDI,
	fireUIAnalytics as fireUIAnalyticsDI,
	type Attributes as AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import type { UseNavigationItemAnalyticsType } from './types';

export const useNavigationItemAnalytics: UseNavigationItemAnalyticsType = ({
	navigationItemId,
	useAnalyticsEvents = useAnalyticsEventsDI,
	fireUIAnalytics = fireUIAnalyticsDI,
	useOverflowStatus = useOverflowStatusDI,
	useCallback = useCallbackDI,
}) => {
	const { isVisible } = useOverflowStatus();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		(attributes = {}) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'navigationItem',
			});

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			fireUIAnalytics(analyticsEvent, navigationItemId, {
				// @ts-expect-error - TS2698 - Spread types may only be created from object types.
				...attributes,
				isInMore: !isVisible,
				navigationLayer: 'global',
			} as AnalyticsAttributes);
		},
		[createAnalyticsEvent, fireUIAnalytics, isVisible, navigationItemId],
	);
};
