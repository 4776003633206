import React, { useMemo } from 'react';
import Avatar from '@atlaskit/avatar';
import { HeadingItem } from '@atlaskit/menu';
import { PROJECTS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat, getLinkFromProject } from '@atlassian/jira-navigation-apps-common';
import { DEFAULT_LOAD_COUNT_FAV } from '../../../../common/constants';
import { FavouriteButton } from '../../../../common/ui/menu/favourite-button';
import { MenuItem } from '../../../../common/ui/menu/item';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list';
import { MenuItemViewAll } from '../../../../common/ui/menu/view-all';
import { useProjectNavItems as useItems } from '../../../../controllers/items';
import messages from './messages';
import type { FavoriteContentProps } from './types';

export const FavoriteContent = ({ testIdPrefix }: FavoriteContentProps) => {
	const { formatMessage } = useIntl();
	const { favoriteItems, changeFavourite: onChangeFavourite, changedItems } = useItems();

	const itemsToShow = useMemo(
		() => favoriteItems.slice(0, DEFAULT_LOAD_COUNT_FAV),
		[favoriteItems],
	);

	const testId = testIdConcat(testIdPrefix, 'items-starred');

	const projectsUrl = '/jira/projects?page=1&sortKey=favourite&sortOrder=DESC';

	return favoriteItems.length > 0 ? (
		<span data-testid={testId}>
			<HeadingItem testId={testIdConcat(testId, 'heading')}>
				{formatMessage(messages.starred)}
			</HeadingItem>
			<MenuList>
				{itemsToShow.map((item, idx) => {
					const { url, id, favourite: isFavourite, metadata, title } = item;
					const contextItem = changedItems[item.id.toString()];
					const isPending =
						contextItem && contextItem.pending === true ? contextItem.pending : false;

					const { params, query, route: to } = getLinkFromProject(item);

					return (
						<MenuListItem key={id} ariaLabel={formatMessage(messages.starred)}>
							<MenuItem
								analytics={{
									actionSubjectId: 'projectsNavigationMenuItem',
									menuItemId: id,
									menuItemType: 'starred',
									position: idx,
									starred: Boolean(isFavourite),
								}}
								description={metadata}
								href={url}
								iconBefore={
									<Avatar
										appearance="square"
										size="small"
										borderColor="transparent"
										src={item.avatarUrl}
									/>
								}
								iconAfter={
									<FavouriteButton
										id={String(id)}
										isFavourite={Boolean(isFavourite)}
										favouriteItemName={title}
										isPending={isPending}
										menuItemType="starred"
										onChangeFavourite={onChangeFavourite}
										type={PROJECTS_ITEM_TYPE}
									/>
								}
								params={params}
								query={query}
								testId={testIdConcat(testId, `item-${idx}`)}
								to={to}
							>
								{title}
							</MenuItem>
						</MenuListItem>
					);
				})}
			</MenuList>
			{favoriteItems.length >= DEFAULT_LOAD_COUNT_FAV && (
				<MenuItemViewAll
					analytics={{
						actionSubjectId: 'projectsNavigationMenuItem',
						menuItemId: 'viewAll',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						position: DEFAULT_LOAD_COUNT_FAV as number,
					}}
					href={projectsUrl}
					testId={testIdConcat(testId, 'item-view-all')}
					to={projectsUrl}
				/>
			)}
		</span>
	) : null;
};
