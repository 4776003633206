import { stringify } from 'query-string';
import fetchDefaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import { NO_CONTENT, UNAUTHORIZED } from '@atlassian/jira-common-constants/src/http-status-codes';
import type { CloudId } from '@atlassian/jira-shared-types';
import { AuthenticationError } from '@atlassian/jira-standard-errors';
import type { JWM_BOARD_TYPE } from '../../constants';
import type {
	RecentItemsQuery,
	RecentResponseItem,
	RecentItemType,
	WorkedOnResponseItem,
} from '../../types';
import { QUERY } from './gql';

const PATH_BUCKETED = '/rest/internal/2/productsearch/search';
const PATH_SINGLE_LIST = '/rest/internal/2/productsearch/singleRecentList';

// dummy handle error
const handleError = (error: AuthenticationError | Error) => {
	throw error;
};

const toQuery = ({ counts }: Partial<RecentItemsQuery>) =>
	// @ts-expect-error - TS2769 - No overload matches this call.
	Object.keys(counts) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Array<any>>((result, itemType) => {
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<ItemType, number>>'.
			result.push(`${itemType}=${counts[itemType]}`);
			return result;
		}, [])
		.join(',');

export const fetchRecentItems = async ({
	counts,
}: RecentItemsQuery): Promise<RecentResponseItem[] | null> => {
	const url = `${PATH_BUCKETED}?${stringify({
		counts: toQuery({ counts }),
		type: Object.keys(counts).join(','),
	})}`;
	try {
		const response = await fetch(url, fetchDefaultOptions);
		const { status } = response;
		if (!response.ok) {
			if (status === UNAUTHORIZED) {
				return handleError(new AuthenticationError(`${status}`));
			}
			// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string | undefined'.
			return handleError(new Error(status));
		}
		if (status === NO_CONTENT) {
			return null;
		}
		return response.json();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return handleError(error);
	}
};

export const fetchRecentItemsSingleList = async ({
	types,
	limit,
}: {
	types: (RecentItemType | typeof JWM_BOARD_TYPE)[];
	limit: number;
}): Promise<RecentResponseItem[] | null> => {
	const url = PATH_SINGLE_LIST;
	try {
		const response = await fetch(url, {
			...fetchDefaultOptions,
			method: 'POST',
			body: JSON.stringify({
				groups: [
					{
						types,
						limit,
					},
				],
			}),
		});

		const { status } = response;
		if (!response.ok) {
			if (status === UNAUTHORIZED) {
				return handleError(new AuthenticationError(`${status}`));
			}
			// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string | undefined'.
			return handleError(new Error(status));
		}
		if (status === NO_CONTENT) {
			return null;
		}
		return response.json();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return handleError(error);
	}
};

export const fetchWorkedOnItems = async ({
	cloudId,
	amount,
}: {
	cloudId: CloudId;
	amount: number;
}): Promise<WorkedOnResponseItem> => {
	try {
		const response = await fetch('/gateway/api/graphql', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				operationName: 'jira_NavigationActivity',
				query: QUERY,
				variables: {
					first: amount,
					cloudID: cloudId,
				},
			}),
		});

		const { status } = response;
		if (!response.ok) {
			if (status === UNAUTHORIZED) {
				return handleError(new AuthenticationError(`${status}`));
			}
			// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string | undefined'.
			return handleError(new Error(status));
		}
		return response.json();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return handleError(error);
	}
};
