import React, { forwardRef } from 'react';
import { Help as AkHelp, type HelpProps } from '@atlaskit/atlassian-navigation';
import { SkeletonHelpButton } from '@atlaskit/atlassian-navigation/skeleton-help-button';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { Help as Nav4Help } from '@atlassian/jira-navigation-system';

// Should this live in the migration layer (@atlassian/jira-navigation-system) instead?
export const Help = forwardRef<HTMLElement, HelpProps>((props, ref) => {
	if (__SERVER__) {
		// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
		return <SkeletonHelpButton label={props.tooltip} />;
	}

	if (getWillShowNav4()) {
		const { badge, onClick, isSelected, label } = props;
		return <Nav4Help badge={badge} label={label} onClick={onClick} isSelected={isSelected} />;
	}
	const buttonProps = {
		...props,
		ref,
	};
	return <AkHelp {...buttonProps} />;
});
