import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	allTickets: {
		id: 'work-category.common.all-tickets',
		defaultMessage: 'All tickets',
		description: 'Title of queues section for all tickets',
	},
	queues: {
		id: 'work-category.common.queues',
		defaultMessage: 'Queues',
		description: 'Title of the queues section for all tickets',
	},
	serviceRequests: {
		id: 'work-category.common.service-requests',
		defaultMessage: 'Service requests',
		description:
			'Title of queues section for tickets which fall under the ITSM practice "Service requests"',
	},
	incidents: {
		id: 'work-category.common.incidents',
		defaultMessage: 'Incidents',
		description:
			'Title of queues section for tickets which fall under the ITSM practice "Incidents"',
	},
	changes: {
		id: 'work-category.common.changes',
		defaultMessage: 'Changes',
		description:
			'Title of the queues section for tickets which fall under the ITSM practice "Changes"',
	},
	problems: {
		id: 'work-category.common.problems',
		defaultMessage: 'Problems',
		description:
			'Title of the queues section for tickets which fall under the ITSM practice "Problems"',
	},
	postIncidentReviews: {
		id: 'work-category.common.post-incident-reviews',
		defaultMessage: 'Post-incident reviews',
		description:
			'Title of the queues section for tickets which fall under the ITSM practice "Post-incident reviews"',
	},
	noCategory: {
		id: 'work-category.common.no-category',
		defaultMessage: 'General',
		description: 'Category for request type which does not belong to any ITSM practice category',
	},
	allTicketsLower: {
		id: 'work-category.common.all-tickets-lower',
		defaultMessage: 'all tickets',
		description: 'Title of queues section for all tickets',
	},
	queuesLower: {
		id: 'work-category.common.queues-lower',
		defaultMessage: 'queues',
		description: 'Title of the queues section for all tickets',
	},
	serviceRequestsLower: {
		id: 'work-category.common.service-requests-lower',
		defaultMessage: 'service requests',
		description:
			'Title of queues section for tickets which fall under the ITSM practice "Service requests"',
	},
	incidentsLower: {
		id: 'work-category.common.incidents-lower',
		defaultMessage: 'incidents',
		description:
			'Title of queues section for tickets which fall under the ITSM practice "Incidents"',
	},
	changesLower: {
		id: 'work-category.common.changes-lower',
		defaultMessage: 'changes',
		description:
			'Title of the queues section for tickets which fall under the ITSM practice "Changes"',
	},
	problemsLower: {
		id: 'work-category.common.problems-lower',
		defaultMessage: 'problems',
		description:
			'Title of the queues section for tickets which fall under the ITSM practice "Problems"',
	},
	postIncidentReviewsLower: {
		id: 'work-category.common.post-incident-reviews-lower',
		defaultMessage: 'post-incident reviews',
		description:
			'Title of the queues section for tickets which fall under the ITSM practice "Post-incident reviews"',
	},
	noCategoryLower: {
		id: 'work-category.common.no-category-lower',
		defaultMessage: 'general',
		description: 'Category for request type which does not belong to any ITSM practice category',
	},
	developerEscalation: {
		id: 'work-category.common.developer-escalation',
		defaultMessage: 'Developer escalations',
		description:
			'Title of queues section for tickets which fall under the cs practice "Developer escalation"',
	},
	developerEscalationLower: {
		id: 'work-category.common.developer-escalation-lower',
		defaultMessage: 'developer escalations',
		description:
			'Title of queues section for tickets which fall under the cs practice "Developer escalation"',
	},
});
