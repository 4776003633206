import type { Action } from 'react-sweet-state';
import type { State } from './types';

// Consider using useService instead if you only need one consumer to fire
// the call.
// Note this also has a mechansim to allow one extra call to be made as long as one
// is not currently in flight, if the first call returns a null response.
export const cachedEndpointCall =
	<CallParams, ResponseData>(
		call: (params: CallParams) => Promise<ResponseData | null>,
	): ((params: CallParams) => Action<State<ResponseData>>) =>
	(params: CallParams): Action<State<ResponseData>> =>
	async ({ getState, setState }) => {
		const { isFetching, wasFetched, fetchedOnce } = getState();
		if (isFetching || wasFetched) {
			return;
		}

		setState({ isFetching: true });

		try {
			const data = await call(params);

			if (data !== null) {
				setState({ data, wasFetched: true });
			} else {
				setState({ wasFetched: fetchedOnce });
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({ fetchError: error });
		} finally {
			setState({ isFetching: false, fetchedOnce: true });
		}
	};
