import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const plansDirectoryRoute = {
	group: ROUTE_GROUPS.DIRECTORIES,
	name: ROUTE_NAMES.PLANS_DIRECTORY,
	path: '/jira/plans',
};
