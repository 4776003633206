import type { Action } from 'react-sweet-state';
import type { State, Attributes } from './types';

const PRODUCTS = ['jira'];

const loadRecentActivities =
	(): Action<State, Attributes> =>
	async ({ setState }, meta) => {
		setState({
			isFetching: true,
			fetchError: null,
		});

		try {
			const promise = meta.activityClient.fetchActivities(
				[50, 125, 250],
				{
					limit: 10,
					rootContainerIds: [`ari:cloud:platform::site/${meta.cloudId}`],
					objectTypes: ['issue'],
					products: PRODUCTS,
				},
				['workedOn'],
			);

			setState({ promise });

			const activityItems = await promise;

			setState({ hasSuccessOnce: true, items: activityItems, isFetching: false });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({ isFetching: false, fetchError: error });
		}
	};

export const actions = { loadRecentActivities } as const;
