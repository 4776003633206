import { createContainer, createHook, createStore } from 'react-sweet-state';
import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import type { Entitlement, StoreType } from './types';
import { convertDateStringToEpoch, fetchEntitlementDetails } from './utils';

const UPSELL_BANNER_DISMISS = 'premium-upsell-banner-dismiss';

const initialState: StoreType = {
	showBanner: false,
	trialEndDateHams: undefined,
	isTrialEndDateHamsSet: false,
};
export const UpsellBannerContextProvider = createContainer();

const Store = createStore({
	initialState,
	actions: {
		getUserPropertyCall:
			({ accountId }: { accountId: string | null }) =>
			({ setState }) => {
				if (accountId) {
					getUserProperty(accountId, UPSELL_BANNER_DISMISS)
						.then((res) => {
							setState({
								showBanner: !res,
							});
						})
						.catch((error) => {
							setState({
								showBanner: error.statusCode === 404,
							});
							if (error.statusCode !== 404) {
								fireErrorAnalytics({
									meta: {
										id: 'getUserPropertyPremiumUpsell',
										packageName: 'jiraAtlassianNavigation',
									},
									error,
									sendToPrivacyUnsafeSplunk: true,
								});
							}
						});
				}
			},
		handleClose:
			({ accountId }: { accountId: string | null }) =>
			({ setState }) => {
				setState({
					showBanner: false,
				});
				if (accountId) {
					setUserProperties(accountId, UPSELL_BANNER_DISMISS, String(true)).catch((error) => {
						fireErrorAnalytics({
							meta: {
								id: 'setUserPropertyPremiumUpsell',
								packageName: 'jiraAtlassianNavigation',
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
					});
				}
			},
		getTrialEndDateHams:
			({ cloudId }: { cloudId: string }) =>
			({ setState }) => {
				fetchEntitlementDetails(cloudId)
					.then((response) => {
						const jsmEntitlement: Entitlement | undefined = response?.entitlements?.find(
							(e: Entitlement) => e.productKey === 'jira-servicedesk.ondemand',
						);
						setState({
							trialEndDateHams: convertDateStringToEpoch(jsmEntitlement?.trialEditionEndDate),
							isTrialEndDateHamsSet: true,
						});
					})
					.catch((error) => {
						fireErrorAnalytics({
							meta: {
								id: 'getTrialEndDateHams',
								packageName: 'jiraAtlassianNavigation',
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
					});
			},
	},
	name: 'ShouldShowPremiumUpsellBanner',
	containedBy: UpsellBannerContextProvider,
});

export const useShouldShowPremiumUpsellBanner = createHook(Store);
