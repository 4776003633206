import type { Action } from 'react-sweet-state';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { KEY_OPEN, QUICKSTART_UI_STATUS, getBackendVisibilityKey } from '../../../common/constants';
import { pushToBackend } from '../../../common/utils';
import type { State } from '../../model/types';
import { getQuickstartTypeSelector } from '../../model/utils';

export const setIsOpen =
	(isOpen: boolean): Action<State> =>
	({ getState, setState }) => {
		if (expValEquals('jsm_business_quickstart', 'cohort', 'experiment')) {
			const state = getState();
			const quickstartType = getQuickstartTypeSelector(state);
			const currentIsOpen = state.visibility[quickstartType] === QUICKSTART_UI_STATUS.OPEN;
			if (isOpen === currentIsOpen) {
				// if state has not been updated we just return
				return;
			}

			const quickstartStatus = isOpen ? QUICKSTART_UI_STATUS.OPEN : QUICKSTART_UI_STATUS.MINIMIZED;

			setState({
				visibility: {
					...state.visibility,
					[quickstartType]: quickstartStatus,
				},
			});

			pushToBackend({
				key: getBackendVisibilityKey(quickstartType),
				value: quickstartStatus,
			});

			// Maintain backwards compatibility with old global quickstart in case we need to turn the feature off
			// Delete code below when cleaning up
			pushToBackend({
				key: KEY_OPEN,
				value: isOpen,
			});

			return;
		}

		/* Grab current state of is isOpen */
		const { isOpen: currentIsOpen } = getState();

		if (isOpen === currentIsOpen) {
			// if state has not been updated we just return
			return;
		}

		setState({ isOpen });

		pushToBackend({
			key: KEY_OPEN,
			value: isOpen,
		});
	};

export default setIsOpen;
