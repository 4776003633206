import React, { useCallback, useState, type ReactNode } from 'react';
import Button from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { Text } from '@atlaskit/primitives';
import { RecommendationModal } from '@atlassian/jira-atlassian-navigation-recommendations/src/common/ui/recommendation-section/recommendation-modal';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { useShouldShowPremiumUpsellBanner } from '../../../../../controllers/premium-upsell-data';
import { DATA_PRIVACY_LINK, PREMIUM_UPSELL_BANNER } from './constants';
import messages from './messages';

export const DismissMenu = () => {
	const { formatMessage } = useIntl();
	const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
	const [moreInformationModal, setMoreInformationModal] = useState(false);
	const [, { handleClose }] = useShouldShowPremiumUpsellBanner();
	const { atlassianAccountId: accountId } = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onNotInterestedButtonClicked = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'DismissButton',
			}),
			PREMIUM_UPSELL_BANNER,
		);
		handleClose({ accountId });
	};
	const onLearnMoreClicked = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'PremiumLearnMoreButton',
			}),
			PREMIUM_UPSELL_BANNER,
		);
		setMoreInformationModal(true);
	};
	const onLearnMoreClosed = useCallback(() => {
		setMoreInformationModal(false);
	}, []);
	return (
		<>
			<Popup
				isOpen={isActionsMenuOpen}
				placement="bottom-start"
				onClose={() => setIsActionsMenuOpen(false)}
				content={() => (
					<MenuGroup onClick={(e) => e.stopPropagation()}>
						<Section>
							<ButtonItem onClick={onNotInterestedButtonClicked}>
								{formatMessage(messages.notInterestedButtonText)}
							</ButtonItem>
							<ButtonItem onClick={onLearnMoreClicked}>
								{formatMessage(messages.learnMoreButtonText)}
							</ButtonItem>
						</Section>
					</MenuGroup>
				)}
				shouldUseCaptureOnOutsideClick
				trigger={(triggerProps) => (
					<Button
						{...triggerProps}
						appearance="subtle"
						onClick={() => setIsActionsMenuOpen(() => !isActionsMenuOpen)}
						iconBefore={<MoreIcon size="medium" label="more" />}
					/>
				)}
			/>
			<RecommendationModal
				isOpen={moreInformationModal}
				onClose={onLearnMoreClosed}
				title={formatMessage(messages.reasonsModalTitle)}
				body={
					<>
						{formatMessage(messages.reasonsModalExplanation, {
							strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
						})}
						<ul>
							<li>{formatMessage(messages.reasonsModalBulletOne)}</li>
							<li>{formatMessage(messages.reasonsModalBulletTwo)}</li>
						</ul>
						<br />
						<Button
							appearance="link"
							spacing="none"
							href={DATA_PRIVACY_LINK}
							target="_blank"
							onClick={() => {
								fireUIAnalytics(
									createAnalyticsEvent({
										action: 'clicked',
										actionSubject: 'PremiumPrivacyPolicyLink',
									}),
									PREMIUM_UPSELL_BANNER,
								);
							}}
						>
							{formatMessage(messages.privacyPolicy)}
						</Button>
					</>
				}
				primaryCta={formatMessage(messages.reasonsModalButtonOk)}
			/>
		</>
	);
};
