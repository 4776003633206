import React, { useEffect, useMemo } from 'react';
import Icon from '@atlaskit/icon/glyph/addon';
import { HeadingItem } from '@atlaskit/menu';
import { useAddon } from '@atlassian/jira-addon-store';
import { useConnectItemClickHistory } from '@atlassian/jira-connect-item-click-history-controller';
import { useForgeGlobalItems } from '@atlassian/jira-forge-global-items-store';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/global-page';
import { GLOBAL_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants';
import { EnvironmentLozenge } from '@atlassian/jira-forge-ui-extension-title';
import { IconCustom } from '@atlassian/jira-icon-custom';
import { useIntl } from '@atlassian/jira-intl';
import { ADDON_ID, testIdConcat } from '@atlassian/jira-navigation-apps-common';
import type { MenuItemProps } from '@atlassian/jira-navigation-types';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { MenuItem } from '../../../../../common/ui/menu/item';
import { MenuList, MenuListItem } from '../../../../../common/ui/menu/list';
import { PRIMARY_COLOR } from './constants';
import messages from './messages';
import type { ItemsProps, EcosystemItem } from './types';

const ContentItems = ({ testIdPrefix }: ItemsProps) => {
	const { formatMessage } = useIntl();
	const { key: projectIdOrKey } = useProjectContext();
	const [{ items: connectItems }, { setContext }] = useAddon();
	const [{ items: forgeStoreItems }] = useForgeGlobalItems();

	const { addNew } = useConnectItemClickHistory();

	useEffect(() => {
		setContext({ projectIdOrKey });
	}, [projectIdOrKey, setContext]);

	const sortedItems: EcosystemItem[] = useMemo(
		() =>
			[
				...(forgeStoreItems || []).map((item) => ({ type: 'forge' as const, item })),
				...connectItems
					.filter((item) => item.id !== ADDON_ID.DISCOVER)
					.map((item) => ({ type: 'connect' as const, item })),
			].sort((a, b) => a.item.title.localeCompare(b.item.title)),
		[connectItems, forgeStoreItems],
	);

	const testId = testIdConcat(testIdPrefix, 'items');

	const getMenuItemProps = ({ item, type }: EcosystemItem): Partial<MenuItemProps> =>
		type === 'connect'
			? {
					onClick: () => item.url != null && addNew(item.url),
					className: item.className,
				}
			: {
					iconAfter: <EnvironmentLozenge environmentType={item.environmentType} />,
					onClick: () => {
						forgeModulesConcurrentLoadTime(item.id).start({ startTime: performance.now() });
					},
				};

	const renderMenuItems = () =>
		sortedItems.map((ecosystemItem, idx) => {
			const { type, item } = ecosystemItem;
			return (
				<MenuListItem key={item.id}>
					<MenuItem
						testId={testIdConcat(testId, `item-${idx}`)}
						href={item.url}
						analytics={{
							actionSubjectId: 'appsNavigationMenuItem',
							menuItemId: item.id,
							menuItemType: 'apps',
							position: idx,
							appName: item.title,
							appType: type,
						}}
						iconBefore={
							(item.avatarUrl != null && item.avatarUrl.trim().length > 0 && (
								<IconCustom url={item.avatarUrl} />
								// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
							)) || <Icon primaryColor={PRIMARY_COLOR} label={item.title} />
						}
						{...getMenuItemProps(ecosystemItem)}
					>
						{item.title}
					</MenuItem>
				</MenuListItem>
			);
		});
	const shouldTriggerForgeScreenEvent = forgeStoreItems && forgeStoreItems.length > 0;

	return (
		<span data-testid={testId}>
			<HeadingItem>{formatMessage(messages.yourAppsText)}</HeadingItem>
			<MenuList>{renderMenuItems()}</MenuList>
			{shouldTriggerForgeScreenEvent && (
				<ForgeScreenEvent
					attributes={{
						moduleType: GLOBAL_PAGE_MODULE,
					}}
				/>
			)}
			<ContextualAnalyticsData sourceName="appsMenuYourAppsSection" sourceType={SCREEN}>
				<FireScreenAnalytics />
			</ContextualAnalyticsData>
		</span>
	);
};

export const Items = (props: ItemsProps) => (
	<TriggerAnalyticsWrapper>
		<ContentItems {...props} />
	</TriggerAnalyticsWrapper>
);
