import React, { useMemo } from 'react';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import { HeadingItem } from '@atlaskit/menu';
import { FILTERS_ITEM_TYPE } from '@atlassian/jira-favourite-change-provider';
import { useIntl } from '@atlassian/jira-intl';
import { getBeforeEntity, testIdConcat } from '@atlassian/jira-navigation-apps-common';
import { handleGinSPAClick } from '@atlassian/jira-navigation-apps-common/src/utils/gin-spa';
import { globalIssueNavigatorRoute } from '@atlassian/jira-router-routes-legacy-global-issue-navigator-routes';
import { DEFAULT_LOAD_COUNT_RECENT } from '../../../../common/constants';
import { FavouriteButton } from '../../../../common/ui/menu/favourite-button';
import { MenuItem } from '../../../../common/ui/menu/item';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list';
import { useFilterNavItems as useItems } from '../../../../controllers/items';
import messages from './messages';
import type { RecentContentProps } from './types';

export const RecentContent = ({ testIdPrefix }: RecentContentProps) => {
	const { formatMessage } = useIntl();
	const {
		recentItems,
		favoriteItems,
		changeFavourite: onChangeFavourite,
		changedItems,
	} = useItems();

	const itemsToShow = useMemo(() => recentItems.slice(0, DEFAULT_LOAD_COUNT_RECENT), [recentItems]);

	const testId = testIdConcat(testIdPrefix, 'items-recent');

	return recentItems.length > 0 ? (
		<span data-testid={testId}>
			<HeadingItem testId={testIdConcat(testId, 'heading')}>
				{formatMessage(messages.recent)}
			</HeadingItem>
			<MenuList>
				{itemsToShow.map((item, idx) => {
					const { url, id, favourite: isFavourite, title } = item;
					const contextItem = changedItems[item.id.toString()];
					const isPending =
						contextItem && contextItem.pending === true ? contextItem.pending : false;
					const { id: beforeEntityId, type: beforeEntityType } = getBeforeEntity(favoriteItems);

					const filterParams = { issueKey: '' };
					const filterQuery = { filter: String(id) };

					return (
						<MenuListItem key={id} ariaLabel={formatMessage(messages.recent)}>
							<MenuItem
								analytics={{
									actionSubjectId: 'filtersNavigationMenuItem',
									menuItemId: id,
									menuItemType: 'recent',
									position: idx,
									starred: Boolean(isFavourite),
								}}
								href={url}
								iconBefore={<FilterIcon label="" />}
								iconAfter={
									<FavouriteButton
										beforeEntityId={beforeEntityId}
										beforeEntityType={beforeEntityType}
										id={String(id)}
										isFavourite={Boolean(isFavourite)}
										favouriteItemName={title}
										isPending={isPending}
										menuItemType="recent"
										onChangeFavourite={onChangeFavourite}
										type={FILTERS_ITEM_TYPE}
									/>
								}
								params={filterParams}
								query={filterQuery}
								testId={testIdConcat(testId, `item-${idx}`)}
								to={globalIssueNavigatorRoute}
								onClick={handleGinSPAClick}
							>
								{title}
							</MenuItem>
						</MenuListItem>
					);
				})}
			</MenuList>
		</span>
	) : null;
};
