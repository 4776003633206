import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const softwareBoardRoute = {
	group: ROUTE_GROUPS.SOFTWARE,
	name: ROUTE_NAMES.SOFTWARE_BOARDS,
	exact: true,
	path: '/jira/software/projects/:projectKey/boards/:boardId',
};
