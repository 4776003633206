export const Experiments = {
	projectTheming: 'projectTheming',
	isSampleProject: 'isSampleProject',
	isNotSampleProject: 'isNotSampleProject',
	trelloCrossflow: 'trelloCrossflow',
	personalizedExperience: 'personalizedExperience',
} as const;

export const PersonalizedExperienceExperiments = {
	marketingLeader: 'personalizedExperience.marketing-leader',
	contentMarketer: 'personalizedExperience.content-marketer',
	projectMarketer: 'personalizedExperience.project-marketer',
	productMarketingManager: 'personalizedExperience.pmm',
} as const;
