import { convertTaskIdToActionSubjectId } from './analytics';

export { pushToBackend } from './push-to-backend';
export { pollBackend } from './poll-backend';
export { convertTaskIdToActionSubjectId, getProductType } from './analytics';
export { localStorageProvider, setLocalStorageHasNavigatedFromLink } from './local-storage';
export { isRoadmapRoute } from './roadmap-route';
export { getAdminHubUrl } from './get-admin-hub-url';
export { getConnectActionLinkProps } from './get-connect-action-link-props';

export const getTaskIndex = (taskList: string[], taskId: string | null) => {
	if (!taskList || taskId === null) {
		return -1;
	}
	return taskList.indexOf(taskId) + 1;
};
type Word = string | null;

export const convertTaskIdToActionSubjectIdForShowMe = (taskId: Word) =>
	`${convertTaskIdToActionSubjectId(taskId)}ShowMe`;

export const convertTaskIdToActionSubjectIdForLink = (taskId: Word) =>
	`${convertTaskIdToActionSubjectId(taskId)}Link`;

export const convertTaskIdToActionSubjectIdForCta = (taskId: Word) =>
	`${convertTaskIdToActionSubjectId(taskId)}Cta`;
