import { createHook, createStore } from 'react-sweet-state';
import { actions } from './actions';
import type { State } from './types';

export type Actions = typeof actions;

const initialState: State = {
	status: 'idle',
};

const store = createStore<State, Actions>({
	actions,
	initialState,
	name: 'atlassian-navigation-recommendation',
});

export const useProjectMenuRecommendations = createHook(store);
