import { differenceInDays } from 'date-fns';
import {
	DAYS_BEFORE_INACTIVE_TENANT,
	DAYS_BEFORE_INACTIVE_USER,
	DAYS_SINCE_LAST_NUDGE,
	DISMISSED_TRAIT_NAME,
	ISSUE_WATCH_NUDGE_TRAIT_NAME,
	MSTEAMS_ISSUES_VIEW_TRAIT_NAME,
	MSTEAMS_TENANT_TRAIT_NAME,
	MSTEAMS_USER_TRAIT_NAME,
	SLACK_TENANT_TRAIT_NAME,
	SLACK_USER_TRAIT_NAME,
	NOTIFICATION_BELL_TRAIT_NAME,
	TEAMS_APP_ID,
	TeamsDeepLinkSource,
	JIRA_FIRST_ACTIVE_TRAIT,
	DAYS_SINCE_FIRST_LOGIN,
} from '../common/constants';
import type { Traits } from '../services/personalization';

let flagId = 0;

export const generateFlagId = () => String(flagId++);

export const getTeamsDeepLink = (source: string): string =>
	`https://teams.microsoft.com/l/app/${TEAMS_APP_ID}?source=${source}`;

export const openTeamsDeepLink = (source = TeamsDeepLinkSource.JSW_COMMENT) => {
	window.open(getTeamsDeepLink(source), '_blank', 'noopener, noreferrer');
};

export const isTenantActiveOnSlack = (traits: Traits): boolean =>
	traits[SLACK_TENANT_TRAIT_NAME] !== undefined &&
	traits[SLACK_TENANT_TRAIT_NAME] <= DAYS_BEFORE_INACTIVE_TENANT;

export const isUserActiveOnSlack = (traits: Traits): boolean =>
	traits[SLACK_USER_TRAIT_NAME] !== undefined &&
	traits[SLACK_USER_TRAIT_NAME] <= DAYS_BEFORE_INACTIVE_USER;

export const isTenantActiveOnMsteams = (traits: Traits): boolean =>
	traits[MSTEAMS_TENANT_TRAIT_NAME] !== undefined &&
	traits[MSTEAMS_TENANT_TRAIT_NAME] <= DAYS_BEFORE_INACTIVE_TENANT;

export const isUserActiveOnMsteams = (traits: Traits): boolean =>
	traits[MSTEAMS_USER_TRAIT_NAME] !== undefined &&
	traits[MSTEAMS_USER_TRAIT_NAME] <= DAYS_BEFORE_INACTIVE_USER;

export const isNewJiraUser = (traits: Traits): boolean => {
	const firstJiraActiveTraitValue = traits[JIRA_FIRST_ACTIVE_TRAIT];
	return (
		firstJiraActiveTraitValue !== undefined &&
		differenceInDays(new Date(), new Date(firstJiraActiveTraitValue)) < DAYS_SINCE_FIRST_LOGIN
	);
};

export const hasSeenIssueCommentNudge = (traits: Traits): boolean => {
	const dismissedTraitValue = traits[DISMISSED_TRAIT_NAME];
	return (
		dismissedTraitValue !== undefined &&
		differenceInDays(new Date(), new Date(dismissedTraitValue)) < DAYS_SINCE_LAST_NUDGE
	);
};

export const hasSeenIssuesViewNudge = (traits: Traits): boolean => {
	const issuesViewTraitValue = traits[MSTEAMS_ISSUES_VIEW_TRAIT_NAME];
	return (
		issuesViewTraitValue !== undefined &&
		differenceInDays(new Date(), new Date(issuesViewTraitValue)) < DAYS_SINCE_LAST_NUDGE
	);
};

export const hasSeenNotificationBellNudge = (traits: Traits): boolean => {
	const notificationBellTraitValue = traits[NOTIFICATION_BELL_TRAIT_NAME];
	return (
		notificationBellTraitValue !== undefined &&
		differenceInDays(new Date(), new Date(notificationBellTraitValue)) < DAYS_SINCE_LAST_NUDGE
	);
};
export const hasSeenIssueWatchNudge = (traits: Traits): boolean => {
	const issuesWatchNudgeTraitValue = traits[ISSUE_WATCH_NUDGE_TRAIT_NAME];
	return (
		issuesWatchNudgeTraitValue !== undefined &&
		differenceInDays(new Date(), new Date(issuesWatchNudgeTraitValue)) < DAYS_SINCE_LAST_NUDGE
	);
};
