import forEach from 'lodash/forEach';
import kebabCase from 'lodash/kebabCase';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest, isClientFetchError } from '@atlassian/jira-fetch';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import type { BaseUrl } from '@atlassian/jira-shared-types';
import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';
import type { RestPropertiesResponse, RestPropertiesOptions } from './types';

let ufoExperiencesMap: {
	[value: string]: UFOExperience;
} = {};
const getUfoExperience = (key: string) => {
	if (!ufoExperiencesMap[key]) {
		ufoExperiencesMap[key] = new UFOExperience(key, {
			type: ExperienceTypes.Operation,
			performanceType: ExperiencePerformanceTypes.Custom,
		});
	}
	return ufoExperiencesMap[key];
};
export const resetUfoExperiencesMap = () => {
	ufoExperiencesMap = {};
};
/**
 * Generates a URL for fetching frontend configuration settings, incorporating optional query parameters
 * for user, project, and instance-specific configurations. This dynamic construction allows for
 * flexible retrieval of settings based on the provided context, facilitating customized frontend
 * experiences.
 */
export const constructEndpoint = (
	baseUrl: string,
	{ userProperties, projectId, projectProperties, instanceProperties }: RestPropertiesOptions,
) => {
	const params = new URLSearchParams();
	userProperties && params.append('user', userProperties.join(','));
	projectId !== undefined && params.append('projectId', projectId);
	projectProperties && params.append('project', projectProperties.join(','));
	instanceProperties && params.append('instance', instanceProperties.join(','));
	return `/rest/internal/latest/frontendconfig/properties?${params.toString()}`;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const patchProperties = (response: any, propertiesPath: string) =>
	(fg('handle_null_response_for_fe_config_properties_call') ? response : true) &&
	forEach(response[propertiesPath], (value, prop) => {
		try {
			response[propertiesPath][prop] = JSON.parse(value);
		} catch (error) {
			// TODO: Endpoint should ideally only return stringified values
			if (prop === 'initial.project.template.name') {
				response[propertiesPath][prop] = value;
			} else {
				throw error;
			}
		}
	});
/**
 * Initiates an asynchronous operation to obtain configuration properties, utilizing a constructed endpoint.
 * This function is instrumental in managing the request lifecycle, incorporating analytics to monitor
 * success or failure, and employs error handling strategies to ensure robust operation. It serves as a
 * critical component in fetching and applying configurations necessary for frontend customization.
 */
export const get = async (
	baseUrl: BaseUrl,
	options: RestPropertiesOptions,
): Promise<RestPropertiesResponse> => {
	const ufoExperience = getUfoExperience(
		`${kebabCase(options.consumerMeta.packageName)}.${kebabCase(options.consumerMeta.id)}`,
	);
	try {
		ufoExperience?.start();
		const url = constructEndpoint(baseUrl, options);
		const response = await performGetRequest(url);
		patchProperties(response, 'user');
		patchProperties(response, 'instance');
		patchProperties(response, 'project');
		const { consumerMeta } = options;
		getAnalyticsWebClientPromise().then((client) => {
			const payload = {
				source: 'frontendConfigProperties',
				action: 'success',
				actionSubject: `${consumerMeta.packageName}.${consumerMeta.id}`,
			};
			client.getInstance().sendOperationalEvent(payload);
		});
		ufoExperience?.success();

		return fg('handle_null_response_for_fe_config_properties_call')
			? response || { user: {}, instance: {}, project: {} }
			: response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		const { consumerMeta } = options;
		!isClientFetchError(error) &&
			fireErrorAnalytics({
				error,
				meta: {
					id: consumerMeta.id,
					packageName: consumerMeta.packageName,
					teamName: consumerMeta.teamName,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		ufoExperience?.failure();
		throw error;
	}
};
