import type { EntryPoint, EntryPointComponent } from 'react-relay';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout';
import { TOP_NAVIGATION } from '@atlassian/jira-entry-point-page-layout-fields';
import type { MenuId } from '@atlassian/jira-navigation-apps-common';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';

export const useMenuHighlighted = (menuId: MenuId) => {
	const route = useCurrentRoute();
	const topNavEntryPointReference = usePageLayoutEntryPoint<
		// typeof topNavigationEntryPoint
		// Importing topNavigationEntryPoint will cause a cyclic dependency
		EntryPoint<EntryPointComponent<{}, {}, {}, { menuId: MenuId }>, { menuId: MenuId }>
	>(TOP_NAVIGATION);

	if (route.navigation) {
		return route.navigation.menuId === menuId;
	}

	if (topNavEntryPointReference) {
		return topNavEntryPointReference.extraProps.menuId === menuId;
	}

	return false;
};
