import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import {
	DEVELOPMENT_ENVIRONMENT,
	STAGING_ENVIRONMENT,
	DEFAULT_ENVIRONMENT_KEY,
} from '@atlassian/jira-forge-ui-constants';
import type { ExtensionEnvironment } from '@atlassian/jira-forge-ui-types';
import { FormattedMessage } from '@atlassian/jira-intl';
import { messages } from './messages';

export const getEnvironmentTranslationKey = (environmentType: ExtensionEnvironment) => {
	switch (environmentType) {
		case DEVELOPMENT_ENVIRONMENT:
			return messages.developmentEnvironmentLozenge;
		case STAGING_ENVIRONMENT:
			return messages.stagingEnvironmentLozenge;
		default:
			return null;
	}
};

export const EnvironmentLozenge = (props: { environmentType: ExtensionEnvironment }) => {
	const { environmentType } = props;
	const labelKey = getEnvironmentTranslationKey(environmentType);

	return labelKey ? (
		<Lozenge
			testId="forge-ui-extension-title.ui.environment-lozenge.lozenge"
			appearance={environmentType === DEVELOPMENT_ENVIRONMENT ? 'default' : 'moved'}
		>
			<FormattedMessage {...labelKey} />
		</Lozenge>
	) : null;
};

export const nameWithCustomEnvironment = (
	name: string,
	environmentType: ExtensionEnvironment,
	environmentKey?: string,
) => {
	if (
		environmentType === DEVELOPMENT_ENVIRONMENT &&
		environmentKey &&
		environmentKey !== DEFAULT_ENVIRONMENT_KEY
	) {
		return `${name} - ${environmentKey}`;
	}
	return name;
};
