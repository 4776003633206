import React from 'react';
import { AppSwitcher as AKAppSwitcher } from '@atlaskit/atlassian-navigation';
import { SkeletonSwitcherButton } from '@atlaskit/atlassian-navigation/skeleton-switcher-button';

type Props = {
	onOpen?: () => void;
};

export const AppSwitcherLight = ({ onOpen }: Props) => {
	if (__SERVER__) {
		return <SkeletonSwitcherButton label="" />;
	}
	// @ts-expect-error - TS2741 - Property 'tooltip' is missing in type '{ onClick: (() => void) | undefined; }' but required in type 'BaseIconButtonProps'.
	return <AKAppSwitcher onClick={onOpen} />;
};
