import React, { useCallback, useMemo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { isValidationError } from '@atlassian/jira-fetch';
import { useFlagsService, type ShowFlagFn } from '@atlassian/jira-flags';
import {
	measureInitialPageLoadAsyncResourcesTiming,
	withMetrics,
} from '@atlassian/jira-forge-ui-analytics';
import { PERFORMANCE_KEYS } from '@atlassian/jira-forge-ui-analytics/src/constants';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	CUSTOM_FIELD_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants';
import { LazyModalIframe } from '@atlassian/jira-forge-ui-iframe-generic';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types';
import { isCustomUIExtension } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import { customFieldEditFailed } from '@atlassian/jira-forge-ui/src/common/ui/flags/custom-field';
import type { ServiceResult } from '@atlassian/jira-forge-ui/src/services/custom-fields/save-field/types';
import type { CustomFieldEdit as CustomFieldEditType } from '@atlassian/jira-forge-ui/src/ui/components/custom-field/edit';
import Placeholder from '@atlassian/jira-placeholder';
import { StopSubmitPropagation } from '../../../common/ui/submit-stop-propogation-wrapper';
import type { LazyComponentProps } from '../../../types';

export type Bridge = {
	submit: (opts: { data: ForgeCustomFieldValue }) => Promise<void>;
};

type EditProps = Flow.Diff<LazyComponentProps<typeof CustomFieldEditType>, Bridge> & {
	onConfirm: (newValue: ForgeCustomFieldValue) => void;
	onCancel: () => void;
	onSave: (value: ForgeCustomFieldValue) => Promise<ServiceResult>;
};

export const onCustomFieldSubmit = (
	fieldValue: ForgeCustomFieldValue,
	onSave: EditProps['onSave'],
	onConfirm: EditProps['onConfirm'],
	showFlag: ShowFlagFn,
) =>
	onSave(fieldValue)
		.then((response) => {
			if (response == null) return;

			// The error is handled in the catch block
			if ('error' in response) {
				if (response.error) {
					throw response.error;
				}
			}
			// There is some rendering issue so onConfirm gets a different reference every time,
			// we don't add it as a dependency of the effect and just call the most recent one
			else {
				onConfirm(response.data);
			}
		})
		.catch((error) => {
			showFlag(customFieldEditFailed(error));
			// Custom UI depends on this error to get know whether the form was successfully submitted or not
			if (isValidationError(error)) throw error;
			throw Error(`Unable to save custom field, error: ${error.message}`);
		});

const getModule = () =>
	measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_END,
		() => import(/* webpackChunkName: "async-forge-ui-custom-field-edit" */ './index'),
	);

export const LazyCustomFieldEditRenderer = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.CustomFieldEdit), { ssr: false }),
);

export const LazyCustomFieldEdit = ({
	onSave,
	onCancel,
	onConfirm,
	onError,
	onRender,
	...restProps
}: EditProps) => {
	const { extension, extensionData, extensionPayload } = restProps;
	const { showFlag } = useFlagsService();

	const extensionDataValue = useMemo(() => {
		const preparedExtensionData =
			extensionData?.issue && extensionData?.project
				? extensionData
				: {
						fieldType: extensionData.fieldType,
						fieldId: extensionData.fieldId,
						fieldName: extensionData.fieldName,
						renderContext: extensionData.renderContext,
					};

		return {
			...preparedExtensionData,
			type: extension.type,
			entryPoint: ENTRY_POINT_EDIT,
		};
	}, [extension.type, extensionData]);

	const extraProps = useMemo(
		() => ({
			module: CUSTOM_FIELD_MODULE,
			entryPoint: ENTRY_POINT_EDIT,
			extensionData: extensionDataValue,
			extensionPayload,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue, extensionPayload],
	);

	const submit = useCallback(
		({ data: fieldValue }: { data: ForgeCustomFieldValue }): Promise<void> =>
			onCustomFieldSubmit(fieldValue, onSave, onConfirm, showFlag),
		// todo SMT-491 onConfirm, onSave (fieldId) was changing every time component tree was changed by Bento issue view.
		// root cause - Bento's connect-field is creating onConfirm function and fieldId every time. More in mentioned ticket
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showFlag],
	);
	const close = onCancel;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const bridge: any = useMemo(() => ({ submit, close }), [submit, close]);

	return (
		<StopSubmitPropagation>
			<Placeholder name="custom-field-edit" fallback={null}>
				{isCustomUIExtension(extension, ENTRY_POINT_EDIT) ? (
					<LazyModalIframe
						{...restProps}
						{...extraProps}
						bridge={bridge}
						onClose={onCancel}
						onLoad={onRender}
					/>
				) : (
					<LazyCustomFieldEditRenderer
						{...restProps}
						{...extraProps}
						onError={onError}
						onRender={onRender}
						onCancel={onCancel}
						submit={submit}
						bridge={bridge}
					/>
				)}
			</Placeholder>
		</StopSubmitPropagation>
	);
};

export const AsyncCustomFieldEdit = LazyCustomFieldEdit;
