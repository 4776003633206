import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'atlassian-navigation.notifications.tooltip',
		defaultMessage: 'Notifications',
		description: 'Tooltip for notification drawer icon in global-navigation',
	},
	label: {
		id: 'atlassian-navigation.notifications.label',
		defaultMessage: 'Notifications',
		description: 'Button label for notification drawer icon in global-navigation',
	},
});
