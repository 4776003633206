import { useEffect } from 'react';
import { useCloudId, useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller';
import { useLinkedOrg } from './linked-org';

export const useOrgId = () => {
	const cloudId = useCloudId();
	const isSiteAdmin = useIsSiteAdmin();
	const [{ value }, { fetchOrgId }] = useLinkedOrg();

	useEffect(() => {
		if (isSiteAdmin) {
			fetchOrgId(cloudId);
		}
	}, [fetchOrgId, isSiteAdmin, cloudId]);

	return value;
};
