import {
	type CommandSections,
	type Command,
	type CommandAction,
	type CommandActionCommandList,
	CommandActionType,
} from '../../types';

export const isSearchableChildrenAction = (
	action?: CommandAction,
): action is CommandActionCommandList & {
	commands: Command[];
	sections?: CommandSections;
} => action?.type === CommandActionType.COMMAND_LIST && action.commands !== undefined;
