import { createHook, createStore } from 'react-sweet-state';
import { actions } from './actions';
import type { State } from './types';

export type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'create-project-permissions',
	initialState: {
		traits: [],
		siteTraits: [],
		isFetching: false,
		fetchError: null,
		promise: null,
		hasFetchedOnce: false,
		hasSuccessOnce: false,
	},
	actions,
});

export const usePersonalizationProvider = createHook(store);
