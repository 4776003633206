import type { Action } from 'react-sweet-state';
import type { State } from '../../model/types';

// We use this action in cases where we want to hide the nudge no matter what.
const forceHideNudge =
	(): Action<State> =>
	({ setState }) => {
		setState({ hasNudgeActive: false });
	};

export { forceHideNudge };
