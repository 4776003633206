// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-legacy
import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context';
import log from '@atlassian/jira-common-util-logging/src/log';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	CUSTOM_FIELD_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	WORKFLOW_VALIDATOR_MODULE,
	WORKFLOW_CONDITION_MODULE,
	WORKFLOW_POST_FUNCTION_MODULE,
	SOURCE_LEGACY,
} from '@atlassian/jira-forge-ui-constants';
import type { DataClassificationProps } from '@atlassian/jira-forge-ui-utils/src/types';
import fetchForgeModules from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules';
import { customField, workflowCondition, workflowValidator, workflowPostFunction } from './ui';

const loadModules = async (dataClassification: DataClassificationProps = null) => {
	const { cloudId, activationId } = getTenantContext_DEPRECATED_DO_NOT_USE();
	const modulesToFetch = [
		CUSTOM_FIELD_MODULE,
		CUSTOM_FIELD_TYPE_MODULE,
		WORKFLOW_VALIDATOR_MODULE,
		WORKFLOW_CONDITION_MODULE,
		WORKFLOW_POST_FUNCTION_MODULE,
	];

	const contextIds = ff('arken-1053-jira-forge-extension-changes-for-aegis_aws9x')
		? [`ari:cloud:jira:${cloudId}:workspace/${activationId}`]
		: undefined;

	const { extensions } = await fetchForgeModules(
		cloudId,
		modulesToFetch,
		dataClassification,
		undefined,
		SOURCE_LEGACY,
		contextIds,
	);

	const customFields = extensions.jiraCustomField;
	const customFieldTypes = extensions.jiraCustomFieldType;
	const workflowValidators = extensions.jiraWorkflowValidator;
	const workflowConditions = extensions.jiraWorkflowCondition || [];
	const workflowPostFunctions = extensions.jiraWorkflowPostFunction || [];
	return {
		customFields,
		customFieldTypes,
		workflowValidators,
		workflowConditions,
		workflowPostFunctions,
	};
};

export const initForgeBridge = async () => {
	const components = {
		customField,
		workflowValidator,
		workflowCondition,
		workflowPostFunction,
	};
	/* eslint-disable jira/wrm/no-load-bridge */

	const [modules, bridge] = await Promise.all([
		loadModules(),
		loadBridge({
			name: 'jira/forge/bridge',
			wrmKeys: 'wrc!com.atlassian.jira.jira-atlaskit-plugin:forge-bridge',
		}).catch((e) => {
			log.safeErrorWithoutCustomerData('forge.legacy.bridge', 'Cannot load the bridge', e);
			return e;
		}),
	]);
	// modules contains all modules data
	// components contains modules to render
	// loadModules method can be invoked with data classification to get the filtered modules by access narrowing
	/* eslint-enable jira/wrm/no-load-bridge */
	const API = {
		modules,
		components,
		loadModules,
	};

	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	bridge(API);
};
