import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tryButtonDescription: {
		defaultMessage: 'Start free trial',
		id: 'atlassian-navigation.settings.menu.premium-upsell.try-button-description',
		description: 'Button label for starting premium trial.',
	},
	upsellBannerHeading: {
		defaultMessage: 'Try Jira Service Management Premium',
		id: 'atlassian-navigation.settings.menu.premium-upsell.upsell-banner-heading',
		description: 'Heading for the upsell banner.',
	},
	upsellBannerDescription: {
		defaultMessage: 'Scale your team with smart incident management and AI.',
		id: 'atlassian-navigation.settings.menu.premium-upsell.upsell-banner-description',
		description: 'Description about the premium plan.',
	},
	whatIsPremiumLink: {
		defaultMessage: 'What is Premium?',
		id: 'atlassian-navigation.settings.menu.premium-upsell.what-is-premium-link',
		description: 'Learn more link for JSM Premium plan.',
	},
});
