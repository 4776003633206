export const TEAMS_APP_ID = 'aa183fd9-7104-46c4-af9f-9ee9b81d717e';

export const DAYS_BEFORE_INACTIVE_TENANT = 180;
export const DAYS_BEFORE_INACTIVE_USER = 150;
export const DAYS_SINCE_LAST_NUDGE = 90;
export const DAYS_SINCE_FIRST_LOGIN = 7;

export const ExperimentType = {
	JSW_COMMENT: 'thirdPartyNudgeAfterComment',
	ISSUES_VIEW: 'thirdPartyNudgeIssueView',
	NOTIFICATION_BELL: 'thirdPartyNotificationBellNudge',
	ISSUES_WATCH: 'thirdPartyNudgeIssueWatch',
};

export const TeamsDeepLinkSource = {
	JSW_COMMENT: 'third-party-nudge-jsw-comment',
	ISSUE_WATCH: 'third-party-nudge-issue-watch',
	ISSUES_VIEW: 'third-party-nudge-issue-view',
	NOTIFICATION_BELL: 'third-party-nudge-notification-bell',
};

export const PERSONALIZATION_URL = '/gateway/api/tap-delivery/api/v3/personalization';

export const MSTEAMS_TENANT_TRAIT_NAME = 'jira_msteams_v2_track_days_since_tenant_last_active';
export const SLACK_TENANT_TRAIT_NAME =
	'external_product_integrations_track_jira_slack_days_since_tenant_last_active';
export const MSTEAMS_USER_TRAIT_NAME = 'jira_msteams_v2_track_days_since_user_last_active';
export const SLACK_USER_TRAIT_NAME =
	'external_product_integrations_track_jira_slack_days_since_user_last_active';
export const DISMISSED_TRAIT_NAME = 'jira_screen_viewed_thirdpartynudgescreen_first_time';
export const MSTEAMS_ISSUES_VIEW_TRAIT_NAME =
	'jira_screen_viewed_issuesnavigatorthirdpartynudgescreen_last_time';
export const NOTIFICATION_BELL_TRAIT_NAME =
	'jira_screen_viewed_notificationbellthirdpartynudgescreen_last_time';
export const JIRA_FIRST_ACTIVE_TRAIT = 'jira_first_active';
export const ISSUE_WATCH_NUDGE_TRAIT_NAME =
	'jira_screen_viewed_issuewatchthirdpartynudgescreen_last_time';
