import React from 'react';
import { Dashboards } from './dashboards';
import { IssueViewModal } from './issue-view-modal';
import { KeyboardShortcuts } from './keyboard-shortcuts';
import { Projects } from './projects';

export const Integrations = () => {
	if (!__SERVER__) {
		return (
			<>
				<KeyboardShortcuts />
				<IssueViewModal />
				<Projects />
				<Dashboards />
			</>
		);
	}
	return null;
};
