import React, { forwardRef } from 'react';
import { Settings as AkSettings, type SettingsProps } from '@atlaskit/atlassian-navigation';
import { SkeletonSettingsButton } from '@atlaskit/atlassian-navigation/skeleton-settings-button';

export const Settings = forwardRef<HTMLElement, SettingsProps>((props, ref) => {
	if (__SERVER__) {
		// @ts-expect-error - Type 'ReactNode' is not assignable to type 'string'.
		return <SkeletonSettingsButton label={props.tooltip} />;
	}

	const buttonProps = {
		...props,
		ref,
	};

	return <AkSettings {...buttonProps} />;
});
