import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { UNIAPA_LOCAL_STORAGE_KEY, SPOTLIGHT_LOCAL_STORAGE_ITEM_KEY } from './constants';
import type {
	TouchpointContextType,
	CoordinationStopProviderProps,
	UsingNotificationsToImproveAdminApprovalProviderProps,
} from './types';

const TouchpointContext = createContext<TouchpointContextType>({
	hasSpotlightBeenShown: true,
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	markSpotlightAsShown: () => {},
});

const localStorage = createLocalStorageProvider(UNIAPA_LOCAL_STORAGE_KEY);

export const CoordinationStopProvider = ({ stop, children }: CoordinationStopProviderProps) =>
	children(stop);

export const UsingNotificationsToImproveAdminApprovalProvider = ({
	children,
}: UsingNotificationsToImproveAdminApprovalProviderProps) => {
	const { cloudId, atlassianAccountId } = useTenantContext();
	const [hasSpotlightBeenShown, setHasSpotlightBeenShown] = useState<boolean>(true);

	const spotlightLocalStorageKey = `${SPOTLIGHT_LOCAL_STORAGE_ITEM_KEY}_${cloudId}_${atlassianAccountId}`;

	useEffect(() => {
		setHasSpotlightBeenShown(localStorage.get(spotlightLocalStorageKey) || false);
	}, [spotlightLocalStorageKey]);

	const markSpotlightAsShown = useCallback(() => {
		localStorage.set(spotlightLocalStorageKey, true);
		setHasSpotlightBeenShown(true);
	}, [setHasSpotlightBeenShown, spotlightLocalStorageKey]);

	return (
		<TouchpointContext.Provider
			value={{
				hasSpotlightBeenShown,
				markSpotlightAsShown,
			}}
		>
			{children}
		</TouchpointContext.Provider>
	);
};
export const useUsingNotificationsToImproveAdminApprovalContext = (): TouchpointContextType =>
	useContext(TouchpointContext);
