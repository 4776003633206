import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	learnMoreButtonText: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.learn-more-button-text',
		defaultMessage: 'Why am I seeing this?',
		description:
			'Button label for users to find more information about why they see a recommendation',
	},
	notInterestedButtonText: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.not-interested-button-text',
		defaultMessage: 'Not interested',
		description: 'Text for the "Not interested" button for user to dismiss the upsell banner',
	},
	reasonsModalTitle: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.reasons-modal-title',
		defaultMessage: 'Why am I seeing this?',
		description: 'Title of the reasons modal',
	},
	reasonsModalExplanation: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.reasons-modal-explanation',
		defaultMessage:
			"We think that <strong>Jira Service Management - Premium</strong> will improve your team's productivity. The recommendation is based on:",
		description: 'Explanation as to why the placeholder template is being shown',
	},
	reasonsModalBulletOne: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.reasons-modal-bullet-one',
		defaultMessage: 'the size of your team',
		description: 'First bullet point of the reasons modal',
	},
	reasonsModalBulletTwo: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.reasons-modal-bullet-two',
		defaultMessage: 'your existing Atlassian products',
		description: 'Second bullet point of the reasons modal',
	},
	privacyPolicy: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.privacy-policy',
		defaultMessage: 'Learn more about your data privacy with Atlassian',
		description: "Link to Atlassian's privacy policy",
	},
	reasonsModalButtonOk: {
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.dismiss-menu.reasons-modal-button-ok',
		defaultMessage: 'Ok',
		description: 'Ok text for the reasons modal',
	},
});
