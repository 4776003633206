import { ff } from '@atlassian/jira-feature-flagging';

export const LEGACY_PERSONAL_SETTINGS_PATH = '/secure/ViewPersonalSettings.jspa';

export const BASE_PERSONAL_SETTINGS_PATH = '/jira/settings/personal';

export const GENERAL_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/general`;

export const NOTIFICATIONS_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/notifications`;

export const OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH = `${BASE_PERSONAL_SETTINGS_PATH}/alert-notifications`;

export function getGeneralPersonalSettingsPath() {
	return ff('sea-1973-personal-settings-sidebar')
		? GENERAL_PERSONAL_SETTINGS_PATH
		: LEGACY_PERSONAL_SETTINGS_PATH;
}

export function getOpsPersonalSettingsPath() {
	return ff('sea-1973-personal-settings-sidebar')
		? OPS_NOTIFICATIONS_PERSONAL_SETTINGS_PATH
		: LEGACY_PERSONAL_SETTINGS_PATH;
}

export function getNotificationsPersonalSettingsPath() {
	if (ff('sea-1973-personal-settings-sidebar')) {
		if (ff('sea-1973-notifications-hub')) {
			return NOTIFICATIONS_PERSONAL_SETTINGS_PATH;
		}
		return GENERAL_PERSONAL_SETTINGS_PATH;
	}
	return LEGACY_PERSONAL_SETTINGS_PATH;
}
