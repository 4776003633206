/** @jsx jsx */
import React, { useEffect, useState, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import Lozenge from '@atlaskit/lozenge';
import { HeadingItem, Section } from '@atlaskit/menu';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useUserTraits } from '@atlassian/jira-business-common';
import {
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat, useProfileLink } from '@atlassian/jira-navigation-apps-common';
import {
	getGeneralPersonalSettingsPath,
	getNotificationsPersonalSettingsPath,
} from '@atlassian/jira-personal-settings-paths';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { peopleProfilesRoute } from '@atlassian/jira-router-routes-people-profiles-routes';
import {
	getGeneralPersonalSettingsRouteForLink,
	getNotificationsPersonalSettingsRouteForLink,
} from '@atlassian/jira-router-routes-personal-settings-routes';
import { userBoardRoute } from '@atlassian/jira-router-routes-user-board-routes';
import { BUSINESS, useJsmOnboardingType } from '@atlassian/jira-servicedesk-onboarding-checklist';
import { OpenMenuItem as JswQuickstartOpenMenuItem } from '@atlassian/jira-software-onboarding-quickstart/src/async';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';
import { EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../common/constants';
import { MenuItem } from '../../../common/ui/menu/item';
import { MenuLayout as Layout } from '../../../common/ui/menu/layout';
import { MenuList, MenuListItem } from '../../../common/ui/menu/list';
import { AccountDetails } from './account-details';
import { ExtraItems } from './extra-items';
import messages from './messages';
import ThemeSwitcher from './theme-switcher-item';
import type { MenuProps } from './types';
import { UpgradeEditionMenuItem } from './upgrade-edition';

export const Menu = ({ testIdPrefix, userBoardId, userBoardOwnerAccountId }: MenuProps) => {
	const { formatMessage } = useIntl();
	const {
		data: { user },
	} = useCurrentUser();
	const accountId = useAccountId();
	const profileLink = useProfileLink();
	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});

	useEffect(() => {
		onSuccess();
	}, [onSuccess]);

	const profileParams = { subPath: accountId };
	const testId = testIdConcat(testIdPrefix, 'menu-popup');
	const testIdContent = testIdConcat(testId, 'content');
	const testIdFooter = testIdConcat(testId, 'footer');
	const [upgradeEditionMenuShown, setUpgradeEditionMenuShown] = useState<boolean>(false);
	const { data: projectContextData } = useProjectContext();
	const [{ data: jsmOnboardingData }, { fetchOnboardingType }] = useJsmOnboardingType();

	useEffect(() => {
		fetchOnboardingType(projectContextData?.projectId, projectContextData?.projectType);
	}, [fetchOnboardingType, projectContextData?.projectId, projectContextData?.projectType]);

	const showInJwmProjects = projectContextData?.projectType === CORE_PROJECT;
	const showInSoftwareProjects = projectContextData?.projectType === SOFTWARE_PROJECT;
	const showInJsmProjects =
		projectContextData?.projectType === SERVICE_DESK_PROJECT &&
		jsmOnboardingData?.onboardingType === BUSINESS;

	let yourBoardsRoute;
	let yourBoardsParams;
	let yourBoardsQuery;
	if (userBoardId != null) {
		yourBoardsRoute = userBoardRoute;
		yourBoardsParams = {
			accountId: userBoardOwnerAccountId,
			boardId: String(userBoardId),
		};
	}

	const boardOffset = userBoardId == null ? -1 : 0;
	const notificationOffset = !ff('sea-1973-notifications-hub') ? -1 : 0;

	const [userTraits] = useUserTraits();
	const showNotificationsNewLozenge = useMemo(() => {
		const accountCreationDate = userTraits?.attributes?.find(
			(trait: { name: string }) => trait?.name === 'jira_first_active',
		)?.value;

		const aWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
		let isNewUser = false;
		if (accountCreationDate && typeof accountCreationDate === 'string') {
			isNewUser = new Date(accountCreationDate) > aWeekAgo;
		}

		return !isNewUser;
	}, [userTraits]);

	useEffect(() => {
		// feature flag to check if the notifications hub is enabled
		if (ff('sea-1973-notifications-hub')) {
			// The Spotlight components listen to the resize event to
			// update their positioning. This will fix Spotlights pulse overlap
			// that are shown on Menu items. Without this the pulse are located
			/// at incorrect position.
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 0);
		}
	});

	return (
		<span
			data-testid={testId}
			role="dialog"
			aria-labelledby="atlassian-navigation-account-dialog-heading"
			css={styles}
		>
			<Layout>
				<Section data-testid={testIdConcat(testIdContent, 'account')}>
					<HeadingItem
						id="atlassian-navigation-account-dialog-heading"
						testId={testIdConcat(testIdContent, 'account', 'heading')}
					>
						{formatMessage(messages.account)}
					</HeadingItem>
					<AccountDetails
						// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
						email={user.emailAddress || ''}
						userFullname={user.userFullname}
						// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
						avatarSrc={user.avatarUrls ? user.avatarUrls['32x32'] : undefined}
						testId={testIdConcat(testIdContent, 'account')}
					/>
				</Section>
				<Section hasSeparator={upgradeEditionMenuShown}>
					<UpgradeEditionMenuItem setUpgradeEditionMenuShown={setUpgradeEditionMenuShown} />
				</Section>
				<Section
					hasSeparator={upgradeEditionMenuShown || !upgradeEditionMenuShown}
					testId={testIdContent}
				>
					{(showInJwmProjects || showInSoftwareProjects || showInJsmProjects) && (
						<JswQuickstartOpenMenuItem MenuItem={MenuItem} />
					)}
					<span data-testid={testIdConcat(testIdContent, 'jira')}>
						<HeadingItem testId={testIdConcat(testIdContent, 'jira', 'heading')}>
							{formatMessage(messages.jira)}
						</HeadingItem>
						<MenuList>
							<MenuListItem ariaLabel={formatMessage(messages.jira)}>
								<MenuItem
									analytics={{
										actionSubjectId: 'profileNavigationMenuItem',
										menuItemId: 'profile',
										menuItemType: 'jira',
										position: 0,
									}}
									href={profileLink}
									params={profileParams}
									testId={testIdConcat(testIdContent, 'jira', 'item-profile')}
									to={peopleProfilesRoute}
								>
									{formatMessage(messages.profile)}
								</MenuItem>
							</MenuListItem>
							{userBoardId !== null && (
								<MenuListItem ariaLabel={formatMessage(messages.jira)}>
									<MenuItem
										analytics={{
											actionSubjectId: 'profileNavigationMenuItem',
											menuItemId: 'your-boards',
											menuItemType: 'jira',
											position: 1,
										}}
										href={`/jira/people/${String(userBoardOwnerAccountId)}/boards/${userBoardId}`}
										params={yourBoardsParams}
										query={yourBoardsQuery}
										testId={testIdConcat(testIdContent, 'jira', 'item-your-boards')}
										to={yourBoardsRoute}
									>
										{formatMessage(messages.yourBoards)}
									</MenuItem>
								</MenuListItem>
							)}
							<MenuListItem ariaLabel={formatMessage(messages.jira)}>
								<MenuItem
									analytics={{
										actionSubjectId: 'profileNavigationMenuItem',
										menuItemId: 'personal-settings',
										menuItemType: 'jira',
										position: 2 + boardOffset,
									}}
									href={getGeneralPersonalSettingsPath()}
									testId={testIdConcat(testIdContent, 'jira', 'item-personal-settings')}
									to={getGeneralPersonalSettingsRouteForLink()}
								>
									{formatMessage(messages.personalSettings)}
								</MenuItem>
							</MenuListItem>
							{ff('sea-1973-notifications-hub') && (
								<>
									<MenuListItem ariaLabel={formatMessage(messages.jira)}>
										<SpotlightTarget name="notifications-menu-spotlight">
											<MenuItem
												analytics={{
													actionSubjectId: 'profileNavigationMenuItem',
													menuItemId: 'notifications',
													menuItemType: 'jira',
													position: 3 + boardOffset,
												}}
												href={getNotificationsPersonalSettingsPath()}
												testId={testIdConcat(testIdContent, 'jira', 'item-notifications')}
												to={getNotificationsPersonalSettingsRouteForLink()}
												iconAfter={
													showNotificationsNewLozenge ? (
														<Lozenge appearance="new">{formatMessage(messages.new)}</Lozenge>
													) : null
												}
											>
												{formatMessage(messages.notifications)}
											</MenuItem>
											<EngagementSpotlight engagementId="notifications-menu-spotlight" />
										</SpotlightTarget>
									</MenuListItem>
								</>
							)}
							<MenuListItem ariaLabel={formatMessage(messages.jira)}>
								<ThemeSwitcher
									testId={testIdConcat(testIdContent, 'jira', 'item-theme')}
									itemPosition={4 + boardOffset + notificationOffset}
								/>
							</MenuListItem>
							<ExtraItems
								startPosition={5 + boardOffset + notificationOffset}
								testIdPrefix={testIdContent}
								itemAriaLabel={formatMessage(messages.jira)}
							/>
						</MenuList>
					</span>
				</Section>

				<Section hasSeparator testId={testIdFooter}>
					<span data-testid={testIdConcat(testIdFooter, 'items')}>
						<MenuItem
							analytics={{
								actionSubjectId: 'profileNavigationMenuItem',
								menuItemId: 'logout',
								menuItemType: 'action',
								position: 0,
							}}
							href="/logout"
							testId={testIdConcat(testIdFooter, 'items', 'item-log-out')}
						>
							{formatMessage(messages.logout)}
						</MenuItem>
					</span>
				</Section>
			</Layout>
		</span>
	);
};
const styles = css({ color: token('color.text', N800) });
