import React, {
	type ComponentType,
	useRef,
	useCallback,
	useEffect,
	useState,
	useMemo,
} from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import {
	ADMIN_PAGE_MODULE,
	CUSTOM_FIELD_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	GLOBAL_PAGE_MODULE,
	ISSUE_ACTION_MODULE,
	ISSUE_ACTIVITY_MODULE,
	ISSUE_CONTEXT_MODULE,
	ISSUE_GLANCE_MODULE,
	ISSUE_PANEL_MODULE,
	PROJECT_PAGE_MODULE,
	PROJECT_SETTINGS_PAGE_MODULE,
} from '@atlassian/jira-forge-ui-constants';
import type {
	ExtensionPointModule,
	EntryPoint,
	InitialRenderPayload,
	Extension,
} from '@atlassian/jira-forge-ui-types';
import {
	getForgeUIType,
	isCustomUIExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import { useHasForgeAccess } from '@atlassian/jira-forge-ui-utils-internal/src/utils/forge-access';
import {
	ContextualAnalyticsData,
	fireOperationalAnalytics,
	FireScreenAnalytics,
	MountEvent,
	SCREEN,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import { MODULE_TYPE_EXTENSION, PACKAGE_NAME_GASV3 } from '../../constants';
import { getEventId, getEventActionName, getBrowserMetricsEventId } from '../utils';
import { createAttributes } from '../utils/create-attributes';
import { fireOperationalFailedEvent } from '../utils/fire-operational-failed-event';
import { getAnalyticsAttributesFromExtension } from '../utils/get-analytics-attributes-from-extension';
import { forgeModulesConcurrentLoadTime } from '../utils/performance-analytics';
import type {
	ContextualAnalyticsDataProps,
	ErrorBoundaryProps,
	MountEventProps,
	ScreenEventProps,
	AnalyticsWrapperProps,
} from './types';

export const ForgeScreenEvent = ({ attributes }: ScreenEventProps) => (
	<FireScreenAnalytics attributes={createAttributes(attributes)} />
);

export const ForgeOperationaFailedEvent = ({
	eventId,
	attributes,
}: {
	eventId: string;
	attributes?: Attributes;
}) => {
	fireOperationalFailedEvent({
		eventId,
		attributes,
	});
	return null;
};

export const ForgeMountEvent = ({ eventId, action, attributes }: MountEventProps) => {
	const hasForgeAccess = useHasForgeAccess();
	const analyticsAttributes: Attributes = createAttributes({
		...attributes,
		hasForgeAccess,
	});

	const fireEvent = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireOperationalAnalytics(
				analyticsEvent,
				getEventActionName(eventId, action),
				analyticsAttributes,
			);
		},
		[eventId, action, analyticsAttributes],
	);

	return <MountEvent onMount={fireEvent} />;
};

export const ForgeContextualAnalyticsData = ({
	children,
	eventId,
}: ContextualAnalyticsDataProps) => (
	<ContextualAnalyticsData sourceType={SCREEN} sourceName={`${PACKAGE_NAME_GASV3}.${eventId}`}>
		{children}
	</ContextualAnalyticsData>
);

export const ForgeErrorBoundary = ({
	children,
	eventId,
	attributes,
	onError,
	errorFallback,
	resetCaughtError,
}: ErrorBoundaryProps) => {
	const fallback = useMemo(() => errorFallback || 'unmount', [errorFallback]);
	const getAttributes = useCallback(
		(error: Error) =>
			createAttributes({
				...attributes,
				error,
			}),
		[attributes],
	);

	return (
		<JSErrorBoundary
			id={eventId}
			packageName={PACKAGE_NAME_GASV3}
			teamName="smite"
			fallback={fallback}
			attributes={getAttributes}
			onError={onError}
			resetCaughtError={resetCaughtError}
		>
			{children}
		</JSErrorBoundary>
	);
};

export const ForgeAnalyticsWrapper = ({
	children,
	module,
	entryPoint,
	attributes,
	moduleType = MODULE_TYPE_EXTENSION,
	errorFallback = 'unmount',
	shouldFireInitializedEvent = false,
	resetCaughtError,
}: AnalyticsWrapperProps) => {
	const eventId = getEventId({
		module,
		moduleType,
		entryPoint,
	});

	const analyticsAttributes = useMemo(() => createAttributes(attributes), [attributes]);

	return (
		<ForgeContextualAnalyticsData eventId={eventId}>
			<ForgeErrorBoundary
				eventId={eventId}
				attributes={analyticsAttributes}
				errorFallback={errorFallback}
				resetCaughtError={resetCaughtError}
			>
				{shouldFireInitializedEvent && (
					<ForgeMountEvent
						eventId={eventId}
						action="initialized"
						attributes={analyticsAttributes}
					/>
				)}
				{children}
			</ForgeErrorBoundary>
		</ForgeContextualAnalyticsData>
	);
};

type ForgeInjectedAnalyticsProps = {
	extension: Extension;
	localId?: string;
	module: ExtensionPointModule;
	entryPoint?: EntryPoint;
	analyticsAttributes?: Attributes;
	onInitialRender?: (payload: InitialRenderPayload) => void;
	onLoad?: () => void;
};

function isExtensionTimeMeasuredFromTriggerClick(
	extensionModule: ExtensionPointModule,
	entryPoint: EntryPoint,
	attributes: Attributes,
): boolean {
	if (
		(extensionModule === CUSTOM_FIELD_MODULE || extensionModule === CUSTOM_FIELD_TYPE_MODULE) &&
		(entryPoint !== 'edit' || attributes.renderContext === 'issue-create')
	) {
		return false;
	}

	if (extensionModule === ISSUE_PANEL_MODULE && !attributes?.isNewToIssue) {
		return false;
	}

	return (
		/* eslint-disable @typescript-eslint/consistent-type-assertions */
		(
			[
				GLOBAL_PAGE_MODULE,
				ISSUE_ACTION_MODULE,
				ISSUE_ACTIVITY_MODULE,
				ISSUE_GLANCE_MODULE,
				ISSUE_CONTEXT_MODULE,
				ISSUE_PANEL_MODULE,
				CUSTOM_FIELD_MODULE,
				CUSTOM_FIELD_TYPE_MODULE,
				PROJECT_PAGE_MODULE,
				ADMIN_PAGE_MODULE,
				PROJECT_SETTINGS_PAGE_MODULE,
			] as ExtensionPointModule[]
		).includes(extensionModule)
		/* eslint-enable @typescript-eslint/consistent-type-assertions */
	);
}

export const withMetrics =
	<C,>(Component: ComponentType<C>): ComponentType<C & ForgeInjectedAnalyticsProps> =>
	(props) => {
		const startTime = useRef(performance.now());
		const isCustomUI = isCustomUIExtension(props.extension, props.entryPoint);
		const [isExtensionRendered, setExtensionRendered] = useState(false);

		const {
			module,
			entryPoint,
			analyticsAttributes,
			localId = '',
			onInitialRender,
			onLoad,
			extension,
		} = props;

		const extensionId = props.extension?.id;

		const eventId = getEventId({
			module,
			entryPoint,
		});

		const browserMetricsEventId = getBrowserMetricsEventId({
			module,
			entryPoint,
			forgeUiType: getForgeUIType(extension, entryPoint),
		});

		const attributes = useMemo(
			() => ({
				...analyticsAttributes,
				...getAnalyticsAttributesFromExtension(extension, entryPoint),
			}),
			// because extension is an object but we only want extension.id to trigger re-render
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[analyticsAttributes, entryPoint, extension.id, isCustomUI],
		);

		useEffect(
			() => {
				if (
					!isExtensionTimeMeasuredFromTriggerClick(
						extension.type,
						entryPoint ?? 'view',
						attributes,
					) &&
					extensionId
				) {
					forgeModulesConcurrentLoadTime(extensionId).start({ startTime: startTime.current });
				}
			},
			// We want to run this code only once on mount
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[],
		);

		const onError = useCallback(() => {
			extensionId && forgeModulesConcurrentLoadTime(extensionId).cancel();
		}, [extensionId]);

		const onInitialRenderUiKitWithMetrics = useCallback(
			(payload: InitialRenderPayload) => {
				onInitialRender && onInitialRender(payload);

				if (payload?.error == null) {
					const stopTimeDeduction = payload?.appTime || 0;
					setExtensionRendered(true);
					extensionId &&
						forgeModulesConcurrentLoadTime(extensionId).stop({
							stopTime: performance.now() - stopTimeDeduction,
							customData: { eventId: browserMetricsEventId },
						});
				} else {
					onError();
					fireOperationalFailedEvent({
						eventId,
						attributes: {
							error: new Error(payload.error),
							...attributes,
						},
					});
				}
			},
			[onInitialRender, extensionId, browserMetricsEventId, onError, eventId, attributes],
		);

		const onLoadCustomUiWithMetrics = useCallback(() => {
			onLoad && onLoad();
			setExtensionRendered(true);
			extensionId &&
				forgeModulesConcurrentLoadTime(extensionId).stop({
					stopTime: performance.now(),
					customData: { eventId: browserMetricsEventId },
				});

			// SMT-1418
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [analyticsAttributes, extensionId, localId, onLoad]);

		return (
			<ForgeContextualAnalyticsData eventId={eventId}>
				<Component
					{...props}
					{...(isCustomUI
						? { onLoad: onLoadCustomUiWithMetrics }
						: { onInitialRender: onInitialRenderUiKitWithMetrics })}
				/>
				{isExtensionRendered && <ForgeScreenEvent attributes={attributes} />}
			</ForgeContextualAnalyticsData>
		);
	};
