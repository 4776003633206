import { useEffect, useState } from 'react';
import { fetchJson } from '@atlassian/jira-fetch';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import type { SlackConnectedResponse, SlackEnabledResponse } from './types';

export const fetchSlackEnabled = (cloudId: string) =>
	fetchJson<SlackEnabledResponse>(`/gateway/api/slack/jira/enabled?cloudId=${cloudId}`, {
		method: 'GET',
	})
		.then((response) => Boolean(response.enabled))
		.catch(() => true);

export const fetchSlackConnected = () =>
	fetchJson<SlackConnectedResponse>('/gateway/api/slack/jira/status', {
		method: 'GET',
	})
		.then((response) => Boolean(response.connected))
		.catch(() => false);

export const useFetchSlackStatus = (
	skip = false,
): {
	isLoading: boolean;
	isSlackIntegrationEnabled: boolean;
	isSlackIntegrationConnected: boolean;
} => {
	const cloudId = useCloudId();

	const [isSlackIntegrationEnabled, setSlackIntegrationEnabled] = useState<boolean>(true);
	const [isSlackIntegrationConnected, setSlackIntegrationConnected] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		if (skip || !cloudId) {
			setLoading(false);
			return;
		}

		setLoading(true);
		setSlackIntegrationEnabled(true);
		setSlackIntegrationConnected(false);

		Promise.all([fetchSlackEnabled(cloudId), fetchSlackConnected()]) // eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then(([isEnabled, isConnected]: [any, any]) => {
				setSlackIntegrationEnabled(isEnabled);
				setSlackIntegrationConnected(isConnected);
			}) // Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	}, [skip, cloudId]);

	return {
		isLoading,
		isSlackIntegrationEnabled,
		isSlackIntegrationConnected,
	};
};
