import type { Action, StoreActionApi } from 'react-sweet-state';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequest } from '@atlassian/jira-fetch';
import { PACKAGE_NAME } from '../../common/constants';
import type { State } from './types';

const performGetRequestForTraits = async (url: string) => {
	try {
		const response = await performGetRequest(url);
		if (response?.attributes) {
			return { data: response.attributes, error: null };
		}
		throw Error('response is not defined or null');
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'jwmSampleProjectService',
				packageName: PACKAGE_NAME,
				teamName: 'jira-werewolves',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
		return { data: [], error };
	}
};

export const fetchTraits =
	(cloudId: string, atlassianAccountId: string) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const { isFetching } = getState();
		if (!isFetching) {
			setState({
				isFetching: true,
				fetchError: null,
			});
			const siteTraitUrl = `/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}`;
			const siteUserTraitUrl = `${siteTraitUrl}/user/${atlassianAccountId}`;
			const [
				{ data: listofSiteUserTraits, error: errorOnSiteUser },
				{ data: listofSiteTraits, error: errorOnSite },
			] = await Promise.all([
				performGetRequestForTraits(siteUserTraitUrl),
				performGetRequestForTraits(siteTraitUrl),
			]);
			const errors = errorOnSiteUser || errorOnSite;
			setState({
				traits: listofSiteUserTraits,
				siteTraits: listofSiteTraits,
				isFetching: false,
				fetchError: errors,
				hasFetchedOnce: true,
				...(errors ? {} : { hasSuccessOnce: true }),
			});
		}
	};

export const actions = {
	fetchTraits:
		(cloudId: string, atlassianAccountId: string): Action<State> =>
		async ({ setState, dispatch }) => {
			const promise = dispatch(fetchTraits(cloudId, atlassianAccountId));

			setState({
				isFetching: true,
				promise,
			});
		},
} as const;
