import React from 'react';
import { CustomItem } from '@atlaskit/menu';
import type { RouteLinkItemProps } from '@atlassian/jira-navigation-types';
import { Link } from '@atlassian/jira-router';

export const RouteLinkItem = ({ rel, target, ...props }: RouteLinkItemProps) => (
	<CustomItem
		component={Link}
		rel={rel ?? target === '_blank' ? 'noopener noreferrer' : ''}
		target={target}
		prefetch="hover"
		{...props}
	/>
);
