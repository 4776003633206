import { createStore, createHook, createContainer, createSubscriber } from 'react-sweet-state';
import {
	SCRUM_TEAM_MANAGED_KEY,
	KANBAN_TEAM_MANAGED_KEY,
} from '@atlassian/jira-common-constants/src/project-template-type-keys';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { KANBAN_ONBOARDING_TASK, QUICKSTART_UI_STATUS } from '../common/constants';
import * as actions from './actions';
import { initialState } from './model';
import { USER_ROLE } from './model/constants';
import { getQuickstartTypeSelector } from './model/utils';
import type { State, Actions } from './types';

export { QUICKSTART_UI_STATUS };

export const Store = createStore<State, Actions>({
	name: 'jsw-onboarding-quickstart',
	initialState,
	actions,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JswOnboardingSubscriber = createSubscriber<State, Actions, State, Record<any, any>>(
	Store,
);

export const useJswOnboarding = createHook(Store);

export const JswOnboardingContainer = createContainer<
	State,
	Actions,
	{
		isGlobal: boolean;
	}
>(Store, {});

export const shouldShowNudgeSelector = (
	state: State,
	{
		itemNames,
	}: {
		itemNames: string[];
	},
) => {
	const commonConditionsToShowNudge =
		state.shouldRenderComponent &&
		(expValEquals('jsm_business_quickstart', 'cohort', 'experiment')
			? state.visibility[getQuickstartTypeSelector(state)] === QUICKSTART_UI_STATUS.OPEN
			: state.isOpen && !state.isDismissed) &&
		// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
		itemNames.includes(state.openTask);

	return commonConditionsToShowNudge && state.hasNudgeActive;
};

export const openTaskSelector = (state: State) => state.openTask;

export const useOpenTask = createHook(Store, {
	selector: openTaskSelector,
});

// nudge step is used to control which nudge to show in multistep nudges
export const nudgeStepSelector = (state: State) => state.nudgeStep;

export const useNudgeStep = createHook(Store, {
	selector: nudgeStepSelector,
});

export const useQuickstartActions = createHook(Store, { selector: null });

export const useShouldShowNudge = createHook(Store, {
	selector: shouldShowNudgeSelector,
});

// TODO: KURO-1678, delete
const useShouldShowOpenMenuItemHook = createHook(Store, {
	selector: (state: State) => {
		if (expValEquals('jsm_business_quickstart', 'cohort', 'experiment')) {
			return (
				state.shouldRenderComponent &&
				state.visibility[getQuickstartTypeSelector(state)] === QUICKSTART_UI_STATUS.DISMISSED
			);
		}

		return state.shouldRenderComponent && state.isDismissed;
	},
});

export type UseShouldShowOpenMenuItemType = () => {
	shouldShow: boolean;
	setIsDismissed: typeof actions.nextSetChecklistIsDismissed;
};

// TODO: KURO-1678, delete and replace usages with useQuickstartUiStatus
// @ts-expect-error - TS2322 - Type '() => { shouldShow: boolean; setIsDismissed: (isDismissed: boolean) => void | Promise<void>; }' is not assignable to type 'UseShouldShowOpenMenuItemType'.
export const useShouldShowOpenMenuItem: UseShouldShowOpenMenuItemType = () => {
	const [shouldShow, { nextSetChecklistIsDismissed }] = useShouldShowOpenMenuItemHook();

	return {
		shouldShow,
		setIsDismissed: nextSetChecklistIsDismissed,
	};
};

export const useInviteToProject = createHook(Store, {
	selector: (state: State) =>
		Boolean(state.projectState && state.projectState.canInvitePeopleToProject),
});

export const useShowVideoCards = createHook(Store, {
	selector: (state: State) => {
		if (!state.projectState) {
			return false;
		}

		const { isNextGen, userRole, isProjectAdmin } = state.projectState;
		return Boolean(
			isNextGen &&
				(userRole === USER_ROLE.JIRA_ADMIN || userRole === USER_ROLE.SITE_ADMIN || isProjectAdmin),
		);
	},
});

export const useProjectState = createHook(Store, {
	selector: (state: State) => state.projectState,
});

export const useQuickstartUiStatus = createHook(Store, {
	selector: (state: State) => {
		if (state.shouldRenderComponent === false) {
			return QUICKSTART_UI_STATUS.NOT_LOADED;
		}

		const quickstartType = getQuickstartTypeSelector(state);
		if (expValEquals('jsm_business_quickstart', 'cohort', 'experiment')) {
			return state.visibility[quickstartType];
		}

		if (state.isDismissed) {
			return QUICKSTART_UI_STATUS.DISMISSED;
		}

		if (state.isOpen) {
			return QUICKSTART_UI_STATUS.OPEN;
		}

		return QUICKSTART_UI_STATUS.MINIMIZED;
	},
});

// TODO: KURO-1678, delete and replace external usages with useQuickstartUiStatus
export const useIsLoaded = createHook(Store, {
	selector: (state: State) => state.isQuickstartLoaded,
});

export const useIsFocusedOn = createHook(Store, {
	selector: (state: State) => state.isFocusedOn,
});

export const useIsDismissed = createHook(Store, {
	selector: (state: State) => {
		if (expValEquals('jsm_business_quickstart', 'cohort', 'experiment')) {
			const visibility = state.visibility[getQuickstartTypeSelector(state)];
			return (
				visibility === QUICKSTART_UI_STATUS.DISMISSED ||
				(visibility === QUICKSTART_UI_STATUS.NOT_LOADED &&
					fg('jsw-perf-async-add-people-modal-load'))
			);
		}

		return state.isDismissed;
	},
});

export const useCompletedTaskList = createHook(Store, {
	selector: (state: State) => state.completedTaskList,
});

export const useAllTasksCompleted = createHook(Store, {
	selector: (state: State) => {
		if (!state.tasks) {
			return null;
		}

		return state.tasks.items.every((task) => state.completedTaskList.includes(task));
	},
});

export const useMountedNudges = createHook(Store, {
	selector: (state: State) => state.mountedNudges,
});

export const useHasEverSeen = createHook(Store, {
	selector: (state: State) => state.hasEverSeenRoute,
});

export const useRoadmapState = createHook(Store, {
	selector: (state: State) => ({
		roadmapIsCreateEnabled: state.roadmapIsCreateEnabled,
		roadmapIsListEmpty: state.roadmapIsListEmpty,
	}),
});

export const useShouldShowScrumOnboardingExperience = createHook(Store, {
	selector: (state: State) =>
		Boolean(
			state.isQuickstartLoaded &&
				!state.isDevOpsProject &&
				state.selectedProjectTemplateKeyFromOnboarding === SCRUM_TEAM_MANAGED_KEY &&
				state.projectState?.userRole === USER_ROLE.SITE_ADMIN,
		),
});

export const useShouldShowOnboardingKanbanTour = createHook(Store, {
	selector: (state: State) =>
		Boolean(
			state.isQuickstartLoaded &&
				!state.isDevOpsProject &&
				state.selectedProjectTemplateKeyFromOnboarding === KANBAN_TEAM_MANAGED_KEY &&
				state.projectState?.userRole === USER_ROLE.SITE_ADMIN &&
				// edge case - if e.g. welcome modal failed to load, and user completed task by themself, then the welcome modal should be skipped on subsequent page loads.
				!state.completedTaskList.includes(KANBAN_ONBOARDING_TASK),
		),
});

export const useHasNudgeActive = createHook(Store, {
	selector: (state: State) => state.hasNudgeActive,
});

export const useSelectedProjectTemplateKeyFromOnboarding = createHook(Store, {
	selector: (state: State) => state.selectedProjectTemplateKeyFromOnboarding,
});

export const usePersonaExperience = createHook(Store, {
	selector: (state: State) => state.personaExperience,
});

export { useOrgId } from './adminhub';
