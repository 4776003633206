import isEmpty from 'lodash/isEmpty';
import type { Extension, ForgeUIType } from '@atlassian/jira-forge-ui-types';

export const getForgeUIType = (extension: Extension, entryPoint = ''): ForgeUIType => {
	const { render, resource } = extension.properties;

	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CommonProps | (CommonProps & { label: string; dynamicProperties?: { function: string; } | undefined; icon?: string | undefined; status?: IssueGlanceStatus | undefined; }) | ... 9 more ... | { ...; }'.
	if (entryPoint && extension.properties[entryPoint]) {
		const { resource: entryPointResource, render: entryPointRender } =
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'CommonProps | (CommonProps & { label: string; dynamicProperties?: { function: string; } | undefined; icon?: string | undefined; status?: IssueGlanceStatus | undefined; }) | ... 9 more ... | { ...; }'.
			extension.properties[entryPoint];

		if (entryPointResource == null) {
			return 'ui-kit';
		}
		if (entryPointRender === 'native') {
			return 'native-ui';
		}
		return 'custom-ui';
	}

	if (resource == null) {
		return 'ui-kit';
	}
	if (render === 'native') {
		return 'native-ui';
	}
	return 'custom-ui';
};

export const isCustomUIExtension = (extension: Extension, entryPoint = '') =>
	getForgeUIType(extension, entryPoint) === 'custom-ui';

export const isNativeUiExtension = (extension: Extension, entryPoint = '') =>
	getForgeUIType(extension, entryPoint) === 'native-ui';

export const overrideExtension = <E extends Extension>(
	extension: E,
	propsToOverride: {
		resource?: string;
		requiresUserConsent?: boolean;
	},
): E => {
	if (extension == null) return extension;
	if (isEmpty(propsToOverride)) return extension;

	const { resource, requiresUserConsent } = propsToOverride;

	// We must also spread `extension.properties` because JS doesn't deep-copy objects.
	// Only spreading `extension` will result in `resource` on the original `extension`
	// object being modified.
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const extensionCopy: E = {
		...extension,
		properties: {
			...extension.properties,
		},
		requiresUserConsent,
	} as any; // eslint-disable-line @typescript-eslint/no-explicit-any

	if (resource != null) {
		extensionCopy.properties.resource = resource;
	}
	return extensionCopy;
};
