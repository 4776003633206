import { Component, type ReactNode, type ReactElement } from 'react';
import { AuthenticationError } from '@atlassian/jira-standard-errors';

export type AuthenticationErrorBoundaryProps = {
	children: ReactNode;
	render: () => ReactElement;
};

type ErrorInfo = {
	componentStack: string;
};

type CaughtError = {
	error: Error;
	info: ErrorInfo;
};

type State = {
	caughtError: CaughtError | null;
};

export class AuthenticationErrorBoundary extends Component<
	AuthenticationErrorBoundaryProps,
	State
> {
	state = { caughtError: null };

	componentDidCatch(error: Error, info: ErrorInfo) {
		if (error instanceof AuthenticationError) {
			this.setState({ caughtError: { error, info } });
			return;
		}
		throw error;
	}

	render() {
		const { caughtError } = this.state;
		const { children, render: renderFallback } = this.props;

		if (!caughtError) {
			return children;
		}

		return renderFallback();
	}
}
