import {
	type ApplicationKey,
	CORE,
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	SOFTWARE,
} from '@atlassian/jira-shared-types';

export const getProductKey = (
	subProduct: string | null,
	explicitlyLicensedProducts: ApplicationKey[],
): ApplicationKey | null => {
	if (subProduct === 'software') {
		return SOFTWARE;
	}
	if (subProduct === 'serviceDesk') {
		return SERVICE_DESK;
	}
	// useSubProduct currently doesn't support polaris
	// JPD's subproduct currently falls under to 'software'
	// https://hello.atlassian.net/wiki/spaces/STAR/pages/2940457206/Detach+from+JSW+analytics+subproduct
	if (subProduct === 'polaris') {
		return PRODUCT_DISCOVERY;
	}
	if (subProduct === 'core') {
		return CORE;
	}

	// If subProduct is null, we may be on a product-agnostic page e.g. "Your Work".
	// If there is only one licensed product, we can infer what the user is using.
	if (explicitlyLicensedProducts.length === 1) {
		return explicitlyLicensedProducts[0];
	}

	return null;
};
