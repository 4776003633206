import type { Alert } from '@atlassian/eoc-alert-common';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { fetchJson } from '@atlassian/jira-fetch';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';

type fetchAlertsParams = {
	cloudId: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	searchParams?: Record<string, any>;
};

export const fetchAlerts = async ({
	cloudId,
	searchParams,
}: fetchAlertsParams): Promise<Alert[]> => {
	try {
		const params = new URLSearchParams(searchParams ?? {});
		const result = await fetchJson(
			`/gateway/api/jsm/ops/web/${cloudId}/v1/alerts/?${params.toString()}`,
		);

		if (!result) {
			throw Error('Could not retrieve alerts');
		}

		// https://data-portal.internal.atlassian.com/analytics/registry/50237
		getAnalyticsWebClient().getInstance().sendOperationalEvent({
			action: 'succeeded',
			actionSubject: 'jiraPlatformServicesAlertsStore.fetchAlerts',
			source: 'alertsStore',
		});
		return result;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// https://data-portal.internal.atlassian.com/analytics/registry/50236
		fireErrorAnalytics({
			meta: { id: 'fetchAlerts', packageName: 'jiraAlertsStore', teamName: 'jenga' },
			attributes: { error, searchParams },
			error,
		});
		throw error;
	}
};
