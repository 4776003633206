import { metrics } from '@atlassian/browser-metrics';
import { TTIFFs } from './constants';
import type { StopTTICustomData } from './types';

export const globalIssueCreateTTIWithAdjustments = metrics.interaction({
	key: 'global-issue-create-tti-with-adjustments',
	timings: [
		{ key: 'gic-tti-delta', startMark: 'gic-tti' },
		{ key: 'script-execution', startMark: 'iframe-load' },
		{ key: 'loading-indicators-visibility', startMark: 'fields-registered' },
		{ key: 'time-until-oninit-resolved', endMark: 'oninit-resolved' },
		{ key: 'gic-all-fields-tti', endMark: 'gic-all-fields-loaded' },
	],
	featureFlags: TTIFFs,
});

export const startGlobalIssueCreateTTIWithAdjustments = () => {
	globalIssueCreateTTIWithAdjustments.start();
};

export const markGicTti = () => globalIssueCreateTTIWithAdjustments.mark('gic-tti');

export const stopTTI = (customData: StopTTICustomData) => {
	globalIssueCreateTTIWithAdjustments.stop({ customData });
};

export const cancelTTI = () => {
	globalIssueCreateTTIWithAdjustments.cancel();
};

export const markIframeLoad = () => {
	globalIssueCreateTTIWithAdjustments.mark('iframe-load');
};

export const markFieldsRegistered = () => {
	globalIssueCreateTTIWithAdjustments.mark('fields-registered');
};

export const markOnInitResolved = () => {
	globalIssueCreateTTIWithAdjustments.mark('oninit-resolved');
};
