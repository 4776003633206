import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	trialButtonTooltip: {
		id: 'navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.trial-button-tooltip',
		defaultMessage: 'More about your trial and how to pay',
		description: 'Tooltip for trial information button',
	},
	addPaymentDetailsTooltip: {
		id: 'navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.add-payment-details-tooltip',
		defaultMessage: 'Add payment details to keep your subscription',
		description: 'Tooltip or add payment details button',
	},
});
