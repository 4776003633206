import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { getLandingPageUrl } from '@atlassian/jira-servicedesk-insight-urls';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { NAVIGATION_ITEM_ID } from '../../common/constants';
import { MenuTrigger } from '../../common/ui/menu/trigger';
import messages from './messages';

export const InsightMenuItem = () => {
	const { formatMessage } = useIntl();
	const { isSiteAdmin, appEditions } = useTenantContext();

	return (
		<MenuTrigger
			href={getLandingPageUrl()}
			menuId={MENU_ID.INSIGHT}
			navigationItemId={NAVIGATION_ITEM_ID.INSIGHT}
			analyticsAttributes={{
				isSiteAdmin,
				serviceDeskEdition: appEditions?.serviceDesk,
			}}
		>
			{formatMessage(messages.titleCmdbAssets)}
		</MenuTrigger>
	);
};
