// eslint-disable-next-line jira/restricted/react-component-props
import React, { useMemo, type ComponentType, type ComponentProps } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	type MessageDescriptorV2 as MessageDescriptor,
	useIntlV2 as useIntlDI,
} from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { ProductSearchInputSkeleton as ProductSearchInputSkeletonDI } from '@atlassian/product-search-dialog/skeleton';
import { useTheme as useThemeDI, type UseThemeType } from '../../../controllers/theme';

type Props = {
	useTheme?: UseThemeType;
	useIntl?: typeof useIntlDI;
	ProductSearchInputSkeleton?: ComponentType<
		JSX.LibraryManagedAttributes<
			typeof ProductSearchInputSkeletonDI,
			ComponentProps<typeof ProductSearchInputSkeletonDI>
		>
	>;
	placeholderMessage?: MessageDescriptor;
	advancedSearchUrl?: string;
};

export const Skeleton = ({
	ProductSearchInputSkeleton = ProductSearchInputSkeletonDI,
	useTheme = useThemeDI,
	useIntl = useIntlDI,
	placeholderMessage,
	advancedSearchUrl,
}: Props) => {
	const theme = useTheme();
	const { formatMessage } = useIntl();
	const skeletonEnabled = ff('quick-search.jira.interactive-skeleton');

	const skeletonDialogFeatures = useMemo(
		() =>
			skeletonEnabled
				? {
						interactiveSkeleton: {
							enabled: skeletonEnabled,
							advancedSearchUrl,
							// @ts-expect-error - TS2345 - Argument of type 'MessageDescriptorV2 | undefined' is not assignable to parameter of type 'MessageDescriptorV2'.
							placeholder: formatMessage(placeholderMessage),
							customScriptDataAttributes: {
								'data-defer-stop': true,
							},
						},
					}
				: undefined,
		[advancedSearchUrl, placeholderMessage, skeletonEnabled, formatMessage],
	);

	return (
		<ProductSearchInputSkeleton
			shouldFillContainer={getWillShowNav4()}
			theme={theme && theme.mode && theme.mode.search}
			// @ts-expect-error - TS2322 - Type '{ interactiveSkeleton: { enabled: true; advancedSearchUrl: string | undefined; placeholder: string; customScriptDataAttributes: { 'data-defer-stop': boolean; }; }; } | undefined' is not assignable to type 'ProductInputSearchSkeletonFeatures | undefined'.
			features={skeletonDialogFeatures}
		/>
	);
};
