import { fetchJson } from '@atlassian/jira-fetch';
import type { AtlRequestInit } from './types';

const defaultFetchOptions: Partial<RequestInit> = {
	mode: 'cors',
	credentials: 'include',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
	},
};

export const fetchEntitlementDetails = (cloudId: string, options: AtlRequestInit = {}) => {
	const url = `/gateway/api/billing-ux/cloud/${cloudId}/billing/entitlement-group`;
	return fetchJson(url, {
		...defaultFetchOptions,
		...options,
		method: 'GET',
		headers: { ...defaultFetchOptions.headers, ...options.headers },
	});
};

export const convertDateStringToEpoch = (dateString?: string) =>
	dateString ? new Date(dateString).getTime() : undefined;
