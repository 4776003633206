import {
	type ApplicationEdition,
	type ApplicationEditionKey,
	PREMIUM_EDITION_KEY,
	STANDARD_EDITION_KEY,
} from '@atlassian/jira-shared-types';

export const EDITION_KEY: Partial<Record<ApplicationEdition, ApplicationEditionKey>> = {
	STANDARD_EDITION: STANDARD_EDITION_KEY,
	PREMIUM_EDITION: PREMIUM_EDITION_KEY,
};

export const NOT_ENROLLED = 'not-enrolled';
export const VARIATION = 'variation';
