import { useEffect, useRef, useState } from 'react';
import { useAddon } from '@atlassian/jira-addon-store';
import { usePersonalizationProvider } from '@atlassian/jira-business-sample-project';
import { DEFAULT_LOAD_COUNT_FAV, useFavoriteActions } from '@atlassian/jira-favorites-store';
import { ff } from '@atlassian/jira-feature-flagging';
import { useForgeGlobalItems } from '@atlassian/jira-forge-global-items-store';
import { useCreateProjectPermissionActions } from '@atlassian/jira-nav-item-service/src/services/create-project-permisson';
import { useRecentActions } from '@atlassian/jira-recents-store';
import { usePageLoadedSubscriber } from '@atlassian/jira-spa-state-controller';
import {
	useAccountId,
	useActivationId,
	useCloudId,
} from '@atlassian/jira-tenant-context-controller';

export const InitialDataLoader = () => {
	const [, { loadRecentAll }] = useRecentActions();
	const [, { loadFavoriteAll }] = useFavoriteActions();
	const [, { fetchCreateProjectPermission }] = useCreateProjectPermissionActions();
	const [, { load: loadForgeItem }] = useForgeGlobalItems();
	const [, { load: loadAddons }] = useAddon();
	const [, { fetchTraits }] = usePersonalizationProvider();

	const hasFetchedAll = useRef(false);
	const [{ hasLoaded }] = usePageLoadedSubscriber();
	const [hasTimedOut, setHasTimedOut] = useState(false);

	const accountId = useAccountId();
	const activationId = useActivationId();
	const cloudId = useCloudId();

	useEffect(() => {
		if ((hasLoaded || hasTimedOut) && !hasFetchedAll.current) {
			loadRecentAll();
			loadFavoriteAll(DEFAULT_LOAD_COUNT_FAV);
			if (ff('arken-1053-jira-forge-extension-changes-for-aegis_aws9x')) {
				loadForgeItem(cloudId, activationId);
			} else {
				loadForgeItem(cloudId);
			}
			fetchCreateProjectPermission();
			loadAddons();
			hasFetchedAll.current = true;
		}
	}, [
		hasLoaded,
		hasTimedOut,
		hasFetchedAll,
		loadRecentAll,
		loadFavoriteAll,
		fetchCreateProjectPermission,
		loadForgeItem,
		loadAddons,
		accountId,
		fetchTraits,
		cloudId,
		activationId,
	]);

	// given not all pages use SpaStateReady yet, we fallback to timeout
	// so we preload nav items anyway after some time
	useEffect(() => {
		let t: ReturnType<typeof setTimeout>;
		if (!hasLoaded) t = setTimeout(() => setHasTimedOut(true), 10 * 1000);
		return () => clearTimeout(t);
	}, [hasLoaded, setHasTimedOut]);

	return null;
};
