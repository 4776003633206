import React, { useMemo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import {
	measureInitialPageLoadAsyncResourcesTiming,
	withMetrics,
} from '@atlassian/jira-forge-ui-analytics';
import { PERFORMANCE_KEYS } from '@atlassian/jira-forge-ui-analytics/src/constants';
import { CONTEXT_TOKEN_TYPE_JIRA, CUSTOM_FIELD_MODULE } from '@atlassian/jira-forge-ui-constants';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types';
import type { CustomField as CustomFieldType } from '@atlassian/jira-forge-ui/src/ui/components/custom-field/view';
import Placeholder from '@atlassian/jira-placeholder';
import { Skeleton } from '../../../common/ui/skeleton';
import type { LazyComponentProps } from '../../../types';

export type Bridge = {
	submit: (opts: { data: ForgeCustomFieldValue }) => Promise<void>;
};

const getModule = () =>
	measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_END,
		() => import(/* webpackChunkName: "async-forge-ui-custom-field" */ './index'),
	);

const skeletonConfig = [{ width: 100 }];

export const LazyCustomFieldRenderer = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.CustomField), { ssr: false }),
);

export const LazyCustomField = (props: LazyComponentProps<typeof CustomFieldType>) => {
	const { extension, extensionData, extensionPayload } = props;
	const loadingComponent = useMemo(() => <Skeleton skeletonConfig={skeletonConfig} />, []);

	const extensionDataValue = useMemo(() => {
		const preparedExtensionData =
			extensionData?.issue && extensionData?.project
				? extensionData
				: {
						fieldType: extensionData.fieldType,
						fieldId: extensionData.fieldId,
						fieldName: extensionData.fieldName,
						renderContext: extensionData.renderContext,
					};

		return {
			...preparedExtensionData,
			type: extension.type,
		};
	}, [extension.type, extensionData]);

	const extraProps = useMemo(
		() => ({
			loadingComponent,
			module: CUSTOM_FIELD_MODULE,
			extensionData: extensionDataValue,
			extensionPayload,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue, extensionPayload, loadingComponent],
	);

	return (
		<Placeholder name="custom-field-renderer" fallback={loadingComponent}>
			<LazyCustomFieldRenderer {...props} {...extraProps} />
		</Placeholder>
	);
};

export const AsyncCustomField = LazyCustomField;
