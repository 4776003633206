import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const emceeAppRoute = {
	name: ROUTE_NAMES.MARKETPLACE_ADDONS_EMCEE,
	path: '/plugins/servlet/ac/com.atlassian.jira.emcee:environment(.?[\\w.]*)?/:page',
};
export const manageAppsRoute = {
	name: ROUTE_NAMES.MARKETPLACE_MANAGE_APPS,
	path: '/plugins/servlet/upm',
};
