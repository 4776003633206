import { useCallback } from 'react';
import { UNSAFE_noExposureExp, expValEquals } from '@atlassian/jira-feature-experiments';
import {
	DISMISSED_TRAIT_NAME,
	ExperimentType,
	MSTEAMS_ISSUES_VIEW_TRAIT_NAME,
	NOTIFICATION_BELL_TRAIT_NAME,
} from '../common/constants';
import { getHasSeenNudge } from '../services/local-storage';
import { usePersonalization } from '../services/personalization';
import { getIssuesViewNudgeState, getNotificationNudgeState } from '../services/session-storage';
import {
	hasSeenIssueCommentNudge,
	hasSeenIssueWatchNudge,
	hasSeenIssuesViewNudge,
	isTenantActiveOnMsteams,
	isTenantActiveOnSlack,
	isUserActiveOnMsteams,
	isUserActiveOnSlack,
	isNewJiraUser,
	hasSeenNotificationBellNudge,
} from '../utils';
import { useThirdPartyNudgeFlag } from './third-party-nudge-flag';

export const useThirdPartyNudgeExperiment = (
	cloudId: string,
	atlassianAccountId: string | null,
) => {
	const { showThirdPartyNudgeFlag } = useThirdPartyNudgeFlag({ cloudId });
	const { fetchAllTraits } = usePersonalization();

	const triggerIssueCommentThirdPartyNudgeExperiment = useCallback(async () => {
		const traits = await fetchAllTraits(cloudId, atlassianAccountId);
		if (!traits) {
			return;
		}

		// If the data is loading, return early, don't fire a feature exposure. A re-render should occur when the data is loaded.
		// If the user has dismissed the nudge, return early, don't fire a feature exposure.
		// If the user is on an active tenant and is not active themselves, show the nudge. Otherwise don't show the nudge since it may not be relevant.
		// If no trait is found, assume inactive.
		// Beware: active traits can be 0 which is considered falsey. Hence the undefined check here.
		const isEligibleForMsteams = isTenantActiveOnMsteams(traits) && !isUserActiveOnMsteams(traits);
		const isEligibleForSlack = isTenantActiveOnSlack(traits) && !isUserActiveOnSlack(traits);

		const hasSeenOtherThirdPartyNudges =
			hasSeenIssuesViewNudge(traits) ||
			hasSeenNotificationBellNudge(traits) ||
			hasSeenIssueWatchNudge(traits);

		const hasAlreadySeen = traits[DISMISSED_TRAIT_NAME] || getHasSeenNudge();

		if (
			hasAlreadySeen ||
			(!isEligibleForMsteams && !isEligibleForSlack) ||
			hasSeenOtherThirdPartyNudges
		) {
			return;
		}

		const [expConfig, fireFeatureExposure] = UNSAFE_noExposureExp(
			'third_party_app_nudge_on_after_jira_comment',
		);
		// Only fire feature exposure if all eligibility requirements have been met.
		// Fire exposure for control and variation.
		fireFeatureExposure();

		// Users in control should not see the nudge.
		if (expConfig.get('cohort', 'not-enrolled') !== 'variation') {
			return;
		}

		const experimentType = 'JSW_COMMENT';

		if (isEligibleForSlack && isEligibleForMsteams) {
			showThirdPartyNudgeFlag({
				variant: 'both',
				experimentType,
			});
		} else if (isEligibleForSlack) {
			showThirdPartyNudgeFlag({
				variant: 'slack',
				experimentType,
			});
		} else {
			showThirdPartyNudgeFlag({
				variant: 'msteams',
				experimentType,
			});
		}
	}, [fetchAllTraits, cloudId, atlassianAccountId, showThirdPartyNudgeFlag]);

	const triggerNotificationBellPartyNudgeExperiment = useCallback(async () => {
		const traits = await fetchAllTraits(
			cloudId,
			atlassianAccountId,
			ExperimentType.NOTIFICATION_BELL,
		);
		if (!traits) {
			return;
		}

		const isSlackOrTeamsActiveUser = isUserActiveOnMsteams(traits) || isUserActiveOnSlack(traits);

		const isNewJiraActiveUser = isNewJiraUser(traits);

		if (isSlackOrTeamsActiveUser || isNewJiraActiveUser) {
			return;
		}

		const hasSeenOtherThirdPartyNudges =
			hasSeenIssuesViewNudge(traits) ||
			hasSeenIssueCommentNudge(traits) ||
			hasSeenIssueWatchNudge(traits);

		const hasAlreadySeen = traits[NOTIFICATION_BELL_TRAIT_NAME] || getNotificationNudgeState();

		if (
			hasAlreadySeen ||
			hasSeenOtherThirdPartyNudges ||
			!expValEquals('third_party_app_notification_bell_nudge', 'cohort', 'variation')
		) {
			return;
		}

		const isEligibleForMsteams = isTenantActiveOnMsteams(traits);
		const isEligibleForSlack = isTenantActiveOnSlack(traits);

		const experimentType = 'NOTIFICATION_BELL';

		if (isEligibleForMsteams) {
			showThirdPartyNudgeFlag({
				variant: 'msteams',
				experimentType,
			});
		} else if (isEligibleForSlack) {
			showThirdPartyNudgeFlag({
				variant: 'slack',
				experimentType,
			});
		} else {
			showThirdPartyNudgeFlag({
				variant: 'both',
				experimentType,
			});
		}
	}, [fetchAllTraits, cloudId, atlassianAccountId, showThirdPartyNudgeFlag]);

	const shouldThirdPartySpotlightAppear = useCallback(async () => {
		const traits = await fetchAllTraits(cloudId, atlassianAccountId, ExperimentType.ISSUES_VIEW);
		if (!traits) {
			return false;
		}

		const isTenantActive = isTenantActiveOnMsteams(traits);

		const hasSeenOtherThirdPartyNudges =
			hasSeenIssueCommentNudge(traits) ||
			hasSeenNotificationBellNudge(traits) ||
			hasSeenIssueWatchNudge(traits);

		const hasAlreadySeen = traits[MSTEAMS_ISSUES_VIEW_TRAIT_NAME] || getIssuesViewNudgeState();

		const isEligibleUser = isTenantActive && !hasSeenOtherThirdPartyNudges && !hasAlreadySeen;

		return isEligibleUser
			? expValEquals('third_party_app_nudge_on_issues_view', 'cohort', 'variation')
			: false;
	}, [fetchAllTraits, cloudId, atlassianAccountId]);

	return {
		triggerIssueCommentThirdPartyNudgeExperiment,
		triggerNotificationBellPartyNudgeExperiment,
		shouldThirdPartySpotlightAppear,
	};
};
