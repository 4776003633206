import React from 'react';
import { styled } from '@compiled/react';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import Lozenge from '@atlaskit/lozenge';
import { HeadingItem } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { ProjectType } from '@atlassian/jira-common-constants';
import { getProductEdition } from '@atlassian/jira-common-editions/src/get-product-edition';
import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common';
import {
	fireUIAnalytics,
	FireUiAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { type ApplicationKey, FREE_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types';
import { useSubProduct } from '@atlassian/jira-subproduct';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { getTrialLength } from '@atlassian/jira-up-flow-common';
import { useUpflowModal } from '@atlassian/jira-upflow-iframe-plugin';
import { MenuItem } from '../../../../common/ui/menu/item';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list';
import {
	IFRAME_FLOW,
	GROWTH_FEATURE,
	CLICKED_EVENT_NAME,
	VIEWED_EVENT_NAME,
	PACKAGE_NAME,
	ANALYTIC_SOURCE,
} from './constants';
import type { UpgradeEditionMenuItemProps, MenuItemInfo } from './types';
import {
	getFreeEditionMenuItem,
	getJiraProductKey,
	shouldRenderMenuItem,
	getHref,
	getActivePageApplicationKey,
} from './utils';

export const RenderUpgradeMenuItem = ({ product }: { product: ApplicationKey | null }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const tenantContext = useTenantContext();
	const productEdition = product ? getProductEdition(product, tenantContext) : FREE_EDITION;
	const trialLength = getTrialLength(productEdition, STANDARD_EDITION);
	const freeEditionMenuItemInfo: MenuItemInfo = getFreeEditionMenuItem(useIntl, trialLength);
	const [, { mountUpflowModal }] = useUpflowModal();

	return (
		<>
			<FireUiAnalytics
				eventName={VIEWED_EVENT_NAME}
				attributes={{
					currentEdition: productEdition,
					growthFeature: GROWTH_FEATURE,
					touchpointId: freeEditionMenuItemInfo.touchpointId,
					flowId: freeEditionMenuItemInfo.flowId,
					isHideJpdFromUpgradeEditionMenuItemEnabled: ff('jsw.onboarding.rollout.kuro-2792'),
					computedProduct: product,
				}}
			/>
			<HeadingItem>{freeEditionMenuItemInfo.heading}</HeadingItem>
			<MenuList>
				<MenuListItem>
					<MenuItem
						analytics={{
							actionSubjectId: 'persistentUpgradeNavigationMenuItem',
							menuItemId: freeEditionMenuItemInfo.touchpointId,
							menuItemType: 'action',
							position: 0,
						}}
						onClick={() => {
							const analyticEvent = createAnalyticsEvent({});

							fireUIAnalytics(analyticEvent, CLICKED_EVENT_NAME, {
								currentEdition: productEdition,
								growthFeature: GROWTH_FEATURE,
								touchpointId: freeEditionMenuItemInfo.touchpointId,
								flowId: freeEditionMenuItemInfo.flowId,
								isHideJpdFromUpgradeEditionMenuItemEnabled: ff('jsw.onboarding.rollout.kuro-2792'),
								computedProduct: product,
							});

							if (product && freeEditionMenuItemInfo.displayUpflowModal === true)
								mountUpflowModal({
									targetEdition: STANDARD_EDITION,
									product,
									flow: IFRAME_FLOW,
									touchpointId: freeEditionMenuItemInfo.touchpointId,
								});
						}}
						href={getHref(freeEditionMenuItemInfo, product)}
						target={product ? undefined : '_blank'}
						iconAfter={
							product ? undefined : (
								<ShortcutIcon
									size="small"
									primaryColor={token('color.icon.subtle', colors.N100)}
									label=""
								/>
							)
						}
					>
						{freeEditionMenuItemInfo.content}
						<LozengeContainer>
							<Lozenge appearance={freeEditionMenuItemInfo.lozengeAppearance} isBold>
								{freeEditionMenuItemInfo.tagContent}{' '}
							</Lozenge>
						</LozengeContainer>
					</MenuItem>
				</MenuListItem>
			</MenuList>
		</>
	);
};

export const UpgradeEditionMenuItemOld = ({
	setUpgradeEditionMenuShown,
}: UpgradeEditionMenuItemProps) => {
	const tenantContext = useTenantContext();
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const subProduct = useSubProduct();
	const { isSiteAdmin } = tenantContext;

	const product = getJiraProductKey(subProduct, explicitlyLicensedProducts);

	if (!shouldRenderMenuItem(isSiteAdmin, product, explicitlyLicensedProducts, tenantContext)) {
		setUpgradeEditionMenuShown(false);
		return null;
	}

	// Add separator bar in the menu
	setUpgradeEditionMenuShown(true);

	return (
		<JSErrorBoundary id={ANALYTIC_SOURCE} packageName={PACKAGE_NAME} fallback="unmount">
			<RenderUpgradeMenuItem product={product} />
		</JSErrorBoundary>
	);
};

export const UpgradeEditionMenuItemNew = ({
	setUpgradeEditionMenuShown,
}: UpgradeEditionMenuItemProps) => {
	const tenantContext = useTenantContext();
	const { data: projectData, loading: projectDataLoading } = useProject();

	if (projectDataLoading) {
		return null;
	}

	const { isSiteAdmin } = tenantContext;
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const applicationKey = getActivePageApplicationKey(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		projectData?.type as ProjectType | undefined,
		explicitlyLicensedProducts,
	);

	if (
		!shouldRenderMenuItem(isSiteAdmin, applicationKey, explicitlyLicensedProducts, tenantContext)
	) {
		setUpgradeEditionMenuShown(false);
		return null;
	}

	// Add separator bar in the menu
	setUpgradeEditionMenuShown(true);

	return (
		<JSErrorBoundary id={ANALYTIC_SOURCE} packageName={PACKAGE_NAME} fallback="unmount">
			<RenderUpgradeMenuItem product={applicationKey} />
		</JSErrorBoundary>
	);
};

export const UpgradeEditionMenuItem = componentWithFF(
	'jsw.onboarding.rollout.kuro-2792',
	UpgradeEditionMenuItemNew,
	UpgradeEditionMenuItemOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeContainer = styled.span({
	marginLeft: token('space.100', '8px'),
});
