import type { CmdbUpsellPageStoreAction } from '../../types';
import { dismissPage } from '../dismiss-page';
import { enablePage } from '../enable-page';

export const toggleShowPage =
	(): CmdbUpsellPageStoreAction =>
	async ({ getState, dispatch }) => {
		const { userHasDismissed } = getState();
		if (userHasDismissed) {
			dispatch(enablePage());
		} else if (userHasDismissed === false) {
			dispatch(dismissPage());
		}
	};
