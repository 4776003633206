import { useState, useEffect } from 'react';
import { createStore, createHook, createContainer } from 'react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Plan } from '@atlassian/jira-portfolio-plan-wizard';
import traceUFOPress from '@atlassian/ufo-trace-press';
import type { State, Actions, ContainerProps } from './types';

const CONTROLLER_NAME = 'create-new-plan-wizard-drawer';

const initialState: State = { isOpen: false, plan: undefined };

const Store = createStore<State, Actions>({
	name: CONTROLLER_NAME,
	initialState,
	actions: {
		openDrawer: (plan?: Plan) => {
			if (fg('add_analytic_to_create_plan')) {
				traceUFOPress('arj-create-plan-wizard-drawer');
			}
			return ({ setState }) => setState({ isOpen: true, plan });
		},

		closeDrawer:
			() =>
			({ setState }) =>
				setState({ isOpen: false, plan: undefined }),
	},
});

export const useCreateNewPlanWizardDrawer = createHook(Store);
export const CreateNewPlanWizardDrawerProvider = createContainer<State, Actions, ContainerProps>(
	Store,
	{
		onInit:
			() =>
			({ setState }, { isOpen, plan }) => {
				setState({ isOpen, plan });
			},
	},
);

const EXCLUDED_I18N_FILES = ['index', 'languages', 'en'];

type IntlMessages = {
	[key: string]: string;
};
type ImportMessagesForLocale = (arg1: string) => Promise<{
	default?: IntlMessages;
}>;

const FALLBACK_INTL_MESSAGES: IntlMessages = Object.freeze({});

export const importMessagesForLocale: ImportMessagesForLocale = async (rawlocale = 'en') => {
	// Filter out country code as the AFE i18n does not use them e.g en => en-US
	const locale = rawlocale.split('-')[0];
	if (!locale || EXCLUDED_I18N_FILES.includes(locale)) {
		return { default: FALLBACK_INTL_MESSAGES };
	}
	try {
		if (process.env.BUNDLER === 'parcel') {
			// eslint-disable-next-line jira/import/webpack-magic-comment-entries
			const i18nModule = await import(
				// @ts-expect-error - TS2307 - Cannot find module '@atlassian/jira-portfolio-plan-wizard/dist/esm/i18n/*.js' or its corresponding type declarations.
				'@atlassian/jira-portfolio-plan-wizard/dist/esm/i18n/*.js'
			);
			const result = i18nModule[locale]();
			return result;
		}

		// eslint-disable-next-line jira/import/webpack-magic-comment-entries
		const result = await import(`@atlassian/jira-portfolio-plan-wizard/dist/cjs/i18n/${locale}`);
		return result;
	} catch {
		return { default: FALLBACK_INTL_MESSAGES };
	}
};

export const useImportedMessagesForLocale = () => {
	const { locale } = useIntl();
	const [state, setState] = useState<{
		locale: string;
		loading: boolean;
		intlMessages: IntlMessages | undefined;
	}>({
		locale,
		loading: true,
		intlMessages: undefined,
	});

	useEffect(() => {
		let mounted = true;
		const load = async () => {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			setState((state) => ({ ...state, loading: true }));
			const { default: intlMessages } = await importMessagesForLocale(locale);
			if (!mounted) return;
			// eslint-disable-next-line @typescript-eslint/no-shadow
			setState((state) => ({ ...state, loading: false, intlMessages }));
		};

		load();

		return () => {
			mounted = false;
		};
	}, [locale]);

	return state;
};
