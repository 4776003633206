import { keyframes, styled } from '@compiled/react';
import type { ThemeProps, ThemeTokens } from '@atlaskit/button/custom-theme-button';
import { N0, P500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EditionAwarenessContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'flex-start',
	gap: token('space.100', '8px'),
});

export const GRADIENT_CTA_BUTTON_SELECTED_START = token(
	'color.background.discovery.bold.pressed',
	'#352C63',
);
export const GRADIENT_CTA_BUTTON_SELECTED_END = token(
	'color.background.selected.bold.pressed',
	'#09326C',
);

export const GRADIENT_CTA_BUTTON_ACTIVE_START = token('color.chart.purple.boldest', '#5E4DB2');
export const GRADIENT_CTA_BUTTON_ACTIVE_END = token('color.chart.blue.boldest', '#0055CC');

export const BUTTON_BORDER_RADIUS = 16;

const EATopNavExperimentGradientBorderStyles = {
	borderRadius: '3px',
	border: `1px solid ${token('color.border.accent.purple', P500)}`,
	// 1px padding needed to show a gradient border
	padding: '1px',
	display: 'inline-flex',
	height: '30px',
	'&:hover': {
		background: `linear-gradient(45deg,
              ${GRADIENT_CTA_BUTTON_ACTIVE_START} 0%,
              ${GRADIENT_CTA_BUTTON_ACTIVE_END} 100%)`,
	},
	'&:active': {
		background: `linear-gradient(45deg,
              ${GRADIENT_CTA_BUTTON_SELECTED_START} 0%,
              ${GRADIENT_CTA_BUTTON_SELECTED_END} 100%)`,
	},
};

const sparkleAnimation = keyframes({
	'0%': {
		transform: 'scale(0)',
		opacity: 0,
	},
	'40%': {
		transform: 'scale(1)',
		opacity: 1,
	},
	// The sparkle itself "ends" at 80%. We save the remaining 20% as a short delay before the animation starts again
	'80%': {
		transform: 'scale(0)',
		opacity: 0,
	},
	'100%': {
		transform: 'scale(0)',
		opacity: 0,
	},
});

const sparkleAnimationProperties = {
	animationName: sparkleAnimation,
	animationDuration: '2s',
	animationTimingFunction: 'ease-in-out',
	animationIterationCount: 'infinite',
	transformOrigin: 'center',
	transformBox: 'fill-box',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EATopNavAnimatedButtonContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...EATopNavExperimentGradientBorderStyles,

	// Animate sparkle icon on button hover
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& #starTopRight': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			...sparkleAnimationProperties,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& #starBottomRight': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			...sparkleAnimationProperties,
			// We delay this star's animation so the two stars sparkle separately
			animationDelay: '0.5s',
			// Because this star has an animation delay, we need to make it invisible to begin with
			// so it doesn't show up in its unanimated state for the first 0.5s of the animation (or however long we set the delay to).
			opacity: 0,
		},
	},
});

export const EATopNavPillCss = {
	display: 'inline-flex',
	alignItems: 'center',
	border: 'none',
	gap: token('space.050', '4px'),

	borderRadius: '3px',
	transition: 'all 0.3s ease-in-out 0s',
	backgroundColor: `${token('color.text.inverse', N0)}`,
	padding: `${token('space.075', '6px')} ${token('space.150', '12px')}`,
	color: `${token('color.background.accent.purple.bolder', '#6E5DC6')}`,
	'> span': {
		margin: '0px',
	},

	'&:hover': {
		cursor: 'pointer',
		'& span': {
			color: `${token('color.text.inverse', N0)}`,
		},
		background: `linear-gradient(45deg,
              ${GRADIENT_CTA_BUTTON_ACTIVE_START} 0%,
              ${GRADIENT_CTA_BUTTON_ACTIVE_END} 100%)`,
		textDecoration: 'none',
	},
	'&:active': {
		cursor: 'pointer',
		color: `${token('color.text.inverse', N0)}`,
		'& span': {
			color: `${token('color.text.inverse', N0)}`,
		},
		background: `linear-gradient(45deg,
              ${GRADIENT_CTA_BUTTON_SELECTED_START} 0%,
              ${GRADIENT_CTA_BUTTON_SELECTED_END} 100%)`,
	},
};

export const EATopNavPillTheme = (
	currentTheme: (props: ThemeProps) => ThemeTokens,
	themeProps: ThemeProps,
) => {
	const theme = currentTheme(themeProps);
	return {
		...theme,
		buttonStyles: {
			...EATopNavPillCss,
		},
	};
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
	marginRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EATopNavButtonText = styled.span({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '14px',
	color: `${token('color.text.accent.blue', '#0055CC')}`,
	lineHeight: '20px',
});
