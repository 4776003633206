import type { CreateUIAnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import {
	fireUIAnalytics,
	fireTrackAnalytics,
	type Attributes,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { ACTION_SUBJECT_ID } from '../../common/constants';
import { convertTaskIdToActionSubjectId } from '../../common/utils';

export const fireAnalyticsOnQuickstartClose = (
	analyticsEvent: UIAnalyticsEvent,
	attributes: Attributes,
) => {
	analyticsEvent.update({ action: 'closed', actionSubject: 'button' });
	fireUIAnalytics(analyticsEvent, 'button closed', ACTION_SUBJECT_ID, attributes);
};

export const fireAnalyticsOnQuickstartDismiss = (
	analyticsEvent: UIAnalyticsEvent,
	attributes: Attributes,
	source: 'checklist' | 'button',
) => {
	if (source === 'button') {
		fireUIAnalytics(analyticsEvent, 'button clicked', 'dismissQuickstartButton', attributes);
	} else if (source === 'checklist') {
		fireUIAnalytics(analyticsEvent, 'button clicked', 'dismissQuickstart', attributes);
	}
};

export const fireAnalyticsOnQuickstartBack = (analyticsEvent: UIAnalyticsEvent) => {
	fireUIAnalytics(analyticsEvent, 'button clicked', 'backButton');
};

export const fireAnalyticsOnQuickstartExperienceComplete = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	attributes: Attributes,
) => {
	const analyticsEvent = createAnalyticsEvent({});
	fireTrackAnalytics(analyticsEvent, 'experience completed', ACTION_SUBJECT_ID, {
		...attributes,
	});
};

export const fireAnalyticsOnChecklistItemCompleted = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	completedTaskId: string | null,
) => {
	const analyticsEvent = createAnalyticsEvent({});

	fireTrackAnalytics(
		analyticsEvent,
		'checklistItem completed',
		convertTaskIdToActionSubjectId(completedTaskId),
		{
			taskId: completedTaskId,
		},
	);
};

export const fireAPIErrorAnalytics = (error: Error, endpointSource: string) => {
	fireErrorAnalytics({
		error,
		meta: {
			id: 'quickstartApiError',
			packageName: 'jiraOnboardingQuickstartCore',
			teamName: 'jlove-makkuro',
		},
		attributes: {
			error: error.message,
			endpointSource,
		},
	});
};

export const fireAnalyticsOnOpenRightHandSidebar = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	attributes: Attributes,
) => {
	const analyticsEvent = createAnalyticsEvent({});
	fireOperationalAnalytics(
		analyticsEvent,
		'nuxOnboardingQuickstart openRightSidebar',
		ACTION_SUBJECT_ID,
		{
			...attributes,
		},
	);
};

export const fireAnalyticsOnMinimiseRightHandSidebar = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	attributes: Attributes,
) => {
	const analyticsEvent = createAnalyticsEvent({});
	fireOperationalAnalytics(
		analyticsEvent,
		'nuxOnboardingQuickstart minimiseRightSidebar',
		ACTION_SUBJECT_ID,
		{
			...attributes,
		},
	);
};

export const fireAnalyticsOnCloseRightHandSidebar = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	attributes: Attributes,
) => {
	const analyticsEvent = createAnalyticsEvent({});
	fireOperationalAnalytics(
		analyticsEvent,
		'nuxOnboardingQuickstart closeRightSidebar',
		ACTION_SUBJECT_ID,
		{
			...attributes,
		},
	);
};
