import type { Action } from 'react-sweet-state';
import type { State } from '../../model/types';

export const setHasNudgeActive =
	(hasNudgeActive: boolean, itemName: string | null = ''): Action<State> =>
	({ getState, setState }) => {
		/* Grab current state of is currentHasNudgeActive */
		const { hasNudgeActive: currentHasNudgeActive, openTask } = getState();

		if (hasNudgeActive === currentHasNudgeActive) {
			// if state has not been updated we just return
			return;
		}

		if (hasNudgeActive === false && itemName !== openTask) {
			// do not update a nudge if the current open task is different than the one we called the function with
			return;
		}

		setState({ hasNudgeActive });
	};

export default setHasNudgeActive;
