import type { Action } from 'react-sweet-state';
import type { State } from '../../types';

export const setHasOpened =
	(): Action<State> =>
	({ getState, setState }) => {
		const { hasOpened } = getState();

		if (hasOpened === true) {
			// if state has been updated already return
			return;
		}

		setState({ hasOpened: true });
	};

export default setHasOpened;
