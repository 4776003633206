import { useEffect } from 'react';
import { expVal, UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ApplicationEdition, ApplicationKey } from '@atlassian/jira-shared-types';

/**
 * Experiment specific code to be removed during cleanup
 */
const trackJDPFreeToStandardExposure = ({
	createAnalyticsEvent,
	applicationKey,
	applicationEdition,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	createAnalyticsEvent: ReturnType<typeof useAnalyticsEvents>['createAnalyticsEvent'];
}) => {
	const cohort = expVal('jpd-free-to-upgrade-pill-au-awareness', 'cohort', 'not-enrolled');

	fireTrackAnalytics(
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			action: 'exposed',
			actionSubject: 'feature',
		}),
		'feature exposed',
		'editionAwarenessButton',
		{
			flagKey: 'jpd-free-to-upgrade-pill-au-awareness',
			value: cohort,
			growthInitiative: 'transmuters',
			applicationKey,
			applicationEdition,
		},
	);
};

/**
 * Experiment specific code to be removed during cleanup
 */
const useShouldSeeJPDFreeToStandardExperiment = ({
	applicationKey,
	applicationEdition,
	canUpgrade,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	canUpgrade: boolean;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [config] = UNSAFE_noExposureExp('jpd-free-to-upgrade-pill-au-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	const isEnrolled = cohort !== 'not-enrolled';

	const shouldSeeButton =
		canUpgrade &&
		applicationKey === 'jira-product-discovery' &&
		applicationEdition === 'FREE_EDITION';

	useEffect(() => {
		if (!isEnrolled) {
			return;
		}

		if (!shouldSeeButton) {
			return;
		}

		trackJDPFreeToStandardExposure({ createAnalyticsEvent, applicationEdition, applicationKey });
	}, [isEnrolled, createAnalyticsEvent, applicationEdition, applicationKey, shouldSeeButton]);

	return cohort === 'variation' && shouldSeeButton;
};

/**
 * Experiment specific code to be removed during cleanup
 */
const trackJSMFreeToStandardExposure = ({
	createAnalyticsEvent,
	applicationKey,
	applicationEdition,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	createAnalyticsEvent: ReturnType<typeof useAnalyticsEvents>['createAnalyticsEvent'];
}) => {
	const cohort = expVal('jsm-auto-upgrade-awareness', 'cohort', 'not-enrolled');

	fireTrackAnalytics(
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			action: 'exposed',
			actionSubject: 'feature',
		}),
		'feature exposed',
		'editionAwarenessButton',
		{
			flagKey: 'jsm-auto-upgrade-awareness',
			value: cohort,
			growthInitiative: 'transmuters',
			applicationKey,
			applicationEdition,
		},
	);
};

/**
 * Experiment specific code to be removed during cleanup
 */
const useShouldSeeJSMFreeToStandardExperiment = ({
	applicationKey,
	applicationEdition,
	canUpgrade,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	canUpgrade: boolean;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [config] = UNSAFE_noExposureExp('jsm-auto-upgrade-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	const isEnrolled = cohort !== 'not-enrolled';

	const shouldSeeButton =
		canUpgrade && applicationKey === 'jira-servicedesk' && applicationEdition === 'FREE_EDITION';

	useEffect(() => {
		if (!isEnrolled) {
			return;
		}

		if (!shouldSeeButton) {
			return;
		}

		trackJSMFreeToStandardExposure({ createAnalyticsEvent, applicationEdition, applicationKey });
	}, [isEnrolled, createAnalyticsEvent, applicationEdition, applicationKey, shouldSeeButton]);

	return cohort === 'variation' && shouldSeeButton;
};

export const useShouldSeeButton = ({
	applicationKey,
	applicationEdition,
	canUpgrade,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	canUpgrade: boolean;
}) => {
	const shouldSeeJPDFreeToStandardExperiment = useShouldSeeJPDFreeToStandardExperiment({
		applicationEdition,
		applicationKey,
		canUpgrade,
	});

	const shouldSeeJSMFreeToStandardExperiment = useShouldSeeJSMFreeToStandardExperiment({
		applicationEdition,
		applicationKey,
		canUpgrade,
	});

	if (shouldSeeJPDFreeToStandardExperiment || shouldSeeJSMFreeToStandardExperiment) {
		return true;
	}

	return false;
};
