// eslint-disable-next-line jira/restricted/react-component-props
import React, { useCallback, useState, type ComponentProps } from 'react';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension';
import {
	EditAnalyticsWrapper,
	ViewAnalyticsWrapper,
} from '@atlassian/jira-forge-ui-analytics/src/ui/custom-field';
import { AsyncCustomFieldEdit } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field-edit/async';
import { AsyncCustomField } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field/async';

export const CustomField = (
	props: JSX.LibraryManagedAttributes<
		typeof AsyncCustomField,
		ComponentProps<typeof AsyncCustomField>
	>,
) => (
	<ViewAnalyticsWrapper
		attributes={{
			...props.analyticsAttributes,
			...getAnalyticsAttributesFromExtension(props.extension),
		}}
	>
		<AsyncCustomField {...props} />
	</ViewAnalyticsWrapper>
);

const CustomFieldEditView = (
	props: JSX.LibraryManagedAttributes<
		typeof AsyncCustomFieldEdit,
		ComponentProps<typeof AsyncCustomFieldEdit>
	>,
) => {
	const [isOpen, setIsOpen] = useState(true);

	const { onCancel, onSave } = props;

	const onCancelCallback = useCallback(() => {
		setIsOpen(false);
		onCancel && onCancel();
	}, [onCancel]);

	const onSaveCallback = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
		(e) => {
			setIsOpen(false);
			return onSave(e);
		},
		[onSave],
	);

	return isOpen ? (
		<AsyncCustomFieldEdit {...props} onCancel={onCancelCallback} onSave={onSaveCallback} />
	) : null;
};

export const CustomFieldEdit = (
	props: JSX.LibraryManagedAttributes<
		typeof AsyncCustomFieldEdit,
		ComponentProps<typeof AsyncCustomFieldEdit>
	>,
) => (
	<EditAnalyticsWrapper
		attributes={{
			...props.analyticsAttributes,
			...getAnalyticsAttributesFromExtension(props.extension),
		}}
	>
		<CustomFieldEditView {...props} />
	</EditAnalyticsWrapper>
);
