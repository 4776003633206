import React from 'react';
import { createStore, createHook, createContainer } from 'react-sweet-state';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import {
	CREATE_SUCCESS_FLAG_ID,
	CREATE_ERROR_FLAG_ID,
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	USER_SETTINGS_ERRORS,
} from '../common/constants';
import { createSamplePlanAsync, pollCreateSamplePlan } from '../common/utils';
import type { State, Actions, ContainerProps } from './types';

const CONTROLLER_NAME = 'create-sample-plan';

const initialState: State = {
	/**
	 * This field controls modal dismissible status
	 */
	isOpen: false,
	/**
	 * This field is only used to redirect the user to plan page if the modal is not closed.
	 */
	planId: null,
	/**
	 * This field is used to detect the correct redirection creation session,
	 * without this field, we don't know which creation session needs to be redirected
	 * e.g. the user started multiple sample creation and stays on the last one - in this case,
	 * only the last creation needs to be redirected.
	 */
	taskId: null,
	/**
	 * Progress of the ongoing task
	 */
	progressPercentage: null,
	/**
	 * Once this field has been set to `true`, we'll know we can call the actions returned
	 * by our useCreateSamplePlan hook.
	 */
	hasBeenInitialised: false,
};

const actions: Actions = {
	open:
		() =>
		({ setState }) => {
			setState({ isOpen: true });
		},
	close:
		() =>
		({ setState }) => {
			const nextState = (({ hasBeenInitialised, ...rest }) => rest)(initialState);
			setState(nextState);
		},
	setPlanId:
		(planId: number) =>
		({ setState }) => {
			setState({ planId });
		},
	setTaskId:
		(taskId: number) =>
		({ setState }) => {
			setState({ taskId });
		},
	setProgressPercentage:
		(progressPercentage: number) =>
		({ setState }) => {
			setState({ progressPercentage });
		},
	submitSamplePlan:
		({ planName, flags: { success: SuccessFlag, error: ErrorFlag } }) =>
		async ({ setState, getState, dispatch }, { showFlag, redirectToPlan, leadAccountId }) => {
			try {
				const taskId = await createSamplePlanAsync({
					body: JSON.stringify({ title: planName, leadAccountId }),
				});

				setState({ taskId });

				const response = await pollCreateSamplePlan(taskId, (progressPercentage) => {
					const { taskId: latestTaskId } = getState();
					// progressPercentage should reflect the progress of the most recent taskId
					// If they don't match, skip updating
					if (taskId !== latestTaskId) return;
					setState({ progressPercentage });
				});

				const { plan } = response.samplePlan;

				const currentState = getState();

				// We only want to redirect them if the taskId for the current modal is the same as the one for this request
				// Else it might belong to a previous modal
				if (currentState.isOpen && taskId === currentState.taskId) {
					redirectToPlan(plan.id);
					dispatch(actions.close());
				} else {
					const flagId = CREATE_SUCCESS_FLAG_ID + plan.id;
					showFlag({
						id: flagId,
						render: () => <SuccessFlag id={flagId} planName={plan.name} planId={plan.id} />,
					});
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				// we only want to track errors unrelated to user settings
				if (!USER_SETTINGS_ERRORS.includes(err.message)) {
					fireErrorAnalytics({
						error: err,
						meta: {
							id: `${ERROR_REPORTING_PACKAGE}SubmitFailed`,
							packageName: ERROR_REPORTING_PACKAGE,
							teamName: ERROR_REPORTING_TEAM,
						},
						sendToPrivacyUnsafeSplunk: true,
					});
				}
				const props = {
					id: CREATE_ERROR_FLAG_ID,
					errorCode: err.message,
				};

				showFlag({
					id: CREATE_ERROR_FLAG_ID,
					render: () => <ErrorFlag {...props} />,
				});
				dispatch(actions.close());
			}
		},
};

const Store = createStore<State, Actions>({
	name: CONTROLLER_NAME,
	initialState,
	actions,
});

export const useCreateSamplePlan = createHook(Store);

export const CreateSamplePlanProvider = createContainer<State, Actions, ContainerProps>(Store, {
	onInit:
		() =>
		({ setState }, { isOpen = false }) => {
			setState({ isOpen, hasBeenInitialised: true });
		},
});
