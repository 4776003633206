import { useEffect, useState } from 'react';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types';
import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import {
	type ApplicationEdition,
	PREMIUM_EDITION,
	PRODUCT_DISCOVERY,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types';
import { useSubProduct } from '@atlassian/jira-subproduct';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { EDITION_KEY } from '../../common/constants';
import { getCcpTransactionAccountId, getProductReferrerKey } from '../../common/utils';
import { getAdminBaseUrl } from '../get-admin-base-url';
import { getProductKey } from '../get-product-key';

type UseGetBillingPageUrlParams = {
	isUsingCcp: boolean;
	editionToUpgradeTo?: ApplicationEdition;
	caller: string; // The name of the component or package calling this hook
	skip?: boolean; // Whether to skip the API call in this hook and stay as 'loading'
	subProduct?: SubProduct; // If none is sent, it will use the one from useSubProduct hook
	onError?: (e: Error) => void; // If no error callback is provided, it will report it to flywheel-tako
};

/**
 * @deprecated use `useLazyFetchBillingPageUrl` instead
 */
export const useGetBillingPageUrl = ({
	isUsingCcp,
	editionToUpgradeTo,
	caller,
	skip = false,
	subProduct,
	onError,
}: UseGetBillingPageUrlParams) => {
	const [billingPageUrl, setBillingPageUrl] = useState<string | undefined>();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | unknown | null>(null);

	const tenantContext = useTenantContext();
	const contextSubProduct = useSubProduct();
	const { data: orgId } = useOrgId();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const subProductToUse = subProduct || contextSubProduct;

	useEffect(() => {
		if (skip) {
			return;
		}

		const loadBillingInfo = async () => {
			try {
				const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
				const productKey = getProductKey(subProductToUse, explicitlyLicensedProducts);
				const referrerKey = getProductReferrerKey(productKey);
				// The product key as used by Commerce.
				// JPD product key as used by Commerce does not include .ondemand
				const commerceProductKey =
					productKey === PRODUCT_DISCOVERY ? productKey : `${productKey}.ondemand`;
				const entitlementId = productKey
					? tenantContext.productEntitlementDetails?.[productKey]?.entitlementId
					: null;

				if (isUsingCcp) {
					if (!entitlementId) {
						fireOperationalAnalytics(createAnalyticsEvent({}), 'RetrieveEntitlementId failed', {
							caller,
							productKey,
							productEntitlementDetails: JSON.stringify(tenantContext.productEntitlementDetails),
							subProduct: subProductToUse,
						});
						throw new Error('Empty entitlement ID');
					}

					const ccpTransactionAccountId = await getCcpTransactionAccountId(entitlementId);

					// List of valid referrer values
					// https://hello.atlassian.net/wiki/spaces/BILLIONS/pages/2652063891/How+to+Deeplink+to+BAC+s+change+plan#How-to-use-this-deeplink%3F
					const ccpBaseUrl = `${getAdminBaseUrl()}/billing/o/${orgId}/${ccpTransactionAccountId}/entitlement/${entitlementId}/change-plan?referrer=${referrerKey}&enableMpucup=true`;

					switch (editionToUpgradeTo) {
						case STANDARD_EDITION:
						case PREMIUM_EDITION:
							setBillingPageUrl(`${ccpBaseUrl}&offeringName=${EDITION_KEY[editionToUpgradeTo]}`);
							break;
						default:
							setBillingPageUrl(ccpBaseUrl);
					}
				} else {
					// HAMS Billing System
					switch (editionToUpgradeTo) {
						case STANDARD_EDITION:
						case PREMIUM_EDITION:
							setBillingPageUrl(
								`${getAdminBaseUrl()}/s/${tenantContext.cloudId}/billing/applications/change-edition/${commerceProductKey}/${EDITION_KEY[editionToUpgradeTo]}`,
							);
							break;
						default:
							setBillingPageUrl(
								`${getAdminBaseUrl()}/s/${tenantContext.cloudId}/billing/applications/change-edition/${commerceProductKey}`,
							);
					}
				}
			} catch (e) {
				setError(e);
				if (onError && e instanceof Error) {
					onError(e);
				}
			} finally {
				setLoading(false);
			}
		};

		loadBillingInfo();
	}, [
		caller,
		createAnalyticsEvent,
		editionToUpgradeTo,
		isUsingCcp,
		onError,
		orgId,
		skip,
		subProduct,
		subProductToUse,
		tenantContext,
		tenantContext.cloudId,
	]);

	return {
		data: {
			billingPageUrl,
		},
		loading,
		error,
	};
};
