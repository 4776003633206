import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { ErrorBoundaryWithChunkErrorLogging } from '@atlassian/jira-onboarding-core/src/ui/error-boundary-with-chunk-error-logging';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const JswQuickStartShouldRenderLazy = lazy(() =>
	import(
		/* webpackChunkName: "async-software-onboarding-quickstart-shouldrender" */ './index'
	).then((exportedModule) => exportedModule.default),
);

export const AsyncJswQuickStartShouldRender = () => (
	<ErrorBoundaryWithChunkErrorLogging
		id="asyncJswQuickStartShouldRender"
		fallback="unmount"
		packageName="jiraSoftwareOnboardingQuickstart"
	>
		<Placeholder name="software-onboarding-quickstart-shouldrender" fallback={<></>}>
			<JswQuickStartShouldRenderLazy />
		</Placeholder>
	</ErrorBoundaryWithChunkErrorLogging>
);
