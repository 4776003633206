import { getUserProperty } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { FetchError } from '@atlassian/jira-fetch';
import { fireJsonOnlyErrorAnalytics } from '@atlassian/jira-servicedesk-common/src/utils/analytics';
import { UPSELL_PAGE_DISMISSED_PROPERTY_KEY } from '../../constants';

export const fetchHasDismissedProperty = async (atlassianAccountId: string | null) => {
	const meta = {
		id: 'fetchUpsellDismissedProperty',
		packageName: 'jiraCmdbUpsellPage',
	};

	if (atlassianAccountId === null) {
		const error = new Error('atlassianAccountId is null');
		fireErrorAnalytics({ error, meta });
		return false;
	}

	try {
		await getUserProperty(atlassianAccountId, UPSELL_PAGE_DISMISSED_PROPERTY_KEY);
		return true;
	} catch (error) {
		if (error instanceof FetchError && error.statusCode === 404) {
			// don't capture analytics for this error as this is expected when a user has dimissed
			return false;
		}
		fireJsonOnlyErrorAnalytics({ error, meta });
		return false;
	}
};
