import type { Action } from 'react-sweet-state';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { fetchJson } from '@atlassian/jira-fetch';
import type { ProjectId } from '@atlassian/jira-shared-types';
import type { OnboardingTypeResponse, State } from '../../types';

const getOnboardingTypeUrl = (projectId: ProjectId) =>
	`/rest/servicedesk/onboarding/1/onboarding-type/project/${projectId}`;

export const fetchOnboardingType =
	(
		projectId: number | undefined,
		projectType: string | undefined,
	): Action<State, any> => // eslint-disable-line @typescript-eslint/no-explicit-any
	async ({ getState, setState }) => {
		if (!projectId || projectType !== SERVICE_DESK_PROJECT) {
			return;
		}

		const { data, isLoading, projectId: oldProjectId } = getState();

		if (projectId !== oldProjectId) {
			setState({
				data: undefined,
				projectId: undefined,
			});
		}

		if (data || isLoading) {
			return;
		}

		setState({
			projectId,
			isLoading: true,
			error: undefined,
		});

		try {
			const onboardingTypeResponse = await fetchJson<OnboardingTypeResponse>(
				getOnboardingTypeUrl(String(projectId)),
			);

			setState({
				isLoading: false,
				data: onboardingTypeResponse,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				isLoading: false,
				error,
			});
		}
	};
