/**
 * @generated SignedSource<<ff1eb35d4b1573d74dea54c4862ab3d5>>
 * @relayHash 1a171caa1697d948acf3d32b1588f8b4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d5369007f52fd194926961741affa5e9d1f74ecb8db80b62bdc3e99df454ac10

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { main_EditionAwarenessTrialModalQuery } from './main_EditionAwarenessTrialModalQuery.graphql';

const node: PreloadableConcreteRequest<main_EditionAwarenessTrialModalQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "d5369007f52fd194926961741affa5e9d1f74ecb8db80b62bdc3e99df454ac10",
    "metadata": {},
    "name": "main_EditionAwarenessTrialModalQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
