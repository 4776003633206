import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	recent: {
		defaultMessage: 'Recent',
		id: 'atlassian-navigation.dashboards.menu.recent-content.recent',
		description: 'Recent dashboard item',
	},
	viewAll: {
		defaultMessage: 'View all recent',
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'atlassian-navigation.dashboards.menu.recent-content.view-all',
		description: 'View all recent dashboard item',
	},
});
