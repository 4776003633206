import type { Action } from 'react-sweet-state';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import {
	KEY_DISMISSED,
	KEY_OPEN,
	QUICKSTART_UI_STATUS,
	getBackendVisibilityKey,
} from '../../../common/constants';
import { pushToBackend } from '../../../common/utils';
import type { State } from '../../model/types';
import { getQuickstartTypeSelector } from '../../model/utils';
import { nextSetChecklistIsOpen } from '../next-checklist-open';

// TODO: Remove this and replace with set visibility action
export const nextSetChecklistIsDismissed =
	(isDismissed: boolean): Action<State> =>
	({ getState, setState, dispatch }) => {
		if (expValEquals('jsm_business_quickstart', 'cohort', 'experiment')) {
			const state = getState();
			const quickstartType = getQuickstartTypeSelector(state);
			const currentIsDismissed =
				state.visibility[quickstartType] === QUICKSTART_UI_STATUS.DISMISSED;
			if (isDismissed === currentIsDismissed) {
				// if state has not been updated we just return
				return;
			}

			const quickstartStatus = isDismissed
				? QUICKSTART_UI_STATUS.DISMISSED
				: QUICKSTART_UI_STATUS.OPEN;

			setState({
				visibility: {
					...state.visibility,
					[quickstartType]: quickstartStatus,
				},
				isFocusedOn: !isDismissed,
			});

			pushToBackend({
				key: getBackendVisibilityKey(quickstartType),
				value: quickstartStatus,
			});

			// Maintain backwards compatibility with Quickstart in case we need to turn the feature off
			// Delete code below when cleaning up
			if (isDismissed === false) {
				// When setting IsDismissed to false, also setting QS status to OPEN so checklist appears in open state
				pushToBackend({
					key: KEY_OPEN,
					value: true,
				});
			} else {
				// When setting IsDismissed, also toggle the open state of the checklist to match.
				pushToBackend({
					key: KEY_OPEN,
					value: false,
				});
			}

			pushToBackend({
				key: KEY_DISMISSED,
				value: isDismissed,
			});
			return;
		}

		/* Grab current state of is isDismissed */
		const { isDismissed: currentIsDismissed } = getState();

		if (isDismissed === currentIsDismissed) {
			// if state has not been updated we just return
			return;
		}

		setState({ isDismissed });

		if (isDismissed === false) {
			// When setting IsDismissed to false, also setting QS status to OPEN so checklist appears in open state
			dispatch(nextSetChecklistIsOpen(true));
		} else {
			// When setting IsDismissed, also toggle the open state of the checklist to match.
			dispatch(nextSetChecklistIsOpen(false));
		}

		pushToBackend({
			key: KEY_DISMISSED,
			value: isDismissed,
		});
	};
