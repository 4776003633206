import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCustomThemeAdminFlag = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-custom-theme-admin-flag" */ './index'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<JSErrorBoundary id="async.jira.custom-theme-admin-flag" packageName="customThemeAdminFlag">
		<Placeholder name="custom-theme-admin-flag" fallback={null}>
			<LazyCustomThemeAdminFlag />
		</Placeholder>
	</JSErrorBoundary>
);
