import React from 'react';
import { GLOBAL_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants';
import { ForgeAnalyticsWrapper } from '../../common/ui';
import type {
	AnalyticsWrapperPropsView,
	AnalyticsWrapperPropsTrigger,
} from '../../common/ui/types';
import { MODULE_TYPE_TRIGGER } from '../../constants';

export const ViewAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper module={GLOBAL_PAGE_MODULE} shouldFireInitializedEvent {...props} />
);

export const TriggerAnalyticsWrapper = (props: AnalyticsWrapperPropsTrigger) => (
	<ForgeAnalyticsWrapper module={GLOBAL_PAGE_MODULE} moduleType={MODULE_TYPE_TRIGGER} {...props} />
);
