import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl';
import type { ApplicationEdition } from '@atlassian/jira-shared-types';
import type { QUICKSTART_TYPE } from '../../common/constants';
import type { TaskList } from '../../common/types';
import type { USER_ROLE } from './constants';

export type UserRoleTypes = (typeof USER_ROLE)[keyof typeof USER_ROLE];

export type ProjectState = {
	canInvitePeopleToProject: boolean;
	projectKey: string;
	projectId: number;
	projectName: string;
	isNextGen: boolean;
	projectType: ProjectType;
	isProjectAdmin: boolean;
	userRole: UserRoleTypes;
	edition: ApplicationEdition;
	isSampleProject: boolean;
	isTrelloCrossflow: boolean;
	personaExperience?: PersonaExperience;
};

export type PersonaExperience = (typeof EXPERIENCE_TYPE)[keyof typeof EXPERIENCE_TYPE];

export const EXPERIENCE_TYPE = {
	MARKETER_LEAD: 'Campaign Management Marketing Lead',
	MARKETER_PROJECT: 'Project Management Marketing Lead',
	MARKETER_CONTENT: 'Content Management Content Marketer',
	MARKETER_PMM: 'Project Management PMM',
} as const;

export const PERSONA_TYPE = {
	MARKETING_GENERIC: 'Marketing Generic',
	PROJECT_MARKETING_LEAD: 'Project Management Marketing Lead',
	CAMPAIGN_MARKETING_LEAD: 'Campaign Management Marketing Lead',
	CONTENT_MARKETING_LEAD: 'Content Management Content Marketer',
	PMM: 'Project Management PMM',
} as const;

export const PERSONA_TRAIT_NAME =
	'marketing_track_tailored_onboarding_initialized_wacprovisioningscreen_changed';

export const SUPPORTED_PERSONA_EXPERIENCES: PersonaExperience[] = [
	EXPERIENCE_TYPE.MARKETER_LEAD,
	EXPERIENCE_TYPE.MARKETER_PROJECT,
	EXPERIENCE_TYPE.MARKETER_CONTENT,
	EXPERIENCE_TYPE.MARKETER_PMM,
] as const;

export type HasEverSeenRoute = Record<
	'jsw.onboarding.experiment.personalised.onboarding.ntkw.onboarding.it2.roadmap',
	boolean
>;

export type RemoteState = {
	readonly completedTaskList: string[];
};

export type Tasks = {
	items: string[];
	title: MessageDescriptor;
};

export type VisibilityState = {
	[key in QUICKSTART_TYPE]: string;
};

export type State = {
	readonly shouldRenderComponent: boolean;
	readonly completedTaskList: string[];
	readonly visibility: VisibilityState;
	readonly isDismissed: boolean; // deprecated, use visibility to allow scoping
	readonly isOpen: boolean; // deprecated, use visibility to allow scoping
	readonly isFocusedOn?: boolean;
	readonly openTask: string | null;
	readonly hasSeenTasks: string[];
	personaExperience?: PersonaExperience;
	projectState: ProjectState | null;
	/* This is used so we can show/hide a nudge with store action.
    As a first approach we choose to not tie this state to a specific nudge item.
    EG: there is *a* nudge active for the checklist.
    */
	readonly hasNudgeActive: boolean;
	readonly isQuickstartLoaded: boolean;
	readonly isDevOpsProject: boolean;
	readonly nudgeStep: string;
	readonly mountedNudges: string[];
	readonly hasEverSeenRoute: HasEverSeenRoute;
	readonly roadmapIsCreateEnabled: boolean;
	readonly roadmapIsListEmpty: boolean;
	readonly previousTaskGroupParents: string[];
	readonly currentTaskGroupParent: string;
	readonly tasks?: Tasks;
	readonly selectedProjectTemplateKeyFromOnboarding?: string;
	readonly isEligibleToUpdateSitename: boolean;
	readonly isCrossProjectBoardToPlanUpsell: boolean;
	readonly canEditPlans: boolean;
	readonly quickstartStateProjectType?: ProjectType;
	readonly taskList?: TaskList;
};
