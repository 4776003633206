import { fg } from '@atlassian/jira-feature-gating';
import { IntegratedChoreographerMessageTypesEnum } from './constants';
import type {
	ChoreographedMessage,
	ChoreographedMessageTypes,
	MaybeChoreographedComponentProps,
} from './types';

export function shouldBeChoreographed(
	props?: MaybeChoreographedComponentProps,
): props is ChoreographedMessage {
	return fg('po-jira-choreographer-api-integration')
		? !!props?.messageType &&
				IntegratedChoreographerMessageTypesEnum.includes(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					props.messageType as ChoreographedMessageTypes,
				)
		: false;
}
