import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties';
import { fireJsonOnlyErrorAnalytics } from '@atlassian/jira-servicedesk-common/src/utils/analytics';
import { UPSELL_PAGE_DISMISSED_PROPERTY_KEY } from '../../constants';
import type { CmdbUpsellPageStoreAction } from '../../types';

export const dismissPage =
	(): CmdbUpsellPageStoreAction =>
	async ({ setState }, { atlassianAccountId }) => {
		// Optimistically hide Assets from the navigation bar immediately
		setState({ userHasDismissed: true });

		try {
			// The specific value being set doesn't matter as long as the property *has* been set
			atlassianAccountId &&
				(await setUserProperties(atlassianAccountId, UPSELL_PAGE_DISMISSED_PROPERTY_KEY, '1'));
		} catch (error) {
			// When setting the user property fails, we still want to hide Assets from the navigation bar
			fireJsonOnlyErrorAnalytics({
				error,
				meta: {
					id: 'fetchUpsellDismissedProperty',
					packageName: 'jiraCmdbUpsellPage',
				},
			});
		}
	};
