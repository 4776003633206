import React from 'react';
import {
	WORKFLOW_VALIDATOR_MODULE,
	ENTRY_POINT_CREATE,
	ENTRY_POINT_EDIT,
	ENTRY_POINT_VIEW,
} from '@atlassian/jira-forge-ui-constants';
import { ForgeAnalyticsWrapper } from '../../common/ui';
import type { AnalyticsWrapperPropsView } from '../../common/ui/types';

export const ViewAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper
		module={WORKFLOW_VALIDATOR_MODULE}
		entryPoint={ENTRY_POINT_VIEW}
		{...props}
	/>
);

export const EditAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper
		module={WORKFLOW_VALIDATOR_MODULE}
		entryPoint={ENTRY_POINT_EDIT}
		{...props}
	/>
);

export const CreateAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper
		module={WORKFLOW_VALIDATOR_MODULE}
		entryPoint={ENTRY_POINT_CREATE}
		{...props}
	/>
);
