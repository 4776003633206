import { createStore, createSubscriber, createContainer, createHook } from 'react-sweet-state';
import { FREE_EDITION } from '@atlassian/jira-shared-types';
import { UPFLOW_MODAL_STORE_NAME } from '../common/constants';
import type { State, StoreApi, ActionProps } from './types';

const initialState: State = {
	renderUpflowModal: false,
	product: 'jira-software',
	touchpointId: '',
	flow: 'learn-more',
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onClose: () => {},
	targetEdition: FREE_EDITION,
};

const actions = {
	mountUpflowModal:
		({ targetEdition, product, flow, touchpointId, subRoute, onClose, onOpen }: ActionProps) =>
		({ setState }: StoreApi) => {
			setState({
				targetEdition,
				product,
				flow,
				touchpointId,
				subRoute,
				onClose,
				onOpen,
				renderUpflowModal: true,
			});
		},
	unMountUpflowModal:
		() =>
		({ setState }: StoreApi) => {
			setState({ renderUpflowModal: false });
		},
} as const;

export type Actions = typeof actions;

const Store = createStore<State, Actions>({
	name: UPFLOW_MODAL_STORE_NAME,
	initialState,
	actions,
});

export const UpflowModalSubscriber = createSubscriber<State, Actions>(Store);
export const UpflowModalContainer = createContainer<State, Actions, null>(Store);
export const useUpflowModal = createHook(Store);
