export const checkForSvg = (url: string) => {
	// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
	const fileType = url
		// eslint-disable-next-line no-useless-escape
		.split(/\#|\?/)[0]
		.split('.')
		.pop()
		.trim();
	return fileType === 'svg';
};
