import type { Action } from 'react-sweet-state';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ONBOARDING_SELECTED_PROJECT_TEMPLATE_KEY } from '@atlassian/jira-onboarding-core/src/constants';
import {
	KEY_OPEN,
	KEY_DISMISSED,
	KEY_OPEN_TASK,
	KEY_TASK_GROUP,
	KEY_HAS_EVER_SEEN_ROUTE,
	KEY_JSW_ONBOARDING,
	QUICKSTART_TYPE,
	getBackendVisibilityKey,
	QUICKSTART_UI_STATUS,
	allTasksExcludingJSMBusinessTasks,
} from '../../../common/constants';
import type { TaskList } from '../../../common/types';
import { pollBackend } from '../../../common/utils';
import { fireAPIErrorAnalytics } from '../../../utils/analytics';
import type { State } from '../../model/types';
import { getOnboardingSkipApplicationProperty } from '../../skip-onboarding';
import { nextSetChecklistIsDismissed } from '../next-checklist-dismiss';
import type { Props } from '../types';

export const refreshAppStateUiStatusOnly =
	({ taskList }: { taskList: TaskList | undefined }): Action<State, Props> =>
	async ({ getState, setState, dispatch }) => {
		// Need to check to see if there is anything in the backend to take that as source of truth
		// If there is nothing then use defaults
		// Check backend for flags that are marked as completed (true)
		// Carry over state from previous actions
		// Create Completed Actions

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const updatedState: Record<string, any> = {};

		const { projectState } = getState();

		if (!projectState) {
			return;
		}

		// Open QS Checklist as default state if state cannot be determined from the backend
		updatedState.isOpen = true;
		updatedState.isDismissed = false;
		updatedState.quickstartStateProjectType = projectState.projectType;
		let backendVisibilityKeys: string[] = [];
		if (expValEquals('jsm_business_quickstart', 'cohort', 'experiment')) {
			// Open QS Checklist as default state if state cannot be determined from the backend
			updatedState.visibility = Object.values(QUICKSTART_TYPE).reduce<
				Record<QUICKSTART_TYPE, string>
			>(
				(acc, type) => {
					acc[type] = QUICKSTART_UI_STATUS.OPEN;
					return acc;
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{} as Record<QUICKSTART_TYPE, string>,
			);

			backendVisibilityKeys = Object.values(QUICKSTART_TYPE).map((type) =>
				getBackendVisibilityKey(type),
			);
		}

		const backendState = await pollBackend({
			keys: [
				KEY_OPEN,
				KEY_DISMISSED,
				KEY_OPEN_TASK,
				KEY_HAS_EVER_SEEN_ROUTE,
				KEY_JSW_ONBOARDING,
				...allTasksExcludingJSMBusinessTasks,
				...backendVisibilityKeys,
			],
		});

		const backendStateExists = backendState !== undefined && Object.keys(backendState).length > 0;

		if (backendStateExists) {
			updatedState.completedTaskList = Object.keys(backendState).filter(
				(key) => [...allTasksExcludingJSMBusinessTasks].includes(key) && backendState[key] === true,
			);

			if (expValEquals('jsm_business_quickstart', 'cohort', 'experiment')) {
				Object.values(QUICKSTART_TYPE).forEach((type: QUICKSTART_TYPE) => {
					// use new state if possible
					if (getBackendVisibilityKey(type) in backendState) {
						updatedState.visibility[type] = backendState[getBackendVisibilityKey(type)];
						// else fallback to old state
					} else if (KEY_DISMISSED in backendState && backendState[KEY_DISMISSED]) {
						updatedState.visibility[type] = QUICKSTART_UI_STATUS.DISMISSED;
					} else if (KEY_OPEN in backendState) {
						updatedState.visibility[type] = backendState[KEY_OPEN]
							? QUICKSTART_UI_STATUS.OPEN
							: QUICKSTART_UI_STATUS.MINIMIZED;
					}
				});
			}

			if (KEY_DISMISSED in backendState) {
				updatedState.isDismissed = backendState[KEY_DISMISSED];
			}
			if (KEY_OPEN in backendState) {
				updatedState.isOpen = backendState[KEY_OPEN];
			}
			if (KEY_OPEN_TASK in backendState) {
				updatedState.openTask = backendState[KEY_OPEN_TASK];
			}
			if (KEY_HAS_EVER_SEEN_ROUTE in backendState) {
				updatedState.hasEverSeenRoute = backendState[KEY_HAS_EVER_SEEN_ROUTE];
			}

			if (KEY_TASK_GROUP in backendState) {
				updatedState.currentTaskGroupParent =
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					backendState[KEY_TASK_GROUP].taskGroup.currentTaskGroupParent;
				updatedState.previousTaskGroupParents =
					// @ts-expect-error - TS2571 - Object is of type 'unknown'.
					backendState[KEY_TASK_GROUP].taskGroup.previousTaskGroupParents;
			}

			if (KEY_JSW_ONBOARDING in backendState) {
				if (
					typeof backendState[KEY_JSW_ONBOARDING] === 'object' &&
					backendState[KEY_JSW_ONBOARDING] !== null &&
					ONBOARDING_SELECTED_PROJECT_TEMPLATE_KEY in backendState[KEY_JSW_ONBOARDING]
				) {
					updatedState.selectedProjectTemplateKeyFromOnboarding =
						backendState[KEY_JSW_ONBOARDING][ONBOARDING_SELECTED_PROJECT_TEMPLATE_KEY];
				}
			}
		}

		setState(updatedState);

		if (!(backendStateExists && KEY_OPEN in backendState)) {
			try {
				const skipApplicationProperty = await getOnboardingSkipApplicationProperty();
				if (skipApplicationProperty.quickstart) {
					dispatch(nextSetChecklistIsDismissed(true));
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireAPIErrorAnalytics(error, 'skipOnboarding');
			}
		}

		setState({ taskList });
		setState({ isQuickstartLoaded: true });
	};
