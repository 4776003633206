import React, { useMemo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { CustomThemeButton, type ThemeProps, type ThemeTokens } from '@atlaskit/button';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import { HeadingItem, Section } from '@atlaskit/menu';
import { Text, Box, Flex, Stack, xcss } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import { token } from '@atlaskit/tokens';
import { useGetBillingPageUrl } from '@atlassian/jira-external-urls';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { useShouldShowPremiumUpsellBanner } from '../../../../controllers/premium-upsell-data';
import { PACKAGE_NAME, WAC_JSM_PREMIUM_URL, PREMIUM_UPSELL_BANNER } from './constants';
import { DismissMenu } from './dismiss-menu';
import messages from './messages';
import type { jsmUpsellHookProps } from './types';
import { getTrialDaysLeft } from './utils';

export const UpsellJsmStandardToPremiumBlendScreen = ({
	onPremiumTrial,
	isUsingCcp,
	trialEndDate,
}: jsmUpsellHookProps) => {
	const { formatMessage } = useIntl();
	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, trialEndDate);

	const { data: billingPageData } = useGetBillingPageUrl({
		isUsingCcp,
		caller: PACKAGE_NAME,
	});

	const [{ showBanner }, { getUserPropertyCall }] = useShouldShowPremiumUpsellBanner();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { atlassianAccountId: accountId } = useTenantContext();

	useMemo(() => {
		getUserPropertyCall({ accountId });
	}, [accountId, getUserPropertyCall]);

	const onPremiumlinkClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'PremiumDocLink',
			}),
			PREMIUM_UPSELL_BANNER,
		);
	};
	const onTryPremiumClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'TryButton',
			}),
			PREMIUM_UPSELL_BANNER,
		);
	};
	const trialEndDateMessage =
		trialDaysLeft === 0
			? formatMessage(messages.jsmPlansDaysLeftOnTrialDataItemHeadingToday)
			: formatMessage(messages.jsmPlansDaysLeftOnTrialDataItemHeading, {
					trialDaysLeft,
				});
	return (
		showBanner && (
			<Section>
				<FireScreenAnalytics
					attributes={{
						screenName: `jsm-upsell-screen-${onPremiumTrial ? 'with-trial-end-date' : 'with-try-premium-button'}`,
					}}
				/>
				<Box xcss={blendScreenWrapperStyles}>
					<HeadingItem headingLevel={3}>
						<Flex xcss={headingContainer}>
							<Text>{formatMessage(messages.jsmPlansHeading)}</Text>
						</Flex>
					</HeadingItem>
					<Flex xcss={tryPremiumItemStyle}>
						<Box xcss={iconWrapper}>
							<PremiumIcon
								label=""
								size="small"
								primaryColor={token('color.icon.inverse', '#FFFFFF')}
							/>
						</Box>
						<Stack grow="fill" xcss={tryPremiumDataItemStyle}>
							<Box>
								{onPremiumTrial
									? trialEndDateMessage
									: formatMessage(messages.jsmPlansTryPremiumDataItemHeading)}
							</Box>
							{!onPremiumTrial && (
								<Box xcss={tryPremiumDataItemDescriptionStyle}>
									{formatMessage(messages.jsmPlansTryPremiumDataItemDescriptionStandard, {
										a: (chunks: ReactNode) => (
											<Anchor
												xcss={tryPremiumDataItemDescriptionAnchorStyle}
												href={WAC_JSM_PREMIUM_URL}
												target="_blank"
												onClick={onPremiumlinkClick}
											>
												{chunks}
											</Anchor>
										),
									})}
								</Box>
							)}
						</Stack>
						{!onPremiumTrial && (
							<>
								<GradientBorderStyles>
									<Box xcss={tryPremiumButtonStyle}>
										<CustomThemeButton
											onClick={onTryPremiumClick}
											href={billingPageData?.billingPageUrl}
											theme={PillTheme}
											interactionName="upsell-premium-hook"
											target="_blank"
										>
											<Box xcss={buttonTextStyle}>
												{formatMessage(messages.jsmPlansTryPremiumButton)}
											</Box>
										</CustomThemeButton>
									</Box>
								</GradientBorderStyles>
								<DismissMenu />
							</>
						)}
					</Flex>
				</Box>
			</Section>
		)
	);
};
const blendScreenWrapperStyles = xcss({
	position: 'relative',
});
const headingContainer = xcss({
	alignItems: 'center',
	justifyContent: 'space-between',
});

const tryPremiumDataItemDescriptionAnchorStyle = xcss({
	color: 'color.text.subtlest',
	textDecoration: 'underline',
});

const tryPremiumItemStyle = xcss({
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
	gap: 'space.150',
	justifyContent: 'space-between',
	alignItems: 'center',
});

const tryPremiumDataItemDescriptionStyle = xcss({
	font: token('font.body.UNSAFE_small'),
	color: 'color.text.subtlest',
	marginTop: 'space.050',
	maxWidth: '275px',
});

const tryPremiumButtonStyle = xcss({
	borderRadius: 'border.radius.050',
	backgroundColor: 'color.background.input',
	color: 'color.text.accent.blue',
});

const tryPremiumDataItemStyle = xcss({
	lineHeight: '16px',
	width: 'auto',
});

const iconWrapper = xcss({
	backgroundColor: 'color.background.accent.purple.bolder',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: 'size.200',
	width: 'size.200',
	borderRadius: 'border.radius.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GradientBorderStyles = styled.div({
	// 1px padding needed to show a gradient border
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	padding: '1px',
	borderRadius: '3px',
	background: `linear-gradient(79.47deg,
        ${token('color.background.accent.purple.subtle', '#9F8FEF')} 26.57%,
        ${token('color.background.accent.blue.subtle', '#579DFF')} 99.57%)`,
	height: 'fit-content',
});

const buttonTextStyle = xcss({
	textAlign: 'center',
	lineHeight: '16px',
});

export const PillTheme = (
	currentTheme: (props: ThemeProps) => ThemeTokens,
	themeProps: ThemeProps,
) => {
	const theme = currentTheme(themeProps);

	return {
		...theme,
		buttonStyles: {
			...PillThemeCss,
		},
	};
};

export const PillThemeCss = {
	display: 'inline-flex',
	alignItems: 'center',
	padding: `${token('space.100', '8px')} 15px`,
	textDecoration: 'none',

	border: 'none',
	borderRadius: '2px',
	background: token('color.background.input', '#FFFFFF'),
	boxShadow: token(
		'elevation.shadow.overflow',
		'0px 0px 8px rgba(9, 30, 66, 0.16), 0px 0px 1px rgba(9, 30, 66, 0.12)',
	),
	backgroundImage: `linear-gradient(79deg,
        ${token('color.background.accent.purple.bolder', '#6E5DC6')} 25.62%,
        ${token('color.background.accent.blue.bolder', '#0C66E4')} 99.57%)`,
	color: 'transparent',
	backgroundClip: 'text',

	transition: 'all 0.3s ease-in-out 0s, color 0s',

	'&:hover': {
		cursor: 'pointer',
		background: `linear-gradient(79deg,
            ${token('color.background.discovery.bold.hovered', '#5E4DB2')} 24.18%,
            ${token('color.background.brand.bold.hovered', '#05C')} 99.57%)`,
		color: token('color.text.inverse', '#FFFFFF'),
	},

	'&:active': {
		cursor: 'pointer',
		background: `linear-gradient(79deg,
            ${token('color.background.discovery.bold.pressed', '#352C63')} 13.86%,
            ${token('color.background.brand.bold.pressed', '#09326C')} 99.57%)`,
		color: token('color.text.inverse', '#FFFFFF'),
	},

	'& span': {
		height: '16px',
		display: 'inline-flex',
		margin: 0,
	},
};
