import type { RecommendationType } from '../common/types';
import jpdRecommendation from './jpd-recommendation';
import jwmSampleProjectRecommendation from './jwm-sample-project-recommendation';

export { RecommendationLoader } from './recommendation-loader';
export { RecommendationSelector } from './recommendation-selector';

const createRecommendationsList = () => {
	const recommendationsList = [];

	recommendationsList.push(jpdRecommendation);
	recommendationsList.push(jwmSampleProjectRecommendation);

	// Add your own templates

	return recommendationsList;
};

export const recommendations: Array<RecommendationType> = createRecommendationsList();
