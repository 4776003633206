import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	findApps: {
		defaultMessage: 'Find new apps',
		id: 'atlassian-navigation.addons.menu.footer.find-apps',
		description: '"Find new apps" menu item',
	},
	manageApps: {
		defaultMessage: 'Manage your apps',
		id: 'atlassian-navigation.addons.menu.footer.manage-apps',
		description: '"Manage apps" menu item',
	},
	appRequests: {
		defaultMessage: 'View app requests',
		id: 'atlassian-navigation.addons.menu.footer.app-requests',
		description: '"View app requests" menu item',
	},
	appPromotions: {
		defaultMessage: 'Promotions',
		id: 'atlassian-navigation.addons.menu.footer.app-promotions',
		description: '"Promotions" menu item',
	} /* eslint-disable jira/i18n/id-named-by-package */,
	exploreApps: {
		id: 'atlassian-navigation.addons.menu.personalised-apps-experiment-enriched-footer.personalised-apps-experiment-footer-section.recommended-explore-apps',
		defaultMessage: 'Explore more apps',
		description: '"Explore more apps" menu item',
	} /* eslint-enable jira/i18n/id-named-by-package */,
	appUpdates: {
		defaultMessage: '{apps, plural, one {update} other {updates}}',
		id: 'atlassian-navigation.addons.menu.footer.app-updates',
		description: 'Number of App updates available',
	},
});
