import type { Action } from 'react-sweet-state';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import {
	BACKLOG_ACCORDION_NUDGE_ID,
	BOARD_CREATE_COLUMN_NUDGE_ID,
} from '@atlassian/jira-onboarding-core/src/constants';
import {
	KEY_OPEN_TASK,
	SCRUM_ONBOARDING_TASK,
	KANBAN_ONBOARDING_TASK,
	ADD_COLUMNS_TASK,
} from '../../../common/constants';
import { pushToBackend } from '../../../common/utils';
import type { State } from '../../model/types';
import { setNudgeStep } from '../nudge-step';
import { setHasNudgeActive } from '../set-has-nudge-active';

const openTask =
	(taskId: string): Action<State> =>
	({ setState }) => {
		setState({ openTask: taskId });

		pushToBackend({
			key: KEY_OPEN_TASK,
			value: taskId,
		});
	};

type NextOpenTaskOptions = {
	closeNudge?: boolean;
};
export const nextOpenTask =
	(taskId: string, { closeNudge }: NextOpenTaskOptions = {}): Action<State> =>
	({ dispatch, getState }) => {
		dispatch(openTask(taskId));
		const { completedTaskList } = getState();
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [simplifyKanbanQsExpConfig] = UNSAFE_noExposureExp('jsw_simplify_kanban_qs');

		const shouldSimplifyKanbanQs =
			simplifyKanbanQsExpConfig.get('cohort', 'not-enrolled') === 'variation';

		const taskIsCompleted = completedTaskList.includes(taskId);
		const taskIdExistsAndIsNotCompleted = !(taskId === '' || taskId === null) && !taskIsCompleted;

		if (closeNudge) {
			dispatch(setHasNudgeActive(false, taskId));
		} else {
			dispatch(setHasNudgeActive(taskIdExistsAndIsNotCompleted, taskId));
		}

		// This is a temporary solution for Scrum Onboarding.
		// See https://hello.atlassian.net/wiki/spaces/agile/pages/1321008806/Draft+Quickstart+Nudge+Interaction for details
		if (taskIdExistsAndIsNotCompleted && taskId === SCRUM_ONBOARDING_TASK) {
			dispatch(setNudgeStep(BACKLOG_ACCORDION_NUDGE_ID));
		} else if (taskIdExistsAndIsNotCompleted && taskId === KANBAN_ONBOARDING_TASK) {
			dispatch(setNudgeStep(BOARD_CREATE_COLUMN_NUDGE_ID));
		} else if (
			shouldSimplifyKanbanQs &&
			taskIdExistsAndIsNotCompleted &&
			taskId === ADD_COLUMNS_TASK
		) {
			dispatch(setNudgeStep(BOARD_CREATE_COLUMN_NUDGE_ID));
		} else {
			dispatch(setNudgeStep(''));
		}
	};

export const nextAutoOpenTask =
	(taskId: string): Action<State> =>
	({ dispatch }) => {
		dispatch(openTask(taskId));
		dispatch(setHasNudgeActive(false, taskId));
		dispatch(setNudgeStep(''));
	};
