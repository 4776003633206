import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const browseProjectRedirectRoute = {
	group: ROUTE_GROUPS.REDIRECTS,
	name: ROUTE_NAMES.BROWSE_PROJECT_REDIRECT,
	path: '/browse/:projectKey([^-/]+)',
	exact: true,
	isRedirect: true,
};
