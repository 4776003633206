// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type CreateDashboardModalType from './index';

const AsyncCreateDashboardModal = lazyForPaint(
	() => import(/* webpackChunkName: "async-create-dashboard-modal" */ './index'),
);

const AsyncCreateDashboardModalContainer = (
	props: JSX.LibraryManagedAttributes<
		typeof CreateDashboardModalType,
		ComponentProps<typeof CreateDashboardModalType>
	>,
) => (
	<Placeholder name="create-dashboard-modal" fallback={<></>}>
		<AsyncCreateDashboardModal {...props} />
	</Placeholder>
);

export default AsyncCreateDashboardModalContainer;
