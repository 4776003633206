import React, { forwardRef } from 'react';
import {
	MenuTrigger as CommonMenuTrigger,
	type MenuTriggerProps,
} from '../../../common/ui/menu/trigger';
import { MenuTrigger as CustomizedMenuTrigger } from './main';

export const MenuTrigger = forwardRef<HTMLElement, MenuTriggerProps>(
	(props: MenuTriggerProps, ref) => {
		if (!__SERVER__) {
			return <CustomizedMenuTrigger {...props} ref={ref} />;
		}

		return <CommonMenuTrigger {...props} ref={ref} />;
	},
);
