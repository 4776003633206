import {
	SOFTWARE,
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	type ApplicationKey,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types';
import { projectToApplicationKey } from '../../common';
import type { SupportedEdition, SupportedProject } from '../../common/types';

type OfferingKeys = Record<ApplicationEdition, string | null>;
// Please don't use access these directly, try to implement these using common platform types such as below
const JSW_PRODUCT_OFFERING_KEYS: OfferingKeys = {
	FREE_EDITION: 'de2887c9-8a55-41d5-b5cf-ad6a5589ebed',
	STANDARD_EDITION: 'a70b5cbb-1ae1-4003-8f4a-9001a4a50526',
	PREMIUM_EDITION: '6dd805b4-da75-4374-a7a7-cf0b12f7ea07',
	UNLICENSED: null,
};

const JPD_PRODUCT_OFFERING_KEYS: OfferingKeys = {
	FREE_EDITION: 'd843dd9e-23c5-4d27-af44-5009d568cb6e',
	STANDARD_EDITION: '35b9268e-66e2-45fb-81d3-dacd1e31831a',
	PREMIUM_EDITION: 'f0b24ca7-c3d8-4cac-b5ed-60f4152d4dcd',
	UNLICENSED: null,
};

const JSM_PRODUCT_OFFERING_KEYS: OfferingKeys = {
	FREE_EDITION: '24656f1f-5c14-45ea-88ea-142b9c633661',
	STANDARD_EDITION: '396efb16-749b-41fa-9cd1-ad10cfefa1d0',
	PREMIUM_EDITION: '0c6683b4-4633-4673-beca-690d171de6a5',
	UNLICENSED: null,
};

/**
 * Returns the offering key for a specified applicationKey and applicationEdition. Useful for CCP GraphQL API calls.
 * @param applicationKey - the product application key, e.g: 'SOFTWARE' aka 'jira-software'
 * @param applicationEdition - the edition tier of the product, e.g: PREMIUM_EDITION
 */
export const getProductOfferingKey = (
	applicationKey: ApplicationKey,
	applicationEdition: ApplicationEdition,
): string | null => {
	switch (applicationKey) {
		case SOFTWARE:
			return JSW_PRODUCT_OFFERING_KEYS[applicationEdition];
		case SERVICE_DESK:
			return JSM_PRODUCT_OFFERING_KEYS[applicationEdition];
		case PRODUCT_DISCOVERY:
			return JPD_PRODUCT_OFFERING_KEYS[applicationEdition];
		// can support more products if implemented in future
		default:
			return null;
	}
};

/**
 * Gets the offeringKey for the specified project. Useful for Commerce GraphQL API calls.
 * WARNING: To support spork, this function will treat CORE projects as SOFTWARE projects.
 * @param project The project that you want to get the offering key for
 * @returns offeringKey for the product and edition
 */
export const getProductOfferingKeyForProject = (
	project: SupportedProject,
	edition: SupportedEdition,
): string => {
	const application: ApplicationKey = projectToApplicationKey(project);
	const offeringKey = getProductOfferingKey(application, edition);
	if (offeringKey === null) {
		throw new Error(`Missing offering key for: ${application} ${edition}`);
	}
	return offeringKey;
};

/**
 * Returns the offering key for a specified applicationKey and applicationEdition. Useful for CCP GraphQL API calls.
 * @param applicationKey - the product application key, e.g: 'SOFTWARE' aka 'jira-software'
 * @param applicationEdition - the edition tier of the product, e.g: PREMIUM_EDITION
 * @throws error if there is no valid offering key for the provided `applicationKey` and `applicationEdition`
 */
export const getProductOfferingKeyOrThrow = (
	applicationKey: Extract<
		ApplicationKey,
		'jira-software' | 'jira-servicedesk' | 'jira-product-discovery'
	>,
	applicationEdition: Extract<
		ApplicationEdition,
		'FREE_EDITION' | 'STANDARD_EDITION' | 'PREMIUM_EDITION'
	>,
): string => {
	const offeringKey = getProductOfferingKey(applicationKey, applicationEdition);

	if (offeringKey === null) {
		throw new Error(`Missing offering key for: ${applicationKey} ${applicationEdition}`);
	}
	return offeringKey;
};
