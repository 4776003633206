import React from 'react';
import { ButtonItem } from '@atlaskit/menu';
import type { MenuItemProps } from '@atlassian/jira-navigation-types';
import { LinkItem } from '../common/ui/link-item';
import { RouteLinkItem } from '../common/ui/route-link-item';
import { useOnClick } from './utils';

export const MenuItem = ({
	analytics,
	href = undefined,
	onClick,
	params,
	query,
	replace,
	target = undefined,
	to,
	type,
	...rest
}: MenuItemProps) => {
	const onClickHandler = useOnClick({ onClick, analytics });

	if (to != null && __SPA__) {
		return (
			<RouteLinkItem
				onClick={onClickHandler}
				params={params}
				query={query}
				replace={replace}
				target={target}
				to={to}
				type={type}
				{...rest}
			/>
		);
	}

	return href ? (
		<LinkItem href={href} target={target} onClick={onClickHandler} {...rest} />
	) : (
		<ButtonItem onClick={onClickHandler} {...rest} />
	);
};
