import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewAllStarred: {
		defaultMessage: 'View all starred',
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'atlassian-navigation.common.menu.view-all.view-all-starred',
		description: 'Link to view all starred items.',
	},
});
