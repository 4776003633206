import { apiReady as eventsApiReady } from './events-bridge';
import {
	legacyIssueCreateInitialRenderInteraction,
	legacyIssueCreateTransitionInteraction,
	legacyIssueCreateCombinedInteraction,
} from './performance-analytics';
import type { TriggerSource } from './types';

export const sendStartMarkForLegacyIssueCreate = ({
	isInitialLoad,
	triggerSource,
}: {
	isInitialLoad?: boolean;
	triggerSource?: TriggerSource;
}): void => {
	if (typeof isInitialLoad === 'boolean' && triggerSource !== undefined) {
		if (isInitialLoad) {
			legacyIssueCreateInitialRenderInteraction.start();
		} else {
			legacyIssueCreateTransitionInteraction.start();
		}
		legacyIssueCreateCombinedInteraction.start();
	}
};

export const sendEndMarkForLegacyIssueCreate = async ({
	isInitialLoad,
	triggerSource,
}: {
	isInitialLoad?: boolean;
	triggerSource?: TriggerSource;
}): Promise<void> => {
	const Events = await eventsApiReady();
	const onCreateIssueDialogRendered = () => {
		if (typeof isInitialLoad === 'boolean') {
			if (isInitialLoad) {
				legacyIssueCreateInitialRenderInteraction.stop({
					customData: {
						// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'CustomValue'.
						triggerSource,
						isGICPrefetchEnabled: true,
					},
				});
			} else {
				legacyIssueCreateTransitionInteraction.stop({
					customData: {
						// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'CustomValue'.
						triggerSource,
						isGICPrefetchEnabled: true,
					},
				});
			}
			legacyIssueCreateCombinedInteraction.stop({
				customData: {
					// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'CustomValue'.
					triggerSource,
					isGICPrefetchEnabled: true,
				},
			});
		}
		Events.unbind('createIssueDialogRendered', onCreateIssueDialogRendered);
	};

	Events.bind('createIssueDialogRendered', onCreateIssueDialogRendered);
};
