import React from 'react';
import {
	CmdbUpsellPageContainer,
	CmdbUpsellPageStoreSubscriber,
} from '@atlassian/jira-cmdb-upsell-page/src/controllers';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isCMDBEnabledInEnvironment } from '@atlassian/jira-servicedesk-cmdb-fedramp';
import { isLicensedPremiumUser } from '@atlassian/jira-servicedesk-insight-common-workspace-context';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import PopupBoxWrapper from '../../common/ui/popup-box-wrapper';
import { InsightMenuItem } from './main';

export const useShowAssets = () => {
	const tenantContext = useTenantContext();
	const { appPermissions } = tenantContext;

	const isJSMUser = appPermissions.hasServiceDeskAccess;

	if (isCMDBEnabledInEnvironment()) {
		if (isJSMUser) {
			return true;
		}
	}
	return false;
};

const Insight = () => {
	const tenantContext = useTenantContext();
	const { appPermissions } = tenantContext;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPremiumUser = isLicensedPremiumUser(tenantContext);
	const isJSMUser = appPermissions.hasServiceDeskAccess;

	return (
		<JSErrorBoundary id="InsightNavMenu" packageName="jiraAtlassianNavigation" fallback="unmount">
			{!isPremiumUser ? (
				<CmdbUpsellPageContainer isGlobal atlassianAccountId={tenantContext.atlassianAccountId}>
					<CmdbUpsellPageStoreSubscriber>
						{({ userHasDismissed }) => {
							const showUpsell = userHasDismissed === false && isJSMUser;
							if (showUpsell) {
								// fire analytics event only for non-premium users that haven't dimissed the Assets upsell page button.
								fireTrackAnalytics(createAnalyticsEvent({}), 'cmdbUpsellPageButton viewed', {
									serviceDeskEdition: tenantContext.appEditions?.serviceDesk,
									isSiteAdmin: tenantContext.isSiteAdmin,
								});
							}
							return showUpsell && <InsightMenuItem />;
						}}
					</CmdbUpsellPageStoreSubscriber>
				</CmdbUpsellPageContainer>
			) : (
				<InsightMenuItem />
			)}
		</JSErrorBoundary>
	);
};

const InsightWihErrorBoundary = () => (
	<TopLevelErrorBoundary id={MENU_ID.INSIGHT}>
		<PopupBoxWrapper>
			<Insight />
		</PopupBoxWrapper>
	</TopLevelErrorBoundary>
);

export { InsightWihErrorBoundary as Insight };
