import { useEffect, useCallback, useState } from 'react';
import type { AccountId } from '@atlassian/jira-shared-types';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';
import { updateHelpButtonState, getHelpButtonState } from '../../services/help-button-state';

export const HELP_BUTTON_UI_STORAGE_KEY = 'help_button_ui_state';

export const useHelpButtonState = () => {
	const [releaseNotesNotifications, setReleaseNotesNotifications] = useState<number | null>(null);
	const [userReleaseNotesNotifications, setUserReleaseNotesNotifications] = useState<number | null>(
		null,
	);
	const accountId: AccountId | null = useAccountId();

	const updateData = useCallback(() => {
		if (userReleaseNotesNotifications !== null) {
			const newReleaseNotesNotificationsValue =
				userReleaseNotesNotifications !== undefined ? userReleaseNotesNotifications : 100;

			if (releaseNotesNotifications !== null) {
				if (userReleaseNotesNotifications !== releaseNotesNotifications) {
					const remoteData = {
						helpPanelMenu: {
							releaseNotesNotifications,
						},
					};

					updateHelpButtonState(accountId, remoteData);
				}
			} else {
				setReleaseNotesNotifications(newReleaseNotesNotificationsValue);
			}
		}
	}, [accountId, releaseNotesNotifications, userReleaseNotesNotifications]);

	const restoreReleaseNotesNotifications = useCallback(async () => {
		if (accountId) {
			const userPropertiesHelpButtonValue = await getHelpButtonState(accountId);
			setUserReleaseNotesNotifications(userPropertiesHelpButtonValue?.releaseNotesNotifications);
		} else {
			setUserReleaseNotesNotifications(0);
		}
	}, [accountId]);

	useEffect(() => {
		if (userReleaseNotesNotifications === null) {
			restoreReleaseNotesNotifications();
		} else {
			updateData();
		}
	}, [userReleaseNotesNotifications, restoreReleaseNotesNotifications, updateData]);

	return { releaseNotesNotifications, setReleaseNotesNotifications };
};
