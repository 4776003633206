import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products';
import { SOFTWARE } from '@atlassian/jira-shared-types';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';

/**
 * Checks whether a user meets the requisites for the Edition Awareness component
 * to be shown in the top navigation. The user must have only JSW on their instance.
 */
export function useMeetsJswEATopNavRequisites() {
	const tenantContext = useTenantContext();

	try {
		const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
		const firstExplicitlyLicensedProduct = explicitlyLicensedProducts[0];
		return explicitlyLicensedProducts.length === 1 && firstExplicitlyLicensedProduct === SOFTWARE;
	} catch {
		return false;
	}
}
