import React from 'react';
import NotificationDirectIcon from '@atlaskit/icon/glyph/notification-direct';
import { type Command, CommandActionType } from '@atlassian/jira-command-palette';
import {
	COMMAND_PALETTE_SECTIONS,
	COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY,
	useCommandKeywords,
} from '@atlassian/jira-command-palette-common';
import {
	useRegisterCommands,
	COMMAND_PALETTE_REGISTRY_IDS,
} from '@atlassian/jira-command-palette-registry';
import { useIntl } from '@atlassian/jira-intl';
import { CHECK_NOTIFICATIONS_COMMAND_ID } from './constants';
import { messages } from './messages';

export const useRegisterNotificationsInCommandPalette = (onClick: () => void) => {
	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();

	const commands: Command[] = [
		{
			id: CHECK_NOTIFICATIONS_COMMAND_ID,
			name: formatMessage(messages.checkNotifications),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: onClick,
			},
			keywords: getKeywords('checkNotificationsSynonyms'),
			section: COMMAND_PALETTE_SECTIONS.quickActions,
			priority: COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY.checkNotifications,
			components: {
				LeftIcon: () => (
					<NotificationDirectIcon label={formatMessage(messages.checkNotifications)} />
				),
			},
			analytics: {
				action: 'checkNotifications',
			},
		},
	];

	useRegisterCommands(COMMAND_PALETTE_REGISTRY_IDS.CHECK_NOTIFICATIONS, commands);
};
