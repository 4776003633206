import type { Action } from 'react-sweet-state';
import type { State } from './types';

export const cachedEndpointCall =
	<P, T>(call: (arg1: P) => Promise<T | null>): ((arg1: P) => Action<State<T>>) =>
	(props: P): Action<State<T>> =>
	async ({ getState, setState }) => {
		const { isFetching, wasFetched, fetchedOnce } = getState();
		if (isFetching) {
			return;
		}

		if (wasFetched) {
			return;
		}
		setState({ isFetching: true });
		const value = await call(props);
		if (value !== null) {
			setState({ value, isFetching: false, wasFetched: true, fetchedOnce: true });
		} else {
			setState({ isFetching: false, wasFetched: fetchedOnce, fetchedOnce: true });
		}
	};
