import React from 'react';
import type { BillingSourceSystem } from '@atlassian/jira-billing';
import {
	type SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { ContextualAnalyticsData, FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller';
import UFOLoadHold from '@atlassian/ufo-load-hold';
import { EditionAwarenessContainer } from '../../common/ui/styled';
import { useMeetsJswEATopNavRequisites } from '../../common/utils';
import { EditionAwarenessCtaButton } from './edition-awareness-cta-button';
import { UsersRemainingCtaButton } from './users-remaining-free-pill';
import { useCheckEditionAwarenessRequisites } from './utils';

export type FrePillSupportedProjects = typeof SOFTWARE_PROJECT | typeof SERVICE_DESK_PROJECT;

export type FreePillProps = {
	projectType: FrePillSupportedProjects;
};

export function FreePill(props: FreePillProps) {
	const { projectType } = props;

	const { userCanView, billingSourceSystem, isBillingAdmin } = useCheckEditionAwarenessRequisites({
		projectType,
	});
	const siteHasJswOnly = useMeetsJswEATopNavRequisites();

	if (userCanView === undefined || isBillingAdmin === undefined) {
		return <UFOLoadHold name="edition-awareness-requisites-loading" />;
	}

	if (userCanView) {
		return (
			<EditionAwarenessFreePill
				projectType={projectType}
				billingSourceSystem={billingSourceSystem}
				isBillingAdmin={isBillingAdmin}
				siteHasJswOnly={siteHasJswOnly}
			/>
		);
	}

	return null;
}

type EditionAwarenessFreePillProps = {
	projectType: typeof SOFTWARE_PROJECT | typeof SERVICE_DESK_PROJECT;
	billingSourceSystem: BillingSourceSystem;
	isBillingAdmin: boolean;
	siteHasJswOnly: boolean;
};

function EditionAwarenessFreePill(props: EditionAwarenessFreePillProps) {
	const { projectType, billingSourceSystem, isBillingAdmin, siteHasJswOnly } = props;
	const isSiteAdmin = useIsSiteAdmin();

	return (
		<ContextualAnalyticsData attributes={{ billingSourceSystem, isSiteAdmin, isBillingAdmin }}>
			<EditionAwarenessContainer>
				<ButtonForProduct projectType={projectType} siteHasJswOnly={siteHasJswOnly} />
				<FireUiAnalytics
					action="viewed"
					actionSubject="button"
					actionSubjectId="editionAwarenessFreePill"
				/>
			</EditionAwarenessContainer>
		</ContextualAnalyticsData>
	);
}

export type ButtonForProductProps = {
	projectType: FrePillSupportedProjects;
	siteHasJswOnly: boolean;
};
function ButtonForProduct(props: ButtonForProductProps) {
	const { projectType, siteHasJswOnly } = props;

	return projectType === SOFTWARE_PROJECT ? (
		<UsersRemainingCtaButton projectType={projectType} siteHasJswOnly={siteHasJswOnly} />
	) : (
		<EditionAwarenessCtaButton />
	);
}
