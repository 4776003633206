import React, { useMemo } from 'react';
import { keyframes, styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { LinkButton } from '@atlaskit/button/new';
import Cross from '@atlaskit/icon/glyph/cross';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useGetBillingPageUrl } from '@atlassian/jira-external-urls';
import { useIntl } from '@atlassian/jira-intl';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { SparkleIcon } from '@atlassian/jira-product-edition-upgrade';
import { useAccountId } from '@atlassian/jira-tenant-context-controller';
import { useShouldShowPremiumUpsellBanner } from '../../../../controllers/premium-upsell-data';
import messages from './messages';

const PREMIUM_DOC = 'https://www.atlassian.com/software/jira/service-management/premium';
const PREMIUM_UPSELL_BANNER = 'PremiumUpsellBanner';

export const PremiumUpsell = ({ isUsingCcp }: { isUsingCcp: boolean }) => {
	const { formatMessage } = useIntl();
	const accountId = useAccountId();
	const [state, actions] = useShouldShowPremiumUpsellBanner();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		data: { billingPageUrl },
	} = useGetBillingPageUrl({
		isUsingCcp,
		caller: 'jiraAtlassianNavigation',
	});

	useMemo(() => {
		actions.getUserPropertyCall({ accountId });
	}, [accountId, actions]);

	const closeBanner = () => {
		actions.handleClose({ accountId });
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'DismissButton',
			}),
			PREMIUM_UPSELL_BANNER,
		);
	};

	return (
		state.showBanner && (
			<>
				<FireScreenAnalytics
					attributes={{
						screenName: 'jsm-upsell-screen',
					}}
				/>
				<Box xcss={upsellComponentStyles}>
					<b>{formatMessage(messages.upsellBannerHeading)}</b>
					<p>{formatMessage(messages.upsellBannerDescription)}</p>
					<Flex gap="space.100" xcss={trialButtonStyles}>
						<AnimatedButtonContainer>
							<Button
								iconBefore={
									<SparkleIcon label="" primaryColor={token('color.icon.inverse', '#FFFFFF')} />
								}
								href={billingPageUrl}
								onClick={() => {
									fireUIAnalytics(
										createAnalyticsEvent({
											action: 'clicked',
											actionSubject: 'TryButton',
										}),
										PREMIUM_UPSELL_BANNER,
									);
								}}
								target="_blank"
							>
								<Box xcss={fontColorStyles}>{formatMessage(messages.tryButtonDescription)}</Box>
							</Button>
						</AnimatedButtonContainer>
						<LinkButton
							appearance="subtle"
							href={PREMIUM_DOC}
							target="_blank"
							onClick={() => {
								fireUIAnalytics(
									createAnalyticsEvent({
										action: 'clicked',
										actionSubject: 'PremiumDocLink',
									}),
									PREMIUM_UPSELL_BANNER,
								);
							}}
						>
							{formatMessage(messages.whatIsPremiumLink)}
						</LinkButton>
					</Flex>
					<Box xcss={iconButtonWrapperStyles}>
						<Button
							onClick={() => {
								closeBanner();
							}}
							appearance="subtle"
							spacing="none"
						>
							<Cross size="small" label="Close Button" />
						</Button>
					</Box>
				</Box>
			</>
		)
	);
};

const gradientButtonStyles = {
	// 1px padding is neccessary for the gradient border to show
	borderRadius: '3px',
	padding: '1px',
	display: 'inline-flex',
	background: `linear-gradient(79.47deg,${token('color.background.accent.purple.bolder', '#6E5DC6')} 25.62%,
        ${token('color.background.accent.blue.bolder', '#0C66E4')} 99.57%)`,
	'&:hover': {
		background: `linear-gradient(79.47deg,${token('color.background.discovery.bold.hovered', '#5E4DB2')} 26.57%,
        ${token('color.background.brand.bold.hovered', '#0C66E4')} 99.57%)`,
	},
	'&:focus': {
		background: `linear-gradient(79.47deg,${token('color.background.discovery.bold.pressed', '#352C63')} 13.86%,
        ${token('color.background.brand.bold.pressed', '#09326C')} 99.57%)`,
	},
};

const iconButtonWrapperStyles = xcss({
	position: 'absolute',
	right: 'space.300',
	top: 'space.200',
});

const sparkleAnimation = keyframes({
	'0%': {
		transform: 'scale(0)',
		opacity: 0,
	},
	'40%': {
		transform: 'scale(1)',
		opacity: 1,
	},
	// The sparkle itself "ends" at 80%. We save the remaining 20% as a short delay before the animation starts again
	'80%': {
		transform: 'scale(0)',
		opacity: 0,
	},
	'100%': {
		transform: 'scale(0)',
		opacity: 0,
	},
});

const sparkleAnimationProperties = {
	animationName: sparkleAnimation,
	animationDuration: '2s',
	animationTimingFunction: 'ease-in-out',
	animationIterationCount: 'infinite',
	transformOrigin: 'center',
	transformBox: 'fill-box',
};

// This is used for the try button when we want the sparkle icon inside it to animate
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AnimatedButtonContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...gradientButtonStyles,

	// Animate sparkle icon on button hover
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& #starTopRight': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			...sparkleAnimationProperties,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& #starBottomRight': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			...sparkleAnimationProperties,
			// We delay this star's animation so the two stars sparkle separately
			animationDelay: '0.5s',
			// Because this star has an animation delay, we need to make it invisible to begin with
			// so it doesn't show up in its unanimated state for the first 0.5s of the animation (or however long we set the delay to).
			opacity: 0,
		},
	},
});

const trialButtonStyles = xcss({
	marginTop: 'space.200',
});

const fontColorStyles = xcss({
	color: 'color.text.inverse',
});

const upsellComponentStyles = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.300',
	position: 'relative',
	background: `linear-gradient(84.09deg, ${token(
		'color.background.accent.blue.subtlest',
		'#E9F2FF',
	)} 0%, ${token('color.background.accent.purple.subtlest', '#F3F0FF')} 100%)`,
});
