import {
	FREE_EDITION,
	getEdition,
	JIRA_SOFTWARE,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller';

export const useIsStandardJSW = (): boolean => {
	const appEditions = useAppEditions();
	const jswEdition = appEditions ? getEdition(JIRA_SOFTWARE, appEditions) : undefined;
	return jswEdition === STANDARD_EDITION;
};

export const useIsFreeJSW = (): boolean => {
	const appEditions = useAppEditions();
	const jswEdition = appEditions ? getEdition(JIRA_SOFTWARE, appEditions) : undefined;
	return jswEdition === FREE_EDITION;
};

export const useIsStandardOrFreeJSW = (): boolean => {
	const appEditions = useAppEditions();
	const jswEdition = appEditions ? getEdition(JIRA_SOFTWARE, appEditions) : undefined;
	return jswEdition === STANDARD_EDITION || jswEdition === FREE_EDITION;
};
