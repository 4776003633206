import type { Action } from 'react-sweet-state';
import { QUICKSTART_UI_STATUS } from '../../../common/constants';
import type { State } from '../../model/types';
import { getQuickstartTypeSelector } from '../../model/utils';

export const resetIsQuickstartLoaded =
	(): Action<State> =>
	({ getState, setState }) => {
		const state = getState();
		const quickstartType = getQuickstartTypeSelector(state);
		setState({
			visibility: {
				...state.visibility,
				[quickstartType]: QUICKSTART_UI_STATUS.NOT_LOADED,
			},
			hasNudgeActive: false,
			isQuickstartLoaded: false,
		});
	};
