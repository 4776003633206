// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import { ff } from '@atlassian/jira-feature-flagging';
import { initForgeBridge } from '@atlassian/jira-forge-legacy';
import type { TriggerSource } from './types';
import { sendStartMarkForLegacyIssueCreate, sendEndMarkForLegacyIssueCreate } from './utils';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	timeout,
	tentatives,

	// isInitialLoad tracks the initial render of global issue create dialog through this entry point (presently called from navigation create button and keyboard shortcut)
	isInitialLoad,

	triggerSource,
	showModern,
}: {
	timeout?: number;
	tentatives?: number;
	isInitialLoad?: boolean;
	triggerSource?: TriggerSource;
	showModern?: boolean;
} = {}): Promise<void> => {
	sendStartMarkForLegacyIssueCreate({ isInitialLoad, triggerSource });

	initForgeBridge();
	// eslint-disable-next-line jira/wrm/no-load-bridge
	return loadBridge({
		name: 'quick-edit/init/create-dialogs',
		wrmKeys: [
			'wrc!com.atlassian.jira.jira-quick-edit-plugin:quick-create-issue-bootstrap',
			'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-general',
			'wrc!com.atlassian.jira.jira-atlaskit-plugin:overrides-dialogs',
		],
		timeout,
		tentatives,
	}).then((api) => {
		if (ff('project.config.pce.modern.global-issue-create.trigger')) {
			if (showModern === true) {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				api.hideLoadingIndicator(false);
			} else {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				api.showCreateDialog({ showModern });
			}
		} else {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			api.showCreateDialog({});
		}

		sendEndMarkForLegacyIssueCreate({ isInitialLoad });
	});
};
