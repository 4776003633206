import {
	createSessionExpirableStorageProvider,
	type ExpirableStorage,
} from '@atlassian/jira-browser-storage-providers';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import type { AccountId } from '@atlassian/jira-shared-types';
import { KEY_HAS_NAVIGATED_FROM_LINK, ACCOUNT_ID } from '../constants';

const DAYS_BEFORE_EXPIRY = 1;

const DAY = 24 * 60 * 60 * 1000;

const getKey = ({ accountId, key }: { accountId: AccountId; key: string }) => `${accountId}.${key}`;

const getExpiryTime = () => {
	const now = Date.now();
	return now + DAYS_BEFORE_EXPIRY * DAY;
};

let localStorage: {
	provider: ExpirableStorage;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	get: (arg: { accountId: AccountId; key: string }) => any;
	set: (arg: { accountId: AccountId; key: string; value: unknown }) => void;
	remove: (arg: { accountId: AccountId; key: string }) => void;
};

export const localStorageProvider = () => {
	if (localStorage) {
		return localStorage;
	}

	const provider = createSessionExpirableStorageProvider('jsw-onboarding-checklist');
	try {
		provider.removeExpiredKeys();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		fireErrorAnalytics({
			meta: {
				id: 'localStorageProviderKeyRemoval',
				packageName: 'jiraOnboardingQuickstartCore',
			},
			error: e,
		});
	}

	const get = ({ accountId, key }: { accountId: AccountId; key: string }) =>
		provider.get(getKey({ accountId, key }));

	const set = ({ accountId, key, value }: { accountId: AccountId; key: string; value: unknown }) =>
		provider.set(getKey({ accountId, key }), value, getExpiryTime());

	const remove = ({ accountId, key }: { accountId: AccountId; key: string }) =>
		provider.remove(getKey({ accountId, key }));

	localStorage = {
		provider,
		remove,
		get,
		set,
	};

	return localStorage;
};

export const setLocalStorageHasNavigatedFromLink = (value: string) => {
	localStorageProvider().set({
		accountId: ACCOUNT_ID,
		key: KEY_HAS_NAVIGATED_FROM_LINK,
		value,
	});
};
