import type { FieldValidationError } from '@atlassian/jira-fetch/src/utils/errors';
import type { FlagConfiguration } from '@atlassian/jira-flags';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'forge-ui.common.flags.custom-field.edit-failed.validation-error.title',
		defaultMessage: 'Validation error',
		description:
			'Error message displayed when a validation error occurs, while saving custom field data.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (errors: FieldValidationError[]): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: errors.map(({ error }) => error).join(', '),
	isAutoDismiss: false,
});
