import React from 'react';
import {
	PRODUCT_DISCOVERY_PROJECT,
	projectTypeToCanonicalId,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	toProjectType,
} from '@atlassian/jira-common-constants/src/project-types';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common';
import Placeholder from '@atlassian/jira-placeholder';
import {
	FREE_EDITION,
	getEditionForProject,
	PREMIUM_EDITION,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { EDITION_AWARENESS, PACKAGE_NAME } from '../common/constants';
import { useMeetsJswEATopNavRequisites } from '../common/utils';
import { useIsImplicitJWMLicense } from '../controllers/is-implicit-jwm-license';
import { EditionAwarenessButton } from './edition-awareness-next';
import { FreePill } from './free-pill';
import { TrialPill } from './trial-pill';
import type { TrialPillProps } from './trial-pill/types';

// Experiment specific code to be removed during cleanup
const isEnrolledInJSMFreeToStandard = () => {
	const [config] = UNSAFE_noExposureExp('jsm-auto-upgrade-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

// Experiment specific code to be removed during cleanup
const isEnrolledInJPDFreeToStandard = () => {
	const [config] = UNSAFE_noExposureExp('jpd-free-to-upgrade-pill-au-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

const ImplicitJWMTrialPill = (props: Omit<TrialPillProps, 'productKey' | 'projectType'>) => {
	const isImplicitJWMLicense = useIsImplicitJWMLicense();

	if (!isImplicitJWMLicense) {
		return null;
	}
	// productKey is set to "jira-software", because it only has an entitlement with JSW, not with JWM.
	// projectType is the real value ("business").
	return (
		<UFOSegment name="edition-awareness-implicit-jwm-premium-trial">
			<TrialPill
				{...props}
				productKey={projectTypeToCanonicalId(SOFTWARE_PROJECT)}
				projectType={CORE_PROJECT}
			/>
		</UFOSegment>
	);
};

const ProductAndEditionChecker = () => {
	const appEditions = useAppEditions();
	const { loading, data: project } = useProject();
	const userMeetsJswTopNavRequisites = useMeetsJswEATopNavRequisites();

	// If the JSW EA top nav relocation is active, we want to default the project type to JSW
	// so that the pill can show even on product-agnostic pages (e.g. "Your work")
	const defaultProjectType = userMeetsJswTopNavRequisites ? SOFTWARE_PROJECT : undefined;
	const projectType = toProjectType(project?.type) || defaultProjectType;

	if (loading || !projectType) {
		return null;
	}

	const edition = getEditionForProject(projectType, appEditions);
	const productKey = projectTypeToCanonicalId(projectType);

	if (projectType === SOFTWARE_PROJECT || projectType === SERVICE_DESK_PROJECT) {
		switch (edition) {
			case FREE_EDITION:
				if (projectType === SERVICE_DESK_PROJECT && isEnrolledInJSMFreeToStandard()) {
					return (
						<UFOSegment name="edition-awareness-jsm-free-to-standard">
							<Placeholder name="edition-awareness-button-placeholder" fallback={null}>
								<EditionAwarenessButton
									applicationEdition="FREE_EDITION"
									applicationKey="jira-servicedesk"
									projectType={projectType}
								/>
							</Placeholder>
						</UFOSegment>
					);
				}

				return (
					<UFOSegment name="edition-awareness-jsw-jsm-free">
						<FreePill projectType={projectType} />
					</UFOSegment>
				);
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsw-jsm-standard-premium-trial">
						<TrialPill productKey={productKey} projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === PRODUCT_DISCOVERY_PROJECT) {
		if (fg('jpd_disable_edition_awareness')) {
			return null;
		}
		switch (edition) {
			case FREE_EDITION:
				if (isEnrolledInJPDFreeToStandard()) {
					return (
						<UFOSegment name="edition-awareness-jpd-free-to-standard">
							<Placeholder name="edition-awareness-button-placeholder" fallback={null}>
								<EditionAwarenessButton
									applicationEdition="FREE_EDITION"
									applicationKey="jira-product-discovery"
									projectType={projectType}
								/>
							</Placeholder>
						</UFOSegment>
					);
				}

				return null;
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jpd-standard-premium-trial">
						<TrialPill productKey={productKey} projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === CORE_PROJECT && edition === PREMIUM_EDITION) {
		// Core projects are partially supported - only for implicit JWM, on premium, on the top nav
		return <ImplicitJWMTrialPill edition={edition} />;
	}
	return null;
};

export const EditionAwareness = () => (
	<JSErrorBoundary
		id={EDITION_AWARENESS}
		packageName={PACKAGE_NAME}
		fallback="unmount"
		teamName="flywheel-tako"
	>
		<UFOSegment name="edition-awareness">
			<ProductAndEditionChecker />
		</UFOSegment>
	</JSErrorBoundary>
);
