import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const classicSoftwareBoardRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_BOARD,
	path: '/jira/software/c/projects/:projectKey/boards/:boardId',
	ufoName: 'classic-board',
};
