import { useCallback } from 'react';
import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';
import { useTopMenus, MENU_ID } from '@atlassian/jira-navigation-apps-common';

const PloverviewsSpotlightStore = createStore({
	initialState: {
		isSpotlightVisible: false,
	},
	actions: {
		showSpotlight:
			() =>
			({ setState }) => {
				setState({ isSpotlightVisible: true });
			},
		hideSpotlight:
			() =>
			({ setState }) => {
				setState({ isSpotlightVisible: false });
			},
	},
	name: 'PloverviewsSpotlightStore',
});

export const usePloverviewsSpotlightActions = createActionsHook(PloverviewsSpotlightStore);
export const usePloverviewsSpotlightState = createStateHook(PloverviewsSpotlightStore);

export const useShowPloverviewsSpotlight = () => {
	const [_, { on: openPlansMenu }] = useTopMenus(MENU_ID.PLANS);
	const { showSpotlight } = usePloverviewsSpotlightActions();

	return useCallback(() => {
		openPlansMenu();
		showSpotlight();
	}, [openPlansMenu, showSpotlight]);
};
