import { createStore, createContainer, createHook, type StoreActionApi } from 'react-sweet-state';
import {
	fetchRecentItems,
	fetchRecentItemsSingleList,
	fetchWorkedOnItems,
} from '@atlassian/jira-nav-item-service';
import {
	BOARD_TYPE,
	DASHBOARD_TYPE,
	FILTER_TYPE,
	PROJECT_TYPE,
	ISSUE_TYPE,
	QUEUE_TYPE,
} from '@atlassian/jira-nav-item-service/src/constants';
import type {
	RecentItemType,
	RecentResponseItem,
	WorkedOnResponseItem,
} from '@atlassian/jira-nav-item-service/src/types';
import {
	RECENTS_STORE_NAME,
	DEFAULT_LOAD_COUNT_RECENT,
	DEFAULT_LOAD_COUNT_WORKED_ON,
	DEFAULT_LOAD_COUNT_RECENT_SINGLE_LIST,
} from './constants';
import type { State, ContainerProps, ItemType } from './types';

export { DEFAULT_LOAD_COUNT_WORKED_ON };

const emptyItemState = {
	items: null,
	isFetching: false,
	fetchError: null,
	promise: null,
	hasFetchedOnce: false,
	hasSuccessOnce: false,
} as const;

export const initialState: State = {
	[BOARD_TYPE]: { ...emptyItemState },
	[DASHBOARD_TYPE]: { ...emptyItemState },
	[FILTER_TYPE]: { ...emptyItemState },
	[PROJECT_TYPE]: { ...emptyItemState },
	[QUEUE_TYPE]: { ...emptyItemState },
	workedOn: { ...emptyItemState },
	all: { ...emptyItemState },
};

const transformItems = (items: RecentResponseItem[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items.reduce<Record<string, any>>((acc, itemResponse) => {
		if (itemResponse.id === 'quick-search-boards') {
			acc[BOARD_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'quick-search-filters') {
			acc[FILTER_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'quick-search-dashboards') {
			acc[DASHBOARD_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'quick-search-projects') {
			acc[PROJECT_TYPE] = itemResponse.items;
		}
		if (itemResponse.id === 'quick-search-queues') {
			acc[QUEUE_TYPE] = itemResponse.items;
		}
		return acc;
	}, {});

const transformWorkedOnItems = (response: WorkedOnResponseItem) =>
	// select the right data and rename it
	response.data.activities.myActivities.workedOn.nodes.map(
		({ object: { id, localResourceId, name, extension, iconUrl, url, containers, type } }) => ({
			id,
			localResourceId,
			title: name,
			metadata: extension.issueKey,
			avatarUrl: iconUrl,
			url,
			attributes: {
				containerName: containers.find((container) => container.type === 'PROJECT')?.name,
			},
			type: `${type.toLowerCase()}s`,
		}),
	);

const getQueryConfig = (amount: number, types: ItemType[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	types.reduce<Record<string, any>>((acc, type) => {
		acc[type] = amount;
		return acc;
	}, {});

const loadRecentItems =
	(amount: number = DEFAULT_LOAD_COUNT_RECENT, types: ItemType[]) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		const typesToFetch = types.filter((type) => !getState()[type].isFetching);

		if (typesToFetch.length) {
			try {
				const promise = fetchRecentItems({
					counts: getQueryConfig(amount, typesToFetch),
				});

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const loadingState = typesToFetch.reduce<Record<string, any>>((acc, type) => {
					acc[type] = {
						...getState()[type],
						isFetching: true,
						fetchError: null,
						promise,
					};

					return acc;
				}, {});

				setState(loadingState);

				const itemResponse = await promise;

				const transformed = transformItems(itemResponse || []);

				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const responseState = typesToFetch.reduce<Record<string, any>>((acc, type) => {
					acc[type] = {
						items: transformed[type],
						isFetching: false,
						promise: null,
						fetchError: null,
						hasFetchedOnce: true,
						hasSuccessOnce: true,
					};
					return acc;
				}, {});

				setState(responseState);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (fetchError: any) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const errorState = typesToFetch.reduce<Record<string, any>>((acc, type) => {
					acc[type] = {
						...getState()[type],
						isFetching: false,
						promise: null,
						fetchError,
						hasFetchedOnce: true,
					};

					return acc;
				}, {});

				setState(errorState);
			}
		}
	};

const loadRecentItemsSingleList =
	(amount: number = DEFAULT_LOAD_COUNT_RECENT_SINGLE_LIST, types: RecentItemType[]) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		if (types.length) {
			try {
				const promise = fetchRecentItemsSingleList({
					types,
					limit: amount,
				});

				const loadingState = {
					all: {
						...getState().all,
						isFetching: true,
						fetchError: null,
						promise,
					},
				};

				setState(loadingState);

				const itemResponse = await promise;

				const responseState = {
					all: {
						items: itemResponse != null ? itemResponse[0].items : [],
						isFetching: false,
						promise: null,
						fetchError: null,
						hasFetchedOnce: true,
						hasSuccessOnce: true,
					},
				};

				setState(responseState);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (fetchError: any) {
				const errorState = {
					all: {
						...getState().all,
						isFetching: false,
						promise: null,
						fetchError,
						hasFetchedOnce: true,
					},
				};

				setState(errorState);
			}
		}
	};

const loadWorkedOnItems =
	(amount: number = DEFAULT_LOAD_COUNT_WORKED_ON) =>
	async ({ setState, getState }: StoreActionApi<State>, { cloudId }: ContainerProps) => {
		try {
			const promise = fetchWorkedOnItems({ cloudId, amount });

			const loadingState = {
				workedOn: {
					...getState().workedOn,
					isFetching: true,
					fetchError: null,
					promise,
				},
			};

			setState(loadingState);

			const itemResponse = await promise;

			const transformedItems = transformWorkedOnItems(itemResponse);

			const responseState = {
				workedOn: {
					items: transformedItems,
					isFetching: false,
					promise: null,
					fetchError: null,
					hasFetchedOnce: true,
					hasSuccessOnce: true,
				},
			};

			// @ts-expect-error - TS2345 - Argument of type '{ workedOn: { items: { id: string | number; localResourceId: string; title: string; metadata: string; avatarUrl: string; url: string; attributes: { containerName: string | null | undefined; }; type: string; }[]; ... 4 more ...; hasSuccessOnce: boolean; }; }' is not assignable to parameter of type 'Partial<State>'.
			setState(responseState);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (fetchError: any) {
			const errorState = {
				workedOn: {
					...getState().workedOn,
					isFetching: false,
					promise: null,
					fetchError,
					hasFetchedOnce: true,
				},
			};

			setState(errorState);
		}
	};

export const actions = {
	loadRecentAll:
		(amount?: number) => async (actions2: StoreActionApi<State>, props: ContainerProps) => {
			loadRecentItems(
				amount,
				[
					props.applicationPermissions.hasSoftwareAccess ? BOARD_TYPE : undefined,
					DASHBOARD_TYPE,
					PROJECT_TYPE,
					FILTER_TYPE,
					props.applicationPermissions.hasServiceDeskAccess ? QUEUE_TYPE : undefined,
				].filter(Boolean),
			)(actions2);
		},
	loadRecentAllSingleList:
		(amount?: number) => async (actions2: StoreActionApi<State>, props: ContainerProps) => {
			loadRecentItemsSingleList(
				amount,
				[
					props.applicationPermissions.hasSoftwareAccess ? BOARD_TYPE : undefined,
					DASHBOARD_TYPE,
					PROJECT_TYPE,
					FILTER_TYPE,
					ISSUE_TYPE,
					props.applicationPermissions.hasServiceDeskAccess ? QUEUE_TYPE : undefined,
				].filter(Boolean),
			)(actions2);
		},
	loadWorkedOnAllSingleList: loadWorkedOnItems,
	loadRecentBoards: (amount?: number) => loadRecentItems(amount, [BOARD_TYPE]),
	loadRecentProjects: (amount?: number) => loadRecentItems(amount, [PROJECT_TYPE]),
	loadRecentDashboards: (amount?: number) => loadRecentItems(amount, [DASHBOARD_TYPE]),
	loadRecentFilters: (amount?: number) => loadRecentItems(amount, [FILTER_TYPE]),
	loadRecentQueues: (amount?: number) => loadRecentItems(amount, [QUEUE_TYPE]),
} as const;

const store = createStore({
	name: RECENTS_STORE_NAME,
	actions,
	initialState,
});

export type Actions = typeof actions;

export const useRecentActions = createHook(store, {
	selector: null,
});

export const useRecentAllSingleList = createHook(store, {
	selector: (state) => state.all,
});

export const useWorkedOnAllSingleList = createHook(store, {
	selector: (state) => state.workedOn,
});

export const useRecentBoards = createHook(store, {
	selector: (state) => state[BOARD_TYPE],
});

export const useRecentDashboards = createHook(store, {
	selector: (state) => state[DASHBOARD_TYPE],
});

export const useRecentProjects = createHook(store, {
	selector: (state) => state[PROJECT_TYPE],
});

export const useRecentFilters = createHook(store, {
	selector: (state) => state[FILTER_TYPE],
});

export const useRecentQueues = createHook(store, {
	selector: (state) => state[QUEUE_TYPE],
});

export const RecentsContainer = createContainer<State, Actions, ContainerProps>(store);
