import {
	createActionsHook,
	createContainer,
	createHook,
	createStore,
	createSubscriber,
} from 'react-sweet-state';
import * as actions from './actions';
import type { State, ContainerProps } from './types';

export const CmdbUpsellPageContainer = createContainer<ContainerProps>();

const initialState: State = {
	userHasDismissed: undefined,
};

const store = createStore({
	initialState,
	actions,
	containedBy: CmdbUpsellPageContainer,
	handlers: { onInit: actions.fetchInitialState },
});

export const useUserHasDismissed = createHook(store, {
	selector: (state) => state.userHasDismissed,
});

export const useCmdbUpsellPageStore = createHook(store);

export const useCmdbUpsellPageStoreActions = createActionsHook(store);

export const CmdbUpsellPageStoreSubscriber = createSubscriber(store);
