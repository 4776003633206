import { getATLContextDomain } from '@atlassian/atl-context';
import type { ProjectType } from '@atlassian/jira-common-constants';
import { projectTypeToCanonicalId } from '@atlassian/jira-common-constants/src/project-types';
import {
	useCurrentUser,
	type ControllerFull as CurrentUserResponse,
} from '@atlassian/jira-platform-services-user-current';
import { SOFTWARE } from '@atlassian/jira-shared-types';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';

export function useIsJswUsingCcp() {
	const { productEntitlementDetails } = useTenantContext();

	const jswEntitlement = productEntitlementDetails?.[SOFTWARE];

	return jswEntitlement?.billingSourceSystem === 'CCP';
}

/*
 * Generalised version of useIsJswUsingCcp() for
 * JSM/JPD experiment by Transmuters
 * Contact: Matthew Yeow
 */
export function useIsProductUsingCcp(projectType: ProjectType) {
	const { productEntitlementDetails } = useTenantContext();
	const productKey = projectTypeToCanonicalId(projectType);

	const productEntitlement = productEntitlementDetails?.[productKey];

	return productEntitlement?.billingSourceSystem === 'CCP';
}

type UseLoggedInPageParams = {
	/**
	 * Unencoded URL to redirect to after confirming login status
	 */
	pageUrl: string;
};

/**
 * Returns a URL that ensures the user is logged in to the correct account before redirecting to the given `pageUrl`.
 * For example, ensuring that the user is logged in to the same account on WAC before redirecting to the JSFO/JSTO page.
 */
export function useLoggedInPage({ pageUrl }: UseLoggedInPageParams) {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { data: currentUserData } = useCurrentUser() as CurrentUserResponse;

	const encodedPageUrl = encodeURIComponent(pageUrl);
	// The user email should always be available unless the user is anonymous, in which case they won't see the edition awareness pill anyway
	const encodedUserEmail = encodeURIComponent(currentUserData.user.emailAddress ?? '');

	// This URL will take them through the login flow (if necessary) then redirect them to the given (encoded) `pageUrl`.
	return `https://${getATLContextDomain('id')}/login?continue=${encodedPageUrl}&login_hint=${encodedUserEmail}&prompt=none`;
}
