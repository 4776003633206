import { type Environment, fetchQuery, graphql } from 'react-relay';
import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types';
import type {
	canProductUserPayQuery$variables,
	canProductUserPayQuery,
} from '@atlassian/jira-relay/src/__generated__/canProductUserPayQuery.graphql';
import { PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types';

export const Query = graphql`
	query canProductUserPayQuery($cloudId: ID!, $productKey: String!) {
		tenantContexts(cloudIds: [$cloudId])
			@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement {
					subscription {
						accountDetails {
							invoiceGroup {
								experienceCapabilities {
									configurePaymentV2 {
										isAvailableToUser @required(action: THROW)
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const canProductUserPay = async (
	cloudId: string,
	productKey: CanonicalId,
	relayEnvironment: Environment,
): Promise<boolean> => {
	// JPD product key as used by Commerce does not include .ondemand
	const commerceProductKey =
		productKey === PRODUCT_DISCOVERY ? productKey : `${productKey}.ondemand`;

	const variables: canProductUserPayQuery$variables = {
		cloudId,
		productKey: commerceProductKey,
	};
	const response = await fetchQuery<canProductUserPayQuery>(relayEnvironment, Query, variables, {
		fetchPolicy: 'store-or-network',
	}).toPromise();

	const entitlementInfo =
		response?.tenantContexts && response.tenantContexts.length > 0
			? response.tenantContexts[0]?.entitlementInfo
			: undefined;

	const canPay =
		entitlementInfo?.entitlement?.subscription?.accountDetails?.invoiceGroup?.experienceCapabilities
			?.configurePaymentV2?.isAvailableToUser;

	return Boolean(canPay);
};
