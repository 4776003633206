import React, { useEffect } from 'react';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { MANAGE_PROJECTS_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { ERROR_BOUNDARY_ID_MANAGE_PROJECTS_NUDGE } from '../../common/constants';
import type { WrapperProps } from '../../common/types';
import { useRegisterNudgeTarget } from '../../common/ui/portalled-nudge';
import { manageProjectsNudgeEntryPoint } from './entrypoint';

const runtimeProps = {};
const entryPointParams = {};
export const ManageProjectsNudgeAsync = ({ children }: WrapperProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		manageProjectsNudgeEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const { ref: manageProjectsNudgeRef } = useRegisterNudgeTarget<HTMLDivElement>(
		MANAGE_PROJECTS_NUDGE_ID,
		true,
	);

	return (
		<>
			<span ref={manageProjectsNudgeRef}>{children}</span>
			<JiraEntryPointContainer
				id={ERROR_BOUNDARY_ID_MANAGE_PROJECTS_NUDGE}
				packageName="jiraManageProjectsOnboarding"
				entryPointReferenceSubject={entryPointReferenceSubject}
				fallback={<></>}
				runtimeProps={runtimeProps}
			/>
		</>
	);
};
