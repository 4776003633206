import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { NAVIGATION_CREATE_ISSUE_BUTTON_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import type { QuickstartNudgeWrapperBaseProps } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/quickstart-nudge/types';
import Placeholder from '@atlassian/jira-placeholder';
import { useCanShowQuickstartNudge } from '../../controllers/quickstart-nudge';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const NavigationCreateIssueButtonLazy = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-navigation-create-issue-nudge" */ './index'
		).then((exportedModule) => exportedModule.NavigationCreateIssueButtonWrapperNudge),
	{
		ssr: false,
	},
);
export const NavigationCreateIssueButtonWrapperNudgeAsync = ({
	children,
}: QuickstartNudgeWrapperBaseProps) => {
	const shouldShowNudge = useCanShowQuickstartNudge();

	if (!shouldShowNudge) {
		return <>{children}</>;
	}

	return (
		<OnboardingNudgeErrorBoundary
			id={NAVIGATION_CREATE_ISSUE_BUTTON_NUDGE_ID}
			fallback={children}
			attributes={{
				nudgeId: NAVIGATION_CREATE_ISSUE_BUTTON_NUDGE_ID,
			}}
		>
			<Placeholder
				name="software-onboarding-navigation-create-issue-nudge"
				fallback={<>{children}</>}
			>
				<NavigationCreateIssueButtonLazy nudgeId={NAVIGATION_CREATE_ISSUE_BUTTON_NUDGE_ID}>
					{children}
				</NavigationCreateIssueButtonLazy>
			</Placeholder>
		</OnboardingNudgeErrorBoundary>
	);
};
