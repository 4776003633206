import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createProject: {
		id: 'atlassian-navigation.projects.menu.footer.create-project',
		defaultMessage: 'Create project',
		description: 'Navigation - Project switcher - Text displayed on create project button',
	},
	viewAll: {
		defaultMessage: 'View all projects',
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'atlassian-navigation.projects.menu.footer.view-all',
		description: '"View all projects" menu item',
	},
	importProject: {
		id: 'atlassian-navigation.projects.menu.footer.import-project',
		defaultMessage: 'Import project',
		description: '"Import project" menu item',
	},
});
