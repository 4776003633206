import {
	type ApplicationKey,
	SOFTWARE,
	SERVICE_DESK,
	type ApplicationEdition,
	type TenantContext,
} from '@atlassian/jira-shared-types';

export const getProductEdition = (
	product: ApplicationKey,
	tenantContext: TenantContext,
): ApplicationEdition => {
	if (product === SOFTWARE) {
		return tenantContext.appEditions.software;
	}
	if (product === SERVICE_DESK) {
		return tenantContext.appEditions.serviceDesk;
	}

	return tenantContext.appEditions.core;
};
