export {
	QUERY_ERROR,
	UNSUPPORTED_FIELD,
	SERVICE_REQUEST,
	INCIDENT_MANAGEMENT,
	PROBLEM_MANAGEMENT,
	CHANGE_MANAGEMENT,
	POST_INCIDENT_REVIEW,
	DEVELOPER_ESCALATION,
	REQUEST_TYPE,
	UNASSIGNED_REQUEST_TYPE,
	ISSUE_TYPE,
} from './graphql-field-types';

export const WORK_LOG_FIELD_TYPE = 'worklog' as const;

export const REQUIRED = 'REQUIRED';

export const UNKNOWN_ERROR = 'unknown' as const;
export const NETWORK_ERROR = 'networkError' as const;
export const GRAPHQL_ERROR = 'graphQLError' as const;

export const FORM_DROPZONE_CONTAINER_ID = 'issue-create-modal-dropzone-container';
export const MINIMISED_DROPZONE_CONTAINER_ID = 'minimised-issue-create-dropzone-container';

export const LOG_WORK_TIME_SPENT_ERROR_KEY = 'worklog_timeLogged';
export const LOG_WORK_TIME_REMAINING_ERROR_KEY = 'worklog_newEstimate';
export const LOG_WORK_DATE_ERROR_KEY = 'worklog_startDate';
export const LOG_WORK_DESCRIPTION_ERROR_KEY = 'worklog_description';
export const COMMON_LOG_WORK_ERROR_KEYS = [
	'worklog_adjustmentAmount',
	'worklog_adjustEstimate',
	'worklog_activate',
] as const;
export const LOG_WORK_ERROR_KEYS = [
	...COMMON_LOG_WORK_ERROR_KEYS,
	LOG_WORK_TIME_SPENT_ERROR_KEY,
	LOG_WORK_TIME_REMAINING_ERROR_KEY,
	LOG_WORK_DATE_ERROR_KEY,
	LOG_WORK_DESCRIPTION_ERROR_KEY,
] as const;

export const MULTI_INPUT_FIELDS_ERROR_KEYS = {
	[WORK_LOG_FIELD_TYPE]: LOG_WORK_ERROR_KEYS,
} as const;

// smooth scrolling is not working correctly on chrome and causes page jump, hence we are using auto
export const DEFAULT_SCROLL_OPTIONS = {
	behavior: 'auto',
	scrollMode: 'if-needed',
	block: 'start',
} as const;

export const UNSUPPORTED_FIELD_TYPES_IN_GIC = [
	'com.atlassian.servicedesk:sd-request-participants',
] as const;

// issueCreateType for the modal in context query
export const ISSUE_CREATE_TYPE = {
	linkedIssue: 'LINKED_ISSUE_CREATE',
	standard: 'STANDARD',
} as const;

export type ISSUE_CREATE_TYPE = (typeof ISSUE_CREATE_TYPE)[keyof typeof ISSUE_CREATE_TYPE];

export const GLOBAL_EVENT_NAMESPACE = 'QuickCreateIssue';
