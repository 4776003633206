import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	daysLeftOnTrialButtonSentenceCase: {
		id: 'navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.trigger-button.days-left-on-trial-button-sentence-case',
		defaultMessage: '{trialDaysLeft, plural, one {# day} other {# days}} left',
		description: 'Trial information button title showing how many days is left in trial',
	},
	addPaymentDetailsSentenceCase: {
		id: 'navigation-apps-sidebar-edition-awareness.trial-pill.trial-pill-button.trigger-button.add-payment-details-sentence-case',
		defaultMessage: 'Add payment details',
		description: 'Button title prompting to add payment details',
	},
});
