import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { performGetRequestWithRetry } from '@atlassian/jira-fetch';
import {
	localStorageGetOnboardingPremiumTrial,
	localStorageGetOnboardingPremiumTrialCohort,
	localStorageSetOnboardingPremiumTrial,
	localStorageSetOnboardingPremiumTrialCohort,
} from '../localstorage';

const endpoint = '/rest/onboarding/1.0/premium-trial';

type OnboardingPremiumTrialResponse = {
	premiumTrialOnSignup: boolean;
	reverseTrialCohort: string;
};

export type GetOnboardingPremiumTrialResult = {
	premiumTrialOnSignup: boolean | undefined;
	reverseTrialCohort: string | undefined;
};

// Rethrow version is used with cachedEndpointCall to set the error into state.
export const getOnboardingPremiumTrialWithRethrow =
	async (): Promise<GetOnboardingPremiumTrialResult> => {
		try {
			// Attempt to get from localStorage
			const localStorageOnboardingPremiumTrial = localStorageGetOnboardingPremiumTrial();
			const localStorageOnboardingPremiumTrialCohort =
				localStorageGetOnboardingPremiumTrialCohort();

			if (
				typeof localStorageOnboardingPremiumTrial === 'boolean' &&
				typeof localStorageOnboardingPremiumTrialCohort === 'string'
			) {
				return {
					premiumTrialOnSignup: localStorageOnboardingPremiumTrial,
					reverseTrialCohort: localStorageOnboardingPremiumTrialCohort,
				};
			}

			const response = await performGetRequestWithRetry<OnboardingPremiumTrialResponse | undefined>(
				endpoint,
			);

			const premiumTrialOnSignup = response?.premiumTrialOnSignup;
			const reverseTrialCohort = response?.reverseTrialCohort;

			if (typeof premiumTrialOnSignup === 'boolean' && typeof reverseTrialCohort === 'string') {
				// Set into localStorage
				localStorageSetOnboardingPremiumTrial(premiumTrialOnSignup);
				localStorageSetOnboardingPremiumTrialCohort(reverseTrialCohort);
				return { premiumTrialOnSignup, reverseTrialCohort };
			}

			throw new Error(
				'response?.premiumTrialOnSignup or response?.reverseTrialCohort is undefined',
			);

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'getOnboardingPremiumTrialWithRethrow',
					packageName: 'jiraReverseTrialUtils',
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		}
	};

// No rethrow version in all other places e.g. consumed by QS.
export const getOnboardingPremiumTrial = async () => {
	try {
		return await getOnboardingPremiumTrialWithRethrow();
	} catch (e) {
		// ignored
	}
};
