export const BACKLOG_CREATE_ISSUE_NUDGE_ID = 'backlogCreateIssueNudge';
export const BACKLOG_CREATE_SPRINT_NUDGE_ID = 'backlogCreateSprintNudge';
export const BACKLOG_START_SPRINT_NUDGE_ID = 'backlogStartSprintNudge';
export const BACKLOG_COMPLETE_SPRINT_NUDGE_ID = 'backlogCompleteSprintNudge';
export const BACKLOG_INSIGHTS_NUDGE_ID = 'backlogInsightsNudge';

export const PACKAGE_NAME = 'jiraSoftwareOnboardingNudges';

export const ERROR_BOUNDARY_ID_SCRUM_TOUR_NUDGE = 'asyncScrumTourNudge';

export const ERROR_BOUNDARY_ID_KANBAN_TOUR_NUDGE = 'asyncKanbanTourNudge';

export const ERROR_BOUNDARY_ID_JWM_CALENDAR_TOUR_NUDGE = 'asyncJWMCalendarTourNudge';
export const ERROR_BOUNDARY_ID_JWM_CALENDAR_AND_TIMELINE_TOUR_NUDGE =
	'asyncJWMCalendarAndTimelineTourNudge';

export const ERROR_BOUNDARY_ID_JWM_BOARD_TOUR_NUDGE = 'asyncJWMBoardTourNudge';

export const ERROR_BOUNDARY_ID_JWM_LIST_TOUR_NUDGE = 'asyncJWMListTourNudge';
export const ERROR_BOUNDARY_ID_JWM_TIMELINE_TOUR_NUDGE = 'asyncJWMTimelineTourNudge';
export const ERROR_BOUNDARY_ID_JWM_FORM_TOUR_NUDGE = 'asyncJWMFormTourNudge';
export const ERROR_BOUNDARY_ID_JWM_TRELLO_KEY_DIFFERENCES_TOUR_NUDGE =
	'asyncJWMTrelloKeyDifferencesTourNudge';

export const ERROR_BOUNDARY_ID_JWM_SUMMARY_AND_REPORTS_TAB_NUDGE =
	'asyncJWMSummaryAndReportsTabNudge';

export const ERROR_BOUNDARY_ID_JWM_SUMMARY_VIEW_NUDGE = 'asyncJWMSummaryViewNudge';
export const ERROR_BOUNDARY_ID_MANAGE_PROJECTS_NUDGE = 'asyncManageProjectsNudge';
export const BUTTON_CLICKED_EVENT_NAME = 'button clicked';
export const ERROR_BOUNDARY_ID_JWM_INVITE_TEAM_NUDGE = 'asyncJWMInviteTeamNudge';

//  Kanban tour
export const BOARD_IN_PROGRESS_COLUMN_NUDGE_ID = 'boardInProgressColumnNudge';
export const BOARD_CREATE_ISSUE_NUDGE_ID = 'boardCreateIssueNudge';

// JWM Calendar tour
export const NAVIGATE_CALENDAR_NUDGE_ID = 'navigateCalendarNudge';

// JWM Board onboarding
export const TAILOR_BOARD_COLUMN_NUDGE_ID = 'tailorBoardColumnsNudge';

// List tour
export const INLINE_EDIT_LIST_NUDGE_ID = 'inlineEditListNudge';
export const FILTER_DROPDOWN_LIST_NUDGE_ID = 'filterListNudge';
export const MORE_DROPDOWN_LIST_NUDGE_ID = 'moreDropdownListNudge';

// JWM Timeline tour
export const TIMELINE_ADD_DATE_NUDGE_ID = 'timelineAddDateNudge';
export const TIMELINE_ADD_CHILD_ITEM_NUDGE_ID = 'timelineAddChildItemNudge';

// JWM calendar and timeline tour
export const TRELLO_CROSSFLOW_ADD_ITEMS_CALENDAR_NUDGE_ID = 'trelloCrossflowAddItemsCalendarNudge';
export const TRELLO_CROSSFLOW_TIMELINE_TAB_NUDGE_ID = 'trelloCrossflowTimelineTabNudge';
// JWM Trello Key Differences Tour
export const TRELLO_KEY_DIFFERENCES_ISSUE_NUDGE_ID = 'trelloKeyDifferencesIssueNudge';
export const TRELLO_KEY_DIFFERENCES_SUMMARY_NUDGE_ID = 'trelloKeyDifferencesSummaryNudge';
export const TRELLO_KEY_DIFFERENCES_BOARD_NUDGE_ID = 'trelloKeyDifferencesBoardNudge';

// JWM Form tour
export const AVAILABLE_FIELDS_FORM_NUDGE_ID = 'availableFieldsFormNudge';
export const CHANGE_ISSUE_TYPE_FORM_NUDGE_ID = 'changeIssueTypeFormNudge';
export const PREVIEW_SAVE_FORM_NUDGE_ID = 'previewSaveFormNudge';

// JWM Background Colour tour
export const BACKGROUND_COLOUR_NUDGE_ID = 'backgroundColorNudge';

// JWM Feedback tour
export const FEEDBACK_NUDGE_ID = 'feedbackNudge';

// JWM Calendar releases
export const CALENDAR_RELEASES_NUDGE_ID = 'calendarReleasesNudge';

// JWM Share project tour
export const SHARE_PROJECT_NUDGE_ID = 'shareProjectNudge';

// JWM Summary and Reports Tab onboarding
export const SUMMARY_TAB_NUDGE_ID = 'summaryTabNudge';
export const REPORTS_TAB_NUDGE_ID = 'reportsTabNudge';
