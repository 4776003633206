import { SUPPORTED_MODULES } from '@atlassian/jira-forge-ui-constants';
import {
	type ForgeUIType,
	toEventId,
	type EntryPoint,
	type ExtensionPointModule,
} from '@atlassian/jira-forge-ui-types';
import type { ModuleType, EventId } from '@atlassian/jira-forge-ui-types/src/common/types';
import { MODULE_TYPE_EXTENSION, PACKAGE_NAME_GASV3 } from '../../constants';

type ModuleParams = {
	module: ExtensionPointModule;
	moduleType?: ModuleType;
	entryPoint?: EntryPoint;
};

type BrowserMetricsModuleParams = {
	module: ExtensionPointModule;
	entryPoint?: EntryPoint;
	forgeUiType: ForgeUIType;
};

const capitalizeFirstLetter = (str?: string) =>
	(str != null && str.charAt(0).toUpperCase() + str.slice(1)) || '';

const prepareModuleMode = (entryPoint?: EntryPoint): string =>
	`${capitalizeFirstLetter(entryPoint)}`;

export const normalizeModuleName = (moduleName: ExtensionPointModule) =>
	moduleName.replace(/^jira:/, '');

const prepareModuleName = (moduleName: ExtensionPointModule) =>
	SUPPORTED_MODULES.includes(moduleName) ? normalizeModuleName(moduleName) : 'unknown';

export const getEventId = ({
	module,
	moduleType = MODULE_TYPE_EXTENSION,
	entryPoint,
}: ModuleParams): EventId => {
	const eventNamePostfix = moduleType === 'trigger' ? 'Trigger' : '';

	return toEventId(
		`${prepareModuleName(module)}${prepareModuleMode(entryPoint)}${eventNamePostfix}`,
	);
};

export const getBrowserMetricsEventId = ({
	module,
	entryPoint,
	forgeUiType,
}: BrowserMetricsModuleParams): EventId =>
	toEventId(`${prepareModuleName(module)}${prepareModuleMode(entryPoint)}-${forgeUiType}`);

export const getEventActionName = (eventId: EventId, action: string) =>
	`${PACKAGE_NAME_GASV3}.${eventId} ${action}`;

export { measureExecutionTimeMetrics } from './measure-execution-time';
export { getErrorType } from './get-error-type';
export { measureInitialPageLoadAsyncResourcesTiming } from './performance-analytics';
