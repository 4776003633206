import { get as getUserProperties } from '@atlassian/jira-common-rest/src/internal/latest/frontendconfig/properties';
import { toBaseUrl } from '@atlassian/jira-shared-types';
import { PACKAGE_NAME, PACKAGE_TEAM_NAME } from '../constants';

type GetSuccess<T> = {
	[key: string]: T;
};

export const pollBackend = async <T,>({ keys }: { keys: string[] }): Promise<GetSuccess<T>> => {
	const { user } = await getUserProperties(toBaseUrl(''), {
		userProperties: keys,
		consumerMeta: {
			id: 'fetchUserProperties',
			packageName: PACKAGE_NAME,
			teamName: PACKAGE_TEAM_NAME,
		},
	});
	return user;
};
