/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	searchYourIssuesSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.search-your-issues-synonyms',
		defaultMessage: 'Find my ideas tickets requests tasks work',
		description:
			'Synonym words for the command "Search your issues" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Search your issues" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	changeStatusSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.change-status-synonyms',
		defaultMessage: 'Move through workflow  Progress Done Complete transition',
		description:
			'Synonym words for the command "Change status" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Change status" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	assignToMeSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.assign-to-me-synonyms',
		defaultMessage: 'self',
		description:
			'Synonym words for the command "Assign to me" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Assign to me" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	assignIssueSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.assign-issue-synonyms',
		defaultMessage: 'Choose person Give work Pick assignee Select ticket idea',
		description:
			'Synonym words for the command "Assign issue" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Assign issue" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	startWatchingIssueSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.start-watching-issue-synonyms',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'Follow work Track Unwatch Unfollow',
		description:
			'Synonym words for the command "Start watching issue" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Start watching issue" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	backlogSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.backlog-synonyms',
		defaultMessage: 'Inventory Go',
		description:
			'Synonym words for the command "Backlog" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Backlog" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	boardSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.board-synonyms',
		defaultMessage: 'Boards Sprint Kanban Swimlanes Do Go',
		description:
			'Synonym words for the command "Board" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Board" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	reportsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.reports-synonyms',
		defaultMessage: 'Summary Insights Burndown Go',
		description:
			'Synonym words for the command "Reports" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Reports" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	timelineSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.timeline-synonyms',
		defaultMessage: 'Plan Work calendar Schedule Overview Delivery Go Roadmap',
		description:
			'Synonym words for the command "Timeline" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Timeline" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	// TODO cleanup isCommandPaletteQuickSearchEnabled
	quickSearchSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.quick-search-synonyms',
		defaultMessage: 'Search products across Atlassian Go to',
		description:
			'Synonym words for the command "Quick search" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Quick search" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	pageSearchSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.page-search-synonyms',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Control f Command cmd cntrl Go find ctrl',
		description:
			'Synonym words for the command "Page search" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Page search" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	createIssueSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.create-issue-synonyms',
		defaultMessage: 'work Start idea New ticket task',
		description:
			'Synonym words for the command "Create issue" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Create issue" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	switchDarkThemeSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.switch-dark-theme-synonyms',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'light Darkmode lightmode Change Appearance Colours colors nightmode daymode',
		description:
			'Synonym words for the command "Switch to dark theme" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Switch to dark theme" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	matchSystemThemeSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.match-system-theme-synonyms',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			'Sync settings Darkmode lightmode Change Appearance Colours colors nightmode daymode',
		description:
			'Synonym words for the command "Match system theme" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Match system theme" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	copyCurrentPageUrlSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.copy-current-page-url-synonyms',
		defaultMessage: 'link website address',
		description:
			'Synonym words for the command "Copy current page URL" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Copy current page URL" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	getHelpSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.get-help-synonyms',
		defaultMessage: 'In-product Support Documentation Questions Answers',
		description:
			'Synonym words for the command "Get help" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Get help" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},

	viewKeyboardShortcutsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.view-keyboard-shortcuts-synonyms',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Hotkeys Show See',
		description:
			'Synonym words for the command "View keyboard shortcuts" in the command palette. It is not expected a translation of this string, but a list of synonyms of "View keyboard shortcuts" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	editDescriptionSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.edit-description-synonyms',
		defaultMessage: 'Completion criteria Info Information Details Add Write',
		description:
			'Synonym words for the command "Edit description" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Edit description" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	addAttachmentSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.add-attachment-synonyms',
		defaultMessage: 'Attach Upload Document Media',
		description:
			'Synonym words for the command "Add attachment" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Add attachment" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	addCommentSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.add-comment-synonyms',
		defaultMessage: 'Write Message',
		description:
			'Synonym words for the command "Add comment" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Add comment" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	logWorkSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.log-work-synonyms',
		defaultMessage: 'Track Record hours Time spent',
		description:
			'Synonym words for the command "Log work" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Log work" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	addRemoveFlagSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.add-remove-flag-synonyms',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Unflag Mark Unmark Star Unstar',
		description:
			'Synonym words for the command "Add/Remove flag" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Add/Remove flag" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	cloneIssueSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.clone-issue-synonyms',
		defaultMessage: 'Copy Duplicate Task Idea Request',
		description:
			'Synonym words for the command "Clone issue" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Clone issue" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	linkConfluencePageSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.link-confluence-page-synonyms',
		defaultMessage: 'Relate Add Connect',
		description:
			'Synonym words for the command "Link Confluence page" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Link Confluence page" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	addAUrlSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.add-a-url-synonyms',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Connect Weblink Link Website Domain',
		description:
			'Synonym words for the command "Add a URL" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Add a URL" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	goToIssuesSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.go-to-issues-synonyms',
		defaultMessage: 'Advanced issue search Project Filter JQL search list View all',
		description:
			'Synonym words for the command "Go to issues" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Go to issues" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	goToPersonalSettingsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.go-to-personal-settings-synonyms',
		defaultMessage: 'Preferences Notifications Theme Jira labs',
		description:
			'Synonym words for the command "Go to personal settings" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Go to personal settings" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	goToAccountSettingsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.go-to-account-settings-synonyms',
		defaultMessage: 'Preferences Privacy Profile Your details Personal information',
		description:
			'Synonym words for the command "Go to account settings" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Go to account settings" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	viewAllIssuesSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.view-all-issues-synonyms',
		defaultMessage: 'Site issues JQL View all',
		description:
			'Synonym words for the command "View all issues" in the command palette. It is not expected a translation of this string, but a list of synonyms of "View all issues" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	viewAllProjectsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.view-all-projects-synonyms',
		defaultMessage: 'List Go',
		description:
			'Synonym words for the command "View all projects" in the command palette. It is not expected a translation of this string, but a list of synonyms of "View all projects" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	viewAllPlansSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.view-all-plans-synonyms',
		defaultMessage: 'List Go',
		description:
			'Synonym words for the command "View all plans" in the command palette. It is not expected a translation of this string, but a list of synonyms of "View all plans" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	viewAllBoardsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.view-all-boards-synonyms',
		defaultMessage: 'List Go',
		description:
			'Synonym words for the command "View all boards" in the command palette. It is not expected a translation of this string, but a list of synonyms of "View all boards" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	viewAllFiltersSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.view-all-filters-synonyms',
		defaultMessage: 'List Go',
		description:
			'Synonym words for the command "View all filters" in the command palette. It is not expected a translation of this string, but a list of synonyms of "View all filters" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	viewAllDashboardsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.view-all-dashboards-synonyms',
		defaultMessage: 'List Go',
		description:
			'Synonym words for the command "View all dashboards" in the command palette. It is not expected a translation of this string, but a list of synonyms of "View all dashboards" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	checkNotificationsSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.check-notifications-synonyms',
		defaultMessage: 'window See Updates',
		description:
			'Synonym words for the command "Check notifications" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Check notifications" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
	goToUserManagementSynonyms: {
		id: 'command-palette-integration.controllers.use-command-keywords.go-to-user-management-synonyms',
		defaultMessage: 'Permissions',
		description:
			'Synonym words for the command "Go to user management" in the command palette. It is not expected a translation of this string, but a list of synonyms of "Go to user managagement" in the target language. More information: https://hello.atlassian.net/wiki/spaces/JSDee/pages/2341166266/Command+palette+keyword+repository',
	},
});
