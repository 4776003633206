import { QUICKSTART_TYPE, QUICKSTART_UI_STATUS, ROOT_TASK } from '../../common/constants';
import type { State } from './types';

export const initialState: State = {
	shouldRenderComponent: false,
	completedTaskList: [],
	hasSeenTasks: [],
	isDismissed: true,
	isOpen: false,
	isFocusedOn: false,
	openTask: '',
	projectState: null,
	hasNudgeActive: false,
	isQuickstartLoaded: false,
	isDevOpsProject: false,
	nudgeStep: '',
	mountedNudges: [],
	hasEverSeenRoute: {
		'jsw.onboarding.experiment.personalised.onboarding.ntkw.onboarding.it2.roadmap': false,
	},
	roadmapIsCreateEnabled: false,
	roadmapIsListEmpty: true,
	previousTaskGroupParents: [],
	currentTaskGroupParent: ROOT_TASK,
	selectedProjectTemplateKeyFromOnboarding: undefined, // could be undefined (e.g. nth users who don't go through project create)
	isEligibleToUpdateSitename: false,
	isCrossProjectBoardToPlanUpsell: false,
	canEditPlans: false,
	visibility: {
		[QUICKSTART_TYPE.JSM_BUSINESS_QUICKSTART_KEY]: QUICKSTART_UI_STATUS.NOT_LOADED,
		[QUICKSTART_TYPE.OLD_GLOBAL_QUICKSTART_KEY]: QUICKSTART_UI_STATUS.NOT_LOADED,
	},
};
