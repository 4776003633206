/**
 * Consider moving into service
 */

import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { FetchError, performPostRequest } from '@atlassian/jira-fetch';
import { TRACE_ID_HEADER } from '@atlassian/jira-fetch/src/utils/constants';
import {
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	UNKNOWN_EXCEPTION,
	USER_SETTINGS_ERRORS,
} from './constants';
import type { PollingResult } from './types';

const throwIfNotOk = async (response: Response) => {
	if (response.ok) {
		return response;
	}
	if (response.status === 500) {
		throw new FetchError(
			response.status,
			UNKNOWN_EXCEPTION,
			response.headers.get(TRACE_ID_HEADER) ?? undefined,
			response,
		);
	}
};

const throwIfFailed = (result: PollingResult) => {
	if (result.samplePlan && !result.samplePlan.success) {
		throw new Error(result.samplePlan.error);
	}
};

const delay = (ms = 3000) =>
	new Promise((res: (result: Promise<undefined> | undefined) => void) => setTimeout(res, ms));

/* Post to async endpoint to get task id */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSamplePlanAsync = async (data: any) => {
	const response = await performPostRequest('/rest/jpo/1.0/plans/sampleplan/async', data);

	const { taskId } = response;

	if (!taskId) {
		throw new Error('Invalid response from createSamplePlan');
	}

	return taskId;
};

/* Poll to endpoint to get new sample plan response and percent progress */
export const pollCreateSamplePlan = async (
	taskId: string,
	onUpdate: (progressPercent: number) => void,
): Promise<PollingResult> => {
	const url = `/rest/jpo/1.0/plans/sampleplan/async/${taskId}`;

	try {
		const response = await fetch(url);

		await throwIfNotOk(response);

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const raw = (await response.json()) as PollingResult;

		throwIfFailed(raw);

		onUpdate(raw.progressPercent);

		if (raw.done) {
			return raw;
		}

		await delay();
		return pollCreateSamplePlan(taskId, onUpdate);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// we only want to track errors unrelated to user settings
		if (!USER_SETTINGS_ERRORS.includes(error.message)) {
			fireErrorAnalytics({
				meta: {
					id: `${ERROR_REPORTING_PACKAGE}PollingFailed`,
					packageName: ERROR_REPORTING_PACKAGE,
					teamName: ERROR_REPORTING_TEAM,
				},
				attributes: { message: 'Polling create sample plan failed' },
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		throw error;
	}
};
