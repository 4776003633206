import {
	type BoundActions,
	type Action,
	type Selector,
	createStore,
	createSubscriber,
	createHook,
	createContainer,
	type SubscriberComponent,
	type ContainerComponent,
	type StoreActionApi,
	type HookFunction,
} from 'react-sweet-state';
import { fetchAddonItems as defaultFetchAddonItems } from '@atlassian/jira-nav-item-service';
import type { AddonItem, FetchAddonContext } from '@atlassian/jira-nav-item-service/src/types';

type StoreConfig<I extends AddonItem> = {
	fetchAddons: (_?: string, context?: FetchAddonContext) => Promise<I[]>;
	addToFavorite: (item: I) => Promise<unknown>;
	removeFromFavorite: (item: I) => Promise<unknown>;
};

type GenericPublicState<I extends AddonItem> = {
	hasFetchedOnce: boolean;
	items: I[];
	fetchError: Error | null;
	promise: Promise<I[]> | null;
	hasSuccessOnce: boolean;
	isFetching: boolean;
};

type InternalState = {
	fetchAddonContext: FetchAddonContext;
	isFetching: boolean;
};

type GenericState<I extends AddonItem> = StoreConfig<I> & GenericPublicState<I> & InternalState;

const handleFetch =
	<I extends AddonItem>() =>
	async ({ setState, getState }: StoreActionApi<GenericState<I>>) => {
		const { fetchAddons, fetchAddonContext, isFetching } = getState();
		if (!isFetching) {
			try {
				const promise = fetchAddons(undefined, fetchAddonContext);

				setState({
					isFetching: true,
					fetchError: null,
					promise,
				});

				const items = await promise;

				setState({
					items,
					hasFetchedOnce: true,
					hasSuccessOnce: true,
					isFetching: false,
					fetchError: null,
					promise: null,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (fetchError: any) {
				setState({
					...getState(),
					fetchError,
					hasFetchedOnce: true,
					isFetching: false,
					promise: null,
				});
			}
		}
	};

const actions = {
	load:
		<I extends AddonItem>() =>
		async ({ dispatch }: StoreActionApi<GenericState<I>>) => {
			const promise = dispatch(handleFetch<I>());
			await promise;
		},

	setFavourite:
		<I extends AddonItem>(item: I, isAddon: boolean) =>
		async ({ setState, getState }: StoreActionApi<GenericState<I>>) => {
			const { items, addToFavorite, removeFromFavorite } = getState();
			const newItemData: I = {
				...item,
				favourite: true,
			};
			if (isAddon) {
				setState({
					items: [newItemData, ...items],
				});
				await addToFavorite(newItemData);
			} else {
				setState({
					items: items.filter((theItem) => theItem.id !== item.id),
				});
				await removeFromFavorite(item);
			}
		},
	setContext:
		<I extends AddonItem>(context: FetchAddonContext) =>
		({ setState }: StoreActionApi<GenericState<I>>) => {
			setState({
				fetchAddonContext: context,
			});
		},
} as const;

type Actions<I extends AddonItem> = {
	load: () => Action<GenericState<I>>;
	setFavourite: (item: I, isAddon: boolean) => Action<GenericState<I>>;
	setContext: (context: FetchAddonContext) => Action<GenericState<I>>;
};

export type UseAddonType<I extends AddonItem = AddonItem> = HookFunction<
	GenericPublicState<I>,
	BoundActions<GenericState<I>, Actions<I>>
>;
type AddonSubscriberType<I extends AddonItem> = SubscriberComponent<
	GenericPublicState<I>,
	BoundActions<GenericState<I>, Actions<I>>,
	{}
>;
type Ret<I extends AddonItem> = {
	AddonSubscriber: AddonSubscriberType<I>;
	useAddonActions: UseAddonType<I>;
	useAddon: UseAddonType<I>;
	AddonContainer: ContainerComponent<StoreConfig<I>>;
	AddonTestContainer: ContainerComponent<GenericState<I>>;
};

export const createAddonStore = <I extends AddonItem>(defaults: StoreConfig<I>): Ret<I> => {
	type Config = StoreConfig<I>;
	type PublicState = GenericPublicState<I>;
	type State = GenericState<I>;

	const initialState: State = {
		fetchAddons: defaults.fetchAddons,
		fetchAddonContext: {},
		addToFavorite: defaults.addToFavorite,
		removeFromFavorite: defaults.removeFromFavorite,
		hasFetchedOnce: false,
		hasSuccessOnce: false,
		items: [],
		fetchError: null,
		promise: null,
		isFetching: false,
	};
	const Store = createStore({
		name: 'addon-store',
		actions,
		initialState,
	});

	const AddonContainer = createContainer<State, Actions<I>, Config>(Store, {
		onInit: () => (api: StoreActionApi<State>, props: Config) => {
			api.setState({
				fetchAddons: props.fetchAddons,
				addToFavorite: props.addToFavorite,
				removeFromFavorite: props.removeFromFavorite,
			});
		},
	});

	const AddonTestContainer = createContainer<State, Actions<I>, State>(Store, {
		onInit: () => (api: StoreActionApi<State>, props: State) => {
			api.setState(props);
		},
	});
	const selector: Selector<State, void, GenericPublicState<I>> = ({
		items,
		hasFetchedOnce,
		fetchError,
		promise,
		hasSuccessOnce,
		isFetching,
	}) => ({
		items,
		hasFetchedOnce,
		hasSuccessOnce,
		fetchError,
		promise,
		isFetching,
	});

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const AddonSubscriber = createSubscriber<State, Actions<I>, PublicState, undefined>(Store, {
		displayName: 'AddonSubscriber',
		selector,
	}) as AddonSubscriberType<I>; // Bug with createSubscriber type means that React children end up "never"

	// Deprecated: to be removed
	const useAddonActions = createHook(Store, {
		selector,
	});

	const useAddon = createHook(Store, {
		selector,
	});

	return { AddonSubscriber, useAddonActions, useAddon, AddonTestContainer, AddonContainer };
};

const defaultAddToFavorite = () =>
	// TODO: to be implemented when api is ready
	new Promise(() => null);
const defaultRemoveFromFavorite = () =>
	// TODO: to be implemented when api is ready
	new Promise(() => null);
export const { useAddon } = createAddonStore<AddonItem>({
	fetchAddons: defaultFetchAddonItems,
	addToFavorite: defaultAddToFavorite,
	removeFromFavorite: defaultRemoveFromFavorite,
});
