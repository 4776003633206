import { usePlanNavItems } from '../../../../controllers/items';

export const useIsPlanListEmpty = () => {
	const { recentItems, favoriteItems } = usePlanNavItems();

	const hasRecentItems = recentItems && recentItems.length > 0;
	const hasFavoriteItems = favoriteItems && favoriteItems.length > 0;

	return !(hasRecentItems || hasFavoriteItems);
};
