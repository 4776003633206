import React from 'react';
import { type JSErrorBoundaryProps, JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { useAddErrorBoundaryAnalyticsAttributes } from '../../utils/chunk-error-logging';

export type Props = JSErrorBoundaryProps & {
	attributes?: Attributes;
};

export const ErrorBoundaryWithChunkErrorLogging = (props: Props) => {
	const { attributes, children, ...rest } = props;
	const addErrorBoundaryAnalyticsAttributes = useAddErrorBoundaryAnalyticsAttributes(attributes);

	return (
		<JSErrorBoundary {...rest} attributes={addErrorBoundaryAnalyticsAttributes}>
			{children}
		</JSErrorBoundary>
	);
};
