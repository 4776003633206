import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { NAVIGATION_APPS_NAV_ITEM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import type { QuickstartNudgeWrapperBaseProps } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/quickstart-nudge/types';
import Placeholder from '@atlassian/jira-placeholder';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const NavigationAppsNavItemLazy = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-navigation-apps-nudge" */ './index'
		).then((exportedModule) => exportedModule.NavigationAppsNavItemWrapperNudge),
	{
		ssr: false,
	},
);
export const NavigationAppsNavItemWrapperNudgeAsync = ({
	children,
	isInsideMenu,
}: QuickstartNudgeWrapperBaseProps) => (
	<OnboardingNudgeErrorBoundary
		id={NAVIGATION_APPS_NAV_ITEM_NUDGE_ID}
		fallback={children}
		attributes={{
			nudgeId: NAVIGATION_APPS_NAV_ITEM_NUDGE_ID,
		}}
	>
		<Placeholder name="navigation-apps-nav-item-lazy" fallback={<>{children}</>}>
			<NavigationAppsNavItemLazy
				nudgeId={NAVIGATION_APPS_NAV_ITEM_NUDGE_ID}
				isInsideMenu={isInsideMenu}
			>
				{children}
			</NavigationAppsNavItemLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);
