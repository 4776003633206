import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	jsmPlansHeading: {
		defaultMessage: 'Jira Service Management plan',
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.jsm-plans-heading',
		description:
			'Title of jira service Management plan section on top navigation bar settings menu',
	},
	jsmPlansTryPremiumDataItemHeading: {
		defaultMessage: 'Try Premium',
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.jsm-plans-try-premium-data-item-heading',
		description:
			'Try Premium heading on Data Item of jira service Management plan section when on standard plan',
	},
	jsmPlansDaysLeftOnTrialDataItemHeading: {
		defaultMessage:
			'Your Premium trial ends in {trialDaysLeft, plural, one {# day} other {# days}}.',
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.jsm-plans-days-left-on-trial-data-item-heading',
		description:
			'trial days left heading on Data Item of jira service Management plan section when on premium trial',
	},
	jsmPlansDaysLeftOnTrialDataItemHeadingToday: {
		defaultMessage: 'Your Premium trial ends today.',
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.jsm-plans-days-left-on-trial-data-item-heading-today',
		description:
			'heading on Data Item of jira service Management plan section when on premium trial when trial ends today',
	},
	jsmPlansTryPremiumDataItemDescriptionStandard: {
		defaultMessage:
			"You're on the Standard plan. <a>Premium</a> helps scale your team with smart incident management and AI.",
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.jsm-plans-try-premium-data-item-description-standard',
		description:
			'Description of Data Item on jira service Management plan section when on standard edition',
	},
	jsmPlansTryPremiumButton: {
		defaultMessage: 'Start free trial',
		id: 'atlassian-navigation.settings.menu.jsm-upsell-hook.jsm-plans-try-premium-button',
		description: 'Try Premium button in jira service Management plan section',
	},
});
