import React from 'react';
import prsLocaleMapper from '@atlaskit/prs-locale-mapper';
import { useIntl } from '@atlassian/jira-intl';
import { PeopleAndTeamsConfigurationProvider } from '@atlassian/people-teams-configuration-client';
import { PeopleMenu as PeopleMenuAsync } from './async';

export const People = () => {
	const { locale } = useIntl();

	return (
		// this won't have any effect on those using the old people-menu except it will still cache
		// the remotely configured result for when they are rolled out to the new version
		<PeopleAndTeamsConfigurationProvider product="jira" locale={prsLocaleMapper(locale)}>
			<PeopleMenuAsync />
		</PeopleAndTeamsConfigurationProvider>
	);
};

export { People as PeopleMenu } from './menu-with-configuration';
