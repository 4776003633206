import type { SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl';
import { getEmceeAppListingLink } from '@atlassian/jira-platform-marketplace-url-resolver';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IntegrationActionLinkProps } from '../types';

export const getConnectActionLinkProps = (
	integrationKey: string,
	linkActionSubjectId: string,
	message: MessageDescriptor,
): IntegrationActionLinkProps => {
	// @ts-expect-error - TS2554 - Expected 3-4 arguments, but got 2.
	const link = getEmceeAppListingLink(integrationKey, {
		source: 'quickstart',
		installDialogOpen: true,
	});

	return {
		message,
		onClick: (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			event.stopPropagation();

			const eventName = `${linkActionSubjectId}ConnectApp`;

			fireUIAnalytics(analyticsEvent, eventName, {
				link,
				integration: integrationKey,
			});
		},
		link,
	};
};
