import { useCallback, useState } from 'react';
import { fetchQuery, useRelayEnvironment, graphql } from 'react-relay';
import type {
	canProductUserUpgradeAndPayQuery,
	canProductUserUpgradeAndPayQuery$data,
} from '@atlassian/jira-relay/src/__generated__/canProductUserUpgradeAndPayQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';

export const Query = graphql`
	query canProductUserUpgradeAndPayQuery($cloudId: ID!, $productKey: String!) {
		tenantContexts(cloudIds: [$cloudId])
			@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
			entitlementInfo(hamsProductKey: $productKey) {
				entitlement {
					transactionAccount {
						isCurrentUserBillingAdmin @required(action: THROW)
						experienceCapabilities {
							addPaymentMethodV2 {
								isAvailableToUser @required(action: THROW)
							}
						}
					}
					experienceCapabilities {
						changeOfferingV2 {
							isAvailableToUser @required(action: THROW)
						}
					}
				}
			}
		}
	}
`;

export type SupportedProductKey = 'jira-software.ondemand' | 'jira-servicedesk.ondemand';

type CanProductUserUpgradeAndPayData = {
	canProductUserUpgradeAndPay: boolean;
	isBillingAdmin: boolean;
};

type ProductUserCanUpgradeAndPayResponse = {
	fetch: (productKey: SupportedProductKey) => void;
	data?: CanProductUserUpgradeAndPayData;
	isLoading: boolean;
	error: Error | null;
};

/**
 * Makes a GraphQL network request to CCP to determine whether a product user can upgrade and pay.
 */
export const useCanProductUserUpgradeAndPayQuery = (): ProductUserCanUpgradeAndPayResponse => {
	const cloudId = useCloudId();
	const relayEnvironment = useRelayEnvironment();

	const [data, setData] = useState<CanProductUserUpgradeAndPayData | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<Error | null>(null);

	const fetch = useCallback(
		(productKey: SupportedProductKey) => {
			setIsLoading(true);

			const variables = {
				cloudId,
				productKey,
			};

			fetchQuery<canProductUserUpgradeAndPayQuery>(relayEnvironment, Query, variables, {
				fetchPolicy: 'store-or-network',
			}).subscribe({
				complete: () => {
					setIsLoading(false);
				},
				next: (response: canProductUserUpgradeAndPayQuery$data) => {
					// Endpoint takes in an array of cloudIds, but we never call this with more than one cloudId
					const entitlement = response?.tenantContexts?.[0]?.entitlementInfo?.entitlement;

					const canPay =
						entitlement?.transactionAccount?.experienceCapabilities?.addPaymentMethodV2
							?.isAvailableToUser;
					const canUpgrade =
						entitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser;

					const isCurrentUserBillingAdmin =
						entitlement?.transactionAccount?.isCurrentUserBillingAdmin;

					const canUpgradeAndPay = canUpgrade && canPay;
					setData({
						canProductUserUpgradeAndPay: canUpgradeAndPay ?? false,
						isBillingAdmin: isCurrentUserBillingAdmin ?? false,
					});
				},
				error: (fetchError: Error) => {
					setIsLoading(false);
					setError(fetchError);
				},
			});
		},
		[cloudId, relayEnvironment],
	);

	return {
		fetch,
		data,
		isLoading,
		error,
	};
};
