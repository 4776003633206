import { useEffect, useState, createContext, useContext } from 'react';
import type { envType } from '@atlassiansox/analytics-web-client';
import { choreographerFactory, ProductIds } from '@atlassian/ipm-choreographer'; // index.d.ts
import getMeta from '@atlassian/jira-get-meta';
import { toEnvironment } from '@atlassian/jira-shared-types';
import type { ChoreographedComponentProps } from './types';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const environment = (toEnvironment(getMeta('ajs-environment')) ?? 'prod') as envType;

type ChoreographerFactory = ReturnType<typeof choreographerFactory>;

/**
 * Stupid hack alert: useChoreographer is throwing a TS compiler error about using MessageEventType
 * internally, and not being able to resolve that name... The usual fix for this is to add the
 * offending type to the project's exports, and then import it here so that the compiler has some
 * knowledge of what that type definition is... In this case, it doesn't seem to be working, but
 * for whatever reason, I can cast the choreographerFactory's return type using nothing more than
 * the information provided by the ReturnType<typeof choreographerFactory> type above, and then
 * define the useChoreographer type as an args list that conforms to its Parameters list, and a
 * return type that conforms to its ReturnType, and that seems to work just fine. Hopefully, somebody
 * can figure out what's going wrong here, and remove this idiocy in favor of something more sane.
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const { plugin, useChoreographer } = choreographerFactory(ProductIds.JIRA, environment) as {
	plugin: ChoreographerFactory['plugin'];
	useChoreographer: (
		...args: Parameters<ChoreographerFactory['useChoreographer']>
	) => ReturnType<ChoreographerFactory['useChoreographer']>;
};

const useChoreographedRender = ({
	messageId,
	onMessageDisposition,
}: ChoreographedComponentProps) => {
	const [shouldRender, setShouldRender] = useState(false);
	const { startMessage, stopMessage } = useChoreographer(messageId, {
		start: () => setShouldRender(true),
	});

	useEffect(() => {
		const startMessageAsync = async () => {
			const disposition = await startMessage();
			onMessageDisposition?.(disposition);
		};

		startMessageAsync();

		return () => {
			stopMessage();
		};
	}, [onMessageDisposition, startMessage, stopMessage]);

	return shouldRender;
};

export const ChoreographedStatusContext = createContext({
	isAlreadyChoreographedAtAncestor: false,
});

export const useChoreographedStatusContext = () => useContext(ChoreographedStatusContext);

export { plugin as choreographerPlugin, useChoreographer, useChoreographedRender };
