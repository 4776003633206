import memoizeOne from 'memoize-one';
import { useService } from '@atlassian/jira-common-services/src/use-service';
import { fetchJson } from '@atlassian/jira-fetch';
import type { PersonalizationResponse, SiteUserPersonalizationRequest } from '../../types';

const getPersonalizationSiteUserTraitsUrl = (cloudId: string, userId: string) =>
	`/gateway/api/tap-delivery/api/v3/personalization/site/${cloudId}/user/${userId}`;

export const fetchSiteUserPersonalization = async ({
	cloudId,
	userId,
}: SiteUserPersonalizationRequest): Promise<PersonalizationResponse['attributes']> => {
	if (!cloudId) throw new Error('empty cloudId');
	if (!userId) throw new Error('empty userId');

	const { attributes } = await fetchJson(getPersonalizationSiteUserTraitsUrl(cloudId, userId), {
		method: 'GET',
		credentials: 'include',
		headers: {
			'Content-Type': 'application/json',
			'x-atlassian-mau-ignore': 'true',
		},
	});

	return attributes;
};

export const memoizedFetchSiteUserPersonalization = memoizeOne(
	fetchSiteUserPersonalization,
	([{ cloudId: newCloudId, userId: newUserId }], [{ cloudId: lastCloudId, userId: lastUserId }]) =>
		newCloudId === lastCloudId && newUserId === lastUserId,
);

/**
 * Use personalisation API to fetch site user traits
 */
export const useSiteUserPersonalization = () => useService(memoizedFetchSiteUserPersonalization);
