import React, { useCallback } from 'react';
import { useExperienceStart } from '@atlassian/jira-experience-tracker';
import { useFavoriteFilters as useFavorite } from '@atlassian/jira-favorites-store';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { useRecentFilters as useRecent } from '@atlassian/jira-recents-store';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import {
	DEFAULT_LOAD_COUNT_FAV,
	DEFAULT_LOAD_COUNT_RECENT,
	EXPERIENCE_NAVIGATION_TOP_MENU,
	NAVIGATION_ITEM_ID,
} from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { MenuTrigger } from '../../common/ui/menu/trigger';
import { Popup } from '../../common/ui/popup';
import PopupBoxWrapper from '../../common/ui/popup-box-wrapper';
import { Menu } from './menu';
import messages from './messages';

const FiltersAnonymous = () => {
	const { formatMessage } = useIntl();

	return (
		<MenuTrigger
			href="/jira/filters?searchName=&Search=Search&filterView=search"
			menuId={MENU_ID.FILTERS}
			navigationItemId={NAVIGATION_ITEM_ID.FILTERS}
		>
			{formatMessage(messages.filters)}
		</MenuTrigger>
	);
};

const FiltersLoggedIn = () => {
	const { formatMessage } = useIntl();
	const [{ hasFetchedOnce: hasFetchedOnceFavorites }, { loadFavoriteFilters: loadFavorite }] =
		useFavorite();
	const [, { loadRecentFilters: loadRecent }] = useRecent();
	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.FILTERS,
		analyticsSource: 'atlassian-navigation',
	});

	const onBeforeMenuOpen = useCallback(() => {
		onStart();
		// refresh recents on open to add recently visited pages
		loadRecent(DEFAULT_LOAD_COUNT_RECENT);
		// load favourites only if not loaded already (optimisation to reduce requests)
		if (!hasFetchedOnceFavorites) loadFavorite(DEFAULT_LOAD_COUNT_FAV);
	}, [hasFetchedOnceFavorites, loadFavorite, loadRecent, onStart]);

	return (
		<PopupBoxWrapper>
			<Popup
				menuId={MENU_ID.FILTERS}
				navigationItemId={NAVIGATION_ITEM_ID.FILTERS}
				Menu={Menu}
				onBeforeMenuOpen={onBeforeMenuOpen}
				label={formatMessage(messages.filters)}
			>
				{formatMessage(messages.filters)}
			</Popup>
		</PopupBoxWrapper>
	);
};

const FiltersWithAuth = () => {
	const isAnonymous = useIsAnonymous();

	return isAnonymous ? <FiltersAnonymous /> : <FiltersLoggedIn />;
};

export const Filters = () => (
	<TopLevelErrorBoundary id={MENU_ID.FILTERS}>
		<FiltersWithAuth />
	</TopLevelErrorBoundary>
);
