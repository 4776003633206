import { createStore, createHook } from 'react-sweet-state';
import type { OrgId } from '../../../common/types';
import type { State } from '../../cached-endpoint/types';
import { fetchOrgId } from './action';

const actions = { fetchOrgId } as const;

type Actions = typeof actions;

const initialState = {
	isFetching: false,
	wasFetched: false,
	fetchedOnce: false,
} as const;

const Store = createStore<State<OrgId>, Actions>({
	initialState,
	actions,
	name: 'jsw-onboarding-quickstart-linked-org',
});

export const useLinkedOrg = createHook(Store);
