import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewAll: {
		defaultMessage: 'View all dashboards',
		// eslint-disable-next-line jira/i18n/id-named-by-package -- PLEASE FIX - ENABLING FLAT LINT CONFIG
		id: 'atlassian-navigation.dashboards.menu.footer.view-all',
		description: '"View all dashboards" menu item',
	},
});
