import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import { NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants';
import { OnboardingNudgeErrorBoundary } from '@atlassian/jira-onboarding-quickstart-core/src/common/ui/onboarding-nudge-error-boundary';
import Placeholder from '@atlassian/jira-placeholder';
import type { WrapperProps } from '../../common/types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const NavigationProfileNavItemWrapperNudgeLazy = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-navigation-profile-nudge" */ './index'
		).then((exportedModule) => exportedModule.NavigationProfileNavItemWrapperNudge),
	{
		ssr: false,
	},
);

export const NavigationProfileNavItemWrapperNudgeAsync = ({ children }: WrapperProps) => (
	<OnboardingNudgeErrorBoundary
		id="asyncProfileNudge"
		fallback={children}
		attributes={{ NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID }}
	>
		<Placeholder name="navigation-profile-nav-item-wrapper-nudge-lazy" fallback={children}>
			<NavigationProfileNavItemWrapperNudgeLazy nudgeId={NAVIGATION_APPS_PROFILE_ITEM_NUDGE_ID}>
				{children}
			</NavigationProfileNavItemWrapperNudgeLazy>
		</Placeholder>
	</OnboardingNudgeErrorBoundary>
);

NavigationProfileNavItemWrapperNudgeAsync.displayName = 'NavigationProfileNavItemWrapperNudgeAsync';
