/** @jsx jsx */
import React, { type FunctionComponent } from 'react';
import { css, jsx } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/import/no-parent-imports
import { messages } from '../messages';

export const NotificationBellTeamsSplash: FunctionComponent = () => {
	const { formatMessage } = useIntl();

	return (
		<div
			css={TeamsSplashStyles}
			data-testid="third-party-nudge.ui.third-party-nudge-flag.splashes.notification-bell-splashes.teams-splash"
		>
			<svg
				width="412"
				height="128"
				viewBox="0 0 412 128"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z"
					fill="url(#paint0_linear_2726_7333)"
				/>
				<g filter="url(#filter0_dd_2726_7333)">
					<g opacity="0.98">
						<path
							d="M112 26.4701C112 25.106 113.106 24.0001 114.47 24.0001H357.53C358.894 24.0001 360 25.106 360 26.4701V100C360 102.209 358.209 104 356 104H116C113.791 104 112 102.209 112 100V26.4701Z"
							fill="white"
						/>
						<text
							fill="#091E42"
							xmlSpace="preserve"
							white-space="pre"
							font-size="9"
							letter-spacing="0em"
						>
							<tspan x="124" y="45.1994">
								{formatMessage(messages.teamsNudgeTitle)}
							</tspan>
						</text>
						<rect x="124" y="54" width="210" height="6" rx="3" fill="#DCDFE4" />
						<rect x="124" y="66" width="91" height="6" rx="3" fill="#DCDFE4" />
						<rect x="218" y="66" width="91" height="6" rx="3" fill="#DCDFE4" />
						<rect
							x="124.3"
							y="80.3001"
							width="61.9924"
							height="15.4"
							rx="1.48113"
							stroke="#DFD8FD"
							stroke-width="0.6"
						/>
						<text
							fill="#5E4DB2"
							xmlSpace="preserve"
							white-space="pre"
							font-size="9"
							letter-spacing="0px"
						>
							<tspan x="134.26" y="91.1994">
								{formatMessage(messages.nudgeCommentButton)}
							</tspan>
						</text>
						<rect
							x="192.892"
							y="80.3001"
							width="60.9924"
							height="15.4"
							rx="1.48113"
							stroke="#DFD8FD"
							stroke-width="0.6"
						/>
						<text
							fill="#5E4DB2"
							xmlSpace="preserve"
							white-space="pre"
							font-size="9"
							letter-spacing="0px"
						>
							<tspan x="202.414" y="91.1994">
								{formatMessage(messages.nudgeEditIssueButton)}
							</tspan>
						</text>
						<rect
							x="260.485"
							y="80.3001"
							width="70.9924"
							height="15.4"
							rx="1.48113"
							stroke="#DFD8FD"
							stroke-width="0.6"
						/>
						<text
							fill="#5E4DB2"
							xmlSpace="preserve"
							white-space="pre"
							font-size="9"
							letter-spacing="0px"
						>
							<tspan x="269.992" y="91.1994">
								{formatMessage(messages.nudgeOpenInJiraButton)}
							</tspan>
						</text>
					</g>
					<rect x="58" y="28" width="42" height="42" rx="4" fill="white" />
					<g clip-path="url(#clip0_2726_7333)">
						<path
							d="M79.42 44.6668C81.582 44.6668 83.3346 42.9691 83.3346 40.8749C83.3346 38.7806 81.582 37.0829 79.42 37.0829C77.258 37.0829 75.5053 38.7806 75.5053 40.8749C75.5053 42.9691 77.258 44.6668 79.42 44.6668Z"
							fill="#7B83EB"
						/>
						<path
							opacity="0.1"
							d="M79.1404 41.6875H75.5948L75.6125 41.7631L75.6141 41.7696L75.6326 41.8395C75.8902 42.7858 76.5153 43.5991 77.3773 44.1096C78.2393 44.6202 79.2715 44.7884 80.2585 44.5792V42.7706C80.2577 42.4836 80.1396 42.2086 79.9301 42.0057C79.7206 41.8028 79.4367 41.6884 79.1404 41.6875Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M78.3021 42.5H75.883C76.2005 43.1484 76.7014 43.6962 77.3275 44.0798C77.9537 44.4634 78.6793 44.667 79.4202 44.667V43.5831C79.4193 43.2961 79.3012 43.0211 79.0917 42.8182C78.8822 42.6153 78.5983 42.5009 78.3021 42.5Z"
							fill="black"
						/>
						<path
							d="M88.2273 44.9375C89.8487 44.9375 91.1631 43.6643 91.1631 42.0938C91.1631 40.5232 89.8487 39.25 88.2273 39.25C86.6059 39.25 85.2915 40.5232 85.2915 42.0938C85.2915 43.6643 86.6059 44.9375 88.2273 44.9375Z"
							fill="#5059C9"
						/>
						<path
							d="M90.7439 45.75H84.1501C84.0077 45.75 83.8712 45.8048 83.7705 45.9023C83.6698 45.9998 83.6133 46.1321 83.6133 46.27V52.8594C83.5951 53.8131 83.9157 54.7438 84.5215 55.4958C85.1273 56.2478 85.9814 56.7754 86.9408 56.9901C87.5518 57.1143 88.1838 57.105 88.7906 56.9627C89.3975 56.8205 89.9639 56.549 90.4487 56.1679C90.9336 55.7868 91.3246 55.3058 91.5933 54.7599C91.862 54.214 92.0016 53.6169 92.002 53.0121V46.9688C92.002 46.6455 91.8695 46.3355 91.6335 46.107C91.3976 45.8784 91.0775 45.75 90.7439 45.75Z"
							fill="#5059C9"
						/>
						<path
							d="M86.1305 46.9688V54.6875C86.1313 56.118 85.6447 57.5087 84.7461 58.6438C83.8476 59.779 82.5873 60.5951 81.1609 60.9655C79.7346 61.3359 78.2219 61.2399 76.8576 60.6924C75.4933 60.1449 74.3537 59.1765 73.6157 57.9375C73.4543 57.6777 73.3141 57.406 73.1963 57.125C73.0866 56.8601 72.9941 56.5887 72.9195 56.3125C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H84.8723C85.0376 45.7498 85.2013 45.7812 85.3541 45.8424C85.5068 45.9035 85.6456 45.9933 85.7625 46.1065C85.8794 46.2197 85.972 46.3542 86.0352 46.5021C86.0983 46.6501 86.1307 46.8087 86.1305 46.9688Z"
							fill="#7B83EB"
						/>
						<path
							opacity="0.2"
							d="M78.3021 42.5H75.883C76.2005 43.1484 76.7014 43.6962 77.3275 44.0798C77.9537 44.4634 78.6793 44.667 79.4202 44.667V43.5831C79.4193 43.2961 79.3012 43.0211 79.0917 42.8182C78.8822 42.6153 78.5983 42.5009 78.3021 42.5Z"
							fill="black"
						/>
						<path
							opacity="0.1"
							d="M80.259 45.75V56.0444C80.259 56.2893 80.1724 56.5269 80.0137 56.7176C79.855 56.9082 79.6337 57.0406 79.3866 57.0925C79.3077 57.1153 79.2257 57.1262 79.1434 57.125H73.1963C73.0866 56.8601 72.9941 56.5887 72.9195 56.3125C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H80.259Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M79.4202 45.75V56.8569C79.4214 56.9366 79.4101 57.0161 79.3866 57.0925C79.333 57.3319 79.1964 57.5462 78.9995 57.7C78.8027 57.8537 78.5574 57.9375 78.3046 57.9375H73.6157C73.4543 57.6777 73.3141 57.406 73.1963 57.125C73.0866 56.8601 72.9941 56.5887 72.9195 56.3125C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H79.4202Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M79.4202 45.75V55.2319C79.4189 55.5181 79.3009 55.7922 79.092 55.9946C78.883 56.197 78.6001 56.3112 78.3046 56.3125H72.9195C72.7813 55.7812 72.7108 55.2355 72.7098 54.6875V46.9688C72.7096 46.8087 72.742 46.6501 72.8052 46.5021C72.8683 46.3542 72.961 46.2197 73.0779 46.1065C73.1947 45.9933 73.3335 45.9035 73.4863 45.8424C73.639 45.7812 73.8027 45.7498 73.968 45.75H79.4202Z"
							fill="black"
						/>
						<path
							opacity="0.2"
							d="M73.968 45.75C73.8027 45.7498 73.639 45.7812 73.4863 45.8424C73.3335 45.9035 73.1947 45.9933 73.0779 46.1065C72.961 46.2197 72.8683 46.3542 72.8052 46.5021C72.742 46.6501 72.7096 46.8087 72.7098 46.9688V54.6875C72.7108 55.2355 72.7813 55.7812 72.9195 56.3125H77.4658C77.7613 56.3112 78.0442 56.197 78.2532 55.9946C78.4621 55.7922 78.5801 55.5181 78.5814 55.2319V45.75H73.968Z"
							fill="black"
						/>
						<path
							d="M66.2787 42.5H77.4632C77.7597 42.5 78.0441 42.6141 78.2538 42.8172C78.4635 43.0204 78.5813 43.2958 78.5813 43.5831V54.417C78.5813 54.7042 78.4635 54.9797 78.2538 55.1828C78.0441 55.3859 77.7597 55.5 77.4632 55.5H66.2787C65.9822 55.5 65.6978 55.3859 65.4881 55.1828C65.2784 54.9797 65.1606 54.7042 65.1606 54.417V43.5831C65.1606 43.2958 65.2784 43.0204 65.4881 42.8172C65.6978 42.6141 65.9822 42.5 66.2787 42.5Z"
							fill="#4B53BC"
						/>
						<path
							d="M75.2092 46.5419H72.7012V53.0582H71.0555V46.5419H68.5324V44.9413H75.2092V46.5419Z"
							fill="white"
						/>
					</g>
					<path
						d="M357.527 24C358.893 24 360 25.1073 360 26.4731L360 28L112 28L112 26.4731C112 25.1073 113.107 24 114.473 24L357.527 24Z"
						fill="#505AC6"
					/>
				</g>
				<defs>
					<filter
						id="filter0_dd_2726_7333"
						x="50"
						y="16"
						width="318"
						height="96.0001"
						filterUnits="userSpaceOnUse"
						color-interpolation-filters="sRGB"
					>
						<feFlood flood-opacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset />
						<feGaussianBlur stdDeviation="4" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.16 0"
						/>
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2726_7333" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset />
						<feGaussianBlur stdDeviation="0.5" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.12 0"
						/>
						<feBlend
							mode="normal"
							in2="effect1_dropShadow_2726_7333"
							result="effect2_dropShadow_2726_7333"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect2_dropShadow_2726_7333"
							result="shape"
						/>
					</filter>
					<linearGradient
						id="paint0_linear_2726_7333"
						x1="408.5"
						y1="3.50001"
						x2="0.106745"
						y2="128.349"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0.0974484" stop-color="#C6E8FF" />
						<stop offset="0.920861" stop-color="#F6F7FF" />
					</linearGradient>
					<clipPath id="clip0_2726_7333">
						<rect x="65" y="36" width="27.3" height="26" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

const TeamsSplashStyles = css({ height: 128 });
