import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages';
import type { KeywordGroups } from './types';

export const useCommandKeywords = () => {
	const { formatMessage } = useIntl();

	const getKeywords = useCallback(
		(keywordGroup: KeywordGroups) =>
			messages[keywordGroup] ? formatMessage(messages[keywordGroup]) : undefined,
		[formatMessage],
	);

	return { getKeywords };
};
