import type { MouseEvent, KeyboardEvent } from 'react';

export const handleGinSPAClick = (
	e:
		| MouseEvent<HTMLAnchorElement>
		| KeyboardEvent<HTMLAnchorElement>
		| MouseEvent<EventTarget>
		| KeyboardEvent<EventTarget>,
) => {
	const href: string | undefined =
		// @ts-expect-error - TS2339 - Property 'href' does not exist on type 'EventTarget | (EventTarget & HTMLAnchorElement)'. | TS2339 - Property 'href' does not exist on type 'EventTarget | (EventTarget & HTMLAnchorElement)'.
		typeof e.currentTarget.href === 'string' ? e.currentTarget.href : undefined;

	const openInNewTab =
		// @ts-expect-error - TS2339 - Property 'button' does not exist on type 'KeyboardEvent<HTMLAnchorElement> | MouseEvent<HTMLAnchorElement, MouseEvent> | MouseEvent<EventTarget, MouseEvent> | KeyboardEvent<...>'. | TS2339 - Property 'button' does not exist on type 'KeyboardEvent<HTMLAnchorElement> | MouseEvent<HTMLAnchorElement, MouseEvent> | MouseEvent<EventTarget, MouseEvent> | KeyboardEvent<...>'.
		e.metaKey || e.altKey || e.ctrlKey || e.shiftKey || (e.button != null && e.button === 1);

	if (href == null || href === '' || e.defaultPrevented || openInNewTab) {
		return;
	}

	if (window.JIRA?.Issues?.Application) {
		e.preventDefault();
		window.JIRA.Issues.Application.execute('navigation:navigateToUrl', href, {
			forceRefresh: true,
			checkPermission: true,
			replace: false,
			reset: true,
			routerEvent: true,
		});
	}
};
