import React from 'react';
import { AlertsStoreContainer } from '@atlassian/jira-alerts-store';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { useCloudId } from '@atlassian/jira-tenant-context-controller';
import { useActivityClient } from '@atlassian/recent-work-client';
import { NAVIGATION_ITEM_ID } from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { Popup } from '../../common/ui/popup';
import PopupBoxWrapper from '../../common/ui/popup-box-wrapper';
import { RecentActivityContainer } from '../../controllers/your-work-recent-activities';
import { AsyncMenu } from './async';
import messages from './messages';

const YourWorkDropdown = () => {
	const { formatMessage } = useIntl();

	const activityClient = useActivityClient('v3', 'jira');
	const cloudId = useCloudId();

	if (ff('alerts-in-your-work_9l8fa')) {
		return (
			<AlertsStoreContainer cloudId={cloudId}>
				<RecentActivityContainer activityClient={activityClient} cloudId={cloudId}>
					<PopupBoxWrapper>
						<Popup
							menuId={MENU_ID.HOME}
							navigationItemId={NAVIGATION_ITEM_ID.HOME}
							Menu={AsyncMenu}
							label={formatMessage(messages.title)}
						>
							{formatMessage(messages.title)}
						</Popup>
					</PopupBoxWrapper>
				</RecentActivityContainer>
			</AlertsStoreContainer>
		);
	}

	return (
		<RecentActivityContainer activityClient={activityClient} cloudId={cloudId}>
			<PopupBoxWrapper>
				<Popup menuId={MENU_ID.HOME} navigationItemId={NAVIGATION_ITEM_ID.HOME} Menu={AsyncMenu}>
					{formatMessage(messages.title)}
				</Popup>
			</PopupBoxWrapper>
		</RecentActivityContainer>
	);
};

const YourWorkDropdownWithErrorBoundary = () => (
	<TopLevelErrorBoundary id={MENU_ID.HOME}>
		<YourWorkDropdown />
	</TopLevelErrorBoundary>
);

export { YourWorkDropdownWithErrorBoundary as YourWorkDropdown };
