import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noCreatorsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-creators-left',
		defaultMessage: 'No creators left',
		description:
			'Informs the user that there are no creators left and they may need to upgrade their product',
	},
	limitedCreatorsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-creators-left',
		defaultMessage: 'Limited creators left',
		description:
			'Informs the user that there are limited creators left before they need to upgrade their product',
	},
	noAgentsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.no-agents-left',
		defaultMessage: 'No agents left',
		description:
			'Informs the user that there are no agents left and they may need to upgrade their product',
	},
	limitedAgentsLeft: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.limited-agents-left',
		defaultMessage: 'Limited agents left',
		description:
			'Informs the user that there are limited agents left before they need to upgrade their product',
	},
	upgrade: {
		id: 'navigation-apps-sidebar-edition-awareness.edition-awareness-next.upgrade',
		defaultMessage: 'Upgrade',
		description: 'Informs the user that they can upgrade their product',
	},
});
