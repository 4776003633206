import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types';
import { ff } from '@atlassian/jira-feature-flagging';
import { PREMIUM_EDITION, SOFTWARE } from '@atlassian/jira-shared-types';
import { useSubProduct } from '@atlassian/jira-subproduct';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { useGetBillingPageUrl } from '../get-billing-page-url';

type UseJswPremiumTrialUpgradeLink = { deepLink?: boolean; skip?: boolean; caller: string };

export const useJswPremiumTrialUpgradeLink = ({
	deepLink = true,
	skip = false,
	caller,
}: UseJswPremiumTrialUpgradeLink) => {
	const { productEntitlementDetails } = useTenantContext();
	const jswEntitlement = productEntitlementDetails?.[SOFTWARE];
	const isJswUsingCcp = jswEntitlement?.billingSourceSystem === 'CCP';

	// When cleaning up jsw-premium-trial-upgrade-link-redirect-refactor_wjgoa, we can remove call to useSubProduct
	// and pass subProduct: SOFTWARE_PROJECT directly to useGetBillingPageUrl
	const contextSubproduct = useSubProduct();
	const subProduct = contextSubproduct || SOFTWARE_PROJECT;

	const { data, ...rest } = useGetBillingPageUrl({
		isUsingCcp: isJswUsingCcp,
		editionToUpgradeTo: deepLink ? PREMIUM_EDITION : undefined,
		caller,
		skip,
		subProduct: ff('jsw-premium-trial-upgrade-link-redirect-refactor_wjgoa')
			? SOFTWARE_PROJECT
			: subProduct,
	});

	return {
		data: { premiumTrialUpgradeLink: data.billingPageUrl },
		...rest,
	};
};
