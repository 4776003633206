import React, { useCallback } from 'react';
import { useUID } from 'react-uid';
import { IconButton } from '@atlaskit/button/new';
import SettingsIcon from '@atlaskit/icon/core/settings';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import ToolTip from '@atlaskit/tooltip';
import { useExperienceStart } from '@atlassian/jira-experience-tracker';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	testIdConcat,
	testIdGenerate,
	MENU_ID,
	useTopMenus,
} from '@atlassian/jira-navigation-apps-common';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import { EXPERIENCE_NAVIGATION_TOP_MENU, NAVIGATION_ITEM_ID } from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { Settings as Icon } from '../../common/ui/settings-button';
import { useNavigationItemAnalytics } from '../../controllers/navigation-item-analytics';
import { UpsellBannerContextProvider } from '../../controllers/premium-upsell-data';
import { Menu } from './menu';
import messages from './menu/messages';

const SettingsInner = () => {
	const [isOpen, { toggle: toggleMenu, off: closeMenu }] = useTopMenus(MENU_ID.SETTINGS);
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.SETTINGS,
	});

	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.SETTINGS,
		analyticsSource: 'atlassian-navigation',
	});

	const onClick = useCallback(() => {
		onStart();
		toggleMenu();
		triggerAnalytics({ shownCommandPalettePrompt: true });
	}, [onStart, toggleMenu, triggerAnalytics]);

	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const testIdPrefix = testIdGenerate('secondary-actions', 'settings');

	// replace this with useId from react 18 when stable
	const id = useUID();
	const titleId = `top-navigation-settings-header-${id}-title`;

	const content = useCallback(
		() => <Menu testIdPrefix={testIdPrefix} titleId={titleId} />,
		[testIdPrefix, titleId],
	);

	const OldTrigger = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<div data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}>
				<Icon
					{...triggerProps}
					onClick={onClick}
					tooltip={formatMessage(messages.settings)}
					isSelected={isOpen}
				/>
			</div>
		),

		[formatMessage, isOpen, onClick, testIdPrefix],
	);

	const NewTrigger = useCallback(
		(triggerProps: Partial<TriggerProps>) => (
			<div data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}>
				<ToolTip content={formatMessage(messages.settings)}>
					<IconButton
						{...triggerProps}
						icon={SettingsIcon}
						onClick={onClick}
						appearance="subtle"
						label={formatMessage(messages.settings)}
						isSelected={isOpen}
					/>
				</ToolTip>
			</div>
		),

		[isOpen, formatMessage, onClick, testIdPrefix],
	);

	const Trigger = getWillShowNav4() ? NewTrigger : OldTrigger;

	if (isAnonymous) return null;

	if (__SERVER__) return <Trigger aria-expanded={false} aria-haspopup />;

	return (
		<Popup
			titleId={titleId}
			isOpen={isOpen}
			content={content}
			onClose={closeMenu}
			placement="bottom-end"
			trigger={Trigger}
			label={formatMessage(messages.settings)}
			role="menu"
		/>
	);
};

const SettingsWithErrorBoundary = () => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.SETTINGS}>
		{expValEquals('premium-upsell-experiment', 'cohort', 'variation') ||
		expValEquals('premium-upsell-experiment', 'cohort', 'experiment') ||
		fg('premium-upsell-trial-end-date') ? (
			<UpsellBannerContextProvider>
				<SettingsInner />
			</UpsellBannerContextProvider>
		) : (
			<SettingsInner />
		)}
	</TopLevelErrorBoundary>
);

export { SettingsWithErrorBoundary as Settings };
