/**
 * @generated SignedSource<<7009fd96b57d96f3a804cd54cbeff896>>
 * @relayHash 43cd413a33357d1fd66c5d347fbf2d7d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9560265d452dac8e1e384e71f9bf7559c617d3089047cf82d243131a11597fac

import type { ConcreteRequest, Query } from 'relay-runtime';
export type CcpBillingInterval = "DAY" | "MONTH" | "WEEK" | "YEAR" | "%future added value";
export type commerceSharedApiEditionAwarenessTrialQuery$variables = {
  cloudId: string;
  productKey: string;
};
export type commerceSharedApiEditionAwarenessTrialQuery$data = {
  readonly tenantContexts: ReadonlyArray<{
    readonly entitlementInfo: {
      readonly entitlement: {
        readonly overriddenEdition?: string | null | undefined;
        readonly subscription: {
          readonly accountDetails: {
            readonly invoiceGroup: {
              readonly id?: string;
              readonly invoiceable: boolean;
            } | null | undefined;
          } | null | undefined;
          readonly pricingPlan: {
            readonly primaryCycle: {
              readonly interval: CcpBillingInterval;
            } | null | undefined;
            readonly type: string;
          } | null | undefined;
        } | null | undefined;
        readonly transactionAccount: {
          readonly isCurrentUserBillingAdmin: boolean;
          readonly isManagedByPartner: boolean;
          readonly key: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
};
export type commerceSharedApiEditionAwarenessTrialQuery = {
  response: commerceSharedApiEditionAwarenessTrialQuery$data;
  variables: commerceSharedApiEditionAwarenessTrialQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productKey"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "cloudIds.0",
        "variableName": "cloudId"
      }
    ],
    "kind": "ListValue",
    "name": "cloudIds"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "hamsProductKey",
    "variableName": "productKey"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "AND": [
        {
          "inTrialOrPreDunning": true
        }
      ]
    }
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "invoiceable"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "CcpInvoiceGroup"
},
v8 = {
  "kind": "ScalarField",
  "name": "interval"
},
v9 = {
  "kind": "ScalarField",
  "name": "type"
},
v10 = {
  "kind": "ScalarField",
  "name": "key"
},
v11 = {
  "kind": "ScalarField",
  "name": "isCurrentUserBillingAdmin"
},
v12 = {
  "kind": "ScalarField",
  "name": "isManagedByPartner"
},
v13 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "overriddenEdition"
    }
  ],
  "type": "HamsEntitlement"
},
v14 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "commerceSharedApiEditionAwarenessTrialQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              {
                "args": (v3/*: any*/),
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v4/*: any*/),
                                "action": "THROW",
                                "path": "tenantContexts.entitlementInfo.entitlement.subscription.accountDetails.invoiceGroup.invoiceable"
                              },
                              (v7/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "pricingPlan",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "primaryCycle",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v8/*: any*/),
                                "action": "THROW",
                                "path": "tenantContexts.entitlementInfo.entitlement.subscription.pricingPlan.primaryCycle.interval"
                              }
                            ]
                          },
                          {
                            "kind": "RequiredField",
                            "field": (v9/*: any*/),
                            "action": "THROW",
                            "path": "tenantContexts.entitlementInfo.entitlement.subscription.pricingPlan.type"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v10/*: any*/),
                        "action": "THROW",
                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.key"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v11/*: any*/),
                        "action": "THROW",
                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.isCurrentUserBillingAdmin"
                      },
                      {
                        "kind": "RequiredField",
                        "field": (v12/*: any*/),
                        "action": "THROW",
                        "path": "tenantContexts.entitlementInfo.entitlement.transactionAccount.isManagedByPartner"
                      }
                    ]
                  },
                  (v13/*: any*/)
                ],
                "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "commerceSharedApiEditionAwarenessTrialQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "concreteType": "TenantContext",
        "kind": "LinkedField",
        "name": "tenantContexts",
        "plural": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "LinkedField",
            "name": "entitlementInfo",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              {
                "args": (v3/*: any*/),
                "kind": "LinkedField",
                "name": "entitlement",
                "plural": false,
                "selections": [
                  (v14/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "accountDetails",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "invoiceGroup",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v4/*: any*/),
                              (v7/*: any*/)
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "pricingPlan",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "primaryCycle",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v8/*: any*/)
                            ]
                          },
                          (v9/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v6/*: any*/),
                        "type": "CcpSubscription"
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "name": "transactionAccount",
                    "plural": false,
                    "selections": [
                      (v14/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": (v6/*: any*/),
                        "type": "CcpTransactionAccount"
                      }
                    ]
                  },
                  (v13/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": "entitlement(where:{\"AND\":[{\"inTrialOrPreDunning\":true}]})"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9560265d452dac8e1e384e71f9bf7559c617d3089047cf82d243131a11597fac",
    "metadata": {},
    "name": "commerceSharedApiEditionAwarenessTrialQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9bcfaad10316f0f987176038c3666c83";

export default node;
