import { useEffect, useRef } from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { usePersistentIssueCreateModalViewResource } from '@atlassian/jira-router-resources-persistent-issue-create-modal-view';

export const useInitializePersistentIssueCreateModalView = () => {
	const [
		{ shouldShowMiniModalSpotlight },
		{ setShouldPushMiniModalDiscoverability, setPersistentModalView },
	] = useTriggerIssueCreateModal();
	const { data: projectContext } = useProjectContext();
	const hasUserSeenMiniModal = useRef(false);
	const isJSM = projectContext?.projectType === SERVICE_DESK_PROJECT;
	const { data: resource } = usePersistentIssueCreateModalViewResource();

	/**
	 * It only handles the initialization part as after the modal is opened the persistentModalView
	 * would be handled by the `actions` of `useTriggerIssueCreateModal`.
	 */
	useEffect(() => {
		setPersistentModalView(resource?.view || 'modal');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * JSM has opted out so we update the `shouldPushMiniModalDiscoverability` on every project change
	 * until the discoverability push has been done, meaning that the user has seen the mini modal being
	 * shown as the default modal.
	 *
	 * If the user has seen the mini modal from discoverability push (hasUserSeenMiniModal) or
	 * dismiss its nudge (isDiscoPushComplete), shouldPushMiniModalDiscoverability should never be true again.
	 */
	useEffect(() => {
		if (resource?.isDiscoPushComplete || hasUserSeenMiniModal.current) {
			setShouldPushMiniModalDiscoverability(false);
		} else {
			setShouldPushMiniModalDiscoverability(!isJSM);
		}
	}, [resource?.isDiscoPushComplete, setShouldPushMiniModalDiscoverability, isJSM]);

	/**
	 * `shouldShowMiniModalSpotlight` can be used to determine if user has seen the mini modal
	 * as it is only set `onModalOpen`, which means, if it is true, the modal is opened with mini view
	 * and the user will see it.
	 */
	useEffect(() => {
		if (shouldShowMiniModalSpotlight && !hasUserSeenMiniModal.current) {
			hasUserSeenMiniModal.current = true;
		}
	}, [shouldShowMiniModalSpotlight]);

	return null;
};
