// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension';
import {
	CreateAnalyticsWrapper,
	EditAnalyticsWrapper,
	ViewAnalyticsWrapper,
} from '@atlassian/jira-forge-ui-analytics/src/ui/workflow-validator';
import {
	AsyncWorkflowValidatorCreateEdit,
	AsyncWorkflowValidatorView,
} from '@atlassian/jira-forge-ui-async/src/ui/workflow-validator/async';

export const WorkflowValidatorCreate = (
	props: JSX.LibraryManagedAttributes<
		typeof AsyncWorkflowValidatorCreateEdit,
		ComponentProps<typeof AsyncWorkflowValidatorCreateEdit>
	>,
) => (
	<CreateAnalyticsWrapper
		attributes={{
			...props.analyticsAttributes,
			...getAnalyticsAttributesFromExtension(props.extension),
		}}
		shouldFireInitializedEvent
	>
		<AsyncWorkflowValidatorCreateEdit {...props} entryPoint="create" />
	</CreateAnalyticsWrapper>
);

export const WorkflowValidatorEdit = (
	props: JSX.LibraryManagedAttributes<
		typeof AsyncWorkflowValidatorCreateEdit,
		ComponentProps<typeof AsyncWorkflowValidatorCreateEdit>
	>,
) => (
	<EditAnalyticsWrapper
		attributes={{
			...props.analyticsAttributes,
			...getAnalyticsAttributesFromExtension(props.extension),
		}}
		shouldFireInitializedEvent
	>
		<AsyncWorkflowValidatorCreateEdit {...props} entryPoint="edit" />
	</EditAnalyticsWrapper>
);

export const WorkflowValidatorView = (
	props: JSX.LibraryManagedAttributes<
		typeof AsyncWorkflowValidatorView,
		ComponentProps<typeof AsyncWorkflowValidatorView>
	>,
) => (
	<ViewAnalyticsWrapper
		attributes={{
			...props.analyticsAttributes,
			...getAnalyticsAttributesFromExtension(props.extension),
		}}
		shouldFireInitializedEvent
	>
		<AsyncWorkflowValidatorView {...props} entryPoint="view" />
	</ViewAnalyticsWrapper>
);
