import React, { Component, type UIEvent } from 'react';
import { styled, css } from '@compiled/react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import StarIcon from '@atlaskit/icon/glyph/star';
import StarFilledIcon from '@atlaskit/icon/glyph/star-filled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { withFireUiAnalytics } from '@atlassian/jira-analytics-web-react/src';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { ff } from '@atlassian/jira-feature-flagging';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import type { Intl } from '@atlassian/jira-shared-types';
import UFOLoadHold from '@atlassian/ufo-load-hold';
import type { Props as OwnProps } from '../model/types';
import messages from './messages';

type Props = Intl & OwnProps;

const SKELETON_TEST_ID = 'favouriting.favourite-button-stateless.skeleton';
const ICON_WRAPPER_TEST_ID_PREFIX = 'favouriting.favourite-button-stateless.icon-wrapper';

// eslint-disable-next-line jira/react/no-class-components
export class FavouriteButtonStateless extends Component<
	Props,
	{
		isAnimating: boolean;
	}
> {
	static defaultProps = {
		isFavourite: undefined,
		favouriteItemName: undefined,
		isShownInList: false,
		isSmall: false,
		hideTooltip: false,
		onClick: noop,
		tooltipPosition: 'bottom',
		skipAnimation: false,
	};

	state = {
		isAnimating: false,
	};

	componentDidUpdate(prevProps: Props) {
		if (
			!this.props.skipAnimation &&
			((!prevProps.pending && this.props.pending) ||
				(!prevProps.isFavourite && this.props.isFavourite))
		) {
			this.onAnimationStart();
			this.onSettingAnimationEnd();
		}
	}

	onBtnClickWrapper = (e: UIEvent<HTMLSpanElement>) => {
		e.stopPropagation(); // should not let this event get outside of the component, to prevent issue when, for example, star button is clicked in an item or row, which goes somewhere else on onClick handler. Cannot put it in onBtnClick, otherwise hideTooltipOnClick in <Tooltip> will not work
		e.preventDefault(); // same for cases when btn is inside a link - not to go to that link (e.g. in Star Drawer)
	};

	onClick = (_: UIEvent<HTMLButtonElement>, analyticsEvent: AnalyticsEvent) => {
		this.props.onClick(analyticsEvent);
	};

	onAnimationStart() {
		this.setState({
			isAnimating: !this.state.isAnimating,
		});
	}

	onSettingAnimationEnd() {
		setTimeout(() => {
			this.setState({
				isAnimating: false,
			});
		}, animationTime * 2);
	}

	render() {
		const {
			isFavourite,
			favouriteItemName,
			isShownInList,
			isSmall,
			hideTooltip,
			tooltipPosition,
			pending,
			intl: { formatMessage },
		} = this.props;

		const { isAnimating } = this.state;

		if (isFavourite === undefined) {
			// show skeleton as a space placeholder (to avoid layout recalculations in browser and visible "jump" when we got data)
			return (
				<UFOLoadHold name="favourite-button">
					<FavouriteButtonSkeleton
						{...(ff('favourite-button-label-and-testid_of2ts')
							? {
									'data-testid': SKELETON_TEST_ID,
								}
							: {
									'data-test-id': SKELETON_TEST_ID,
								})}
						isSmall={isSmall}
					/>
				</UFOLoadHold>
			);
		}

		const iconWrapperTestId = isShownInList
			? `${ICON_WRAPPER_TEST_ID_PREFIX}-list`
			: ICON_WRAPPER_TEST_ID_PREFIX;

		const sizeProps = isSmall ? { size: 'small' } : {};

		const buttonContent = (
			<FavIconWrapper
				isFavourite={isFavourite}
				isAnimating={isAnimating}
				{...(ff('favourite-button-label-and-testid_of2ts')
					? {
							'data-testid': iconWrapperTestId,
						}
					: {
							'data-test-id': iconWrapperTestId,
						})}
				isSmall={isSmall}
			>
				{isFavourite ? (
					// @ts-expect-error - TS2769 - No overload matches this call. | TS2769 - No overload matches this call.
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<StarFilledIcon
						{...sizeProps}
						{...(ff('favourite-button-label-and-testid_of2ts') && {
							label: formatMessage(messages.removeFromStarred),
						})}
					/>
				) : (
					// @ts-expect-error - TS2769 - No overload matches this call. | TS2769 - No overload matches this call.
					// eslint-disable-next-line @atlaskit/design-system/no-legacy-icons
					<StarIcon
						{...sizeProps}
						{...(ff('favourite-button-label-and-testid_of2ts') && {
							label: formatMessage(messages.addToStarred),
						})}
					/>
				)}
			</FavIconWrapper>
		);

		const tooltipContent = isFavourite
			? formatMessage(messages.removeFromStarred)
			: formatMessage(messages.addToStarred);

		const ariaLabel = favouriteItemName
			? formatMessage(messages.toggleStarredWithItemName, { itemName: favouriteItemName })
			: formatMessage(messages.toggleStarred);

		return (
			<FavouriteButtonWrapper
				role="presentation"
				onClick={this.onBtnClickWrapper}
				onKeyPress={this.onBtnClickWrapper}
				data-is-favourite={isFavourite}
			>
				<Tooltip
					content={!hideTooltip && tooltipContent}
					hideTooltipOnClick
					position={tooltipPosition}
				>
					<Button
						appearance={isShownInList ? 'subtle-link' : 'subtle'}
						spacing="none"
						aria-label={ariaLabel}
						aria-pressed={isFavourite}
						onClick={!pending ? this.onClick : noop}
					>
						{buttonContent}
					</Button>
				</Tooltip>
			</FavouriteButtonWrapper>
		);
	}
}

export default flow(
	withFireUiAnalytics({
		onClick: () => ({
			name: 'star',
		}),
	}),
	injectIntl,
)(FavouriteButtonStateless);

const animationTime = 500;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavouriteButtonWrapper = styled.div({
	display: 'inline-block',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FavouriteIconWrapperBase = styled.span<{
	isSmall?: boolean;
	isFavourite?: boolean;
	isAnimating?: boolean;
}>(
	{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		width: (props) => (props.isSmall ? `${gridSize * 3}px` : `${gridSize * 4}px`),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		height: (props) => (props.isSmall ? `${gridSize * 3}px` : `${gridSize * 4}px`),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isAnimating &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'@keyframes energyPop': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'0%': {
					// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
					color: '#253858',
					backgroundColor: 'transparent',
					transform: 'scale3d(0.5, 0.5, 0.5)',
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'20%': {
					// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
					color: '#fffae6',
					// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
					backgroundColor: 'rgba(255, 171, 0, 0.2)',
					transform: 'scale3d(1, 1, 1)',
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'80%': {
					backgroundColor: 'transparent',
					transform: 'scale3d(1.2, 1.2, 1.2)',
				},
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'100%': {
					backgroundColor: 'transparent',
					color: 'currentColor',
					transform: 'none',
				},
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			svg: {
				borderRadius: '50%',
				animation: `energyPop ${animationTime / 1000}s ease`,
				animationFillMode: 'forwards',
				willChange: 'transform',
			},
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FavIconWrapper = styled(FavouriteIconWrapperBase)<{
	isSmall?: boolean;
	isFavourite?: boolean;
	isAnimating?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) =>
		props.isFavourite
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.icon.accent.yellow', colors.Y500)
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				token('color.icon.subtle', colors.N200),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) =>
			props.isFavourite
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.background.accent.yellow.bolder', colors.Y300)
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('color.icon', colors.N600),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FavouriteButtonSkeleton = styled.div<{ isSmall?: boolean }>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	width: (props) => (props.isSmall ? `${gridSize * 3}px` : `${gridSize * 4}px`),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: (props) => (props.isSmall ? `${gridSize * 3}px` : `${gridSize * 4}px`),
});
