import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { IconCustomProps } from './types';
import { checkForSvg as checkForSVGDI } from './utils';

// got this value through trial and error
// in the long term, we should change the color of icon generated for the conntect apps
const colorMatrixValues = '0.08, 0, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 0,0.2, 0, 0, 0, 0, 0, 1, 0';

export const IconCustom = ({
	url,
	shouldChangeFillColor = true,
	checkForSvg = checkForSVGDI,
	ariaLabelledBy,
}: IconCustomProps) => (
	<Box xcss={styles}>
		<svg viewBox="0 0 20 20">
			<defs>
				<filter id="changeFillColor">
					<feColorMatrix type="matrix" values={colorMatrixValues} />
				</filter>
			</defs>
			<image
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xlinkHref={url}
				width="20"
				height="20"
				x="0"
				y="0"
				filter={shouldChangeFillColor && checkForSvg(url) ? 'url(#changeFillColor)' : ''}
				aria-labelledby={ariaLabelledBy}
			/>
		</svg>
	</Box>
);

const styles = xcss({
	display: 'inline-block',
	height: token('space.250', '20px'),
	width: token('space.250', '20px'),
	margin: 'space.025',
});
