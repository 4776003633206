import React from 'react';
import { Section } from '@atlaskit/menu';
import { messages as messagesDI } from '@atlassian/jira-atlassian-navigation-global-settings-home';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { MenuItem } from '../../../../common/ui/menu/item';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list';
import { HomeHeading } from './heading';
import type { HomeProps } from './types';

export const Home = ({ messages = messagesDI, settingsDrawerItems, testIdPrefix }: HomeProps) => {
	const { formatMessage } = useIntl();

	if (getWillShowNav4()) {
		return (
			<>
				{settingsDrawerItems.map((group) => (
					<Section key={group.id} testId={testIdConcat(testIdPrefix, group.id)}>
						<HomeHeading group={group} messages={messages} testIdPrefix={testIdPrefix} />
						<MenuList>
							{group.items &&
								group.items.map((item, idx) => {
									if (item.type === 'Content' && item.content) {
										return item.content({
											update: () => Promise.resolve(null),
											isOpen: false,
											setInitialFocusRef: () => null,
										});
									}

									if (item.href === null || item.href === undefined) return null;
									if (item.text === null || item.text === undefined) return null;

									return (
										<MenuListItem key={item.id} ariaLabel={formatMessage(messages[group.id])}>
											<MenuItem
												analytics={{
													actionSubjectId: 'settingsNavigationMenuItem',
													menuItemId: item.id,
													menuItemType:
														group.id === 'site-admin' ? 'adminSettings' : 'jiraSettings',
													position: idx,
												}}
												iconBefore={item.before ? <item.before /> : null}
												iconAfter={item.after ? <item.after isHover /> : null}
												href={item.href}
												// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type '(("_self" | "_blank" | "_parent" | "_top") & HTMLAttributeAnchorTarget) | undefined'.
												target={item.target}
												testId={testIdConcat(testIdPrefix, group.id, `item-${item.id}`)}
												description={item.description || ''}
											>
												{item.text || ''}
											</MenuItem>
										</MenuListItem>
									);
								})}
						</MenuList>
					</Section>
				))}
			</>
		);
	}

	return (
		<>
			{settingsDrawerItems.map((group) => (
				<Section key={group.id} testId={testIdConcat(testIdPrefix, group.id)}>
					<HomeHeading group={group} messages={messages} testIdPrefix={testIdPrefix} />
					<MenuList>
						{group.items &&
							group.items.map((item, idx) => {
								if (item.href === null || item.href === undefined) return null;
								if (item.text === null || item.text === undefined) return null;

								return (
									<MenuListItem key={item.id} ariaLabel={formatMessage(messages[group.id])}>
										<MenuItem
											analytics={{
												actionSubjectId: 'settingsNavigationMenuItem',
												menuItemId: item.id,
												menuItemType: group.id === 'site-admin' ? 'adminSettings' : 'jiraSettings',
												position: idx,
											}}
											iconBefore={item.before ? <item.before /> : null}
											iconAfter={item.after ? <item.after isHover /> : null}
											href={item.href}
											// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type '(("_self" | "_blank" | "_parent" | "_top") & HTMLAttributeAnchorTarget) | undefined'.
											target={item.target}
											testId={testIdConcat(testIdPrefix, group.id, `item-${item.id}`)}
											description={messages[item.id] ? formatMessage(messages[item.id]) : undefined}
										>
											{item.text || ''}
										</MenuItem>
									</MenuListItem>
								);
							})}
					</MenuList>
				</Section>
			))}
		</>
	);
};
