import {
	createSessionExpirableStorageProvider,
	createLocalExpirableStorageProvider,
} from '@atlassian/jira-browser-storage-providers';

export { isWithinLast } from './is-within-last';
export { isWithinLast30Days } from './is-within-last-30-days';

export {
	userBelongsToBusinessUserSegCategory,
	userBelongsToSoftwareUserSegCategory,
	isInSoftwareTeam,
} from './user-segmentation';

export { getConditional } from './conditional-rendering';
export type { Conditional, Condition } from './conditional-rendering/types';

export const sessionStorage = createSessionExpirableStorageProvider('onboarding-core');
export const sessionStorageProvider = createSessionExpirableStorageProvider('onboarding-core');
export const localStorage = createLocalExpirableStorageProvider('onboarding-core');
