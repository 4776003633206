import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { styled } from '@compiled/react';
import { Section } from '@atlaskit/menu';
import { isFedRamp } from '@atlassian/atl-context';
import { NAVIGATION_TOP_DROPDOWN_MARKS } from '@atlassian/jira-atlassian-navigation-performance-metrics';
import { AuthenticationErrorBoundary, JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { useExperienceFail } from '@atlassian/jira-experience-tracker';
import {
	getSlackConsentUrl,
	openSlackIntegrationPopup,
	useFetchSlackStatus,
} from '@atlassian/jira-growth-improved-integrations';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID, testIdConcat } from '@atlassian/jira-navigation-apps-common';
import Placeholder from '@atlassian/jira-placeholder';
import {
	ContextualAnalyticsData,
	fireOperationalAnalytics,
	fireTrackAnalytics,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsSiteAdmin, useLocale } from '@atlassian/jira-tenant-context-controller';
import { PACKAGE_NAME, EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../common/constants';
import { MenuError } from '../../../common/ui/menu/error';
import { MenuLayout } from '../../../common/ui/menu/layout';
import { MenuSkeletonContent } from '../../../common/ui/menu/skeleton/content';
import { MenuSkeletonFooter } from '../../../common/ui/menu/skeleton/footer';
import { usePersonalisedAppsTeamType } from '../../../controllers/personalised-apps-team-type';
import useFetchIntegrations from '../../../services/fetch-integrations';
import { Content } from './content';
import { DiscoverSection } from './discover-section';
import { SLACK, RECOMMENDATION_TYPE } from './discover-section/constants';
import messages from './discover-section/messages';
import type { Integration, IntegrationLinkProps } from './discover-section/types';
import { getTeamAlignedAppList } from './discover-section/utils';
import { Footer } from './footer';
import type { MenuProps } from './types';

const EVENT_DATA_ADDONS = { id: MENU_ID.ADDONS } as const;
const EVENT_DATA_INTEGRATIONS = { id: 'integrations-apps-menu' } as const;

const createIntegrationLink = (
	// @ts-expect-error - TS7006 - Parameter 'integration' implicitly has an 'any' type.
	integration,
	isSiteAdmin: boolean,
	// @ts-expect-error - TS2749 - 'MessageDescriptorV2' refers to a value, but is being used as a type here. Did you mean 'typeof MessageDescriptorV2'?
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatMessage: (MessageDescriptorV2: MessageDescriptorV2, values?: any) => string,
): IntegrationLinkProps => {
	const label = `${integration.label}${
		integration.categoryMessageKey !== undefined
			? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ popularApps: MessageDescriptorV2; personalisedAppsTitle: MessageDescriptorV2; personalisedAppsText: MessageDescriptorV2; ... 8 more ...; }'.
				` (${formatMessage(messages[integration.categoryMessageKey])})`
			: ''
	}`;
	return integration.key === SLACK
		? {
				url: getSlackConsentUrl('apps-menu'),
				icon: integration.icon,
				actionSubjectId: integration.actionSubjectId,
				onClick: () => {
					openSlackIntegrationPopup('apps-menu');
				},
				label,
			}
		: {
				url: integration.getUrl(
					`source=apps-menu&${isSiteAdmin ? 'installDialogOpen=true' : 'requestDialogOpen=true'}`,
				),
				icon: integration.icon,
				actionSubjectId: integration.actionSubjectId,
				label,
			};
};

export const Menu = ({ testIdPrefix }: MenuProps) => {
	const { formatMessage } = useIntl();

	const [isFooterVisible, setIsFooterSeparatorVisible] = useState(true);
	const FooterSection = isFooterVisible ? AppsSectionWrapper : Fragment;

	const onFail = useExperienceFail({ experience: EXPERIENCE_NAVIGATION_TOP_MENU });
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const locale = useLocale();
	const isFedrampEnvironment = isFedRamp();

	const testIdContent = testIdConcat(testIdPrefix, 'content');
	const testIdFooter = testIdConcat(testIdPrefix, 'footer');
	const testIdDiscover = testIdConcat(testIdPrefix, 'discover');
	const isSiteAdmin = useIsSiteAdmin();
	const [integrations, setIntegrations] = useState<Integration[]>([]);
	const [personalisedApps, setPersonalisedApps] = useState<IntegrationLinkProps[]>([]);
	const [isLoadingPersonalisedApps, setIsLoadingPersonalisedApps] = useState(true);
	const [{ teamType, error: teamTypeError, isLoading: isTeamTypeLoading }] =
		usePersonalisedAppsTeamType();
	const [isAppsMenuFinishedRendering, setIsAppsMenuFinishedRendering] = useState(false);
	const [
		{
			isFetching: loadingIntegrations,
			hasFetchedOnce: hasFetchedIntegrations,
			fetchError: fetchIntegrationsError,
			integrations: appsInstalled,
		},
		{ fetchIntegrations },
	] = useFetchIntegrations();
	const {
		isSlackIntegrationEnabled,
		isSlackIntegrationConnected,
		isLoading: isLoadingSlackStatus,
	} = useFetchSlackStatus();

	const onDiscoverError = useCallback(
		(location: string, error: Error) => {
			onFail(location, error);
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'appsDiscoverSection failed',
				'appsMenuDiscoverSection',
				{ error: 'Error loading discover section' },
			);
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'loadingPersonalisedAppsExperiment failed',
				{
					message:
						error !== null && typeof error.message !== 'undefined' ? error.message : undefined,
					stack: error !== null && typeof error.stack !== 'undefined' ? error.stack : undefined,
				},
			);
		},
		[createAnalyticsEvent, onFail],
	);

	useEffect(() => {
		try {
			const teamAppList = getTeamAlignedAppList(teamType);
			setIntegrations(teamAppList);
			if (teamTypeError) {
				setIsLoadingPersonalisedApps(false);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setIsLoadingPersonalisedApps(false);
			onDiscoverError('Getting a potential list of personalised apps failed.', error);
		}
	}, [teamType, isTeamTypeLoading, setIntegrations, teamTypeError, onDiscoverError, locale]);

	useEffect(() => {
		try {
			if (!loadingIntegrations && hasFetchedIntegrations && !isLoadingSlackStatus) {
				const integrationsToShow = integrations
					.filter(({ key }) =>
						key === SLACK
							? isSlackIntegrationEnabled && !isSlackIntegrationConnected
							: // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{}'.
								Boolean(appsInstalled[key]) === false,
					)
					.sort(() => 0.5 - Math.random())
					.slice(0, 3)
					.map((integration) => createIntegrationLink(integration, isSiteAdmin, formatMessage));
				setPersonalisedApps(integrationsToShow);
				setIsLoadingPersonalisedApps(false);
			}
			if (fetchIntegrationsError) {
				setIsLoadingPersonalisedApps(false);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setIsLoadingPersonalisedApps(false);
			onDiscoverError('Preparing personalised apps failed.', error);
		}
	}, [
		integrations,
		appsInstalled,
		isSiteAdmin,
		setPersonalisedApps,
		loadingIntegrations,
		hasFetchedIntegrations,
		fetchIntegrationsError,
		isSlackIntegrationEnabled,
		isSlackIntegrationConnected,
		isLoadingSlackStatus,
		formatMessage,
		onDiscoverError,
	]);

	useEffect(() => {
		if (integrations.length) {
			fetchIntegrations(integrations.map((app) => app.key));
		}
	}, [integrations, fetchIntegrations]);

	const errorFallback = useCallback(
		() => <MenuError testIdPrefix={testIdContent} />,
		[testIdContent],
	);
	const errorFallbackDiscover = useCallback(
		() => <MenuError testIdPrefix={testIdDiscover} />,
		[testIdDiscover],
	);

	useEffect(() => {
		if (isAppsMenuFinishedRendering) {
			NAVIGATION_TOP_DROPDOWN_MARKS.ADDONS.stop();
		}
	}, [isAppsMenuFinishedRendering]);

	return (
		<MenuLayout isWide>
			<JSErrorBoundary
				id="personalised-apps"
				packageName={PACKAGE_NAME}
				fallback={errorFallback}
				extraEventData={EVENT_DATA_ADDONS}
				onError={onFail}
				withExperienceTracker
			>
				<AuthenticationErrorBoundary render={errorFallback}>
					<Placeholder
						fallback={
							<AppsSectionWrapper>
								<MenuSkeletonContent testIdPrefix={testIdContent} />
							</AppsSectionWrapper>
						}
						name="apps-section-wrapper"
					>
						<Content testIdPrefix={testIdContent} />
					</Placeholder>
				</AuthenticationErrorBoundary>
			</JSErrorBoundary>
			<JSErrorBoundary
				id="integrations-apps-menu"
				packageName={PACKAGE_NAME}
				extraEventData={EVENT_DATA_INTEGRATIONS}
				render={errorFallbackDiscover}
				withExperienceTracker={false}
				onError={onDiscoverError}
			>
				<Placeholder
					fallback={
						<AppsSectionWrapper hasSeparator>
							<MenuSkeletonContent testIdPrefix={testIdDiscover} />
						</AppsSectionWrapper>
					}
					name="discover-section"
				>
					<ContextualAnalyticsData sourceName="appsMenuDiscoverSection" sourceType={SCREEN}>
						<DiscoverSection
							apps={personalisedApps}
							isLoadingApps={isLoadingPersonalisedApps}
							title={messages.personalisedAppsTitle}
							description={messages.personalisedAppsText}
							recommendationType={RECOMMENDATION_TYPE.Personalised}
							setIsAppsMenuFinishedRendering={setIsAppsMenuFinishedRendering}
							isFedrampEnvironment={isFedrampEnvironment}
						/>
					</ContextualAnalyticsData>
				</Placeholder>
			</JSErrorBoundary>
			<JSErrorBoundary
				id="personalised-footer"
				packageName={PACKAGE_NAME}
				extraEventData={EVENT_DATA_INTEGRATIONS}
				render={errorFallbackDiscover}
				withExperienceTracker={false}
				onError={onDiscoverError}
			>
				<FooterSection hasSeparator testId={testIdFooter}>
					<Placeholder fallback={<MenuSkeletonFooter testIdPrefix={testIdFooter} />} name="footer">
						<Footer
							onUpdateIsFooterVisible={setIsFooterSeparatorVisible}
							testIdPrefix={testIdFooter}
							isFedrampEnvironment={isFedrampEnvironment}
						/>
					</Placeholder>
				</FooterSection>
			</JSErrorBoundary>
		</MenuLayout>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AppsSectionWrapper = styled(Section)({
	minHeight: '50px',
});
