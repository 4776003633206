import React from 'react';
import { lazy } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import Placeholder from '@atlassian/jira-placeholder';
import { PACKAGE_NAME } from '../../common/constants';
import type { People as PeopleType } from './menu-with-configuration';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyMenuWithConfiguration = lazy<typeof PeopleType>(() =>
	import(/* webpackChunkName: "async-people-menu" */ './menu-with-configuration').then(
		({ People }) => People,
	),
);

export const PeopleMenu = () => (
	<JSErrorBoundary
		id="people-menu-dropdown-menu"
		packageName={PACKAGE_NAME}
		teamName="navigation"
		fallback="flag"
	>
		<Placeholder name="lazy-people-menu" fallback={null}>
			<LazyMenuWithConfiguration />
		</Placeholder>
	</JSErrorBoundary>
);
