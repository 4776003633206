import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const CreateIssueUFOExperience = new UFOExperience('global-issue-create.create-issue', {
	type: ExperienceTypes.Experience,
	performanceType: ExperiencePerformanceTypes.Custom,
	category: 'global-issue-create.create-issue',
});

export const StatusFieldLoad = new UFOExperience('global-issue-create.create-issue.status-field', {
	type: ExperienceTypes.Load,
	performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
});

export const GlobalIssueCreateModalSubmitUFOExperience = new UFOExperience(
	'modern-global-issue-create.modal.issue-submit',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.Custom,
		category: 'modern-global-issue-create.modal.issue-submit',
	},
);
