import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const filtersDirectoryRoute = {
	group: ROUTE_GROUPS.DIRECTORIES,
	name: ROUTE_NAMES.FILTERS_DIRECTORY,
	path: '/jira/filters',
};
export const filtersDirectoryRedirectRoute = {
	group: ROUTE_GROUPS.DIRECTORIES,
	name: `${ROUTE_NAMES.FILTERS_DIRECTORY}-redirect`,
	path: '/ManageFilters.jspa',
};

export const secureFiltersDirectoryRedirectRoute = {
	group: ROUTE_GROUPS.DIRECTORIES,
	name: `${ROUTE_NAMES.FILTERS_DIRECTORY_SECURE}-redirect`,
	path: '/secure/ManageFilters.jspa',
};
