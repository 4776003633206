import React, { useCallback, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { lazy } from 'react-loosely-lazy';
import PopupDI, { type ContentProps } from '@atlaskit/popup';
// eslint-disable-next-line jira/restricted/@atlassian+jira-common-styles
import { gridSize } from '@atlassian/jira-common-styles/src';
import { UsingNotificationsToImproveAdminApprovalProvider } from '@atlassian/jira-cross-flow-using-notifications-to-improve-admin-approval';
import { JSErrorBoundary as JSErrorBoundaryDI } from '@atlassian/jira-error-boundaries';
import { useExperienceStart as useExperienceStartDI } from '@atlassian/jira-experience-tracker';
import { useIntlV2 as useIntlDI } from '@atlassian/jira-intl';
import {
	useTopMenus as useTopMenusDI,
	testIdConcat,
	testIdGenerate,
	MENU_ID,
} from '@atlassian/jira-navigation-apps-common';
import Placeholder from '@atlassian/jira-placeholder';
import {
	useIsAnonymous as useIsAnonymousDI,
	useTenantContext,
	useCloudId,
} from '@atlassian/jira-tenant-context-controller';
import { useThirdPartyNudgeExperiment } from '@atlassian/jira-third-party-nudge/src/ui';
import { EXPERIENCE_NAVIGATION_TOP_MENU, NAVIGATION_ITEM_ID } from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { Notification as IconDI } from '../../common/ui/notification-button';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics';
import { useRegisterNotificationsInCommandPalette } from './command-palette';
import messages from './messages';
import type { NotificationsProps } from './types';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const BadgeDI = lazy(
	() =>
		import(/* webpackChunkName: "atlassian-navigation.async-notification-badge" */ './badge').then(
			({ Badge }) => Badge,
		),
	{
		ssr: false,
	},
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const MenuDI = lazy(
	() =>
		import(
			/* webpackChunkName: "atlassian-navigation.async-notification-menu-native" */ './menu'
		).then(({ Menu }) => Menu),
	{
		ssr: false,
	},
);

const Notifications = ({
	Badge = BadgeDI,
	ErrorBoundary = JSErrorBoundaryDI,
	Icon = IconDI,
	Menu = MenuDI,
	NotificationContentFrame = NotificationContentFrameDI,
	Popup = PopupDI,
	useExperienceStart = useExperienceStartDI,
	useIntl = useIntlDI,
	useIsAnonymous = useIsAnonymousDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	useTopMenus = useTopMenusDI,
}: NotificationsProps) => {
	const testIdPrefix = testIdGenerate('secondary-actions', 'notifications');
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const [isNotificationCleared, setIsNotificationCleared] = useState(false);
	const [isMenuOpen, { toggle: toggleMenu, off: closeMenu, on: openMenu }] = useTopMenus(
		MENU_ID.NOTIFICATIONS,
	);
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.NOTIFICATIONS,
	});
	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.NOTIFICATIONS,
		analyticsSource: 'atlassian-navigation',
	});

	const cloudId = useCloudId();
	const { atlassianAccountId } = useTenantContext();

	const { triggerNotificationBellPartyNudgeExperiment } = useThirdPartyNudgeExperiment(
		cloudId,
		atlassianAccountId,
	);

	const onClick = useCallback(() => {
		if (isMenuOpen) {
			performance.clearMarks('notification.list.render.start');
		} else {
			performance.mark('notification.list.render.start');
		}

		onStart();
		toggleMenu();
		triggerAnalytics();
		setIsNotificationCleared(true);
		triggerNotificationBellPartyNudgeExperiment();
	}, [
		isMenuOpen,
		onStart,
		toggleMenu,
		triggerAnalytics,
		triggerNotificationBellPartyNudgeExperiment,
	]);

	const handleNotificationIconHover = () => {
		MenuDI.preload();
	};

	useRegisterNotificationsInCommandPalette(onClick);

	const content = useCallback(
		({ update }: ContentProps) => (
			<NotificationContentFrame>
				<ErrorBoundary
					id="notifications.list.async"
					packageName="AtlassianNavigation"
					teamName="navigation"
				>
					<Placeholder name="notification-menu-native" fallback={null}>
						<Menu scheduleUpdate={update} testIdPrefix={testIdPrefix} />
					</Placeholder>
				</ErrorBoundary>
			</NotificationContentFrame>
		),

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[testIdPrefix],
	);

	const badge = useCallback(
		() => (
			<ErrorBoundary
				id="notifications.badge.async"
				packageName="AtlassianNavigation"
				teamName="navigation"
				fallback="unmount"
			>
				<Placeholder name="badge" fallback={null}>
					<Badge
						isNotificationCleared={isNotificationCleared}
						setIsNotificationCleared={setIsNotificationCleared}
						isNotificationDrawerOpen={isMenuOpen}
					/>
				</Placeholder>
			</ErrorBoundary>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isNotificationCleared, isMenuOpen],
	);

	const badgeEmpty = useCallback(() => null, []);

	const trigger = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'triggerProps' implicitly has an 'any' type.
		(triggerProps) => (
			<div data-testid={testIdConcat(testIdPrefix, 'menu-trigger')}>
				<Icon
					{...triggerProps}
					badge={badge}
					isSelected={isMenuOpen}
					onClick={onClick}
					onMouseEnter={handleNotificationIconHover}
					tooltip={formatMessage(messages.tooltip)}
					label={formatMessage(messages.label)}
					onViewRequests={openMenu}
				/>
			</div>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[badge, isMenuOpen, onClick, formatMessage, testIdPrefix],
	);

	if (isAnonymous) return null;

	if (__SERVER__) return <Icon badge={badgeEmpty} tooltip={formatMessage(messages.tooltip)} />;

	return (
		<Popup
			content={content}
			isOpen={isMenuOpen}
			onClose={closeMenu}
			placement="bottom-end"
			trigger={trigger}
		/>
	);
};

const NotificationWithErrorBoundary = (props: NotificationsProps) => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.NOTIFICATIONS}>
		<UsingNotificationsToImproveAdminApprovalProvider>
			<Notifications {...props} />
		</UsingNotificationsToImproveAdminApprovalProvider>
	</TopLevelErrorBoundary>
);

export { NotificationWithErrorBoundary as Notifications };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NotificationContentFrameDI = styled.div<{ children: ReactNode }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 67.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${gridSize * 12.5}px)`,
	display: 'flex',
});
