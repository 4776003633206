import { defineMessages } from '@atlassian/jira-intl';

/* eslint-disable jira/i18n/id-named-by-package */
export default defineMessages({
	yourAppsText: {
		id: 'atlassian-navigation.addons.menu.personalised-apps-experiment-enriched-content.personalised-apps-experiment-content.explore-marketplace-text',
		defaultMessage: 'Your apps',
		description: 'Header for app items section',
	} /* eslint-enable jira/i18n/id-named-by-package */,
});
