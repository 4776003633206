import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { ExperienceSuccess } from '@atlassian/ufo';
import type { RecommendationSelectorProps } from '../../common/types';
import { useProjectMenuRecommendations } from '../../controllers/project-menu-recommendations';
import { projectsMenuDropdownRecommendationSelector } from '../../experiences';

export const RecommendationSelector = ({ recommendations }: RecommendationSelectorProps) => {
	const [state] = useProjectMenuRecommendations();

	if (state.status !== 'success') return null;

	const selectedRecommendation = recommendations.find(
		(recommendation) => recommendation.key === state.recommendationKey,
	);

	return selectedRecommendation ? (
		<JSErrorBoundary
			id={`projects-menu-recommendation.${selectedRecommendation.key}`}
			packageName="jiraAtlassianNavigationRecommendations"
			onError={() => projectsMenuDropdownRecommendationSelector.failure()}
			fallback="unmount"
			teamName="navigation"
		>
			<ContextualAnalyticsData
				attributes={{
					recommendationKey: selectedRecommendation.key,
				}}
			>
				<ExperienceSuccess experience={projectsMenuDropdownRecommendationSelector} />
				<selectedRecommendation.Component />
			</ContextualAnalyticsData>
		</JSErrorBoundary>
	) : null;
};
