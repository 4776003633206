import React, { type ComponentType, forwardRef, type ReactNode } from 'react';
import { useShouldNestedElementRender } from '@atlaskit/side-navigation';
import { getDisplayName } from '@atlassian/jira-common-util-with-injected-props';

export const withNestingOptimisation = <Props extends {}>(Component: ComponentType<Props>) => {
	const WithNestingOptimisation = forwardRef<HTMLElement, Props>((props, ref) => {
		const children: ReactNode =
			// @ts-expect-error - Property 'children' does not exist on type 'Props'.ts(2339)
			typeof props === 'object' && props != null ? props.children : null;

		return useShouldNestedElementRender().shouldRender && !__SERVER__ ? (
			<Component {...props} ref={ref} />
		) : (
			children
		);
	});

	WithNestingOptimisation.displayName = `withNestingOptimisation(${getDisplayName(Component)})`;
	return WithNestingOptimisation;
};
