import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets';

export const userBoardRoute = {
	group: ROUTE_GROUPS.CLASSIC_SOFTWARE,
	name: ROUTE_NAMES.RAPIDBOARD_USER_BOARD,
	path: '/jira/people/:accountId/boards/:boardId',
	preloadOptions: {
		earlyChunks: ['rapidboard-early-entry'],
	},
	earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
};
