import React, { useCallback } from 'react';
import Heading from '@atlaskit/heading';
import CreditcardFilledIcon from '@atlaskit/icon/glyph/creditcard-filled';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import RecentIcon from '@atlaskit/icon/glyph/recent';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	PREMIUM_REVERSE_TRIAL_TASK,
	ADVANCED_ROADMAPS_TASK,
	useJswOnboarding,
} from '@atlassian/jira-onboarding-quickstart-core';
import { useOpenChecklist } from '@atlassian/jira-software-onboarding-quickstart';
import { ChangePlansUrlProvider } from './change-plans-url-provider';
import { ListItem } from './list-item';
import messages from './messages';

export type Props = {
	billingPageUrl: string;
	onFireUiAnalyticsEvent: (
		action: string,
		actionSubject: string,
		actionSubjectId: string,
		attributes?: Record<string, unknown>,
	) => void;
};

export const ReverseTrialExperimentPopupContent = ({
	billingPageUrl,
	onFireUiAnalyticsEvent,
}: Props) => {
	const { formatMessage } = useIntl();
	const openChecklist = useOpenChecklist();
	const [{ currentTaskGroupParent }, { changeTaskGroupParent, nextOpenTask }] = useJswOnboarding();

	const onClickExplorePremiumFeatures = useCallback(() => {
		onFireUiAnalyticsEvent('clicked', 'button', 'trialPillExplorePremium');
		const taskGroupToEnter = PREMIUM_REVERSE_TRIAL_TASK;

		if (currentTaskGroupParent !== taskGroupToEnter) {
			changeTaskGroupParent(taskGroupToEnter);
		}

		openChecklist({
			trigger: 'ReverseTrialPopup',
		});

		nextOpenTask(ADVANCED_ROADMAPS_TASK, {
			closeNudge: true,
		});
	}, [
		onFireUiAnalyticsEvent,
		currentTaskGroupParent,
		openChecklist,
		nextOpenTask,
		changeTaskGroupParent,
	]);

	const onLinkClick = useCallback(
		(title: string) => {
			onFireUiAnalyticsEvent('clicked', 'button', title);
		},
		[onFireUiAnalyticsEvent],
	);

	return (
		<>
			<Heading size="small">{formatMessage(messages.title)}</Heading>
			<Stack as="ul" xcss={listContainerStyles} alignInline="stretch">
				<ListItem
					title={formatMessage(messages.explorePremiumFeaturesLink)}
					onClick={onClickExplorePremiumFeatures}
					icon={<PremiumIcon label="" size="small" />}
				/>
				<ListItem
					title={formatMessage(messages.addPaymentDetailsLink)}
					link={billingPageUrl}
					onClick={() => onLinkClick('trialPillAddPaymentDetails')}
					icon={<CreditcardFilledIcon label="" size="small" />}
				/>
				<ChangePlansUrlProvider>
					{(changePlansUrl) =>
						changePlansUrl ? (
							<ListItem
								title={formatMessage(messages.endTrialAndChangePlansLink)}
								link={changePlansUrl}
								onClick={() => onLinkClick('trialPillChangePlans')}
								icon={<RecentIcon label="" size="small" />}
							/>
						) : null
					}
				</ChangePlansUrlProvider>
			</Stack>
			<Box as="p" xcss={downgradeTextStyles}>
				{formatMessage(messages.afterTrial)}
			</Box>
		</>
	);
};

const listContainerStyles = xcss({
	listStyle: 'none',
	marginTop: 'space.100',
	padding: 'space.0',
});

const downgradeTextStyles = xcss({
	font: token('font.body.UNSAFE_small'),
	color: 'color.text.subtle',
});
