import type {
	CanonicalId,
	JiraProjectType,
} from '@atlassian/jira-common-constants/src/project-types';
import mainProjectKeysTrialAwarenessModalQueryParameters from '@atlassian/jira-relay/src/__generated__/main_EditionAwarenessTrialModalQuery$parameters';
import type { JiraApplicationKey } from '@atlassian/jira-shared-types/src/application';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	productKey: CanonicalId;
	jiraProjectType: JiraProjectType;
	jiraApplicationKey: JiraApplicationKey;
};

export const editionAwarenessTrialModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "edition-awareness-trial-modal" */ './src/ui'),
	),
	// @ts-expect-error - JiraApplicationKey in AGG schema does not support CUSTOMER_SERVICE
	getPreloadProps: ({
		cloudId,
		productKey,
		jiraProjectType,
		jiraApplicationKey,
	}: EntryPointParams) => ({
		extraProps: { productKey },
		entryPoints: {},
		queries: {
			editionAwarenessTrialModalQueryRef: {
				parameters: mainProjectKeysTrialAwarenessModalQueryParameters,
				variables: {
					cloudId,
					hamsProductKey: productKey,
					projectType: jiraProjectType,
					jiraApplicationKey,
				},
			},
		},
	}),
});
