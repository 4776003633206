import React from 'react';
import { CustomThemeButton, type CustomThemeButtonProps } from '@atlaskit/button';
import { useEntryPoint } from '@atlassian/jira-entry-point';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container';
import { entryPointPremiumUpgradeModal } from '../../entrypoint';

const entryPointParams = {};
export const PremiumUpgradeModalEntryPointButton = (
	buttonProps: Exclude<CustomThemeButtonProps, 'ref'>,
) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		entryPointPremiumUpgradeModal,
		entryPointParams,
	);
	const buttonTrigger = useEntryPointButtonTrigger(entryPointActions);
	return (
		<>
			<CustomThemeButton {...buttonProps} ref={buttonTrigger} />
			<ModalEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="jira-premium-upgrade-modal"
				packageName="jiraPremiumUpgradeModal"
				errorFallback="flag"
				runtimeProps={{ onClose: entryPointActions.unload }}
				entryPointActions={entryPointActions}
			/>
		</>
	);
};
