import { useCallback, useEffect, useState } from 'react';
import memoizeOne from 'memoize-one';
import getExplicitlyLicensedProducts from '@atlassian/jira-common-get-explicitly-licensed-products';
import { fireErrorAnalytics } from '@atlassian/jira-errors-handling';
import { getProductKey } from '@atlassian/jira-external-urls';
import { performGetRequest } from '@atlassian/jira-fetch';
import { useSiteUserPersonalization } from '@atlassian/jira-personalization-service';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import { SOFTWARE } from '@atlassian/jira-shared-types';
import { useSubProduct } from '@atlassian/jira-subproduct';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import {
	JIRA_SOFTWARE_FREE_IS_CCP_BILLING_ADMIN_TRAIT,
	PACKAGE_NAME,
} from '../../common/constants';
import type { BillingAdminCheckMethod } from '../../types';
import { useCanProductUserUpgradeAndPayQuery } from './can-product-user-upgrade-and-pay';

const fetchEntitlementDetails = (entitlementId: string) =>
	performGetRequest(`/gateway/api/ccp/api/v1/entitlements/${entitlementId}/details`, {
		headers: {
			'Content-Type': 'application/json',
		},
	});

const fetchBillingAdmins = (transactionAccountId: string) =>
	performGetRequest('/gateway/api/ccp/api/v1/billing-admins', {
		headers: {
			'Content-Type': 'application/json',
			'X-transaction-account': transactionAccountId,
		},
	});

export const memoizedFetchEntitlementDetails = memoizeOne(fetchEntitlementDetails);
export const memoizedFetchBillingAdmins = memoizeOne(fetchBillingAdmins);

type UseCanUpgradeAndPayForJswFreeParams = {
	/**
	 * The method to use for checking whether the user is a billing admin. If not specified, `traits` is used by default.
	 * This parameter is only relevant for CCP as there are no "billing admins" on HAMS.
	 *
	 * `ccpApi` uses the `/billing-admins` endpoint from CCP.
	 * `traits` uses the `/personalization` endpoint from the Personalization API. Note that the billing admin trait is updated only once a day.
	 */
	billingAdminCheckMethod?: BillingAdminCheckMethod;
	/** The name of the component or package from which this hook is called */
	caller: string;
	/** If true, the check is not performed. This is useful for when you want the check to execute conditionally */
	skip?: boolean;
};

/**
 * This hook determines whether the current user has permission to upgrade and manage billing for the Jira Software Free instance.
 */
export const useCanUpgradeAndPayForJswFree = ({
	caller,
	skip = false,
	billingAdminCheckMethod = 'traits',
}: UseCanUpgradeAndPayForJswFreeParams) => {
	const [canUpgradeAndPay, setCanUpgradeAndPay] = useState<boolean | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);
	const [ineligibilityReason, setIneligibilityReason] = useState<string | undefined>(undefined);

	const { isSiteAdmin, cloudId, atlassianAccountId, productEntitlementDetails } =
		useTenantContext();
	const { fetch, error: personalizationApiError } = useSiteUserPersonalization();

	const isBillingSystemCcp = productEntitlementDetails?.[SOFTWARE]?.billingSourceSystem === 'CCP';
	const entitlementId = productEntitlementDetails?.[SOFTWARE]?.entitlementId;

	const checkIsCcpBillingAdmin = useCallback(async (): Promise<boolean | undefined> => {
		if (!entitlementId) {
			throw new Error('Empty entitlementId');
		}

		if (!atlassianAccountId) {
			throw new Error('Empty atlassianAccountId');
		}

		// CCP API approach
		if (billingAdminCheckMethod === 'ccpApi') {
			const entitlementDetails = await memoizedFetchEntitlementDetails(entitlementId);
			const transactionAccountId = entitlementDetails?.transactionAccountId;
			const billingAdminsResponse = await memoizedFetchBillingAdmins(transactionAccountId);
			const isBillingAdmin = !!billingAdminsResponse?.data?.find(
				({ id = null }) => id === atlassianAccountId,
			);

			return isBillingAdmin;
		}

		// Traits approach
		const personalizationData = await fetch({ cloudId, userId: atlassianAccountId });

		if (!personalizationData) {
			return undefined;
		}

		const isBillingAdmin =
			personalizationData.find(
				(trait) =>
					trait.name === JIRA_SOFTWARE_FREE_IS_CCP_BILLING_ADMIN_TRAIT && trait.value === true,
			) !== undefined;

		return isBillingAdmin;
	}, [atlassianAccountId, billingAdminCheckMethod, cloudId, entitlementId, fetch]);

	/**
	 * This `useEffect` performs the permission checks.
	 */
	useEffect(() => {
		if (skip) {
			return;
		}

		setLoading(true);

		// Only logged in site admins can upgrade
		if (isSiteAdmin) {
			if (isBillingSystemCcp) {
				// On CCP, only billing admins can manage billing
				checkIsCcpBillingAdmin()
					.then((isBillingAdmin) => {
						setCanUpgradeAndPay(isBillingAdmin);
						// Empty ineligibilityReason marks that the requisites were checked
						setIneligibilityReason(isBillingAdmin ? '' : 'not a billing admin');
					})
					.catch((e) => {
						setError(e);
						setIneligibilityReason('error checking eligibility');
					})
					.finally(() => {
						setLoading(false);
					});
			} else {
				// On HAMS, site admins can also manage billing
				setCanUpgradeAndPay(true);
				setLoading(false);
				// Empty ineligibilityReason marks that the requisites were checked
				setIneligibilityReason('');
			}
		} else {
			setCanUpgradeAndPay(false);
			setLoading(false);
			setIneligibilityReason('not a site admin');
		}
	}, [atlassianAccountId, caller, checkIsCcpBillingAdmin, isBillingSystemCcp, isSiteAdmin, skip]);

	/**
	 * Errors from the Traits API are not thrown. Instead, they are stored in the `error` field from `useSiteUserPersonalization`.
	 * Since we can't catch those errors, we need to listen for changes to that `error` field and update our own "error" state accordingly.
	 */
	useEffect(() => {
		if (personalizationApiError) {
			setError(personalizationApiError);
		}
	}, [personalizationApiError]);

	useEffect(() => {
		if (error) {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'useCanUpgradeAndPayForJswFree',
					packageName: PACKAGE_NAME,
					teamName: 'flywheel-tako',
				},
				attributes: {
					billingSourceSystem: isBillingSystemCcp ? 'CCP' : 'HAMS',
					caller,
					billingAdminCheckMethod,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [error, isBillingSystemCcp, caller, billingAdminCheckMethod]);

	return { canUpgradeAndPay, loading, error, ineligibilityReason };
};

type UseCanUpgradeAndPayForCurrentProductParams = {
	/** The name of the component or package from which this hook is called */
	caller: string;
	/** If true, the check is not performed. This is useful for when you want the check to execute conditionally */
	skip?: boolean;
};

/**
 * This hook determines whether the current user has permission to upgrade and manage billing for the product they are currently in,
 * e.g. Jira Software, Jira Service Management, Jira Work Management, etc.
 * The hook will fail and return an error if executed outside the context of a specific product (e.g. on the "Your work" page).
 */
export const useCanUpgradeAndPayForCurrentProduct = ({
	caller,
	skip = false,
}: UseCanUpgradeAndPayForCurrentProductParams) => {
	const [canUpgradeAndPay, setCanUpgradeAndPay] = useState<boolean | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<Error | undefined>(undefined);

	const tenantContext = useTenantContext();
	const subProduct = useSubProduct();
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const applicationKey = getProductKey(subProduct, explicitlyLicensedProducts);

	useEffect(() => {
		if (skip) {
			return;
		}

		if (!applicationKey) {
			setError(new Error('Empty applicationKey'));
			return;
		}

		const isBillingSystemCcp =
			tenantContext.productEntitlementDetails?.[applicationKey]?.billingSourceSystem === 'CCP';
		const entitlementId = tenantContext.productEntitlementDetails?.[applicationKey]?.entitlementId;

		const checkIsCcpBillingAdmin = async (): Promise<boolean | undefined> => {
			if (!entitlementId) {
				throw new Error('Empty entitlementId');
			}

			if (!tenantContext.atlassianAccountId) {
				throw new Error('Empty atlassianAccountId');
			}

			const entitlementDetails = await memoizedFetchEntitlementDetails(entitlementId);
			const transactionAccountId = entitlementDetails?.transactionAccountId;
			const billingAdminsResponse = await memoizedFetchBillingAdmins(transactionAccountId);
			const isBillingAdmin =
				billingAdminsResponse?.data?.some(
					({ id = null }) => id === tenantContext.atlassianAccountId,
				) ?? false;

			return isBillingAdmin;
		};

		setLoading(true);

		// Only logged in site admins can upgrade
		if (tenantContext.isSiteAdmin && tenantContext.atlassianAccountId) {
			if (isBillingSystemCcp) {
				// On CCP, only billing admins can manage billing
				checkIsCcpBillingAdmin()
					.then((isBillingAdmin) => {
						setCanUpgradeAndPay(isBillingAdmin);
					})
					.catch((e) => {
						setError(e);
						fireErrorAnalytics({
							error: e,
							meta: {
								id: 'useCanUpgradeAndPayForCurrentProduct',
								packageName: PACKAGE_NAME,
								teamName: 'flywheel-tako',
							},
							attributes: {
								billingSourceSystem: isBillingSystemCcp ? 'CCP' : 'HAMS',
								caller,
								subProduct,
								applicationKey,
								licensedProducts: tenantContext.licensedProducts,
								productEntitlementDetails: tenantContext.productEntitlementDetails,
							},
							sendToPrivacyUnsafeSplunk: true,
						});
					})
					.finally(() => {
						setLoading(false);
					});
			} else {
				// On HAMS, site admins can also manage billing
				setCanUpgradeAndPay(true);
				setLoading(false);
			}
		} else {
			setCanUpgradeAndPay(false);
			setLoading(false);
		}
	}, [
		applicationKey,
		caller,
		skip,
		subProduct,
		tenantContext.atlassianAccountId,
		tenantContext.isSiteAdmin,
		tenantContext.licensedProducts,
		tenantContext.productEntitlementDetails,
	]);

	return { canUpgradeAndPay, loading, error };
};

type UseCanUpgradeAndPayForCurrentProductLazyParams = {
	/** The name of the component or package from which this hook is called */
	caller: string;
	/** Callback executed when an error occurs. Provides the error and some base attributes as arguments. */
	onError?: (error: Error, baseAttributes: AnalyticsAttributes) => void;
};

export const useCanUpgradeAndPayForCurrentProductLazy = ({
	caller,
	onError,
}: UseCanUpgradeAndPayForCurrentProductLazyParams) => {
	const tenantContext = useTenantContext();
	const subProduct = useSubProduct();
	const explicitlyLicensedProducts = getExplicitlyLicensedProducts(tenantContext);
	const applicationKey = getProductKey(subProduct, explicitlyLicensedProducts);

	const checkIsCcpBillingAdmin = useCallback(
		async (entitlementId: string | undefined): Promise<boolean> => {
			if (!entitlementId) {
				throw new Error('Empty entitlementId');
			}

			if (!tenantContext.atlassianAccountId) {
				throw new Error('Empty atlassianAccountId');
			}

			const entitlementDetails = await memoizedFetchEntitlementDetails(entitlementId);
			const transactionAccountId = entitlementDetails?.transactionAccountId;
			const billingAdminsResponse = await memoizedFetchBillingAdmins(transactionAccountId);
			const isBillingAdmin =
				billingAdminsResponse?.data?.some(
					({ id = null }) => id === tenantContext.atlassianAccountId,
				) ?? false;

			return isBillingAdmin;
		},
		[tenantContext.atlassianAccountId],
	);

	/**
	 * Checks whether the user has upgrade and billing permission (i.e. is site and billing admin).
	 * Returns `true` if the user has permission for both, `false` if they don't, and `null` if the check failed.
	 */
	const hasUpgradeAndBillingPermission = useCallback(async (): Promise<boolean | null> => {
		if (!applicationKey) {
			const error = new Error('Empty applicationKey');
			const analyticsAttributes = {
				caller,
				subProduct,
				applicationKey,
				licensedProducts: tenantContext.licensedProducts,
				productEntitlementDetails: tenantContext.productEntitlementDetails,
			};
			onError?.(error, analyticsAttributes);
			return null;
		}

		// Site admin is required for upgrading products
		if (!tenantContext.isSiteAdmin || !tenantContext.atlassianAccountId) {
			return false;
		}

		const isBillingSystemCcp =
			tenantContext.productEntitlementDetails?.[applicationKey]?.billingSourceSystem === 'CCP';

		// On CCP, only billing admins can manage billing
		if (isBillingSystemCcp) {
			const entitlementId =
				tenantContext.productEntitlementDetails?.[applicationKey]?.entitlementId;
			return checkIsCcpBillingAdmin(entitlementId)
				.then((isBillingAdmin) => isBillingAdmin)
				.catch((e) => {
					const analyticsAttributes = {
						billingSourceSystem: isBillingSystemCcp ? 'CCP' : 'HAMS',
						caller,
						subProduct,
						applicationKey,
						licensedProducts: tenantContext.licensedProducts,
						productEntitlementDetails: tenantContext.productEntitlementDetails,
					};
					onError?.(e, analyticsAttributes);
					return null;
				});
		}
		// On HAMS, site admins can manage billing
		return true;
	}, [
		applicationKey,
		caller,
		checkIsCcpBillingAdmin,
		onError,
		subProduct,
		tenantContext.atlassianAccountId,
		tenantContext.isSiteAdmin,
		tenantContext.licensedProducts,
		tenantContext.productEntitlementDetails,
	]);

	return { hasUpgradeAndBillingPermission };
};

type UseCanProductUserUpgradeAndPayProps = {
	skip: boolean;
	productKey?: 'jira-software.ondemand' | 'jira-servicedesk.ondemand';
};

/**
 * Helper function to determine whether a product user can upgrade and pay.
 */
export function useCanProductUserUpgradeAndPay({
	skip,
	productKey,
}: UseCanProductUserUpgradeAndPayProps) {
	const {
		data,
		isLoading,
		error,
		fetch: fetchCanProductUserUpgradeAndPay,
	} = useCanProductUserUpgradeAndPayQuery();

	useEffect(() => {
		if (skip || !productKey) {
			return;
		}

		fetchCanProductUserUpgradeAndPay(productKey);
	}, [fetchCanProductUserUpgradeAndPay, productKey, skip]);

	const canProductUserUpgradeAndPay = data?.canProductUserUpgradeAndPay;
	const isProductUserBillingAdmin = data?.isBillingAdmin;

	return {
		isProductUserBillingAdmin,
		canProductUserUpgradeAndPay,
		isLoading,
		error,
	};
}
