import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const dashboardRoute = {
	group: ROUTE_GROUPS.DASHBOARD,
	name: ROUTE_NAMES.DASHBOARD,
	path: '/secure/Dashboard.jspa',
};

export const selectedDashboardRoute = {
	group: ROUTE_GROUPS.DASHBOARD,
	name: ROUTE_NAMES.DASHBOARD_SELECTED,
	path: '/secure/Dashboard.jspa',
	query: ['selectPageId'],
};

// IMPORTANT - must precede the view route due to substring path match
export const dashboardEditRoute = {
	group: ROUTE_GROUPS.DASHBOARD,
	name: ROUTE_NAMES.DASHBOARD_EDIT,
	path: '/jira/dashboards/:dashboardId(\\d+)/edit',
	query: ['maximized?'],
	exact: true,
};

export const dashboardWallboardRoute = {
	group: ROUTE_GROUPS.DASHBOARD,
	name: ROUTE_NAMES.DASHBOARD_WALLBOARD,
	path: '/jira/dashboards/wallboard',
	query: ['dashboardId', 'cyclePeriod?', 'transitionFx?', 'random?'],
	exact: true,
};

export const dashboardViewRoute = {
	group: ROUTE_GROUPS.DASHBOARD,
	name: ROUTE_NAMES.DASHBOARD_VIEW,
	path: '/jira/dashboards/:dashboardId(\\d+|last-visited)',
	query: ['maximized?'],
	exact: true,
};

export const dashboardViewEmbedRoute = {
	group: ROUTE_GROUPS.DASHBOARD,
	name: ROUTE_NAMES.DASHBOARD_VIEW_EMBED,
	path: '/jira/dashboards/:dashboardId(\\d+)/embed',
	query: [],
	exact: true,
};

export const systemDashboardEditRoute = {
	group: ROUTE_GROUPS.DASHBOARD,
	name: ROUTE_NAMES.SYSTEM_DASHBOARD_EDIT,
	path: '/secure/admin/EditDefaultDashboard(!default)?.jspa',
	query: ['maximized?'],
};
