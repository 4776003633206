import type {
	EntryPoint,
	Extension,
	ForgeUiAnalyticsAttributes,
} from '@atlassian/jira-forge-ui-types';
import { getForgeUIType } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id';

export const getAnalyticsAttributesFromExtension = (
	extension: Extension,
	entryPoint?: EntryPoint,
): ForgeUiAnalyticsAttributes => {
	const { appId, envId } = parseExtensionId(extension.id);
	return {
		appId,
		envId,
		forgeType: getForgeUIType(extension, entryPoint),
		envType: extension?.environmentType,
	};
};
