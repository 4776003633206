import { TASK_LIST, TASK_VARIANTS } from './constants';
import { Experiments, PersonalizedExperienceExperiments } from './experiments';
import { UserRole } from './user-roles';

export const JWM = {
	taskList: TASK_LIST,
	taskVariants: TASK_VARIANTS,
	userRole: UserRole,
	experiments: Experiments,
	personalizedExperienceExperiments: PersonalizedExperienceExperiments,
} as const;
