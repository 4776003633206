export const getStartOfUTCDay = (timestamp: number) => new Date(timestamp).setUTCHours(0, 0, 0, 0);
export const getTrialDaysLeft = (currentDate: number, trialEndTime?: number): number | undefined =>
	trialEndTime && Math.ceil((getStartOfUTCDay(trialEndTime) - currentDate) / (24 * 3600 * 1000));

export const isInGracePeriod = (currentDate: number, trialEndTime?: number): boolean => {
	const trialDaysLeft = getTrialDaysLeft(currentDate, trialEndTime);

	// Technically we do not know whether user is in grace period or not, but if user is able to access the Jira with no payment details
	// and is trialEndTime is missing or over we assume that site is in grace period
	return !trialDaysLeft || trialDaysLeft < 0;
};
