import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import { MODAL_ID } from '../../../../../common/constants';
import { MenuItem } from '../../../../../common/ui/menu/item';
import messages from './messages';
import type { CreateButtonProps } from './types';

export const CreateButton = ({ testId }: CreateButtonProps) => {
	const { formatMessage } = useIntl();
	const [, { on: modalOpen }] = useSwitchModals(MODAL_ID.DASHBOARD_CREATE);

	return (
		<MenuItem
			analytics={{
				actionSubjectId: 'dashboardsNavigationMenuItem',
				menuItemId: 'create',
				menuItemType: 'action',
				position: 1,
			}}
			onClick={modalOpen}
			testId={testId}
		>
			{formatMessage(messages.createButton)}
		</MenuItem>
	);
};
