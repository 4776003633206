/** @jsx jsx */
import React from 'react';
import { css, styled, jsx } from '@compiled/react';
import { AppSkeletonImage } from '../../common/ui';
import globalSVG from './assets/global-table-skeleton-with-projects.tpl.svg';
import mimetypeSelectSVG from './assets/mimetype-skeleton.tpl.svg';
import imgSVG from './assets/personal-settings-notifications-app-skeleton-with-projects.tpl.svg';
import projectSVG from './assets/project-accordion-skeleton.tpl.svg';

const maxWidthCssStyles = css({
	maxWidth: '1000px',
	margin: '0 auto',
});
const customCssStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		border: 'none',
	},
});

export const PersonalSettingsNotificationsAppSkeleton = () => (
	<AppSkeletonImage src={imgSVG} css={maxWidthCssStyles} />
);
export const PersonalSettingsNotificationsGlobalTableSkeleton = () => (
	<SkeletonWrapper>
		<AppSkeletonImage src={globalSVG} css={customCssStyles} />
	</SkeletonWrapper>
);
export const MimetypeSelectSkeleton = () => (
	<SkeletonWrapper>
		<AppSkeletonImage src={mimetypeSelectSVG} css={customCssStyles} />
	</SkeletonWrapper>
);

export const ProjectNotificationAccordionSkeleton = () => (
	<SkeletonWrapper>
		<AppSkeletonImage src={projectSVG} css={customCssStyles} />
	</SkeletonWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SkeletonWrapper = styled.div({
	maxHeight: '500px',
	overflowY: 'hidden',
});
