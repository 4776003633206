import { ROUTE_NAMES } from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const globalIssueNavigatorRoute = {
	name: ROUTE_NAMES.LEGACY_GIN,
	path: '/issues/:issueKey(.*)?',
	apdexIgnoreParams: ['issueKey'],
};

export const globalIssueNavigatorBrowseRoute = {
	name: ROUTE_NAMES.LEGACY_GIN_ISSUE,
	path: '/browse/:issueKey?',
	query: ['jql|filter'],
};

export const globalIssueNavigatorRedirectRoute = {
	name: ROUTE_NAMES.LEGACY_GIN_QUICKSEARCH,
	path: '/secure/QuickSearch.jspa',
	query: ['searchString'],
};
