import React, { useEffect, useCallback } from 'react';
import { Section } from '@atlaskit/menu';
import { AuthenticationErrorBoundary, JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { useExperienceFail } from '@atlassian/jira-experience-tracker';
import { useFavoriteDashboards as useFavorite } from '@atlassian/jira-favorites-store';
import { MENU_ID, testIdConcat } from '@atlassian/jira-navigation-apps-common';
import Placeholder from '@atlassian/jira-placeholder';
import {
	DEFAULT_LOAD_COUNT_FAV,
	PACKAGE_NAME,
	EXPERIENCE_NAVIGATION_TOP_MENU,
} from '../../../common/constants';
import { MenuError as Error } from '../../../common/ui/menu/error';
import { MenuLayout as Layout } from '../../../common/ui/menu/layout';
import { MenuSkeletonContent as SkeletonContent } from '../../../common/ui/menu/skeleton/content';
import { MenuSkeletonFooter as SkeletonFooter } from '../../../common/ui/menu/skeleton/footer';
import { Empty } from './empty';
import { FavoriteContent } from './favorite-content';
import { Footer } from './footer';
import { LoadStatus } from './load-status';
import { RecentContent } from './recent-content';
import type { MenuProps } from './types';

const EVENT_DATA = { id: MENU_ID.DASHBOARDS } as const;

export const Menu = ({ testIdPrefix }: MenuProps) => {
	const [, { loadFavoriteDashboards: loadFavorite }] = useFavorite();
	const onFail = useExperienceFail({ experience: EXPERIENCE_NAVIGATION_TOP_MENU });

	useEffect(
		() => () => {
			// Refresh favourites on close to update if user has toggled any item
			loadFavorite(DEFAULT_LOAD_COUNT_FAV);
		},
		[loadFavorite],
	);

	const testIdContent = testIdConcat(testIdPrefix, 'content');
	const testIdFooter = testIdConcat(testIdPrefix, 'footer');

	const errorFallback = useCallback(() => <Error testIdPrefix={testIdContent} />, [testIdContent]);

	return (
		<Layout isWide>
			<Section isScrollable>
				<JSErrorBoundary
					id={MENU_ID.DASHBOARDS}
					packageName={PACKAGE_NAME}
					fallback={errorFallback}
					withExperienceTracker
					extraEventData={EVENT_DATA}
					onError={onFail}
				>
					<AuthenticationErrorBoundary render={errorFallback}>
						<Empty testIdPrefix={testIdContent}>
							<Placeholder
								name="navigation-apps-dashboards-menu"
								fallback={<SkeletonContent testIdPrefix={testIdContent} />}
							>
								<LoadStatus shouldThrowError />
								<FavoriteContent testIdPrefix={testIdContent} />
								<RecentContent testIdPrefix={testIdContent} />
							</Placeholder>
						</Empty>
					</AuthenticationErrorBoundary>
				</JSErrorBoundary>
			</Section>
			<Section hasSeparator>
				<Placeholder
					name="navigation-apps-dashboards-footer"
					fallback={<SkeletonFooter testIdPrefix={testIdFooter} />}
				>
					<LoadStatus />
					<Footer testIdPrefix={testIdFooter} />
				</Placeholder>
			</Section>
		</Layout>
	);
};
