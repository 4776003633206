import React, { useEffect } from 'react';
import get from 'lodash/get';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common';
import { useResource } from '@atlassian/jira-router';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src';
import { MenuItem } from '../../../../common/ui/menu/item';
import { MenuListItem } from '../../../../common/ui/menu/list';

export type ExtraItemsProps = {
	startPosition: number;
	testIdPrefix?: string;
	itemAriaLabel?: string;
};

const useConnectItems = () => {
	const {
		data: resourceData,
		loading,
		error,
		refresh,
	} = useResource(navigationSidebarGlobalResource);

	const data = resourceData?.response;

	useEffect(() => {
		if (!data && !loading && !error) {
			refresh();
		}
	}, [data, error, loading, refresh]);

	return data
		? get(data, 'global.profile.items', []).filter(
				(item) => !['view_profile', 'log_out'].includes(item.id),
			)
		: [];
};

export const ExtraItems = ({ startPosition, testIdPrefix, itemAriaLabel }: ExtraItemsProps) => {
	const connectItems = useConnectItems();

	if (!connectItems.length) return null;

	return (
		<>
			{connectItems.map((item, idx) => {
				// @ts-expect-error - TS2339 - Property 'styleClass' does not exist on type '{ id: string; name: string; url: string; }'.
				const className = item.styleClass;
				return (
					<MenuListItem key={item.url} ariaLabel={itemAriaLabel || ''}>
						<MenuItem
							analytics={{
								actionSubjectId: 'profileNavigationMenuItem',
								menuItemId: item.id,
								menuItemType: 'jira',
								position: startPosition + idx,
							}}
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={className}
							href={item.url}
							testId={testIdConcat(testIdPrefix, `item-addon-${idx}`)}
						>
							{item.name}
						</MenuItem>
					</MenuListItem>
				);
			})}
		</>
	);
};
