import {
	ROUTE_GROUPS,
	ROUTE_NAMES,
} from '@atlassian/jira-common-constants/src/spa-routes-legacy-do-not-use';

export const peopleProfilesRoute = {
	group: ROUTE_GROUPS.PEOPLE_PROFILES,
	name: ROUTE_NAMES.PEOPLE_INDEX,
	path: '/jira/people/:subPath?',
};
