import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common';
import { dashboardsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller';
import { MenuItem } from '../../../../common/ui/menu/item';
import { CreateButton } from './create-button';
import messages from './messages';
import type { FooterProps } from './types';

export const Footer = ({ testIdPrefix }: FooterProps) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();

	const testId = testIdConcat(testIdPrefix, 'items');
	const dashboardsUrl = '/jira/dashboards';

	return (
		<span data-testid={testId}>
			<MenuItem
				analytics={{
					actionSubjectId: 'dashboardsNavigationMenuItem',
					menuItemId: 'viewAll',
					menuItemType: 'action',
					position: 0,
				}}
				href={dashboardsUrl}
				testId={testIdConcat(testId, 'item-view-all')}
				to={dashboardsDirectoryRoute}
			>
				{formatMessage(messages.viewAll)}
			</MenuItem>
			{!isAnonymous && <CreateButton testId={testIdConcat(testId, 'item-create')} />}
		</span>
	);
};
