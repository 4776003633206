import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types';
import wrapperEditionAwarenessModalWrapperQuery from '@atlassian/jira-relay/src/__generated__/wrapperEditionAwarenessModalWrapperQuery$parameters';
import type { JiraApplicationKey } from '@atlassian/jira-shared-types/src/application';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = {
	cloudId: string;
	jiraApplicationKey: JiraApplicationKey;
	productKey: CanonicalId;
	offeringKey: string;
	chargeElement: 'user' | 'agent';
};

export const editionAwarenessModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "edition-awareness-next-modal-wrapper" */ './wrapper'),
	),
	// @ts-expect-error - JiraApplicationKey in AGG schema does not support CUSTOMER_SERVICE
	getPreloadProps: ({
		cloudId,
		jiraApplicationKey,
		productKey,
		offeringKey,
		chargeElement,
	}: EntryPointParams) => ({
		entryPoints: {},
		extraProps: {},
		queries: {
			editionAwareness: {
				parameters: wrapperEditionAwarenessModalWrapperQuery,
				variables: {
					cloudId,
					jiraApplicationKey,
					hamsProductKey: productKey,
					offeringKey,
					chargeElement,
				},
			},
		},
	}),
});
