import React, { useMemo, type ReactNode } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries';
import { PACKAGE_NAME, TOP_LEVEL_ERROR_BOUNDARY_ID } from '../../constants';

type Props = {
	id: string;
	children: ReactNode;
};

export const TopLevelErrorBoundary = ({ id, children }: Props) => {
	const EVENT_DATA = useMemo(() => ({ id }), [id]);

	return (
		<JSErrorBoundary
			id={`${TOP_LEVEL_ERROR_BOUNDARY_ID}.${id}`}
			packageName={PACKAGE_NAME}
			extraEventData={EVENT_DATA}
			fallback="unmount"
		>
			{children}
		</JSErrorBoundary>
	);
};
