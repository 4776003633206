import type { Environment } from '@atlassian/jira-shared-types';
import { FIRST_PARTY_CCP_PRODUCT_IDS } from '@atlassian/surface-analytics';

export const JPD_RECOMMENDATION_KEY = 'jpd-recommendation';
export const JPD_KEY = 'jpd.product-discovery';
export const JPD_RECOMMENDATION_DISMISS_USER_PROPERTY = 'JpdRecommendationDismissed';
export const USER_PROPERTIES_KEY = 'RecommendJpdInProjectsMenuKey';
export const DATA_PRIVACY_LINK = 'https://www.atlassian.com/legal/privacy-policy';

export const JPD_RECOMMENDATION_EXPERIMENT = 'recommending_jpd_in_projects_menu';
export const JPD_ENTITY_ID = 'jira.polaris:discovery';

export const getProductTypeCcpReferenceIdMapForEnv = (
	environment: Environment,
): Record<string, string> => {
	const env = environment === 'prod' ? 'prod' : 'staging';
	return {
		BUSINESS: FIRST_PARTY_CCP_PRODUCT_IDS[env].jira_work_management_cloud,
		SOFTWARE: FIRST_PARTY_CCP_PRODUCT_IDS[env].jira_software_cloud,
		SERVICE_DESK: FIRST_PARTY_CCP_PRODUCT_IDS[env].jira_service_management_cloud,
		PRODUCT_DISCOVERY: FIRST_PARTY_CCP_PRODUCT_IDS[env].jira_product_discovery,
	};
};
