import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers';

const store = createLocalStorageProvider('third-party-nudge');
const HAS_SEEN_NUDGE_KEY = 'has-seen-nudge';

export const setHasSeenNudge = () => {
	store.set(HAS_SEEN_NUDGE_KEY, true);
};

export const getHasSeenNudge = () => store.get(HAS_SEEN_NUDGE_KEY) || false;
