import { getConditional } from '@atlassian/jira-onboarding-core';
import type { Cards, Conditional } from '../../../common/types';

const getTaskVisibility = (variants: string[], visibility?: Conditional<boolean>): boolean =>
	visibility !== undefined ? getConditional(visibility, variants) : true;

const getTaskPosition = (variants: string[], position: Conditional<number>): number =>
	getConditional(position, variants);

export const getTasks = (variants: string[], taskVariants: Cards, taskList: string[]): string[] =>
	taskList
		.filter((task) => getTaskVisibility(variants, taskVariants[task].visibility))
		.sort(
			(taskA, taskB) =>
				getTaskPosition(variants, taskVariants[taskA].position) -
				getTaskPosition(variants, taskVariants[taskB].position),
		);
