import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { AtlassianNavigation as AkAtlassianNavigation } from '@atlaskit/atlassian-navigation';
import { token } from '@atlaskit/tokens';
import AsyncAtlaskitAtlassianNavigationNav4 from '@atlassian/jira-atlassian-navigation-nav4/src/async';
import {
	NAVIGATION_TOP_MARKS,
	NavTopPerformanceMark,
	NavTopPerformanceReport,
	useNavPerformanceMetrics,
} from '@atlassian/jira-atlassian-navigation-performance-metrics';
import {
	RecommendationLoader,
	recommendations,
} from '@atlassian/jira-atlassian-navigation-recommendations/src/ui';
import AsyncCustomThemeAdminFlag from '@atlassian/jira-custom-theme-admin-flag/src/async';
import { FavoritesContainer } from '@atlassian/jira-favorites-store';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useHideTopNavForLockScreen } from '@atlassian/jira-lock-screen-experiment-common';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common';
import { EditionAwareness } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import AsyncNav4RolloutAnalytics from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-analytics/src/async';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { RecentsContainer } from '@atlassian/jira-recents-store';
import {
	useApplicationPermissions,
	useCloudId,
	useIsAnonymous,
	useIsSiteAdmin,
} from '@atlassian/jira-tenant-context-controller';
import { NavigationSuccessTracker } from '../controllers/navigation-success-tracker';
import { useTheme } from '../controllers/theme';
import { Addons } from './addons';
import { AppSwitcher } from './app-switcher';
import { ConversationAssistant } from './conversation-assistant';
import { Create } from './create';
import { Dashboards } from './dashboards';
import { Filters } from './filters';
import { Help } from './help';
import { InitialDataLoader } from './initial-data-loader';
import { Insight, useShowAssets } from './insight';
import { Integrations } from './integrations';
import messages from './messages';
import { Notifications } from './notifications';
import { People } from './people';
import { OpenOverflowMenuForPlansSpotlightObserver, Plans } from './plans';
import { ProductHome } from './product-home';
import { Profile } from './profile';
import { Projects } from './projects';
import { Roadmaps, useShowRoadmaps } from './roadmaps';
import { NavigationRouterListener } from './router-listener';
import { Search } from './search';
import { Settings } from './settings';
import { SignIn } from './sign-in';
import { YourWorkDropdown } from './your-work-dropdown';

/*
    The DiscoverMore component is from experiment TBLZ-197, so rather than updating the Atlassian Navigation
    package we are extending the renderNotifications prop for now.
    If this experiment is productionised, we will move the code into the appropriate location.
*/
const RenderNotificationsWithConversationAssistant = () => (
	<>
		<ConversationAssistant />
		<Notifications />
	</>
);

const SearchWithoutProps = () => <Search />;

const EditionAwarenessAndSearch = () => (
	<>
		<EditionAwarenessWrapper>
			<EditionAwareness />
		</EditionAwarenessWrapper>
		<SearchWithoutProps />
	</>
);

const isSPA = () => {
	if (__SERVER__) return false;
	if (typeof window !== 'undefined') return window.__SPA__;
	return false;
};

// @ts-expect-error - TS2555 - Expected at least 1 arguments, but got 0.
const testIdPrefix = testIdGenerate();

export const AtlassianNavigation = () => {
	const topNavSegment = useNavPerformanceMetrics();
	topNavSegment.mark(NAVIGATION_TOP_MARKS.renderStart);

	const theme = useTheme();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const applicationPermissions = useApplicationPermissions();
	const isAnonymous = useIsAnonymous();
	const isSiteAdmin = useIsSiteAdmin();
	const isScreenLocked = useHideTopNavForLockScreen();
	const showAssets = useShowAssets();
	const showRoadmaps = useShowRoadmaps();

	const PrimaryMenuItems = useMemo(
		() =>
			[
				<OpenOverflowMenuForPlansSpotlightObserver key="arj-open-overflow-menu-for-plans-spotlight-observer" />,
				!isAnonymous && <YourWorkDropdown key="yourWorkDropdown" />,
				<Projects key="projects" />,
				<Filters key="filters" />,
				<Dashboards key="dashboards" />,
				<People key="people" />,
				<Plans key="plans" />,
				showRoadmaps && <Roadmaps key="roadmaps" />,
				showAssets && <Insight key="insight" />,
				<Addons key="addons" />,
			].filter(Boolean),
		[isAnonymous, showAssets, showRoadmaps],
	);

	const renderNavigation = useCallback(
		() => (
			<>
				{getWillShowNav4() ? (
					<AsyncAtlaskitAtlassianNavigationNav4
						label={formatMessage(messages.topNav)}
						renderAppSwitcher={AppSwitcher}
						renderProductHome={ProductHome}
						renderSearch={SearchWithoutProps}
						renderCreate={Create}
						renderEditionAwareness={EditionAwareness}
						renderHelp={Help}
						renderNotifications={Notifications}
						renderConversationAssistant={ConversationAssistant}
						renderSettings={Settings}
						renderProfile={Profile}
						renderSignIn={SignIn}
						theme={theme}
					/>
				) : (
					<AkAtlassianNavigation
						primaryItems={PrimaryMenuItems}
						label={formatMessage(messages.topNav)}
						moreLabel={formatMessage(messages.more)}
						renderAppSwitcher={AppSwitcher}
						renderCreate={Create}
						renderHelp={Help}
						renderNotifications={RenderNotificationsWithConversationAssistant}
						renderProductHome={ProductHome}
						renderProfile={Profile}
						renderSearch={EditionAwarenessAndSearch}
						renderSettings={Settings}
						renderSignIn={SignIn}
						theme={theme}
						testId={testIdPrefix}
					/>
				)}
				{!__SERVER__ && (
					<>
						{!isAnonymous && !ff('navigation.disable.prefetched.data.for.dropdowns') && (
							<>
								<InitialDataLoader />
								<RecommendationLoader recommendations={recommendations} />
							</>
						)}
						<Integrations />
					</>
				)}
			</>
		),
		[PrimaryMenuItems, formatMessage, isAnonymous, theme],
	);

	const route = useCurrentRoute();
	const routeName = route.name ?? route.group ?? 'UNKNOWN';
	const projectContext = useProjectContext();
	const customData = useMemo(() => {
		if (fg('blu-2183_navtopperformancereport_custom_data'))
			return {
				route: routeName,
				projectType: projectContext?.data?.projectType ?? 'UNKNOWN',
				isSPA: isSPA(),
			};

		return undefined;
	}, [routeName, projectContext]);

	if (isScreenLocked) return null;

	return (
		<>
			<NavigationSuccessTracker>
				{isAnonymous ? (
					renderNavigation()
				) : (
					<RecentsContainer
						isGlobal
						cloudId={cloudId}
						applicationPermissions={applicationPermissions}
					>
						<FavoritesContainer isGlobal applicationPermissions={applicationPermissions}>
							{renderNavigation()}
						</FavoritesContainer>
					</RecentsContainer>
				)}
			</NavigationSuccessTracker>
			<NavigationRouterListener />
			<NavTopPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderEnd} />
			<NavTopPerformanceReport customData={customData} />
			{isSiteAdmin && <AsyncCustomThemeAdminFlag />}
			{fg('jira_nav4') && <AsyncNav4RolloutAnalytics />}
		</>
	);
};

export default AtlassianNavigation;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditionAwarenessWrapper = styled.div({
	marginRight: token('space.200', '16px'),

	// On screen widths < 680px, hide the Edition Awareness component
	'@media (max-width: 680px)': {
		display: 'none',
	},
});
