import {
	JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK,
	JSM_REVIEW_YOUR_CHANNELS_TASK,
	JSM_INVITE_YOUR_TEAM_TASK,
} from '../../../../common/constants';
import type { Cards, TaskList } from '../../../../common/types';
import { messages } from './messages';

export const JSM_TASK_LIST: TaskList = Object.freeze({
	root: {
		title: messages.rootTitle,
		items: [
			JSM_REVIEW_YOUR_CHANNELS_TASK,
			JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK,
			JSM_INVITE_YOUR_TEAM_TASK,
		],
	},
});

export const Experiments = {
	projectTheming: 'projectTheming',
} as const;

export const UserRole = {
	projectAdmin: 'projectAdmin',
	jiraAdmin: 'jiraAdmin',
	siteAdmin: 'siteAdmin',
} as const;

export const JSM_TASK_VARIANTS: Cards = {
	[JSM_REVIEW_YOUR_CHANNELS_TASK]: {
		position: { default: 0 },
		visibility: {
			default: true,
		},
	},
	[JSM_CUSTOMIZE_YOUR_REQUEST_TYPES_TASK]: {
		position: { default: 1 },
		visibility: {
			default: true,
		},
	},
	[JSM_INVITE_YOUR_TEAM_TASK]: {
		position: { default: 2 },
		visibility: {
			default: true,
		},
	},
};
