import React from 'react';
import { CUSTOM_FIELD_MODULE, ENTRY_POINT_EDIT } from '@atlassian/jira-forge-ui-constants';
import { ForgeAnalyticsWrapper } from '../../common/ui';
import type {
	AnalyticsWrapperPropsTrigger,
	AnalyticsWrapperPropsView,
} from '../../common/ui/types';

export const ViewAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper module={CUSTOM_FIELD_MODULE} shouldFireInitializedEvent {...props} />
);

export const EditAnalyticsWrapper = (props: AnalyticsWrapperPropsTrigger) => (
	<ForgeAnalyticsWrapper module={CUSTOM_FIELD_MODULE} entryPoint={ENTRY_POINT_EDIT} {...props} />
);
