import React, { useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Section, SkeletonHeadingItem, SkeletonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { getGlobalSettingsAdminItems } from '@atlassian/jira-atlassian-navigation-global-settings-home';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout';
import { ContextualAnalyticsData, BANNER } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import {
	PREMIUM_EDITION,
	SERVICE_DESK,
	STANDARD_EDITION,
	getEditionForProject,
} from '@atlassian/jira-shared-types';
import {
	useApplicationPermissions,
	useIsAdmin,
	useIsSiteAdmin,
	useAppEditions,
	useTenantContext,
} from '@atlassian/jira-tenant-context-controller';
import { EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../common/constants';
import { MenuLayout } from '../../../common/ui/menu/layout';
import { useManageUsersPermission } from '../../../controllers/manage-users-permission';
import { useShouldShowPremiumUpsellBanner } from '../../../controllers/premium-upsell-data';
import { Home } from './home';
import { UpsellJsmStandardToPremiumBlendScreen } from './jsm-upsell-hook';
import messages from './messages';
import { Personal } from './personal';
import { PremiumUpsell } from './premium-upsell';
import { Warning } from './warning';

export type MenuProps = {
	testIdPrefix?: string;
	titleId: string;
};

export const Menu = ({ testIdPrefix, titleId }: MenuProps) => {
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const appPermissions = useApplicationPermissions();
	const manageUsersPermission = useManageUsersPermission();
	const premiumUpsellExperimentCohort = expVal(
		'premium-upsell-experiment',
		'cohort',
		'not-enrolled',
	);

	// ff to show premium upsell trial end date screen when experiment ends and user is still on premium trial
	const isPremiumUpsellTrialEndDateEnabled = fg('premium-upsell-trial-end-date');
	const willShowNav4 = getWillShowNav4();

	const settingsNavigationItems = useMemo(
		() =>
			getGlobalSettingsAdminItems(
				formatMessage,
				appPermissions,
				{
					isAdmin,
					isSiteAdmin,
					manageUsersPermission,
				},
				willShowNav4,
			),
		[formatMessage, appPermissions, isAdmin, isSiteAdmin, manageUsersPermission, willShowNav4],
	);

	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});

	useEffect(() => {
		onSuccess();
	}, [onSuccess]);

	const testId = testIdConcat(testIdPrefix, 'menu-popup');
	const testIdHeader = testIdConcat(testId, 'header');

	if (willShowNav4) {
		return (
			<span data-testid={testId}>
				<MenuLayout isWide>
					<Section>
						<Personal testIdPrefix={testId} />

						{settingsNavigationItems.length > 0 && (
							<Home settingsDrawerItems={settingsNavigationItems} testIdPrefix={testId} />
						)}
						{manageUsersPermission.loading ? (
							<>
								<SkeletonHeadingItem testId={`${testId}--loading-skeleton--heading`} isShimmering />
								<SkeletonItem testId={`${testId}--loading-skeleton--item`} isShimmering hasIcon />
							</>
						) : (
							settingsNavigationItems.length === 0 && <Warning testIdPrefix={testId} />
						)}
					</Section>
				</MenuLayout>
			</span>
		);
	}

	return (
		<span data-testid={testId}>
			<MenuLayout isWide>
				<Section>
					<SettingsTitle
						{...(testIdHeader != null ? { 'data-testid': testIdHeader } : {})}
						id={titleId}
					>
						{formatMessage(messages.settings)}
					</SettingsTitle>

					<Personal testIdPrefix={testId} />

					{settingsNavigationItems.length > 0 && (
						<Home settingsDrawerItems={settingsNavigationItems} testIdPrefix={testId} />
					)}
					{manageUsersPermission.loading ? (
						<>
							<SkeletonHeadingItem testId={`${testId}--loading-skeleton--heading`} isShimmering />
							<SkeletonItem testId={`${testId}--loading-skeleton--item`} isShimmering hasIcon />
						</>
					) : (
						settingsNavigationItems.length === 0 && <Warning testIdPrefix={testId} />
					)}
				</Section>
				{(premiumUpsellExperimentCohort === 'variation' ||
					premiumUpsellExperimentCohort === 'experiment' ||
					isPremiumUpsellTrialEndDateEnabled) && <PremiumUpsellExp />}
			</MenuLayout>
		</span>
	);
};

const getUpsellComponent = (
	isSiteAdmin: boolean,
	isServiceDeskProjectType: boolean | undefined,
	edition: string | undefined,
	premiumUpsellExperimentCohort: string,
	isUsingCcp: boolean,
	onPremiumTrial: boolean,
	trialEndDateCcp: number | undefined,
	trialEndDateHams: number | undefined,
) => {
	// ff to show premium upsell trial end date screen when experiment ends and user is still on premium trial
	const isPremiumUpsellTrialEndDateEnabled = fg('premium-upsell-trial-end-date');
	if (isSiteAdmin && isServiceDeskProjectType) {
		if (premiumUpsellExperimentCohort === 'variation') {
			if (edition === STANDARD_EDITION) {
				return <PremiumUpsell isUsingCcp={isUsingCcp} />;
			}
			if (onPremiumTrial) {
				return (
					<UpsellJsmStandardToPremiumBlendScreen
						onPremiumTrial={onPremiumTrial}
						isUsingCcp={isUsingCcp}
						trialEndDate={isUsingCcp ? trialEndDateCcp : trialEndDateHams}
					/>
				);
			}
		}
		if (premiumUpsellExperimentCohort === 'experiment') {
			if (edition === STANDARD_EDITION || onPremiumTrial) {
				return (
					<UpsellJsmStandardToPremiumBlendScreen
						onPremiumTrial={onPremiumTrial}
						isUsingCcp={isUsingCcp}
						trialEndDate={isUsingCcp ? trialEndDateCcp : trialEndDateHams}
					/>
				);
			}
		}
		if (isPremiumUpsellTrialEndDateEnabled && onPremiumTrial) {
			return (
				<UpsellJsmStandardToPremiumBlendScreen
					onPremiumTrial={onPremiumTrial}
					isUsingCcp={isUsingCcp}
					trialEndDate={isUsingCcp ? trialEndDateCcp : trialEndDateHams}
				/>
			);
		}
	}
};

const PremiumUpsellExp = () => {
	const isSiteAdmin = useIsSiteAdmin();
	const appEditions = useAppEditions();
	const { data: projectContext } = useProjectContext();

	const { productEntitlementDetails, cloudId } = useTenantContext();
	const entitlementDetails = productEntitlementDetails?.[SERVICE_DESK];
	const billingSourceSystem = entitlementDetails?.billingSourceSystem || 'HAMS';
	const isUsingCcp = billingSourceSystem === 'CCP';

	const projectType = projectContext?.projectType;
	const isServiceDeskProjectType = projectType && projectType === SERVICE_DESK_PROJECT;
	const edition = projectType && getEditionForProject(projectType, appEditions);
	const premiumUpsellExperimentCohort = expVal(
		'premium-upsell-experiment',
		'cohort',
		'not-enrolled',
	);

	// ff to show premium upsell trial end date screen when experiment ends and user is still on premium trial
	const isPremiumUpsellTrialEndDateEnabled = fg('premium-upsell-trial-end-date');
	const [{ trialEndDateHams, isTrialEndDateHamsSet }, { getTrialEndDateHams }] =
		useShouldShowPremiumUpsellBanner();
	useEffect(() => {
		if (
			isSiteAdmin &&
			isServiceDeskProjectType &&
			!isTrialEndDateHamsSet &&
			billingSourceSystem === 'HAMS' &&
			(premiumUpsellExperimentCohort === 'variation' ||
				premiumUpsellExperimentCohort === 'experiment' ||
				isPremiumUpsellTrialEndDateEnabled)
		) {
			getTrialEndDateHams({ cloudId });
		}
	}, [
		getTrialEndDateHams,
		cloudId,
		billingSourceSystem,
		isTrialEndDateHamsSet,
		premiumUpsellExperimentCohort,
		isSiteAdmin,
		isServiceDeskProjectType,
		isPremiumUpsellTrialEndDateEnabled,
	]);

	const trialEndDateCcp = entitlementDetails?.trialEndTime;
	let onPremiumTrial = false;
	if (
		(billingSourceSystem === 'CCP' && trialEndDateCcp) ||
		(billingSourceSystem === 'HAMS' && trialEndDateHams)
	) {
		onPremiumTrial = edition === PREMIUM_EDITION;
	}

	return (
		<ContextualAnalyticsData
			sourceName="PremiumUpsell"
			sourceType={BANNER}
			attributes={{
				premiumUpsellExperimentCohort,
				edition,
				billingSourceSystem: isUsingCcp ? 'CCP' : 'HAMS',
			}}
		>
			{getUpsellComponent(
				isSiteAdmin,
				isServiceDeskProjectType,
				edition,
				premiumUpsellExperimentCohort,
				isUsingCcp,
				onPremiumTrial,
				trialEndDateCcp,
				trialEndDateHams,
			)}
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SettingsTitle = styled.h2({
	fontSize: token('font.heading.medium'),
	lineHeight: 'initial',
	letterSpacing: '-0.008em',
	padding: `${token('space.300', '24px')} ${token('space.300', '24px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.250', '20px')}
    `,
});
