import React, { useCallback, useEffect, useMemo } from 'react';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { useAddon } from '@atlassian/jira-addon-store';
import { NAVIGATION_TOP_DROPDOWN_MARKS } from '@atlassian/jira-atlassian-navigation-performance-metrics';
import { useExperienceStart } from '@atlassian/jira-experience-tracker';
import { ff } from '@atlassian/jira-feature-flagging';
import { useForgeGlobalItems } from '@atlassian/jira-forge-global-items-store';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { NavigationAppsNavItemWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/async';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller';
import { EXPERIENCE_NAVIGATION_TOP_MENU, NAVIGATION_ITEM_ID } from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { FullHeightTextColorWrapper } from '../../common/ui/nudges/styled';
import { Popup } from '../../common/ui/popup';
import { usePersonalisedAppsTeamType } from '../../controllers/personalised-apps-team-type';
import { AsyncMenu } from './menu/async';
import messages from './messages';

const AddonsView = () => {
	const { key: projectIdOrKey } = useProjectContext();
	const [, { load: loadItem, setContext }] = useAddon();
	const [, { load: loadForgeItem }] = useForgeGlobalItems();

	useEffect(() => {
		setContext({ projectIdOrKey });
	}, [projectIdOrKey, setContext]);

	const [
		{ hasSuccessOnce: hasSuccessOnceUserSegmentation },
		{ loadPersonalisedAppsUserSegmentation },
	] = usePersonalisedAppsTeamType();

	const { activationId, cloudId } = useTenantContext();

	const { formatMessage } = useIntl();
	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.ADDONS,
		analyticsSource: 'atlassian-navigation',
	});

	const onBeforeMenuOpen = useCallback(() => {
		onStart();

		loadItem();
		if (ff('arken-1053-jira-forge-extension-changes-for-aegis_aws9x')) {
			loadForgeItem(cloudId, activationId);
		} else {
			loadForgeItem(cloudId);
		}
		!hasSuccessOnceUserSegmentation && loadPersonalisedAppsUserSegmentation();

		NAVIGATION_TOP_DROPDOWN_MARKS.ADDONS.start();
	}, [
		onStart,
		loadItem,
		hasSuccessOnceUserSegmentation,
		loadPersonalisedAppsUserSegmentation,
		loadForgeItem,
		cloudId,
		activationId,
	]);

	const attributes = useMemo(
		() => ({
			isEmbeddedMarketplaceEnabledForEndUsers: ff('enduser-emcee-discover'),
			isEmbeddedMarketplaceSpaEnabled: ff('marketplace.emcee.native.spa.enable'),
		}),
		[],
	);

	return (
		<ContextualAnalyticsData attributes={attributes}>
			<Popup
				menuId={MENU_ID.ADDONS}
				navigationItemId={NAVIGATION_ITEM_ID.ADDONS}
				Menu={AsyncMenu}
				onBeforeMenuOpen={onBeforeMenuOpen}
				label={formatMessage(messages.apps)}
			>
				{formatMessage(messages.apps)}
			</Popup>
		</ContextualAnalyticsData>
	);
};

export const Addons = () => {
	const { isVisible } = useOverflowStatus();
	return (
		<TopLevelErrorBoundary id={MENU_ID.ADDONS}>
			{!__SERVER__ ? (
				<NavigationAppsNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
					<FullHeightTextColorWrapper>
						<AddonsView />
					</FullHeightTextColorWrapper>
				</NavigationAppsNavItemWrapperNudgeAsync>
			) : (
				<AddonsView />
			)}
		</TopLevelErrorBoundary>
	);
};
