import React, { useMemo } from 'react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import Tooltip from '@atlaskit/tooltip';
import { getAdminBaseUrl, WAC_URL } from '@atlassian/jira-external-urls';
import { useIntl } from '@atlassian/jira-intl';
import {
	isEligibleForJsmJpdTrialAwarenessV2,
	isInVariationForJsmJpdTrialAwarenessV2,
	isEligibleForJswTrialAwarenessV2,
	isInVariationForJswTrialAwarenessV2,
} from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-trial-modal';
import { useLoggedInPage } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness-utils';
import { FireUiAnalytics, ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types';
import {
	useCloudId,
	useEnvironment,
	useIsSiteAdmin,
	useLocale,
} from '@atlassian/jira-tenant-context-controller';
import { EATopNavAnimatedButtonContainer } from '../../../common/ui/styled';
import { BillingDetailsPopup, type TriggerProps } from './billing-details-popup';
import { productAbbreviations } from './constants';
import messages from './messages';
import { TriggerButton } from './trigger-button';
import type { TrialPillButtonProps } from './types';
import { getTrialDaysLeft, isInGracePeriod } from './utils';

export const TrialPillButton = ({
	productKey,
	projectType,
	edition,
	entitlementDetails,
	invoiceGroupId,
	transactionAccountId,
	isBillingAdmin,
}: TrialPillButtonProps) => {
	const { formatMessage } = useIntl();
	const locale = useLocale();
	const environment = useEnvironment();
	const cloudId = useCloudId();
	const isSiteAdmin = useIsSiteAdmin();
	const { trialEndTime, billingSourceSystem } = entitlementDetails;

	const currentDate = useMemo(() => Date.now(), []);
	const trialDaysLeft = getTrialDaysLeft(currentDate, trialEndTime);
	const inGracePeriod = isInGracePeriod(currentDate, trialEndTime);

	const isEnglishLocale = locale.startsWith('en');
	const isJswStandard = productKey === 'jira-software' && edition === STANDARD_EDITION;

	const isEligibleForJsmJpdTrialAwarenessV2Experiment =
		isEligibleForJsmJpdTrialAwarenessV2(projectType);

	const isJsmJpdTrialAwarenessV2ExperimentEnabled =
		isEligibleForJsmJpdTrialAwarenessV2Experiment && isInVariationForJsmJpdTrialAwarenessV2();

	const isEligibleForJswTrialAwarenessV2Experiment = isEligibleForJswTrialAwarenessV2(
		projectType,
		edition,
	);

	const isJswTrialAwarenessV2ExperimentEnabled =
		isEligibleForJswTrialAwarenessV2Experiment && isInVariationForJswTrialAwarenessV2();

	// I wanted to add a comment to this condition, but comment would be longer then the component itself so I gave up ...
	const jstoTarget =
		!!isBillingAdmin &&
		!inGracePeriod &&
		isSiteAdmin &&
		isJswStandard &&
		!isJswTrialAwarenessV2ExperimentEnabled;

	const rawJstoUrl = `${
		WAC_URL[environment]
	}/software/jira?ref=edition_awareness_standard_trial_${billingSourceSystem.toLowerCase()}&cloudSiteId=${cloudId}`;
	const jstoUrl = useLoggedInPage({ pageUrl: rawJstoUrl });

	const addBillingDetailsUrl =
		entitlementDetails?.billingSourceSystem === 'CCP'
			? `${getAdminBaseUrl()}/billing/${transactionAccountId}/${invoiceGroupId}/payment-flow?referrer=${productAbbreviations[productKey]}`
			: `${getAdminBaseUrl()}/s/${cloudId}/billing/paymentdetails/add`;

	const tooltipMessage = formatMessage(
		inGracePeriod ? messages.addPaymentDetailsTooltip : messages.trialButtonTooltip,
	);

	const analyticsAttributes = useMemo(
		() => ({
			edition,
			productKey,
			projectType,
			isEnglishLocale,
			entitlementDetails,
			transactionAccountId,
			invoiceGroupId,
			isBillingAdmin,
		}),
		[
			edition,
			entitlementDetails,
			invoiceGroupId,
			isEnglishLocale,
			productKey,
			projectType,
			transactionAccountId,
			isBillingAdmin,
		],
	);

	const triggerButtonProps = useMemo(
		() => ({
			trialDaysLeft,
			inGracePeriod,
			jstoUrl: jstoTarget ? jstoUrl : undefined,
			addBillingDetailsUrl,
			analyticsAttributes,
			productKey,
			projectType,
			edition,
		}),
		[
			trialDaysLeft,
			inGracePeriod,
			jstoTarget,
			jstoUrl,
			addBillingDetailsUrl,
			analyticsAttributes,
			productKey,
			projectType,
			edition,
		],
	);

	let content: null | JSX.Element = null;

	if (jstoTarget || inGracePeriod) {
		content = (
			<Tooltip content={tooltipMessage} position="bottom">
				{(tooltipProps) => (
					<TriggerButton
						{...triggerButtonProps}
						triggerProps={tooltipProps}
						// We must ensure isEligibleForJswTrialAwarenessModalV2 will always be false when inGracePeriod
						// but we still need to check it when jstoTarget is true as user might be in control group.
						isEligibleForJswTrialAwarenessModalV2={
							!inGracePeriod && isEligibleForJswTrialAwarenessV2Experiment
						}
					/>
				)}
			</Tooltip>
		);
	} else if (isJsmJpdTrialAwarenessV2ExperimentEnabled || isJswTrialAwarenessV2ExperimentEnabled) {
		content = (
			<TriggerButton
				{...triggerButtonProps}
				isEligibleForJsmJpdTrialAwarenessModalV2={isEligibleForJsmJpdTrialAwarenessV2Experiment}
				isEligibleForJswTrialAwarenessModalV2={isEligibleForJswTrialAwarenessV2Experiment}
			/>
		);
	} else {
		content = (
			<BillingDetailsPopup
				analyticsAttributes={analyticsAttributes}
				edition={edition}
				inGracePeriod={inGracePeriod}
				addBillingDetailsUrl={addBillingDetailsUrl}
			>
				{(triggerProps: TriggerProps) => (
					<TriggerButton
						{...triggerButtonProps}
						{...triggerProps}
						isEligibleForJsmJpdTrialAwarenessModalV2={isEligibleForJsmJpdTrialAwarenessV2Experiment}
						isEligibleForJswTrialAwarenessModalV2={isEligibleForJswTrialAwarenessV2Experiment}
					/>
				)}
			</BillingDetailsPopup>
		);
	}

	if (content !== null) {
		return (
			<ContextualAnalyticsData attributes={{ isSiteAdmin, isBillingAdmin }}>
				<SpotlightTarget name="edition-awareness-trial-pill">
					<EATopNavAnimatedButtonContainer>
						{content}
						<FireUiAnalytics
							action="viewed"
							actionSubject="button"
							actionSubjectId="editionAwarenessTrialPill"
							attributes={analyticsAttributes}
						/>
					</EATopNavAnimatedButtonContainer>
				</SpotlightTarget>
			</ContextualAnalyticsData>
		);
	}
	return null;
};
