/**
 * @generated SignedSource<<e255cf3013a7ce8250a609ddf75552a7>>
 * @relayHash d544cdd2979e6cb27ef690146df66d84
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2fdb5a6382858635ab5da13fa22046a4f68383872329b6a08688ec6a38e4922d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery } from './uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery.graphql';

const node: PreloadableConcreteRequest<uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2fdb5a6382858635ab5da13fa22046a4f68383872329b6a08688ec6a38e4922d",
    "metadata": {},
    "name": "uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
