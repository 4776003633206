import {
	type CommandActionCommandList,
	type CommandInternal,
	CommandActionType,
} from '../../types';

export const getActiveCommandList = (
	expandedCommand: CommandInternal | undefined,
	wasSecondaryTriggered?: boolean,
): CommandActionCommandList | null => {
	if (
		wasSecondaryTriggered &&
		expandedCommand?.secondaryAction?.type === CommandActionType.COMMAND_LIST
	) {
		return expandedCommand.secondaryAction;
	}
	if (
		!wasSecondaryTriggered &&
		expandedCommand?.primaryAction?.type === CommandActionType.COMMAND_LIST
	) {
		return expandedCommand.primaryAction;
	}
	return null;
};
