import type { Action } from 'react-sweet-state';
import type { TaskList } from '../../../common/types';
import type { State, ProjectState, PersonaExperience } from '../../model/types';
import { getAllTasks } from '../../tasks';

type NextGetTasksProps = {
	isDevOpsProject: boolean;
	shouldShowScrumOnboardingExperience: boolean;
	canCreateProject: boolean;
	routeName: string;
	projectState: ProjectState | null;
	taskGroupParent: string;
	mountedNudges: string[];
	isEligibleToUpdateSitename: boolean;
	isCustomerTemplateProject: boolean;
	isCrossProjectBoardToPlanUpsell: boolean;
	canEditPlans: boolean;
	premiumTrialOnSignup: boolean | undefined;
	personaExperience?: PersonaExperience;
	taskList?: TaskList;
};

export const nextGetTasks =
	(props: NextGetTasksProps): Action<State> =>
	({ getState, setState }) => {
		const { taskList } = getState();
		const tasks = getAllTasks({ ...props, taskList });
		setState({ tasks });
	};
