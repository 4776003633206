import { createHook, createStore } from 'react-sweet-state';
import { actions } from './actions';
import type { State } from './types';

export type Actions = typeof actions;

const initialState: State = {
	isLoading: false,
	isSuccess: false,
	error: null,
	promise: null,
	teamType: undefined,
	hasSuccessOnce: false,
};

const store = createStore({
	actions,
	initialState,
	name: 'atlassian-navigation-addons-personalised-apps-team-type',
});

export const usePersonalisedAppsTeamType = createHook(store);
export const usePersonalisedAppsTeamTypeActions = createHook(store, {
	selector: null,
});
