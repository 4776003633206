import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import {
	useActiveRightSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller';
import {
	PANEL_ID,
	PANEL_WIDTH,
} from '@atlassian/jira-onboarding-checklist-next/src/common/constants';
import {
	useJswOnboarding,
	useQuickstartUiStatus,
} from '@atlassian/jira-onboarding-quickstart-core';
import { QUICKSTART_UI_STATUS } from '@atlassian/jira-onboarding-quickstart-core/src/services';
import { fireAnalyticsOnOpenRightHandSidebar } from '@atlassian/jira-onboarding-quickstart-core/src/utils/analytics';
import type { TriggersToOpenChecklist } from '../../common/types';

// We tend to combine flows of openChecklist from both minimised and dismissed

type OpenChecklistProps = {
	trigger: TriggersToOpenChecklist;
	openTask?: string;
};

export const useOpenChecklist = () => {
	const [
		{ isDismissed, openTask: currentOpenTask },
		{ nextSetChecklistIsOpen, nextSetChecklistIsDismissed, nextOpenTask },
	] = useJswOnboarding();
	const { openRightSidebar } = useRightSidebarController();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [quickstartStatus] = useQuickstartUiStatus();
	const currentRightSidebarState = useActiveRightSidebarState();

	const currentPanelId = currentRightSidebarState?.panelId;

	const openChecklist = ({ trigger, openTask }: OpenChecklistProps) => {
		if (openTask && openTask !== currentOpenTask) {
			nextOpenTask(openTask);
		}

		openRightSidebar(PANEL_ID, PANEL_WIDTH, false, true);

		fireAnalyticsOnOpenRightHandSidebar(createAnalyticsEvent, {
			quickstartStatus,
			currentRightSidebarPanelId: currentPanelId || '',
			openRightSidebarToTop: true,
			trigger,
		});
		if (
			expValEquals('jsm_business_quickstart', 'cohort', 'experiment')
				? quickstartStatus === QUICKSTART_UI_STATUS.DISMISSED
				: isDismissed
		) {
			nextSetChecklistIsDismissed(false);
		} else {
			nextSetChecklistIsOpen(true);
		}
	};

	return openChecklist;
};
